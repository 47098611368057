import Modal from 'react-bootstrap/Modal'
import moment from 'moment'

const DuplicateReserveModal = (props: any) => {
  const handleClose = () => {
    props.handleClose()
  }

  const overrideCheckout = () => {
    const newData = {
      ...props.data,
      overrideReservation: true,
    }
    props?.handleLeaseSubmit(newData)
  }

  return (
    <Modal
      className='custom-modal-lg px-2'
      show={props.show}
      onHide={handleClose}
      backdrop='static'
      size='lg'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Already Reserved</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>Following assets are reserved:</p>
          <ul className='list-unstyled already-reserved-assets'>
            {props?.reservedData &&
              props?.reservedData?.length > 0 &&
              props?.reservedData?.map((item: any) => {
                return (
                  <li className='border p-4 mb-1'>
                    <p className='mb-0'>
                      <strong>{item?.assetTagId}</strong> is reserved by{' '}
                      <span>{item?.reserverBy}</span> for{' '}
                      {moment(item?.startDate).format('MMM DD, YYYY')} to{' '}
                      {moment(item?.endDate).format('MMM DD, YYYY')}.
                    </p>
                  </li>
                )
              })}
          </ul>
          <p>Please select different dates. Refer to the calendar for available dates.</p>
        </div>
        <div className=' d-flex align-items-center justify-content-end'>
          <button className='btn btn-light-primary   main-btn-style me-2' onClick={handleClose}>
            Close
          </button>
          <button
            type='button'
            onClick={overrideCheckout}
            className='btn btn-primary main-btn-style'
          >
            Override Reservation and Lease
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DuplicateReserveModal
