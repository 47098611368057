export type Customer = {
  firstName: string
  lastName: string
  company?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
  phone?: string
  cell?: string
  email?: string
  notes?: string
}

export const initialCustomers: Customer = {
  firstName: '',
  lastName: '',
  company: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  cell: '',
  email: '',
  notes: '',
}

export type CustomerResponse = {
  id: number
  firstName: string
  lastName: string
  company?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
  phone?: string
  cell?: string
  email?: string
  notes?: string
}
