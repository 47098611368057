import { useEffect, useState } from 'react'
import { PageTitle } from 'Template/layout/core'
import { AssetsReportHeader } from '../AssetsReportHeader'
import SetupCoulmnListing from 'App/Modules/Reports/AssetReports/Components/SetupCoulmnListing'
import AssetColumns from '../../../../../../../Data/AssetColumns.json'
import { useGetAllAssetsReportsQuery } from 'App/Modules/Services/AssetsReports'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { useAuth } from 'App/Modules/Auth'
import { currentUserAssets } from 'config'
import { toAbsoluteUrlImage } from 'Template/helpers'
import SetupColumns from '../../../Asset/Components/SetupColumns'

function ReportByAssetPicture() {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const [assets, setAssets] = useState<any>([])
  const [showSetupColumns, setShowSetupColumns] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedColumns, setSelectedColumns] = useState<{ key: any; value: any }[]>(AssetColumns)
  const selectedColumnsRender = selectedColumns.filter((column) => column.value === true)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [skip, setSkip] = useState<boolean>(false)
  const [exportButton, setExportButton] = useState<boolean>(false)
  const [toastRendered, setToastRendered] = useState(false)
  const [pagination, setPagination] = useState<any>({})

  const location = useLocation()
  const { pathname } = location
  const [searchAssets, setSearchAssets] = useState<any>({
    keyword: '',
    searchField: '',
    siteId: '',
    locationId: '',
    categoryId: '',
    departmentId: '',
    person: '',
    customer: '',
    status: '',
    groupBy: '',
    recordCount: '',
    dateRangeBy: 'purchasedDate',
    quickDateRange: '',
    startDate: '',
    endDate: '',
    exportToExcel: false,
    requestUrl: pathname,
  })
  const {
    data: companyData,
    isSuccess: company,
    isLoading: isLoadingGetCompany,
    refetch: refetchComapnyData,
  } = useGetCompanyQuery({})
  const excelKeys = selectedColumnsRender.map((column) => column.key.replace(/_/g, ' '))
  const {
    data: assetsData,
    isLoading: isLoadingGetAssets,
    error,
    isSuccess,
    isError,
  } = useGetAllAssetsReportsQuery(
    { body: new URLSearchParams(searchAssets).toString(), page, limit, keys: excelKeys },
    { skip }
  )
  useEffect(() => {
    if (currentUser && assetsData?.userAssets?.length > 0) {
      setAssets(currentUserAssets(currentUser, assetsData))
      setPagination(assetsData?.pagination)
      setSkip(true)
      setLoading(false)
    }
  }, [assetsData])
  useEffect(() => {
    switch (true) {
      case isSuccess && !toastRendered: {
        setLoading(false)
        setExportButton(false)
        const { message: successMessage } = assetsData
        toast.dismiss()
        toast.success(successMessage)
        setToastRendered(true)
        break
      }
      case isError && 'message' in error && !toastRendered: {
        setLoading(false)
        const { message: errorMessage } = error
        toast.dismiss()
        toast.error(errorMessage)
        setToastRendered(true)
        break
      }
      default:
        // Handle any other cases if needed
        break
    }

    return () => {
      setToastRendered(false)
    }
  }, [assetsData, error, isError, isSuccess])

  const handleSearchSubmit = () => {
    setLoading(true)
    setPage(1)
    setSkip(false)
  }

  const handleExport = () => {
    setExportButton(true)
    setSearchAssets((prevSearchAssets) => ({
      ...prevSearchAssets,
      exportToExcel: true,
    }))
    handleSearchSubmit()
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Report</PageTitle>
      {!(isLoadingGetAssets || isLoadingGetCompany || loading) ? (
        <>
          <SetupCoulmnListing
            showSetupColumns={showSetupColumns}
            setShowSetupColumns={setShowSetupColumns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            assetsData={assetsData}
          />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <div id='kt_content_container' className='container'>
                <div className='card'>
                  <div className='card-body'>
                    <AssetsReportHeader
                      handleExport={handleExport}
                      title='Assets by Tag with Pictures'
                    />
                    <div className='d-flex align-items-center justify-content-between flex-wrap mb-4'>
                      <div className='d-flex limit-options align-items-center mb-sm-0 mb-3'>
                        <span className='text-muted'>Showing</span>
                        <select className='form-select form-select-sm fw-bold mx-3 border-1'>
                          <option value='10'>10</option>
                          <option value='15'>15</option>
                          <option value='20'>20</option>
                          <option value='25'>25</option>
                          <option value='50'>50</option>
                        </select>
                        <span className='text-muted'>entries</span>
                      </div>
                      <SetupColumns setShowSetupColumns={setShowSetupColumns} />
                    </div>
                    {assets?.length > 0 ? (
                      assets.map((asset: any, rowIndex: number) => (
                        <div className='table-responsive mb-5 pb-4' id='print_area'>
                          <div className='d-flex py-3 fs-5'>
                            <span className='me-2'>Asset Tag:</span>
                            <span className='fw-bold'>{asset?.assetTagId}</span>
                          </div>
                          <table
                            className='asset-details table table-bordered dataTable'
                            id='preview-table'
                            aria-label='Common Content Reports'
                            role='presentation'
                          >
                            <tbody>
                              <tr>
                                <td className='assetImg_col text-center align-middle w-20'>
                                  <div className='overlay-wrapper asset-image'>
                                    <img
                                      src={toAbsoluteUrlImage(asset?.photo)}
                                      alt='asset'
                                      className='img-fluid center-block asset_img'
                                    />
                                  </div>
                                </td>
                                <td className='ps-3'>
                                  <table
                                    role='presentation'
                                    className='table table-bordered table-asset table-sm mb-0'
                                    aria-label='Report Grid'
                                  >
                                    <tbody>
                                      <tr key={rowIndex}>
                                        {selectedColumnsRender.map((column, colIndex) => (
                                          <tr key={colIndex}>
                                            <td className='lable_col bg-light text-end text-nowrap w-1'>
                                              {column.key.split('_').join(' ')}
                                            </td>
                                            <td colSpan={1} className='detail_col w-49'>
                                              {column.key === 'Asset_Tag_ID'
                                                ? asset.assetTagId
                                                : column.key === 'Brand'
                                                  ? asset.brand
                                                  : column.key === 'Cost'
                                                    ? asset.cost
                                                    : column.key === 'Created_By'
                                                      ? asset.createdBy
                                                      : column.key === 'Date_Created'
                                                        ? asset.createdAt
                                                        : column.key === 'Description'
                                                          ? asset.description
                                                          : column.key === 'Model'
                                                            ? asset.model
                                                            : column.key === 'Purchased_Date'
                                                              ? asset.purchasedDate
                                                              : column.key === 'Purchased_From'
                                                                ? asset.purchasedFrom
                                                                : column.key === 'Serial_No'
                                                                  ? asset.serialNo
                                                                  : ''}
                                            </td>
                                          </tr>
                                        ))}
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={selectedColumnsRender?.length + 2} className='text-center'>
                          <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                            No records found
                          </div>
                        </td>
                      </tr>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}
export default ReportByAssetPicture
