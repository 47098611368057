import React from 'react'
import PersonsListing from './Components/PersonsListing'

function Customers() {
  return (
    <div>
      <PersonsListing />
    </div>
  )
}

export default Customers
