import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'
import { useReplicateAssetMutation } from 'App/Modules/Services/Assets'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import CustomRadioButton from '../RadioButtons/RadioButton'
import { nonNumericKeys } from 'config'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
interface ReplicateModalProps {
  replicateModal: boolean
  handleClose: () => void
  asset: any
}

const initialValues = {
  copies: 2,
  prefix: '',
  consecutiveNumber: 0,
  suffix: '',
}
const validationSchema = Yup.object({
  copies: Yup.number().required('Copies is required').min(2, 'Number must be within 2 to 100.'),
  prefix: Yup.string(),
  consecutiveNumber: Yup.string().required('Starting Number is required'),
  suffix: Yup.string(),
})

const ReplicateModal: React.FC<ReplicateModalProps> = ({ replicateModal, handleClose, asset }) => {
  const navigate = useNavigate()
  const { saveAuth, setCurrentUser } = useAuth()
  const [replicateAsset] = useReplicateAssetMutation()
  const [replicateOption, setReplicateOption] = useState<string | null>(null)

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReplicateOption(event.target.value)
  }

  const handleReplicate = async (values, resetForm) => {
    try {
      const { copies, prefix, consecutiveNumber, suffix } = values
      const assetId = asset?.id
      const data: any = await replicateAsset({
        assetId,
        copies,
        prefix,
        consecutiveNumber,
        suffix,
      }).unwrap()
      if (data?.success) {
        resetForm()
        navigate('/assets')
      } else {
        toast.error('Failed to update asset donate status')
        checkUserUnauthorized(data?.error?.data, saveAuth, setCurrentUser, toast)
      }
    } catch (error: any) {
      toast.error(error?.data?.message)
    }
  }

  const handleSubmit = (values, { resetForm }) => {
    resetForm()
    if (replicateOption === 'option1') {
      navigate(`/asset/copy/${asset?.id}`)
    } else {
      handleReplicate(values, resetForm)
    }
  }

  return (
    <>
      <Modal className='replicate-modal ' show={replicateModal} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Replicate Asset</Modal.Title>
        </Modal.Header>
        <div className='modal-body'>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, handleSubmit, resetForm }) => (
              <>
                <h5>
                  Do you want to create a copy of the asset{' '}
                  <strong className='replicate-asset-name'>{asset?.assetTagId}</strong>?
                </h5>
                <div className='radio-buttons mb-4 d-flex align-items-center justify-content-center'>
                  <span className='me-4'>Replicate</span>
                  <CustomRadioButton
                    id='option1'
                    name='customRadio'
                    value='option1'
                    checked={replicateOption === 'option1'}
                    onChange={handleRadioChange}
                    label='Single Time'
                  />
                  <CustomRadioButton
                    id='option2'
                    name='customRadio'
                    value='option2'
                    checked={replicateOption === 'option2'}
                    onChange={handleRadioChange}
                    label='Multiple Times'
                  />
                </div>
                {replicateOption === 'option2' && (
                  <Form className='replicate-form d-flex align-items-center justify-content-between'>
                    <div className='form-group'>
                      <label htmlFor='copies'>Copies</label>
                      <Field
                        type='number'
                        id='copies'
                        name='copies'
                        min='1'
                        className={`input copies-input ${
                          touched.copies ? (errors.copies ? 'error-border' : 'success-border') : ''
                        }`}
                        onKeyDown={(e) => {
                          nonNumericKeys(e)
                        }}
                      />
                      <ErrorMessage name='copies' component='div' className='error-message' />
                    </div>
                    <div className='form-group'>
                      <label>Starting Asset Tag ID</label>
                      <div className='input-group'>
                        <Field
                          type='text'
                          name='prefix'
                          placeholder='Prefix'
                          className={`input ${
                            touched.prefix
                              ? errors.prefix
                                ? 'error-border'
                                : 'success-border'
                              : ''
                          }`}
                        />
                        <Field
                          type='string'
                          name='consecutiveNumber'
                          placeholder='Consecutive Number'
                          min='0'
                          className={`input ${
                            touched.consecutiveNumber
                              ? errors.consecutiveNumber
                                ? 'error-border'
                                : 'success-border'
                              : ''
                          }`}
                          onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                        />
                        <Field
                          type='text'
                          name='suffix'
                          placeholder='Suffix'
                          className={`input ${
                            touched.suffix
                              ? errors.suffix
                                ? 'error-border'
                                : 'success-border'
                              : ''
                          }`}
                        />
                      </div>
                      <ErrorMessage
                        name='consecutiveNumber'
                        component='div'
                        className='error-message'
                      />
                    </div>
                  </Form>
                )}
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn replicate-btn text-dark'
                    data-bs-dismiss='modal'
                    onClick={() => handleSubmit()}
                  >
                    Replicate
                  </button>
                  <button
                    type='button'
                    className='btn cancel-btn'
                    data-bs-dismiss='modal'
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  )
}

export default ReplicateModal
