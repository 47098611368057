import { useState, useEffect } from 'react'
import { CheckoutReportsHeader } from '../CheckoutReportsHeader'
import SetupColumns from '../../../Asset/Components/SetupColumns'
import { useGetCustomersQuery } from 'App/Modules/Services/Customers'
import Select from 'react-select'

function ReportsByPersonEmployee() {
  const { data: customerData } = useGetCustomersQuery({})
  const [customerOptions, setCustomerOptions] = useState([])

  useEffect(() => {
    if (customerData) {
      const options = customerData?.customers?.map((item) => ({
        value: item?.email,
        label: (
          <div className='d-flex flex-column'>
            <span className='personFullName'>{`${item?.firstName} ${item?.lastName}`}</span>
            <span className='personEmail'> {item?.email}</span>
          </div>
        ),
      }))
      setCustomerOptions(options)
    }
  }, [customerData])

  return (
    <>
      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          <div id='kt_content_container' className='container'>
            <div className='card'>
              <div className='card-body'>
                <CheckoutReportsHeader title='Check-Out by Persons' />
                <div className='d-flex align-items-center justify-content-between flex-wrap mb-4'>
                  <div className='row'>
                    <div className='col-lg-5'>
                      <div className='form-group d-flex align-items-center mb-0 me-3 fiter-select'>
                        <label className='col-form-label fw-bold fs-6 me-2'>
                          <span>Report Type: </span>
                        </label>
                        <select className='form-select flex-1'>
                          <option>Current Status</option>
                          <option>All Time</option>
                          <option>Year</option>
                          <option>Month</option>
                          <option>Date Range</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <SetupColumns />
                </div>
                <div className='row'>
                  <div className='col-lg-5'>
                    <div className='form-group d-flex align-items-center mb-4 me-3'>
                      <label className='col-form-label fw-bold fs-6 me-2 d-flex'>
                        <i className='la la-user-tie fs-3 me-2' aria-hidden='true'></i>
                        <span>Person</span>
                      </label>
                      <Select
                        options={customerOptions}
                        isSearchable={true}
                        placeholder='Select a customer'
                        classNamePrefix='custom-select'
                        className='custom-react-select w-100'
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex limit-options align-items-center mb-sm-0 mb-3'>
                  <span className='text-muted'>Showing</span>
                  <select className='form-select form-select-sm fw-bold mx-3 border-1'>
                    <option value='10'>10</option>
                    <option value='15'>15</option>
                    <option value='20'>20</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                  </select>
                  <span className='text-muted'>entries</span>
                </div>
                <div className='react-grid-card'>
                  <div className='table-responsive pb-4'>
                    <table
                      id='kt_table_users'
                      className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    >
                      <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th>Asset Tag ID</th>
                          <th>Description</th>
                          <th>Purchase Date</th>
                          <th>Purchased From</th>
                          <th className='text-center'>Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr role='row'>
                          <td className='table-elipses'>Abc 1</td>
                          <td className='table-elipses'>Some Description</td>
                          <td className='table-elipses'>08/08/2023</td>
                          <td className='table-elipses'>Lorem Ips</td>
                          <td className='text-center'>C$45,454.00</td>
                        </tr>
                        <tr role='row'>
                          <td className='table-elipses'>Abc 1</td>
                          <td className='table-elipses'>Some Description</td>
                          <td className='table-elipses'>08/08/2023</td>
                          <td className='table-elipses'>Lorem Ips</td>
                          <td className='text-center'>C$45,454.00</td>
                        </tr>
                        <tr role='row' className='price_total'>
                          <td className='table-elipses text-nowrap fw-bold text-dark'>
                            Total: 3 assets
                          </td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td className='price_sum text-nowrap text-center  fw-bold text-dark'>
                            {' '}
                            <span> C$45,510.00</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportsByPersonEmployee
