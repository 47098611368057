import { Dispatch, SetStateAction, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
interface MainPhotosUploadProps {
  mainPhotosUploadModal: boolean
  setMultiplesPhotoUploadModal: Dispatch<SetStateAction<boolean>>
  setSinglePhotoUploadModal: Dispatch<SetStateAction<boolean>>
  setMainPhotosUploadModal: Dispatch<SetStateAction<boolean>>
  handleClose: () => void
}

const MainPhotosUploadModal: React.FC<MainPhotosUploadProps> = ({
  mainPhotosUploadModal,
  setMultiplesPhotoUploadModal,
  setSinglePhotoUploadModal,
  setMainPhotosUploadModal,
  handleClose,
}) => {
  return (
    <>
      <Modal
        className='main-upload-photos-modal'
        show={mainPhotosUploadModal}
        onHide={handleClose}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>How would you like to upload ?</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <Row>
            <Col md={12}>
              <div className='upload-type spacer'>
                <Button
                  className='upload-btn mb-5'
                  variant='primary'
                  onClick={() => {
                    setSinglePhotoUploadModal(true)
                    setMainPhotosUploadModal(false)
                  }}
                >
                  {' '}
                  <i className='fa-solid fa-image text-white fs-2 me-3'></i>Single Upload
                </Button>
                <p>
                  Upload one photo at a time with photo editing features like rotation and cropping.
                </p>
              </div>
            </Col>
            <Col md={12}>
              <div className='upload-type'>
                <Button
                  className='upload-btn mb-5'
                  variant='primary'
                  onClick={() => {
                    setMultiplesPhotoUploadModal(true)
                    setMainPhotosUploadModal(false)
                  }}
                >
                  {' '}
                  <i className='fa-solid fa-photo-film fs-2 me-3'></i>Mass Upload
                </Button>
                <p>
                  Select and upload multiple photos at a time. Edit photos later in 'Your Uploads'
                  section.
                </p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn cancel-btn border' onClick={handleClose}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MainPhotosUploadModal
