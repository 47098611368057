import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { changePassword } from '../../../../Auth/Core/Requests'
import { IUpdatePassword, updatePassword } from '../SettingsModel'
import { toast } from 'react-toastify'
import { checkUserUnauthorized } from 'App/Modules/Auth'
import { useAuth } from 'App/Modules/Auth'
import { passwordRegex } from 'config'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const passwordFormValidationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(8, 'Current Password must be at least 8 characters')
    .max(50, 'Current Password must not exceed 50 characters')
    .matches(
      passwordRegex,
      'Current Password must include at least one uppercase letter, one lowercase letter, one number, and one symbol'
    )
    .required('Current Password is required'),
  newPassword: Yup.string()
    .min(8, 'New Password must be at least 8 characters')
    .max(50, 'New Password must not exceed 50 characters')
    .matches(
      passwordRegex,
      'New Password must include at least one uppercase letter, one lowercase letter, one number, one symbol and no spaces'
    )
    .required('New Password is required'),
  passwordConfirmation: Yup.string()
    .min(8, 'Confirm New Password must be at least 8 characters')
    .max(50, 'Confirm New Password must not exceed 50 characters')
    .matches(
      passwordRegex,
      'Confirm New Password must include at least one uppercase letter, one lowercase letter, one number, one symbol and no spaces'
    )
    .required('Confirm New Password is required')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
})

const SignInMethod: React.FC = () => {
  const { saveAuth, setCurrentUser } = useAuth()
  const [passwordUpdateData, setPasswordUpdateData] = useState<IUpdatePassword>(updatePassword)
  const [showPasswordForm, setPasswordForm] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showConfirmNewPasswordPassword, setShowConfirmNewPasswordPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)

  const formik2 = useFormik<IUpdatePassword>({
    initialValues: {
      ...passwordUpdateData,
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      toast.dismiss()
      setLoading(true)
      try {
        const { data } = await changePassword(
          values.currentPassword,
          values.newPassword,
          values.passwordConfirmation
        )
        setLoading(false)
        toast.success(data?.message)
        resetForm()
      } catch (error: any) {
        setLoading(false)
        toast.error(error?.response?.data?.message)
        checkUserUnauthorized(error?.response?.data, saveAuth, setCurrentUser, toast)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Change Password</h3>
        </div>
      </div>
      <div id='kt_account_signin_method' className='collapse show'>
        <div className='card-body border-top p-lg-9 p-md-7 p-6'>
          <div className='d-flex flex-wrap align-items-center'>
            <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
              <div className='fs-6 fw-bolder mb-1'>Password</div>
              <div className='fw-bold text-gray-600'>************</div>
            </div>
            <div
              id='kt_signin_password_edit'
              className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
            >
              <form
                onSubmit={formik2.handleSubmit}
                id='kt_signin_change_password'
                className='form'
                noValidate
              >
                <Row className='mb-1'>
                  <Col lg="4">
                    <div className='fv-row mb-0'>
                      <label
                        htmlFor='currentpassword'
                        className='form-label fs-6 fw-bolder mb-3 required'
                      >
                        Current Password
                      </label>
                      <div>
                        <input
                          type={showCurrentPassword ? 'text' : 'password'}
                          placeholder='Current Password'
                          className='form-control  form-control-solid '
                          id='currentpassword'
                          {...formik2.getFieldProps('currentPassword')}
                        />
                      </div>
                      <span
                        className='eyes'
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                      >
                        {showCurrentPassword ? (
                          <i className='bi bi-eye-slash'></i>
                        ) : (
                          <i className='bi bi-eye fs-md-5 fs-6'></i>
                        )}
                      </span>
                      {formik2.touched.currentPassword && formik2.errors.currentPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik2.errors.currentPassword}</div>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className='fv-row mb-0'>
                      <label
                        htmlFor='newpassword'
                        className='form-label fs-6 fw-bolder mb-3 required'
                      >
                        New Password
                      </label>
                      <div>
                        <input
                          type={showNewPassword ? 'text' : 'password'}
                          placeholder='New Password'
                          className='form-control  form-control-solid '
                          id='newpassword'
                          {...formik2.getFieldProps('newPassword')}
                        />
                      </div>
                      <span className='eyes' onClick={() => setShowNewPassword(!showNewPassword)}>
                        {showNewPassword ? (
                          <i className='bi bi-eye-slash'></i>
                        ) : (
                          <i className='bi bi-eye fs-md-5 fs-6'></i>
                        )}
                      </span>
                      {formik2.touched.newPassword && formik2.errors.newPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik2.errors.newPassword}</div>
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col lg="4">
                    <div className='fv-row mb-0'>
                      <label
                        htmlFor='confirmpassword'
                        className='form-label fs-6 fw-bolder mb-3 required'
                      >
                        Confirm New Password
                      </label>
                      <div>
                        <input
                          type={showConfirmNewPasswordPassword ? 'text' : 'password'}
                          placeholder='Confirm New Password'
                          className='form-control  form-control-solid '
                          id='confirmpassword'
                          {...formik2.getFieldProps('passwordConfirmation')}
                        />
                      </div>
                      <span
                        className='eyes'
                        onClick={() =>
                          setShowConfirmNewPasswordPassword(!showConfirmNewPasswordPassword)
                        }
                      >
                        {showConfirmNewPasswordPassword ? (
                          <i className='bi bi-eye-slash'></i>
                        ) : (
                          <i className='bi bi-eye fs-md-5 fs-6'></i>
                        )}
                      </span>
                      {formik2.touched.passwordConfirmation &&
                        formik2.errors.passwordConfirmation && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              {formik2.errors.passwordConfirmation}
                            </div>
                          </div>
                        )}
                    </div>
                  </Col>
                </Row>
                <div className='form-text mb-5'>
                  Password must be at least 8 character and contain symbols
                </div>
                <div className='d-flex justify-content-end py-4'>
                  <button
                    onClick={() => {
                      setPasswordForm(false)
                      formik2.resetForm()
                    }}
                    id='kt_password_cancel'
                    type='button'
                    className='btn btn-light-primary   main-btn-style me-2'
                  >
                    Cancel
                  </button>
                  <button
                    id='kt_password_submit'
                    type='submit'
                    className='btn btn-primary main-btn-style'
                    disabled={!formik2.isValid || !formik2.dirty}
                  >
                    {!loading && 'Update Password'}
                    {loading && (
                      <span className='indicator-progress d-block'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
            <div
              id='kt_signin_password_button'
              className={'ms-auto ' + (showPasswordForm && 'd-none')}
            >
              <button
                onClick={() => {
                  setPasswordForm(true)
                }}
                className='btn btn-light-primary'
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { SignInMethod }
