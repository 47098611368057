import { ILayout } from './LayoutModels'

export const DefaultLayoutConfig: ILayout = {
  main: {
    type: 'default',
    primaryColor: '#009EF7',
    darkSkinEnabled: true,
  },
  loader: {
    display: true,
    type: 'default',
  },
  scrolltop: {
    display: true,
  },
  header: {
    width: 'fluid',
    fixed: {
      desktop: false,
      tabletAndMobile: true,
    },
  },
  megaMenu: {
    display: false,
  },
  aside: {
    minimized: false,
    minimize: true,
  },
  content: {
    width: 'fixed',
  },
  footer: {
    width: 'fluid',
  },
}
