import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { useGetCustomersQuery } from 'App/Modules/Services/Customers'
import { useUpdateAssetEventMutation } from 'App/Modules/Services/Assets'
import { useDispatch } from 'react-redux'
import { refresh } from 'App/Store'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

interface NewAuditModalProps {
  editLeaseEventModal: boolean
  handleClose: () => void
  event: any
  assetId: any
}

const EditLeaseEventModal: React.FC<NewAuditModalProps> = ({
  editLeaseEventModal,
  handleClose,
  event,
  assetId,
}) => {
  const { saveAuth, setCurrentUser } = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [updateAssetEvent] = useUpdateAssetEventMutation()
  const { data: customerData } = useGetCustomersQuery({})
  const [customerOptions, setCustomerOptions] = useState<any>([])
  const [assignOptions, setAssignOptions] = useState<any>([])
  const [formData, setFormData] = useState({
    startDate: event.leased ? new Date(event.leased.startDate).toISOString().slice(0, 10) : '',
    customerId: event.leased ? event.leased.Customer?.id : '',
    expiryDate: event.leased ? new Date(event.leased.expiryDate).toISOString().slice(0, 10) : '',
    notes: event.leased?.notes || '',
  })
  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    if (event.leased && event.leased.Customer) {
      setCustomerOptions({
        label: `${event.leased.Customer.firstName} ${event.leased.Customer.lastName}`,
        value: event.leased.Customer.id,
      })
    }
  }, [event.leased])

  useEffect(() => {
    if (customerData && customerData.customers) {
      const options = customerData.customers.map((customer) => ({
        label: `${customer.firstName} ${customer.lastName}`,
        value: customer.id,
      }))
      setAssignOptions(options)
    }
  }, [customerData])

  useEffect(() => {
    setIsFormValid(formData.startDate && formData.customerId)
  }, [formData])

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value
    setFormData((prev) => ({
      ...prev,
      customerId: selectedValue,
    }))
    setCustomerOptions({
      label: e.target.options[e.target.selectedIndex].text,
      value: selectedValue,
    })
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = async () => {
    if (isFormValid) {
      const dataToUpdate = {
        eventId: event.id,
        data: {
          startDate: formData.startDate,
          customerId: formData.customerId,
          expiryDate: formData.expiryDate,
          notes: formData.notes,
        },
      }
      const result: any = await updateAssetEvent(dataToUpdate)
      if (result?.data?.success) {
        dispatch(refresh(true))
        toast.success(result.data.message)
        handleClose()
        navigate(`/asset/view/${assetId}`)
      } else {
        toast.error('Failed to update event')
        checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
      }
    }
  }

  return (
    <Modal show={editLeaseEventModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{event?.event}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-3' controlId='formDate'>
            <Form.Label className='required-label'>Lease Begins</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                name='startDate'
                value={formData.startDate}
                onChange={handleInputChange}
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formAssignTo'>
            <Form.Label className='required-label'>Leasing Customer</Form.Label>
            <Form.Control as='select' value={formData.customerId} onChange={handleSelectChange}>
              <option value={formData.customerId || ''}>
                {`${event.leased?.Customer?.firstName || ''} ${event.leased?.Customer?.lastName || ''}` ||
                  'Select a Customer'}
              </option>
              {assignOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formDate'>
            <Form.Label>Lease Expires</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                name='expiryDate'
                value={formData.expiryDate}
                onChange={handleInputChange}
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formTextArea'>
            <Form.Label>Lease Notes</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              name='notes'
              value={formData.notes}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={handleSubmit} disabled={!isFormValid}>
          Update
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditLeaseEventModal
