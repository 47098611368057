const SetupColumns = (props: any) => {
  return (
    <div>
      <button
        type='button'
        className='btn btn-light-primary d-flex align-items-center main-btn-style'
        onClick={() => props.setShowSetupColumns(true)}
      >
        <i className='la la-cog fs-3 me-sm-1 me-0'></i>
        <span className='d-sm-block d-none'>Setup Column</span>
      </button>
    </div>
  )
}

export default SetupColumns
