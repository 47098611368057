import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../App/Modules/Auth'
import { toAbsoluteUrlImage } from '../../../helpers'
import { Images } from 'Template/assets/Assets'
import SplashScreen from 'App/Loaders/SplashScreen'

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const permission = currentUser?.SecurityGroup
  const ownerUser = currentUser?.userType === 1
  const staffUser = currentUser?.userType === 2
  const personUser = currentUser?.userType === 3
  const customerUser = currentUser?.userType === 4

  const handleClick = (event) => {
    event.preventDefault()
    setLoading(true)
    logout()
    setLoading(false)
  }

  function truncateEmail(email: string | undefined | null): string {
    if (!email) {
      return ''
    }

    const atTheIndex = email.indexOf('@')
    const atIndex = atTheIndex - 3
    if (atIndex === -1) {
      return email
    }

    const firstPart = email.slice(0, atIndex)
    const secondPart = email.slice(atIndex)
    const truncatedFirstPart = firstPart.slice(0, 6)

    return `${truncatedFirstPart}...${secondPart}`
  }
  return (
    <>
      <SplashScreen isLoadingTemplate={loading} />
      {!loading ? (
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-primary fw-bold py-4 fs-6 custom-menu-dropdown'
          data-kt-menu='true'
        >
          <div className='menu-item px-3'>
            <div className='menu-content d-flex align-items-center px-3'>
              <div className='symbol symbol-50px me-3'>
                <img
                  className='image-input-wrapper '
                  src={
                    currentUser?.photo ? toAbsoluteUrlImage(currentUser?.photo) : Images.Avatar_JPG
                  }
                  alt='profile'
                  crossOrigin='anonymous'
                />
              </div>
              <div className='d-flex flex-column'>
                <div className='fw-bolder d-flex align-items-center fs-5 text-capitalize'>
                <span className='d-block text-break pre-line'>{currentUser?.firstName} {currentUser?.lastName}</span>
                </div>
                <Link
                  to='/account/settings'
                  className='fw-bold text-muted text-hover-primary fs-7 email-link'
                >
                  {truncateEmail(currentUser?.email)}
                </Link>
              </div>
            </div>
          </div>
          <div className='separator my-2'></div>
          <div className='menu-item px-3'>
            <Link to={'/account/settings'} className='menu-link px-5'>
              My Profile
            </Link>
          </div>
          {(ownerUser || (staffUser && permission?.accountDetails)) &&
            !(personUser || customerUser) && (
              <div className='menu-item px-3'>
                <Link to='/account-details/overview' className='menu-link px-5'>
                  Account Details
                </Link>
              </div>
            )}
          <div className='menu-item px-3'>
            <div onClick={handleClick} className='menu-link px-5'>
              Logout
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export { HeaderUserMenu }
