import { useState, useEffect } from 'react'
import PreviewTable from './PreviewTable'

const OrderTableColumns = ({ setColumnsData, columnsData }: any) => {
  const [allColumns, setAllColumns] = useState(false)
  const [getColumn, setGetColumn] = useState(false)
  const [organizedData, setOrganizedData] = useState([])

  // Function to handle toggling all columns
  const handleAllColumns = () => {
    const newAllColumns = !allColumns
    setAllColumns(newAllColumns)
    setColumnsData((prevData: any) => ({
      ...prevData,
      selectedColumns: prevData.selectedColumns.map((obj: any, i: number) => {
        const key = Object.keys(obj)[0]
        return { [key]: newAllColumns }
      }),
    }))
    setGetColumn(newAllColumns)
  }

  // Function to handle individual checkbox change
  const handleCheckboxChange = (key: string, index: number) => {
    const selectedColumns = columnsData.selectedColumns
    const selectedCount = selectedColumns.filter((value) => value).length

    if (selectedColumns[key] && selectedCount === 1) {
      return
    }

    setColumnsData((prevData: any) => ({
      ...prevData,
      selectedColumns: prevData.selectedColumns.map((obj: any, i: number) =>
        i === index ? { [key]: !obj[key] } : obj
      ),
    }))

    const allTrue = columnsData?.selectedColumns.every((value) => {
      const key = Object.keys(value)[0]
      return value[key] === true
    })
    setAllColumns(allTrue)
    setGetColumn(
      columnsData?.selectedColumns?.some((value) => {
        const key = Object.keys(value)[0]
        return value[key] === true
      })
    )
  }

  // Effect to initialize and update organizedData
  useEffect(() => {
    const initialSelectedColumns: any = columnsData?.selectedColumns?.map((col) => {
      const key = Object.keys(col)[0]
      return { key, value: col[key] }
    })
    setOrganizedData(initialSelectedColumns)
    const allTrue = columnsData?.selectedColumns.every((value) => {
      const key = Object.keys(value)[0]
      return value[key] === true
    })
    setAllColumns(allTrue)
    setGetColumn(
      columnsData?.selectedColumns?.some((value) => {
        const key = Object.keys(value)[0]
        return value[key] === true
      })
    )
  }, [columnsData?.selectedColumns])

  return (
    <>
      <div className='card-body border-top px-0 pb-2 mb-1'>
        <div className='heading'>
          <h5>Step 2: Select and Order Table Columns</h5>
        </div>
        <p>Choose which columns you want in your table by checking the appropriate boxes.</p>
        <label className='mb-4' htmlFor='all-columns-checkbox'>
          <div className='form-check form-check-sm form-check-custom'>
            <input
              id='all-columns-checkbox'
              type='checkbox'
              className='custom-form-check-input form-check-input sig_input me-lg-3 me-2'
              checked={allColumns}
              onChange={handleAllColumns}
              disabled={allColumns}
            />
            {'All columns'}
          </div>
        </label>
        <div className='row'>
          {organizedData.map((item: any, index: any) => (
            <div key={item.key} className='col-md-4 col-sm-6 col-12'>
              <ul className='list-unstyled'>
                <li key={item.key} className='mb-2'>
                  <label htmlFor={`column-checkbox-${item.key}`}>
                    <input
                      id={`column-checkbox-${item.key}`}
                      type='checkbox'
                      className='custom-form-check-input form-check-input sig_input me-lg-3 me-2'
                      checked={item.value}
                      onChange={() => handleCheckboxChange(item.key, index)}
                    />
                    {item.key}
                  </label>
                </li>
              </ul>
            </div>
          ))}
        </div>
      </div>
      {getColumn && <PreviewTable columnsData={columnsData} />}
    </>
  )
}

export default OrderTableColumns
