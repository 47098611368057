export interface MoveInterface {
  siteId: number | null
  locationId: number | null
  departmentId: number | null
  assetId: string | undefined
}
export const defaultMoveDetail: MoveInterface = {
  siteId: null,
  locationId: null,
  departmentId: null,
  assetId: undefined,
}
