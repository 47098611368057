import { useFoundAssetMutation } from 'App/Modules/Services/AssetActions'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getCurrentDate } from 'config'
import Loader from 'App/Loaders/BeatLoader'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

interface NewAuditModalProps {
  foundModal: boolean
  handleClose: () => void
  assetId: any
  refetchAssetDetails: () => void
  setRefreshHistory: any
}

const validationSchema = Yup.object().shape({
  foundDate: Yup.string().required('Date Found is required.'),
  notes: Yup.string().max(250, 'Note should not exceed 250 characters'),
})

const FoundModal: React.FC<NewAuditModalProps> = ({
  foundModal,
  handleClose,
  assetId,
  refetchAssetDetails,
  setRefreshHistory,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [foundStatus] = useFoundAssetMutation()
  const currentDate = getCurrentDate()
  const formik = useFormik({
    initialValues: {
      assetId,
      foundDate: getCurrentDate(),
      notes: '',
    },
    validationSchema,
    validateOnChange: true, // Validate on change
    validateOnBlur: true, // Validate on blur
    onSubmit: async (values) => {
      try {
        setLoading(true)
        const result: any = await foundStatus(values)
        if (result?.data?.success) {
          refetchAssetDetails()
          setRefreshHistory((prev) => !prev)
          toast.success(result?.data?.message)
          handleClose()
          setLoading(false)
        } else {
          toast.error('Failed to update asset found status')
          checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
        }
      } catch (error: any) {
        toast.error('Failed to update asset found status')
      }
    },
  })

  return (
    <Modal show={foundModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Found Asset</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='formDate'>
            <Form.Label className='required-label'>Date Found</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                name='foundDate'
                max={currentDate}
                value={formik.values.foundDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.foundDate && formik.touched.foundDate}
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
            {formik.errors.foundDate && formik.touched.foundDate && (
              <div className='text-danger'>{formik.errors.foundDate}</div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='formTextArea'>
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as='textarea'
              name='notes'
              rows={3}
              value={formik.values.notes}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.notes && formik.errors.notes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{String(formik.errors.notes)}</div>
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn cancel-btn border' onClick={handleClose}>
            Cancel
          </button>
          <button type='submit' className='btn btn-primary' disabled={!formik.values?.foundDate}>
            Found
          </button>
        </Modal.Footer>
      </Form>
      <Loader loading={loading} />
    </Modal>
  )
}

export default FoundModal
