import { toAbsoluteUrlImage } from 'Template/helpers'
import { dateFormat, defaultDateFormat } from 'config'
import { Images } from 'Template/assets/Assets'
import { useAuth } from 'App/Modules/Auth'
import moment from 'moment'
interface ColumnDataProps {
  column: string
  assetsData?: any
  currencyLogo?: any
  assetMainteneces?: any
}

const date = new Date()

export const ColumnData: React.FC<ColumnDataProps> = ({ column, assetsData, currencyLogo }) => {
  const { currentUser } = useAuth()
  const renderData = () => {
    switch (column) {
      case 'Asset Photo':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <div className='d-inline align-items-center asset-img-table'>
            {asset?.Asset?.photo && asset?.Asset?.photo?.length > 0 ? (
              <img
                src={
                  asset?.Asset?.photo[0]?.uploadType === 1
                    ? toAbsoluteUrlImage(asset?.Asset?.photo[0]?.photo)
                    : asset?.Asset?.photo[0]?.stockImage?.imgUrl
                }
                alt='asset'
                className='img-fluid'
                crossOrigin='anonymous'
              />
            ) : (
              <img src={Images?.AssetsPlaceholder} alt='asset' className='img-fluid' />
            )}
          </div>
        ))
      case 'Asset Tag ID':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.assetTagId}</span>
        ))
      case 'Description':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.description}</span>
        ))
      case 'Brand':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.brand}</span>
        ))
      case 'Purchased Date':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.Asset?.purchasedDate
              ? dateFormat(
                  asset?.Asset?.purchasedDate,
                  currentUser?.dateFormat || defaultDateFormat
                )
              : ''}
          </span>
        ))
      case 'Cost':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.Asset?.cost ? currencyLogo + ' ' + asset?.Asset?.cost : ''}
          </span>
        ))
      case 'Status':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span
            className={`rounded bg-light-${asset?.Asset?.status === 'Available' ? 'green' : asset?.Asset?.status === 'Broken' ? 'red' : asset?.Asset?.status === 'Under repair' ? 'purple' : asset?.Asset?.status === 'Checked out' ? 'blue' : ''} py-3 px-3`}
            key={index}
          >
            {asset?.Asset?.status}
          </span>
        ))
      case 'Created By':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.Asset?.creator?.firstName} {asset?.Asset?.creator?.lastName}
          </span>
        ))
      case 'Date Created':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.Asset?.createdAt
              ? dateFormat(asset?.Asset?.createdAt, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Model':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.model}</span>
        ))
      case 'Purchased From':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.purchasedFrom}</span>
        ))
      case 'Serial No':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.serialNo}</span>
        ))
      case 'Reservation':
        return assetsData.allMaintenances.map((asset: any, index: number) => {
          return (
            <span key={index}>
              {asset?.Asset?.reservationDate && (
                <>
                  Res.{' '}
                  {dateFormat(
                    asset?.Asset?.reservationDate,
                    currentUser?.dateFormat || defaultDateFormat
                  )}
                </>
              )}
            </span>
          )
        })
      case 'Category':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.category?.category}</span>
        ))
      case 'Department':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.department?.department}</span>
        ))
      case 'Location':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.location?.location}</span>
        ))
      case 'Site':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.site?.name}</span>
        ))
      case 'Assigned to':
        return assetsData?.allMaintenances?.map((asset: any, index: number) =>
          asset?.Asset?.assigneeType === 1 ? (
            <span key={index}>
              {`${asset?.Asset?.assignedPerson?.firstName || ''} ${asset?.Asset?.assignedPerson?.lastName || ''}`}
            </span>
          ) : asset?.Asset?.assigneeType === 2 ? (
            <span key={index}>
              {`${asset?.Asset?.assignedSite?.name || ''} ${
                asset?.Asset?.assignedSite?.name && asset?.Asset?.location?.location ? '/' : ''
              } ${asset?.Asset?.location?.location || ''}`}
            </span>
          ) : null
        )
      case 'Event Date':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.Asset?.eventDate
              ? dateFormat(asset?.Asset?.eventDate, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Event Due Date':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.Asset?.eventDueDate
              ? dateFormat(asset?.Asset?.eventDueDate, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Event Notes':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.eventNotes || ''}</span>
        ))
      case 'Leased to':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{`${asset?.Asset?.leased?.firstName || ''} ${
            asset?.Asset?.leased?.lastName || ''
          }`}</span>
        ))
      case 'Title':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.title}</span>
        ))
      case 'Expires':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.dueDate
              ? dateFormat(asset?.dueDate, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Frequency':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.frequency}</span>
        ))
      case 'Is Repeating':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceReapting ? 'yes' : 'no'}</span>
        ))
      case 'Maintenance By':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceBy}</span>
        ))
      case 'Maintenance Completion Date':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.dateCompleted
              ? dateFormat(asset?.dateCompleted, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Maintenance Cost':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.cost !== null ? `${currencyLogo} ${asset.cost}` : ''}</span>
        ))
      case 'Maintenance Detail':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.details}</span>
        ))
      case 'Recur on every':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{`${asset?.recurOnEvery || ''} ${asset?.frequency || ''}`}</span>
        ))
      case 'on (day or Weekday)':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.recurOn}</span>
        ))
      case 'Maintenance Due Date':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.dueDate}</span>
        ))
      case 'Maintenance Status':
        return assetsData?.allMaintenances?.map((asset: any, index: number) =>
          asset?.status ? (
            <span
              className={`bg-light-${asset?.status === 'completed' ? 'green' : asset?.status === 'inProgress' ? 'blue' : asset?.status === 'onHold' ? 'danger' : asset?.status === 'scheduled' ? 'warning' : asset?.status === 'cancelled' ? 'secondary' : ''} d-inline align-items-center px-3 py-2 rounded`}
              key={index}
            >
              {asset?.status === 'scheduled'
                ? 'Scheduled'
                : asset?.status === 'inProgress'
                  ? 'In Progress'
                  : asset?.status === 'onHold'
                    ? 'On Hold'
                    : asset?.status === 'cancelled'
                      ? 'Cancelled'
                      : asset?.status === 'completed'
                        ? 'Completed'
                        : asset?.status}
            </span>
          ) : null
        )
      case 'RAM':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.ram}</span>
        ))
      case 'Storage':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.storage}</span>
        ))
      case 'IMEI':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.imei}</span>
        ))
      case 'Ports':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.ports}</span>
        ))
      case 'Mbps':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.mbps}</span>
        ))
      case 'Stands':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.stands}</span>
        ))
      case 'Batteries':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.batteries}</span>
        ))
      case 'Bulbs':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.bulbs}</span>
        ))
      case 'Lens':
        return assetsData?.allMaintenances?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.lens}</span>
        ))
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}

export const SampleColumnData: React.FC<ColumnDataProps> = ({ column }) => {
  const { currentUser } = useAuth()
  const renderData = () => {
    switch (column) {
      case 'Asset Photo':
        return 'Sample Photo'
      case 'Asset Tag ID':
        return 'Sample TagId'
      case 'Description':
        return 'Sample Description'
      case 'Brand':
        return 'Sample Brand'
      case 'Purchased Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Cost':
        return 'RS 1000'
      case 'Status':
        return 'Sample Status'
      case 'Created By':
        return 'Sample userName'
      case 'Date_Created':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Model':
        return 'Sample Model'
      case 'Purchased From':
        return 'Sample Purchased Location'
      case 'Serial No':
        return 'Sr-12'
      case 'Transact as a whole':
        return 'yes'
      case 'Relation':
        return 'Sample'
      case 'Date Created':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Reservation':
        return 'Sample Reservation'
      case 'Category':
        return 'Sample Category'
      case 'Department':
        return 'Sample Department'
      case 'Location':
        return 'Sample Location'
      case 'Site':
        return 'Sample Site'
      case 'Assigned to':
        return 'Sample Assigned'
      case 'Event Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Event Due Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Event Notes':
        return 'Sample Event Notes'
      case 'Leased to':
        return 'Sample Customer Name'
      case 'Title':
        return 'Sample Title'
      case 'Expires':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Frequency':
        return 'Sample Frequency'
      case 'Is Repeating':
        return 'Weekly'
      case 'on (day or Weekday)':
        return 'Weekly'
      case 'Maintenance By':
        return 'Sample Company Name'
      case 'Maintenance Completion Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Maintenance Cost':
        return '500'
      case 'Maintenance Detail':
        return 'Sample Deatils'
      case 'Recur on every':
        return 'Sample'
      case 'Maintenance Status':
        return 'In-Progress'
      case 'RAM':
        return 'Sample RAM'
      case 'Storage':
        return 'Sample Storage'
      case 'IMEI':
        return 'Sample IMEI'
      case 'Ports':
        return 'Sample Ports'
      case 'Mbps':
        return 'Sample Mbps'
      case 'Stands':
        return 'Sample Stands'
      case 'Batteries':
        return 'Sample Batteries'
      case 'Bulbs':
        return 'Sample Bulbs'
      case 'Lens':
        return 'Sample Lens'
      case 'Maintenance Due Date':
        return 'Sample Maintenance Due Date'
      case 'Maintenance Cost	':
        return 'Sample Maintenance Cost'
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}
