import { createApi } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { customFetchBaseQuery } from './Headers/CustomHeaders'
import { toast } from 'react-toastify'

export const customersApi = createApi({
  reducerPath: 'customersApi',
  baseQuery: customFetchBaseQuery({ path: 'customer' }),
  tagTypes: ['Customers'],
  endpoints: (build) => ({
    getAllCustomers: build.query<any, any>({
      query: ({ search, searchField, page = 1, limit = 10 }) => ({
        url: 'get',
        params: { search, searchField, limit, page },
        method: 'GET',
      }),
      providesTags: ['Customers'],
    }),
    getCustomers: build.query<any, any>({
      query: () => 'get-customers',
      providesTags: ['Customers'],
    }),
    getSingleCustomer: build.query<any, any>({
      query: ({ id }) => ({
        url: `get-customer/${id}`,
        method: 'GET',
      }),
      providesTags: ['Customers'],
    }),
    addCustomer: build.mutation<any, any>({
      query: (body) => ({
        url: `add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Customers' }],
    }),
    updateCustomer: build.mutation<any, { id: any; values: any }>({
      query: ({ id, values }) => ({
        url: `update/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: () => [{ type: 'Customers' }],
    }),
    deleteCustomers: build.mutation<any, any>({
      query({ id }) {
        return {
          url: `delete`,
          method: 'DELETE',
          body: id,
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(assetsApi.util.invalidateTags(['Assets']))
          })
          .catch((err) => toast.error(err?.message))
      },
      invalidatesTags: (result, error, userId) => [{ type: 'Customers', userId }],
    }),
  }),
})

export const {
  useGetAllCustomersQuery,
  useGetCustomersQuery,
  useGetSingleCustomerQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomersMutation,
} = customersApi
