import { createApi } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { customFetchBaseQuery } from './Headers/CustomHeaders'
import { toast } from 'react-toastify'

export const reserveApi = createApi({
  reducerPath: 'reserveApi',
  baseQuery: customFetchBaseQuery({ path: '' }),
  tagTypes: ['Reserve'],
  endpoints: (build) => ({
    addReserve: build.mutation<any, any>({
      query: (data) => ({
        url: `reserve`,
        method: 'POST',
        body: data,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(assetsApi.util.invalidateTags(['Assets']))
          })
          .catch((error) => {
            toast.error(error?.message)
          })
      },
      invalidatesTags: () => [{ type: 'Reserve' }],
    }),
    getReserveAssets: build.query<any, any>({
      query: ({ assetId }) => ({
        url: `reserve/get-all/${assetId}`,
        method: 'GET',
      }),
    }),
    getReserveAssetsById: build.query<any, any>({
      query: ({ reserveId }) => ({
        url: `reserve/get/${reserveId}`,
        method: 'GET',
      }),
    }),
    updateReserveAssetsById: build.mutation<any, any>({
      query: ({ reserveId, data }) => ({
        url: `reserve/update/${reserveId}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteReserveAssetsById: build.mutation<any, any>({
      query: ({ reserveId }) => ({
        url: `reserve/delete/${reserveId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useAddReserveMutation,
  useGetReserveAssetsQuery,
  useGetReserveAssetsByIdQuery,
  useUpdateReserveAssetsByIdMutation,
  useDeleteReserveAssetsByIdMutation,
} = reserveApi
