import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const accountDetailsApi = createApi({
  reducerPath: 'accountDetailsApi',
  baseQuery: customFetchBaseQuery({ path: 'user' }),
  tagTypes: ['AccountDetails'],
  endpoints: (build) => ({
    getAccountDetails: build.query<void, void>({
      query: () => ({
        url: `/account-details`,
        method: 'GET',
      }),
      providesTags: ['AccountDetails'],
    }),
  }),
})

export const { useGetAccountDetailsQuery } = accountDetailsApi
