import { createApi } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { toast } from 'react-toastify'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const departmentsApi = createApi({
  reducerPath: 'departmentsApi',
  baseQuery: customFetchBaseQuery({ path: 'department' }),
  tagTypes: ['Departments'],
  endpoints: (build) => ({
    getAllDepartments: build.query<any, any>({
      query: ({ page = 0, limit = 0 }) => ({
        url: 'list',
        method: 'GET',
        params: { page, limit },
      }),
      providesTags: ['Departments'],
    }),
    addDepartment: build.mutation<any, any>({
      query: (values) => ({
        url: `create`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: () => [{ type: 'Departments' }],
    }),
    updateDepartment: build.mutation<any, { id: any; values: any }>({
      query: ({ id, values }) => ({
        url: `update/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: () => [{ type: 'Departments' }],
    }),
    deleteDepartment: build.mutation<any, { cascade: number; id: any }>({
      query({ cascade, id }) {
        return {
          url: `delete/?cascade=${cascade}`,
          method: 'DELETE',
          body: id,
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(assetsApi.util.invalidateTags(['Assets']))
          })
          .catch((err) => toast.error(err.message))
      },
      invalidatesTags: (userId) => [{ type: 'Departments', userId }],
    }),
  }),
})

export const {
  useGetAllDepartmentsQuery,
  useAddDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
} = departmentsApi
