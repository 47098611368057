import { BeatLoader } from 'react-spinners'

const Loader = ({ loading }) => {
  return (
    <>
      {loading ? (
        <div className='simple-loader'>
          <BeatLoader color='#FFC427' size={18} margin={4} />
        </div>
      ) : null}
    </>
  )
}

export default Loader
