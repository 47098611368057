import { createApi } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { customFetchBaseQuery } from './Headers/CustomHeaders'
import { toast } from 'react-toastify'

export const disposeApi = createApi({
  reducerPath: 'disposeApi',
  baseQuery: customFetchBaseQuery({ path: '' }),
  tagTypes: ['Dispose'],
  endpoints: (build) => ({
    addDispose: build.mutation<any, any>({
      query: (data) => ({
        url: `dispose`,
        method: 'POST',
        body: data,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(assetsApi.util.invalidateTags(['Assets']))
          })
          .catch((err) => toast.error(err?.message))
      },
      invalidatesTags: () => [{ type: 'Dispose' }],
    }),
  }),
})

export const { useAddDisposeMutation } = disposeApi
