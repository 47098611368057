import { useEffect, useState } from 'react'

function ReportsTab({ checked, handleCheckboxChange }) {
  const [reports, setReports] = useState(false)

  const onClickReports = () => {
    const newReportState = !reports
    setReports(newReportState)
    handleCheckboxChange('assetByTagId', newReportState)
    handleCheckboxChange('assetByTagIdPicture', newReportState)
    handleCheckboxChange('assetByCategory', newReportState)
    handleCheckboxChange('assetBySite', newReportState)
    handleCheckboxChange('assetByDepartment', newReportState)
    handleCheckboxChange('assetByWarranty', newReportState)
  }
  useEffect(() => {
    if (
      checked.assetByTagId &&
      checked.assetByTagIdPicture &&
      checked.assetByCategory &&
      checked.assetBySite &&
      checked.assetByDepartment &&
      checked.assetByWarranty
    ) {
      setReports(true)
    } else {
      setReports(false)
    }
  }, [checked])
  return (
    <>
      <div className='mb-5 d-none'>
        <h4 className='mb-3'>Custom Reports and Setup</h4>
        <p>
          User can generate custom reports. Setup columns for reports. Send automated email reports:
        </p>
        <div className='row'>
          <div className='offset-lg-1 col-lg-10'>
            <div className='table-responsive mb-4'>
              <table className='table table-bordered mb-4'>
                <thead className='thead-light  light-yellow-table'>
                  <tr>
                    <th>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='reportSetup'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='reportSetup'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Report Setup</span>
                        </label>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='customReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='customReports'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Generate Custom Reports</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='emailReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='emailReports'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Setup Automated Email Reports</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='reportColumn'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='reportColumn'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Enable/Disable Report Columns</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='saveReportSetup'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='saveReportSetup'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Save Report Setup</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className='mb-5'>
        <h4 className='mb-3'>View and Print Reports</h4>
        <p>User can generate these reports:</p>
        <div className='row'>
          <div className='col-xl-6'>
            <div className='table-responsive mb-4'>
              <table className='table table-bordered mb-4'>
                <thead className='thead-light  light-yellow-table'>
                  <tr>
                    <th>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='assetReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='assetReports'
                              name='assetReports'
                              checked={reports}
                              onClick={onClickReports}
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Asset Reports</span>
                        </label>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='tagReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              checked={checked?.assetByTagId}
                              id='tagReport'
                              name='tagReport'
                              onChange={(e) =>
                                handleCheckboxChange('assetByTagId', e.target.checked)
                              }
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Assets by Asset Tag Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='picturesReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              checked={checked?.assetByTagIdPicture}
                              id='picturesReports'
                              name='picturesReports'
                              onChange={(e) =>
                                handleCheckboxChange('assetByTagIdPicture', e.target.checked)
                              }
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Assets by Asset Tag Report With Pictures Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='categoryReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              checked={checked?.assetByCategory}
                              id='categoryReport'
                              name='categoryReport'
                              onChange={(e) =>
                                handleCheckboxChange('assetByCategory', e.target.checked)
                              }
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Assets by Category Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='sitesReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              checked={checked?.assetBySite}
                              id='sitesReport'
                              name='sitesReport'
                              onChange={(e) =>
                                handleCheckboxChange('assetBySite', e.target.checked)
                              }
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Assets by Site/Location Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='departmentReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              checked={checked?.assetByDepartment}
                              id='departmentReport'
                              name='departmentReport'
                              onChange={(e) =>
                                handleCheckboxChange('assetByDepartment', e.target.checked)
                              }
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Assets by Department Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='informationReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              checked={checked?.assetByWarranty}
                              id='informationReport'
                              name='informationReport'
                              onChange={(e) =>
                                handleCheckboxChange('assetByWarranty', e.target.checked)
                              }
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Assets Warranty Information Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr className='d-none'>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='assetsLinking'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='assetsLinking'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Assets by Linking</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-responsive mb-4 d-none'>
              <table className='table table-bordered mb-4'>
                <thead className='thead-light  light-yellow-table'>
                  <tr>
                    <th>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='depreciationReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='depreciationReports'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Asset Depreciation Reports</span>
                        </label>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='depreciationCategory'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='depreciationCategory'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Asset Depreciation by Category</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label
                          className='radio mb-0 d-inline-flex'
                          htmlFor='depreciationDepartment'
                        >
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='depreciationDepartment'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Asset Depreciation by Department Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='depreciationDisposed'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='depreciationDisposed'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Asset Depreciation for Disposed Asset Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='depreciationYearly'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='depreciationYearly'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Yearly Asset Depreciation</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='depreciationQuarterly'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='depreciationQuarterly'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Quarterly Asset Depreciation</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='depreciationMonthly'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='depreciationMonthly'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Monthly Asset Monthly</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-responsive mb-4 d-none'>
              <table className='table table-bordered mb-4'>
                <thead className='thead-light  light-yellow-table'>
                  <tr>
                    <th>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='fundingReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='fundingReports'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Funding Reports</span>
                        </label>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='fundingReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='fundingReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Funding Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='fundingAssetReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='fundingAssetReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Funding Associated with Asset Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-responsive mb-4 d-none'>
              <table className='table table-bordered mb-4'>
                <thead className='thead-light  light-yellow-table'>
                  <tr>
                    <th>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='checkoutReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='checkoutReports'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Check-Out Reports</span>
                        </label>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label
                          className='radio mb-0 d-inline-flex'
                          htmlFor='checkoutEmployeeReports'
                        >
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='checkoutEmployeeReports'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Check-Out to Employee Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='checkoutAssetReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='checkoutAssetReports'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Check-Out to Employee by Asset Tag Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='checkoutDueDate'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='checkoutDueDate'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Check-Out by Due Date Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='checkoutPastDue'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='checkoutPastDue'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Check-Out by Past Due Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='checkoutSiteReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='checkoutSiteReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Check-Out by Site/Location Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='checkoutTimeFrame'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='checkoutTimeFrame'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Check-Out in a Time Frame</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-responsive mb-4 d-none'>
              <table className='table table-bordered mb-4'>
                <thead className='thead-light  light-yellow-table'>
                  <tr>
                    <th>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='leasedReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='leasedReports'
                              name='hasleased'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Leased Asset Reports</span>
                        </label>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='leasedCustomerReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='leasedCustomerReports'
                              name='hasleased'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Leased to Customer Reports</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label
                          className='radio mb-0 d-inline-flex'
                          htmlFor='leasedCustomerAssetReports'
                        >
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='leasedCustomerAssetReports'
                              name='hasleased'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Leased to Customer by Asset Tag Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='leasedDueDate'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='leasedDueDate'
                              name='hasleased'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Leased by Due Date Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='leasedPastDue'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='leasedPastDue'
                              name='hasleased'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Leased by Past Due Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='leasedTimeFrame'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='leasedTimeFrame'
                              name='hasleased'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Leased in a Time Frame</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-responsive mb-4 d-none'>
              <table className='table table-bordered mb-4'>
                <thead className='thead-light  light-yellow-table'>
                  <tr>
                    <th>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='reserveReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='reserveReports'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Reserve Reports</span>
                        </label>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='reserveAssetReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='reserveAssetReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Reserve by Asset Tag</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-responsive mb-4 d-none'>
              <table className='table table-bordered mb-4'>
                <thead className='thead-light  light-yellow-table'>
                  <tr>
                    <th>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='insuranceReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='insuranceReports'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Insurance Reports</span>
                        </label>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='insurancesReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='insurancesReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Insurances Reports</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='insurancesAssetReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='insurancesAssetReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Insurance by Asset Tag</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-xl-6'>
            <div className='table-responsive mb-4 d-none'>
              <table className='table table-bordered mb-4'>
                <thead className='thead-light  light-yellow-table'>
                  <tr>
                    <th>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='contractReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='contractReports'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Contract/License Reports</span>
                        </label>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='contractsReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='contractsReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Contracts Reports</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='contractAssetReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='contractAssetReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Contract by Asset Tag</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label
                          className='radio mb-0 d-inline-flex'
                          htmlFor='contractSoftwareReport'
                        >
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='contractSoftwareReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Contracts by Software License Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-responsive mb-4 d-none'>
              <table className='table table-bordered mb-4'>
                <thead className='thead-light  light-yellow-table'>
                  <tr>
                    <th>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='assetMaintenance'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='assetMaintenance'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Asset Maintenance Reports</span>
                        </label>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='maintenanceAssetTag'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='maintenanceAssetTag'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Maintenance Schedule by Asset Tag Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label
                          className='radio mb-0 d-inline-flex'
                          htmlFor='maintenancePersonReport'
                        >
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='maintenancePersonReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Maintenance Schedule by Assigned Person Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label
                          className='radio mb-0 d-inline-flex'
                          htmlFor='assetMaintenanceHistory'
                        >
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='assetMaintenanceHistory'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Asset Maintenance History by Asset Tag Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='assetMaintenanceDate'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='assetMaintenanceDate'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Asset Maintenance History by Date Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-responsive mb-4 d-none'>
              <table className='table table-bordered mb-4'>
                <thead className='thead-light  light-yellow-table'>
                  <tr>
                    <th>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='auditReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='auditReports'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Audit Reports</span>
                        </label>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='auditAssetTagReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='auditAssetTagReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Asset Audit History by Asset Tag Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='auditDateReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='auditDateReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Asset Audit History by Date Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='auditSiteReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='auditSiteReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Asset Audit History by Site/Location Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='nonAuditAssetReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='nonAuditAssetReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Non-Audited Assets Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label
                          className='radio mb-0 d-inline-flex'
                          htmlFor='auditDiscrepancyReport'
                        >
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='auditDiscrepancyReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Audit Site Discrepancy</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='auditFundingReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='auditFundingReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Audit by Funding Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='nonAuditFundingReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='nonAuditFundingReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>
                            Non-Audited Funding Assets Report
                          </span>
                        </label>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-responsive mb-4 d-none'>
              <table className='table table-bordered mb-4'>
                <thead className='thead-light  light-yellow-table'>
                  <tr>
                    <th>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='otherReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='otherReports'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Other Reports</span>
                        </label>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='customerListReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='customerListReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Customer List Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='categoryListReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='categoryListReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Category List Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='departmentListReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='departmentListReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Department List Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='employeeListReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='employeeListReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Employee List Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='locationListReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='locationListReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Location List Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='subCategoryListReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='subCategoryListReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Sub Category List Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-responsive mb-4 d-none'>
              <table className='table table-bordered mb-4'>
                <thead className='thead-light  light-yellow-table'>
                  <tr>
                    <th>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='statusReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='statusReports'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Status Reports</span>
                        </label>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='assetsRepairReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='assetsRepairReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Assets Under Repair</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='brokenAssetReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='brokenAssetReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Broken Assets Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='disposedAssetReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='disposedAssetReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Disposed Assets Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='donatedAssetReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='donatedAssetReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Donated Assets Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='leasedAssetReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='leasedAssetReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Leased Assets Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='lostAssetReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='lostAssetReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Lost Assets Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='soldAssetReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='soldAssetReport'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Sold Assets Report</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-responsive mb-4 d-none'>
              <table className='table table-bordered mb-4'>
                <thead className='thead-light  light-yellow-table'>
                  <tr>
                    <th>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='transactionReports'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='transactionReports'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Transaction Reports</span>
                        </label>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='addAssetsReport'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              checked={checked?.addAsset}
                              id='addAssetsReport'
                              name='hasCheckOut'
                              onChange={(e) => handleCheckboxChange('addAsset', e.target.checked)}
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Add Assets</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='brokenAsset'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='brokenAsset'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Broken Asset</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='checkout/Checkin'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              checked={checked?.addCheckout}
                              id='checkout/Checkin'
                              name='hasCheckOut'
                              onChange={(e) =>
                                handleCheckboxChange('addCheckout', e.target.checked)
                              }
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Checkout/Check in</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='disposedAssets'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='disposedAssets'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Disposed Assets</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='donatedAssets'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              checked={checked?.updateAsset}
                              id='donatedAssets'
                              name='hasCheckOut'
                              onChange={(e) =>
                                handleCheckboxChange('updateAsset', e.target.checked)
                              }
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Donated Assets</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='editAssets'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              checked={checked?.updateAsset}
                              id='editAssets'
                              name='hasCheckOut'
                              onChange={(e) =>
                                handleCheckboxChange('updateAsset', e.target.checked)
                              }
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Edit Assets</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='lease-leaseReturn'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='lease-leaseReturn'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Lease/Lease return</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='lost-missingAssets'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='lost-missingAssets'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Lost/Missing Assets</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='moveAssets'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='moveAssets'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Move Assets</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='repairAssets'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='repairAssets'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Repair Assets</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='reserveAssets'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              checked={checked?.addReserve}
                              id='reserveAssets'
                              name='hasCheckOut'
                              onChange={(e) => handleCheckboxChange('addReserve', e.target.checked)}
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Reserve Assets</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='sellAssets'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='sellAssets'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Sell Assets</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='transactionHistory'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='transactionHistory'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Transaction History</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='mb-0'>
                        <label className='radio mb-0 d-inline-flex' htmlFor='userActions'>
                          <div>
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input me-2'
                              id='userActions'
                              name='hasCheckOut'
                              value='True'
                            />
                          </div>
                          <span className='vertically_align'>Actions by Users</span>
                        </label>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportsTab
