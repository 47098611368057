import { useEffect, useState } from 'react'

function AssetsTagList({ criteria, clearSearch, searchAssets }) {
  const [value, setValue] = useState([])

  useEffect(() => {
    const savedCriteria = JSON.parse(sessionStorage.getItem('criteria') || '{}')
    setValue(savedCriteria)
  }, [criteria])

  useEffect(() => {
    if (Object.keys(criteria).length > 0) {
      setValue(criteria)
      sessionStorage.setItem('criteria', JSON.stringify(criteria))
      sessionStorage.setItem('searchAssets', JSON.stringify(searchAssets))
    }
  }, [criteria])

  return (
    <div className='inputtags'>
      {Object.keys(value).map((key, index) => {
        const values = value[key]
        if (values) {
          return (
            <span className='tag' key={index}>
              <span className='lbl'>
                {key === 'keyword'
                  ? 'Keyword'
                  : key === 'searchField'
                    ? 'Search Fields'
                    : key === 'siteId'
                      ? 'Site'
                      : key === 'locationId'
                        ? 'Location'
                        : key === 'categoryId'
                          ? 'Category'
                          : key === 'departmentId'
                            ? 'Department'
                            : key === 'person'
                              ? 'Person'
                              : key === 'customer'
                                ? 'Customer'
                                : key === 'status'
                                  ? 'Status'
                                  : key === 'groupBy'
                                    ? 'Group By'
                                    : key === 'recordCount'
                                      ? 'Records Count'
                                      : key === 'dateRangeBy'
                                        ? 'Date Range By'
                                        : key === 'quickDateRange'
                                          ? 'Quick Date Range'
                                          : key === 'purchasedDate'
                                            ? 'Purchased Date'
                                            : key === 'dateCreated'
                                              ? 'Date Created'
                                              : key === 'startDate'
                                                ? 'Start Date'
                                                : key === 'endDate'
                                                  ? 'End Date'
                                                  : ''}
              </span>
              <div className='d-flex align-items-center justify-content-between'>
                <span className='tag-values'>
                  {key === 'searchField' || key === 'status'
                    ? values?.split(',')[0].trim() === 'All'
                      ? values.split(',').slice(1).join(',')
                      : values
                    : ' ' + values}
                </span>
                <button
                  title='Remove tag'
                  onClick={() => clearSearch(key)}
                  className='bg-transparent border-0 ms-5'
                >
                  <i className='la la-close fs-5 text-secondary vertical-1'></i>
                </button>
              </div>
            </span>
          )
        }
        return null
      })}
    </div>
  )
}

export default AssetsTagList
