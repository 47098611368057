import { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Loader from 'App/Loaders/BeatLoader'
import { useGetWarrantyQuery } from 'App/Modules/Services/Warranty'
import { toast } from 'react-toastify'
import moment from 'moment'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

const ViewAssetModal = (props: any) => {
  const [data, setData] = useState<any>({})
  const { saveAuth, setCurrentUser } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const warrantyId = props.warrantyId
  const {
    data: warrantyData,
    error,
    isLoading,
    isSuccess,
  } = useGetWarrantyQuery({ id: warrantyId }, { skip: !warrantyId })

  useEffect(() => {
    if (isLoading) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [isLoading])

  useEffect(() => {
    switch (true) {
      case isSuccess && !!warrantyData: {
        const { warranty } = warrantyData
        setData(warranty)
        setLoading(false)
        break
      }
      case !isSuccess && !!error:
        setLoading(false)
        if (toast) {
          toast.error('Something went wrong while fetching the details')
          checkUserUnauthorized(error, saveAuth, setCurrentUser, toast)
        }
        if (props && props.handleClose) {
          props.handleClose()
        }
        break

      default:
        break
    }
  }, [warrantyData, isSuccess, error])

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Modal
          show={props.show}
          onHide={() => {
            props.handleClose()
          }}
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Asset Warranty</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='assetProduct_detail mb-5'>
              <div className='asset_detail_table table-responsive'>
                <table
                  className='table table-bordered detail-table table-sm mb-0'
                  aria-label='Quick View'
                  role='presentation'
                >
                  <tbody>
                    <tr>
                      <td className='label_col bg-light'>Length</td>
                      <td className='fw-bold detail_col table-elipses'>{data?.length}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Expiration Date</td>
                      <td className='fw-bold detail_col table-elipses'>
                        {data?.expiryDate && moment(data?.expiryDate).format('MM/DD/YYYY')}
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Notes</td>
                      <td
                        className={`${data?.notes ? 'fw-bold' : 'text-muted'} detail_col table-elipses`}
                      >
                        {data?.notes || "You don't write any note"}
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Created by</td>
                      <td className='fw-bold detail_col table-elipses'>
                        {data && data.User ? `${data.User.firstName} ${data.User.lastName}` : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='text-end pt-2'>
              <div
                className='btn btn-light-primary   main-btn-style me-2'
                onClick={() => {
                  props.handleClose()
                }}
              >
                Close{' '}
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default ViewAssetModal
