import { useAuth } from 'App/Modules/Auth'
import { Images } from 'Template/assets/Assets'
import { toAbsoluteUrlImage } from 'Template/helpers'
import { dateFormat, defaultDateFormat, getAssetStatusClass } from 'config'
import { ColumnDataProps } from '../Core/Model'

const date = new Date()

export const ColumnData: React.FC<ColumnDataProps> = ({ column, assetsData, currencyLogo }) => {
  const { currentUser } = useAuth()
  const renderData = () => {
    switch (column) {
      case 'Asset Photo':
        return assetsData.assets.map((asset: any, index: number) => (
          <div className='d-inline align-items-center asset-img-table'>
            {asset?.photo?.length > 0 ? (
              <img
                src={
                  asset?.photo[0]?.uploadType === 1
                    ? toAbsoluteUrlImage(asset?.photo[0]?.photo)
                    : asset?.photo[0]?.stockImage?.imgUrl
                }
                alt='asset'
                className='img-fluid'
                crossOrigin='anonymous'
              />
            ) : (
              <img src={Images.AssetsPlaceholder} alt='asset' className='img-fluid' />
            )}
          </div>
        ))
      case 'Asset Tag ID':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>{asset.assetTagId}</span>
        ))
      case 'Description':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>{asset.description}</span>
        ))
      case 'Brand':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>{asset.brand}</span>
        ))
      case 'Purchased Date':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.purchasedDate
              ? dateFormat(asset?.purchasedDate, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Cost':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>{asset?.cost ? currencyLogo + ' ' + asset?.cost : ''}</span>
        ))
      case 'Status':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>
            <div
              className={`d-inline align-items-center px-3 py-2 rounded ${getAssetStatusClass(asset?.status)}`}
            >
              {asset?.status}
            </div>
          </span>
        ))
      case 'Created By':
        return assetsData?.assets?.map((asset: any, index: any) => {
          const { creator } = asset
          const fullName = creator ? `${creator.firstName} ${creator.lastName}` : ''
          return <span key={index}>{fullName}</span>
        })
      case 'Date Created':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.createdAt
              ? dateFormat(asset?.createdAt, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Model':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>{asset?.model}</span>
        ))
      case 'Purchased From':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>{asset?.purchasedFrom}</span>
        ))
      case 'Serial No':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>{asset?.serialNo}</span>
        ))
      case 'Reservation':
        return assetsData.assets.map((asset: any, index: number) => {
          return (
            <span key={index}>
              {asset?.reservationDate && (
                <>
                  Res.{' '}
                  {dateFormat(asset?.reservationDate, currentUser?.dateFormat || defaultDateFormat)}
                </>
              )}
            </span>
          )
        })
      case 'Category':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>{asset?.category?.category}</span>
        ))
      case 'Department':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>{asset?.department?.department}</span>
        ))
      case 'Location':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>{asset?.location?.location}</span>
        ))
      case 'Site':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>{asset?.site?.name}</span>
        ))
      case 'Assigned to':
        return assetsData.assets.map((asset: any, index: number) =>
          asset?.assigneeType === 1 ? (
            <span key={index}>
              {`${asset?.assignedPerson?.firstName || ''} ${asset?.assignedPerson?.lastName || ''}`}
            </span>
          ) : asset?.assigneeType === 2 ? (
            <span key={index}>
              {`${asset?.assignedSite?.name || ''} ${
                asset?.assignedSite?.name && asset.location?.location ? '/' : ''
              } ${asset?.location?.location || ''}`}
            </span>
          ) : null
        )
      case 'Event Date':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.eventDate
              ? dateFormat(asset?.eventDate, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Event Due Date':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.eventDueDate
              ? dateFormat(asset?.eventDueDate, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Event Notes':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>{asset?.eventNotes || ''}</span>
        ))
      case 'Leased to':
        return assetsData.assets.map((asset: any, index: number) => (
          <span key={index}>{`${asset?.leased?.firstName || ''} ${
            asset?.leased?.lastName || ''
          }`}</span>
        ))
      case 'RAM':
        return assetsData.assets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.ram}</span>
        ))
      case 'Storage':
        return assetsData.assets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.storage}</span>
        ))
      case 'IMEI':
        return assetsData.assets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.imei}</span>
        ))
      case 'Ports':
        return assetsData.assets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.ports}</span>
        ))
      case 'Mbps':
        return assetsData.assets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.mbps}</span>
        ))
      case 'Stands':
        return assetsData.assets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.stands}</span>
        ))
      case 'Batteries':
        return assetsData.assets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.batteries}</span>
        ))
      case 'Bulbs':
        return assetsData.assets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.bulbs}</span>
        ))
      case 'Lens':
        return assetsData.assets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.lens}</span>
        ))
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}

export const SampleColumnData: React.FC<ColumnDataProps> = ({ column }) => {
  const { currentUser } = useAuth()
  const renderData = () => {
    switch (column) {
      case 'Asset Photo':
        return 'Sample Photo'
      case 'Asset Tag ID':
        return 'Sample TagId'
      case 'Description':
        return 'Sample Description'
      case 'Brand':
        return 'Sample Brand'
      case 'Purchased Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Cost':
        return 'RS 1000'
      case 'Status':
        return 'Sample Status'
      case 'Created By':
        return 'Sample userName'
      case 'Date_Created':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Model':
        return 'Sample Model'
      case 'Purchased From':
        return 'Sample Purchased Location'
      case 'Serial No':
        return 'Sr-12'
      case 'Date Created':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Reservation':
        return 'Sample Reservation'
      case 'Category':
        return 'Sample Category'
      case 'Department':
        return 'Sample Department'
      case 'Location':
        return 'Sample Location'
      case 'Site':
        return 'Sample Site'
      case 'Assigned to':
        return 'Sample Assigned'
      case 'Event Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Event Due Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Event Notes':
        return 'Sample Event Notes'
      case 'Leased to':
        return 'Sample Customer Name'
      case 'Relation':
        return 'Sample Relationship'
      case 'RAM':
        return 'Sample RAM'
      case 'Storage':
        return 'Sample Storage'
      case 'IMEI':
        return 'Sample IMEI'
      case 'Ports':
        return 'Sample Ports'
      case 'Mbps':
        return 'Sample Mbps'
      case 'Stands':
        return 'Sample Stands'
      case 'Batteries':
        return 'Sample Batteries'
      case 'Bulbs':
        return 'Sample Bulbs'
      case 'Lens':
        return 'Sample Lens'
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}
