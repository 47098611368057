import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function StopEmails(stopEmailPara_1) {
  return (
    <Row className='mb-6'>
      <Col lg="2">
        <div className='col-form-label fw-bold fs-6'>Email Frequency</div>
      </Col>
      <Col lg="10">
        <div className='fv-row'>
          <p className='mb-2 text-muted'>
            {' '}
            <i className='la la-info-circle fs-5 text-muted'></i> When do you want us to stop sending
            the alert email?
          </p>
          <p>{stopEmailPara_1.stopEmailPara_1}</p>
        </div>
      </Col>
    </Row>
  )
}

export default StopEmails
