import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const assetHistoryApi = createApi({
  reducerPath: 'assetHistory',
  baseQuery: customFetchBaseQuery({ path: 'asset-history' }),
  tagTypes: ['History'],
  endpoints: (build) => ({
    getAssetHistory: build.query<any, any>({
      query: ({ id }) => ({
        url: `/get/${id}`,
        method: 'GET',
      }),
      providesTags: ['History'],
    }),
  }),
})

export const { useGetAssetHistoryQuery } = assetHistoryApi
