import Loader from 'App/Loaders/BeatLoader'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { useGetAssetHistoryQuery } from 'App/Modules/Services/AssetHistory'
import { convertFieldsToJSON, dateFormat, defaultDateFormat } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const AssetHistory = ({ assetDetail, refreshKey }) => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const ownerUser = currentUser?.userType === 1
  const permission = currentUser?.SecurityGroup
  const personUser = currentUser?.userType === 3
  const customerUser = currentUser?.userType === 4
  const staffUser = currentUser?.userType === 2

  const [histories, setHistories] = useState<any>([])

  const { data, error, isLoading, refetch, isRefetching, isError, isSuccess }: any =
    useGetAssetHistoryQuery(
      { id: assetDetail?.asset?.id },
      { skip: personUser || customerUser || (staffUser && !permission?.viewHistory) }
    )

  useEffect(() => {
    if (ownerUser || permission?.viewHistory) {
      refetch()
    }
  }, [])

  useEffect(() => {
    if (ownerUser || permission?.viewHistory) {
      refetch()
    }
  }, [refreshKey])

  useEffect(() => {
    if (isSuccess && data) {
      if (data?.history?.length > 0) {
        const history = data.history.map((history) => {
          return {
            ...history,
            fieldDetails: convertFieldsToJSON(history?.fieldDetails),
          }
        })
        setHistories(history)
      }
    } else if (error && isError) {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error, saveAuth, setCurrentUser, toast)
    }
  }, [data, error, isError, isSuccess])

  return (
    <>
      {isLoading || isRefetching ? (
        <Loader loading={isLoading || isRefetching} />
      ) : (
        <>
          {histories?.length > 0 ? (
            histories?.map((history: any, idx: any) => {
              return (
                <tr key={idx} role='row'>
                  <td role='cell' className='event-cell sss'>
                    <div className='table-elipses'>
                      {history?.createdAt
                        ? dateFormat(
                            history?.createdAt,
                            currentUser?.dateFormat || defaultDateFormat
                          )
                        : ''}
                    </div>
                  </td>
                  <td role='cell' className='event-cell'>
                    <div className='table-elipses'>{history?.event}</div>
                  </td>
                  <td role='cell' className='event-cell'>
                    {history?.fieldDetails?.map((fields) => {
                      return (
                        <>
                          <tr>
                            <td>
                              <div className='table-elipses'>{fields?.field}</div>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </td>
                  <td role='cell' className='event-cell'>
                    {history?.fieldDetails?.map((fields) => {
                      return (
                        <>
                          <tr>
                            <td>
                              <div className='table-elipses'>{fields?.changedFrom || ' '}</div>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </td>
                  <td role='cell' className='event-cell'>
                    {history?.fieldDetails?.map((fields) => {
                      return (
                        <>
                          <tr>
                            <td>
                              <div className='table-elipses'>{fields?.changedTo || ' '}</div>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </td>
                  <td role='cell' className='event-cell'>
                    <div className='table-elipses'>{`${history?.actionCreator?.firstName} ${history?.actionCreator?.lastName}`}</div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={8}>
                <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                  No records found
                </div>
              </td>
            </tr>
          )}
        </>
      )}
      <tbody className='text-gray-600 fw-bold' role='rowgroup'></tbody>
    </>
  )
}

export default AssetHistory
