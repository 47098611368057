import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { useGetAssetEventsQuery } from 'App/Modules/Services/AssetEventHistory'
import { dateFormat, defaultDateFormat } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const MoreDetails = ({
  assetDetail,
  personUser,
  customerUser,
  refreshKey,
  permission,
  ownerUser,
  staffUser,
}) => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const [events, setEvents] = useState([])

  const { data, error, isSuccess, isError, refetch, isRefeching } = useGetAssetEventsQuery(
    {
      id: assetDetail?.asset?.id,
    },
    { skip: personUser || customerUser || (staffUser && !permission?.viewEvent) }
  )

  useEffect(() => {
    if (isSuccess && data) {
      setEvents(data?.events)
    } else if (error) {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error, saveAuth, setCurrentUser, toast)
    }
  }, [data, error, isSuccess, isError])

  const lastIndex = events?.length - 1
  const lastEvent = events[lastIndex]

  useEffect(() => {
    if (ownerUser || permission?.viewHistory) {
      refetch()
    }
  }, [refreshKey])

  return (
    <div className='tab-content'>
      <h4 className='mb-5'>Asset Details</h4>
      <Row className='form-group mb-3'>
        <Col lg="2" className='d-flex align-items-center'>
          <h6 className='fw-normal'>Miscellaneous</h6>
        </Col>
        <Col lg="5">
          <table
            className='table table-bordered table-view-detail table-sm mb-lg-0'
            role='presentation'
            aria-label='Asset Details'
          >
            <tbody>
              <tr>
                <td className='label_col w-50'>Serial No</td>
                <td className='detail-column fw-bold w-50'>{assetDetail?.asset?.serialNo}</td>
              </tr>
              <tr>
                <td className='label_col w-50'>RAM</td>
                <td className='detail-column fw-bold w-50'>
                <span className='d-block text-break pre-line'>{assetDetail?.asset?.ram}</span>
                  </td>
              </tr>
              <tr>
                <td className='label_col w-50'>Storage</td>
                <td className='detail-column fw-bold w-50'>{assetDetail?.asset?.storage}</td>
              </tr>
              <tr>
                <td className='label_col w-50'>IMEI Number</td>
                <td className='detail-column fw-bold w-50'>{assetDetail?.asset?.imei}</td>
              </tr>
              <tr>
                <td className='label_col w-50'>Ports</td>
                <td className='detail-column fw-bold w-50'>{assetDetail?.asset?.ports}</td>
              </tr>
              <tr>
                <td className='label_col w-50'>Mbps</td>
                <td className='detail-column fw-bold w-50'>{assetDetail?.asset?.mbps}</td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col lg="5">
          <table
            className='table table-bordered table-view-detail table-sm mb-lg-0'
            role='presentation'
            aria-label='Asset Details'
          >
            <tbody>
              {!(personUser || customerUser) && (
                <tr>
                  <td className='label_col w-50'>Purchased From</td>
                  <td className='detail-column fw-bold w-50'>
                    {assetDetail?.asset?.purchasedFrom}
                  </td>
                </tr>
              )}
              <tr>
                <td className='label_col w-50'>Stands</td>
                <td className='detail-column fw-bold w-50'>{assetDetail?.asset?.stands}</td>
              </tr>
              <tr>
                <td className='label_col w-50'>Batteries</td>
                <td className='detail-column fw-bold w-50'>{assetDetail?.asset?.batteries}</td>
              </tr>
              <tr>
                <td className='label_col w-50'>Bulbs</td>
                <td className='detail-column fw-bold w-50'>{assetDetail?.asset?.bulbs}</td>
              </tr>
              <tr>
                <td className='label_col w-50'>Lens</td>
                <td className='detail-column fw-bold w-50'>{assetDetail?.asset?.lens}</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      {lastEvent &&  /* last event data */
        !(personUser || customerUser) &&
        (() => {
          switch (lastEvent.event) {
            case 'Repair':
              return (
                <Row className='form-group mb-3'>
                  <Col lg="2" className='d-flex align-items-center'>
                    <h6 className='fw-normal'>{lastEvent.event}</h6>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Schedule Date </td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent.repair?.scheduleDate
                              ? dateFormat(
                                lastEvent.repair.scheduleDate,
                                currentUser?.dateFormat || defaultDateFormat
                              )
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td className='label_col w-50'>Date Completed </td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent.repair?.completeDate
                              ? dateFormat(
                                lastEvent.repair.completeDate,
                                currentUser?.dateFormat || defaultDateFormat
                              )
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td className='label_col w-50'>Repair Cost </td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent.repair?.repairCost}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Assigned to </td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent.repair?.assignedTo}
                          </td>
                        </tr>
                        <tr>
                          <td className='label_col w-50'>Notes</td>
                          <td className='detail-column fw-bold w-50'>
                            <span className='d-block text-break pre-line'>{lastEvent.repair?.notes}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )
            case 'Broken':
              return (
                <Row className='form-group mb-3'>
                  <Col lg="2" className='d-flex align-items-center'>
                    <h6 className='fw-normal'>{lastEvent.event}</h6>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Date Broken </td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent.broken?.brokenDate
                              ? dateFormat(
                                lastEvent.broken.brokenDate,
                                currentUser?.dateFormat || defaultDateFormat
                              )
                              : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Notes</td>
                          <td className='detail-column fw-bold w-50'>
                            <span className='d-block text-break pre-line'>{lastEvent.broken?.notes}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )
            case 'Lost/Missing':
              return (
                <Row className='form-group mb-3'>
                  <Col lg="2" className='d-flex align-items-center'>
                    <h6 className='fw-normal'>{lastEvent.event}</h6>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Date Lost </td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent.lost?.lostDate
                              ? dateFormat(
                                lastEvent.lost.lostDate,
                                currentUser?.dateFormat || defaultDateFormat
                              )
                              : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Notes</td>
                          <td className='detail-column fw-bold w-50'>
                            <span className='d-block text-break pre-line'> {lastEvent.lost?.notes}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )
            case 'Found':
              return (
                <Row className='form-group mb-3'>
                  <Col lg="2" className='d-flex align-items-center'>
                    <h6 className='fw-normal'>{lastEvent.event}</h6>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Date Found </td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent.found?.foundDate
                              ? dateFormat(
                                lastEvent.found.foundDate,
                                currentUser?.dateFormat || defaultDateFormat
                              )
                              : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Notes</td>
                          <td className='detail-column fw-bold w-50'>
                            <span className='d-block text-break pre-line'> {lastEvent.found?.notes}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )
            case 'Donate':
              return (
                <Row className='form-group mb-3'>
                  <Col lg="2" className='d-flex align-items-center'>
                    <h6 className='fw-normal'>{lastEvent.event}</h6>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Donate Value </td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent?.donate?.donateValue}
                          </td>
                        </tr>
                        <tr>
                          <td className='label_col w-50'>Date Donated </td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent?.donate?.donatedDate
                              ? dateFormat(
                                lastEvent?.donate?.donatedDate,
                                currentUser?.dateFormat || defaultDateFormat
                              )
                              : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Donate to</td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent?.donate?.donateTo}
                          </td>
                        </tr>
                        <tr>
                          <td className='label_col w-50'>Deductible</td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent?.donate?.deductible === true ? 'Yes' : 'No'}
                          </td>
                        </tr>
                        <tr>
                          <td className='label_col w-50'>Notes</td>
                          <td className='detail-column fw-bold w-50'>
                            <span className='d-block text-break pre-line'>{lastEvent?.donate?.notes}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )
            case 'Sell':
              return (
                <Row className='form-group mb-3'>
                  <Col lg="2" className='d-flex align-items-center'>
                    <h6 className='fw-normal'>{lastEvent.event}</h6>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Sale amount </td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent?.sell?.saleAmount}
                          </td>
                        </tr>
                        <tr>
                          <td className='label_col w-50'>Sold to</td>
                          <td className='detail-column fw-bold w-50'>{lastEvent?.sell?.soldTo}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Sale Date</td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent?.sell?.saleDate
                              ? dateFormat(
                                lastEvent?.sell?.saleDate,
                                currentUser?.dateFormat || defaultDateFormat
                              )
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td className='label_col w-50'>Notes</td>
                          <td className='detail-column fw-bold w-50'>
                            <span className='d-block text-break pre-line'>{lastEvent?.sell?.notes}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )
            case 'Check out':
              return (
                <Row className='form-group mb-3'>
                  <Col lg="2" className='d-flex align-items-center'>
                    <h6 className='fw-normal'>{lastEvent.event}</h6>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Assigned to</td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent?.checkOut?.assigneeType == 1 ? (
                              <span>
                                {`${lastEvent?.checkOut?.Person?.firstName || ''} ${lastEvent?.checkOut?.Person?.lastName || ''
                                  }`}
                              </span>
                            ) : lastEvent?.checkOut?.assigneeType == 2 ? (
                              <span>
                                {`${lastEvent?.checkOut?.Site?.name || ''} ${lastEvent?.checkOut?.Site?.name &&
                                  lastEvent?.checkOut?.Location?.location
                                  ? '/'
                                  : ''
                                  } ${lastEvent?.checkOut?.Location?.location || ''}`}
                              </span>
                            ) : (
                              <span></span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className='label_col w-50'>Check-out Date</td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent?.checkOut?.checkoutDate
                              ? dateFormat(
                                lastEvent?.checkOut?.checkoutDate,
                                currentUser?.dateFormat || defaultDateFormat
                              )
                              : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Check-out Notes</td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent?.checkOut?.notes}
                          </td>
                        </tr>
                        <tr>
                          <td className='label_col w-50'>Due date </td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent?.checkOut?.dueDate
                              ? dateFormat(
                                lastEvent?.checkOut?.dueDate,
                                currentUser?.dateFormat || defaultDateFormat
                              )
                              : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )
            case 'Lease':
              return (
                <Row className='form-group mb-3'>
                  <Col lg="2" className='d-flex align-items-center'>
                    <h6 className='fw-normal'>{lastEvent.event}</h6>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Leased to</td>
                          <td className='detail-column fw-bold w-50'>
                            {`${lastEvent?.leased?.Customer?.firstName || ''} ${lastEvent?.leased?.Customer?.lastName || ''}`}
                          </td>
                        </tr>
                        <tr>
                          <td className='label_col w-50'>Lease Begins </td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent?.leased?.startDate
                              ? dateFormat(
                                lastEvent?.leased?.startDate,
                                currentUser?.dateFormat || defaultDateFormat
                              )
                              : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Lease Notes </td>
                          <td className='detail-column fw-bold w-50'>
                            {' '}
                            {lastEvent?.leased?.notes}
                          </td>
                        </tr>
                        <tr>
                          <td className='label_col w-50'>Lease Expires </td>
                          <td className='detail-column fw-bold w-50'>
                            {lastEvent?.leased?.expiryDate
                              ? dateFormat(
                                lastEvent?.leased?.expiryDate,
                                currentUser?.dateFormat || defaultDateFormat
                              )
                              : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )
            case 'Dispose':
              return (
                <Row className='form-group mb-3'>
                  <Col lg="2" className='d-flex align-items-center'>
                    <h6 className='fw-normal'>{lastEvent?.event}</h6>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Date Disposed</td>
                          <td className='detail-column fw-bold'>
                            {dateFormat(
                              lastEvent?.dispose?.dateDispose,
                              currentUser?.dateFormat || defaultDateFormat
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col lg="5">
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Notes</td>
                          <td className='detail-column fw-bold'>
                            <span className='d-block text-break pre-line'>{lastEvent?.dispose?.notes}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col lg="5" className='offset-lg-2 '>
                    <table
                      className='table table-bordered table-view-detail table-sm mb-lg-0'
                      role='presentation'
                      aria-label='Asset Details'
                    >
                      <tbody>
                        <tr>
                          <td className='label_col w-50'>Dispose To</td>
                          <td className='detail_col fw-bold'>{lastEvent?.dispose?.disposedTo}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )
          }
        })()}
      {!(personUser || customerUser) && (
        <Row className='form-group row mat-asset-detail-event'>
          <Col lg="2" className='d-flex align-items-center'>
            <h6 className='fw-normal'>Creation</h6>
          </Col>
          <Col lg="5">
            <table
              className='table table-bordered table-view-detail table-sm mb-lg-0'
              aria-label='Assets View'
              role='presentation'
            >
              {!(personUser || customerUser) && (
                <tbody>
                  <tr>
                    <td className='label_col w-50'>Date Created</td>
                    <td className='detail-column fw-bold w-50'>
                      {dateFormat(
                        assetDetail?.asset?.createdAt,
                        currentUser?.dateFormat || defaultDateFormat
                      )}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </Col>
          <Col lg="5">
            <table
              className='table table-bordered table-view-detail table-sm mb-0'
              aria-label='Assets View'
              role='presentation'
            >
              <tbody>
                <tr>
                  <td className='label_col w-50'>Created by</td>
                  <td className='detail-column fw-bold w-50'>
                    {assetDetail?.asset?.creator?.firstName +
                      ' ' +
                      assetDetail?.asset?.creator?.lastName}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default MoreDetails
