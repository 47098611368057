import { useAuth } from 'App/Modules/Auth'
import { useGetAuditByAuditIdQuery, useUpdateAuditByIdMutation } from 'App/Modules/Services/Audit'
import { useGetAllAuditNamesQuery } from 'App/Modules/Services/AuditName'
import { useGetAllLocationsBySiteQuery } from 'App/Modules/Services/Locations'
import { useGetAllSitesQuery } from 'App/Modules/Services/Sites'
import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Select from 'react-select'
import { Formik, Field, Form as FormikForm } from 'formik'
import { toast } from 'react-toastify'

interface AuditModalProps {
  assetAuditModal: boolean
  handleClose: () => void
  auditId: any
}

interface OptionType {
  value: string
  label: string
}

const AssetAuditModal: React.FC<AuditModalProps> = ({ assetAuditModal, handleClose, auditId }) => {
  const { currentUser } = useAuth()
  const [selectedAuditName, setSelectedAuditName] = useState<OptionType | null>(null)
  const [selectedSite, setSelectedSite] = useState<OptionType | null>(null)
  const [selectedLocation, setSelectedLocation] = useState<OptionType | null>(null)
  const [auditNameList, setAuditNameList] = useState<any>([])
  const [siteList, setSiteList] = useState([])
  const [locationList, setLocationList] = useState<any>([])
  const [initialAuditData, setInitialAuditData] = useState<any>(null)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [searchAssets, setSearchAssets] = useState<any>({
    siteId: '',
    locationId: '',
    categoryId: '',
    assetTagIds: '',
  })

  const { data: auditNameData } = useGetAllAuditNamesQuery({
    userId: currentUser?.id,
  })

  const { data: siteData } = useGetAllSitesQuery({
    userId: currentUser?.id,
  })

  const { data: locationData } = useGetAllLocationsBySiteQuery(
    { userId: currentUser?.id, id: searchAssets?.siteId },
    { skip: !searchAssets?.siteId }
  )

  const { data: auditData } = useGetAuditByAuditIdQuery({ auditId })

  useEffect(() => {
    if (auditData) {
      setInitialAuditData(auditData?.audit)
    }
  }, [auditData])

  useEffect(() => {
    if (initialAuditData) {
      if (initialAuditData.auditname) {
        setSelectedAuditName({
          value: initialAuditData.auditNameId,
          label: initialAuditData.auditname.name,
        })
      }
      if (initialAuditData.site) {
        setSelectedSite({
          value: initialAuditData.siteId,
          label: initialAuditData.site.name,
        })
      }
      if (initialAuditData.location) {
        setSelectedLocation({
          value: initialAuditData.locationId,
          label: initialAuditData.location.location,
        })
      }
    }
  }, [initialAuditData])

  const [updateAudit] = useUpdateAuditByIdMutation()

  useEffect(() => {
    if (auditNameData) {
      const options = auditNameData?.auditNames?.map((item) => ({
        value: item?.id,
        label: item?.name,
        status: item?.status,
      }))
      setAuditNameList(options)
    }
  }, [auditNameData])

  useEffect(() => {
    if (siteData) {
      const options = siteData?.sites?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }))
      setSiteList(options)
    }
  }, [siteData])

  useEffect(() => {
    if (locationData) {
      const options = locationData?.locations?.map((item) => ({
        value: item?.id,
        label: item?.location,
      }))
      setLocationList(options)
    }
  }, [locationData])

  const handleAuditNameFilter = (selectedOption) => {
    setSelectedAuditName(selectedOption)
  }

  const handleSelectChange = (selectedOption) => {
    setSelectedSite(selectedOption)
    setSearchAssets((prevState) => ({
      ...prevState,
      siteId: selectedOption?.value,
    }))
  }

  const handleLocationFilter = (selectedOption) => {
    setSelectedLocation(selectedOption)
    setSearchAssets((prevState) => ({
      ...prevState,
      locationId: selectedOption?.value,
    }))
  }

  const handleCheckboxChange = (setFieldValue) => {
    setFieldValue('map', !isChecked)
    setIsChecked(!isChecked)
  }

  return (
    <Modal className='edit-audit-modal' show={assetAuditModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Asset Audit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {initialAuditData && (
          <Formik
            initialValues={{
              auditNameId: initialAuditData?.auditNameId || '',
              siteId: initialAuditData?.siteId || '',
              locationId: initialAuditData?.locationId || '',
              notes: initialAuditData?.notes || '',
              map: initialAuditData?.map || false,
              latitude: initialAuditData?.latitude || '',
              longitude: initialAuditData?.longitude || '',
              createdBy: currentUser?.id,
            }}
            enableReinitialize
            onSubmit={async (values) => {
              try {
                const res = await updateAudit({ id: auditId, values })
                if (res?.data?.success) {
                  toast?.success(res?.data?.message)
                  handleClose()
                }
              } catch (error: any) {
                throw new Error(error)
              }
            }}
          >
            {({ values, setFieldValue }) => (
              <FormikForm>
                <Form.Group controlId='formAuditName'>
                  <Form.Label className='required-label'>Audit Name</Form.Label>
                  <Select
                    name='auditNameId'
                    value={selectedAuditName}
                    onChange={(option) => {
                      handleAuditNameFilter(option)
                      setFieldValue('auditNameId', option?.value)
                    }}
                    options={auditNameList}
                    placeholder='Select an Audit Name'
                    classNamePrefix='custom-select'
                  />
                </Form.Group>
                <Form.Group controlId='formSite'>
                  <Form.Label className='required-label'>Site</Form.Label>
                  <Select
                    name='siteId'
                    value={selectedSite}
                    onChange={(option) => {
                      handleSelectChange(option)
                      setFieldValue('siteId', option?.value)
                    }}
                    options={siteList}
                    placeholder='Select Site'
                    classNamePrefix='custom-select'
                  />
                </Form.Group>
                <Form.Group controlId='formLocation'>
                  <Form.Label className='required-label'>Location</Form.Label>
                  <Select
                    name='locationId'
                    value={selectedLocation}
                    onChange={(option) => {
                      handleLocationFilter(option)
                      setFieldValue('locationId', option?.value)
                    }}
                    options={locationList}
                    placeholder='Select Location'
                    classNamePrefix='custom-select'
                  />
                </Form.Group>
                <Form.Group controlId='formAuditNotes'>
                  <Form.Label>Audit Notes</Form.Label>
                  <Field as='textarea' rows={3} name='notes' className='form-control' />
                </Form.Group>
                <Form.Group className='my-4' controlId='formGps'>
                  <div className='form-check'>
                    <Field
                      name='map'
                      type='checkbox'
                      checked={values.map}
                      onChange={() => handleCheckboxChange(setFieldValue)}
                      className='form-check-input'
                      id='recordGps'
                    />
                    <label className='form-check-label ms-2'>Record GPS Coordinates</label>
                  </div>
                </Form.Group>
                {values?.map && (
                  <>
                    <Form.Group controlId='formLatitude'>
                      <Form.Label>Latitude</Form.Label>
                      <Field
                        type='number'
                        name='latitude'
                        className='form-control'
                        placeholder='Latitude'
                      />
                    </Form.Group>
                    <Form.Group controlId='formLongitude'>
                      <Form.Label>Longitude</Form.Label>
                      <Field
                        type='number'
                        name='longitude'
                        className='form-control'
                        placeholder='Longitude'
                      />
                    </Form.Group>
                  </>
                )}
                <Modal.Footer>
                  <button className='btn cancel-btn border' onClick={handleClose}>
                    Cancel
                  </button>
                  <Button type='submit' className='btn btn-primary'>
                    Update
                  </Button>
                </Modal.Footer>
              </FormikForm>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default AssetAuditModal
