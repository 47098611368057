import { Link } from 'react-router-dom'

const UsersHeader = (props: any) => {
  return (
    <div
      className='card-body d-flex border-bottom border-bottom-gray-200 justify-content-between mb-3 align-items-center flex-wrap'
      data-kt-user-table-toolbar='base'
    >
      <h3 className='card-title mb-0'>
        <span className='me-2 align-middle'>
          <i className='la la-list fs-1' aria-hidden='true'></i>
        </span>
        <span className='card-label fw-bold fs-3 mb-1'>List of Users</span>
      </h3>
      <div className='d-flex justify-content-between'>
        {!props.permission?.addUser && props.staffUser ? (
          ''
        ) : (
          <Link
            to={'/users/add'}
            className='btn btn-light-primary main-btn-style'
            onClick={() => props.handleShow()}
          >
            <i className='la la-plus fs-3' />
            Add <span className='d-xl-inline d-none'>New Users</span>
          </Link>
        )}
      </div>
    </div>
  )
}

export { UsersHeader }
