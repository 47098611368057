import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getCurrentDate } from 'config'
import { useUpdateAssetEventMutation } from 'App/Modules/Services/Assets'
import { useDispatch } from 'react-redux'
import { refresh } from 'App/Store'
import { useNavigate } from 'react-router-dom'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

interface NewAuditModalProps {
  editDonateEventModal: boolean
  handleClose: () => void
  assetId: any
  event: any
  currencyObject: any
}

const validationSchema = Yup.object().shape({
  donatedDate: Yup.string().required('Date Donated is required.'),
  donateValue: Yup.number()
    .required('Donate Value is required.')
    .positive('Donate Value must be positive.'),
  deductible: Yup.boolean().required('Deductible is required.'),
  notes: Yup.string().max(250, 'Note should not exceed 250 characters'),
})

const EditDonateEventModal: React.FC<NewAuditModalProps> = ({
  editDonateEventModal,
  handleClose,
  assetId,
  event,
  currencyObject,
}) => {
  const [updateAssetEvent, { isLoading }] = useUpdateAssetEventMutation()
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentDate = getCurrentDate()

  const formik = useFormik({
    initialValues: {
      assetId,
      donatedDate: event.donate.donatedDate
        ? new Date(event.donate.donatedDate).toISOString().slice(0, 10)
        : currentDate,
      donateTo: event.donate.donateTo || '',
      donateValue: parseInt(event.donate.donateValue) || '',
      deductible: event.donate.deductible ? event.donate.deductible === 'true' : false,
      notes: event.donate.notes || '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const processedValues = {
        ...values,
        donateValue: values.donateValue || null,
      }
      try {
        const payload = {
          eventId: event?.id,
          data: {
            donatedDate: processedValues.donatedDate,
            donateTo: processedValues.donateTo,
            donateValue: processedValues.donateValue,
            deductible: processedValues.deductible,
            notes: processedValues.notes,
          },
        }
        const result: any = await updateAssetEvent(payload)
        if (result?.data?.success) {
          dispatch(refresh(true))
          toast.success(result.data.message)
          handleClose()
          navigate(`/asset/view/${assetId}`)
        } else {
          toast.error('Failed to update asset donate status')
          checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
        }
      } catch (error: any) {
        toast.error('Failed to update asset donate status')
      }
    },
  })

  const splitName = currencyObject?.name.split(' - ')
  const currencyName = splitName && splitName[1]
  const currencySymbol = splitName && splitName[2]

  return (
    <Modal show={editDonateEventModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Donate Asset</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='formDate'>
            <Form.Label className='required-label'>Date Donated</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                name='donatedDate'
                max={currentDate}
                value={formik.values.donatedDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!(formik.errors.donatedDate && formik.touched.donatedDate)}
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
            {formik.errors.donatedDate && formik.touched.donatedDate && (
              <div className='text-danger'>{formik.errors.donatedDate}</div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='formDonateTo'>
            <Form.Label>Donate to</Form.Label>
            <Form.Control
              type='text'
              name='donateTo'
              value={formik.values.donateTo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='formDonateValue'>
            <Form.Label className='required-label'>Donate Value</Form.Label>
            <InputGroup>
              <InputGroup.Text className='bg-primary text-light'>{currencySymbol}</InputGroup.Text>
              <Form.Control
                type='text'
                name='donateValue'
                value={formik.values.donateValue}
                onChange={(e) => {
                  const value = e.target.value
                  if (/^\d*\.?\d*$/.test(value)) {// Update regex to handle decimal numbers
                     formik.handleChange(e)
                  }
                }}
                onBlur={formik.handleBlur}
                isInvalid={!!(formik.errors.donateValue && formik.touched.donateValue)}
                placeholder={currencyName}
              />
            </InputGroup>
            {formik.errors.donateValue && formik.touched.donateValue && (
              <div className='text-danger'>{formik.errors.donateValue}</div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='formDeductible'>
            <Form.Label className='required-label'>Deductible</Form.Label>
            <div className='d-flex align-items-center justify-content-start radio-controls p-2'>
              <div className='me-4'>
                <input
                  type='radio'
                  id='yes'
                  name='deductible'
                  className='custom-radio'
                  value='true'
                  checked={formik.values.deductible === true}
                  onChange={() => formik.setFieldValue('deductible', true)}
                />
                <label className='custom-radio-label' htmlFor='yes'>
                  Yes
                </label>
              </div>
              <div>
                <input
                  type='radio'
                  id='no'
                  name='deductible'
                  className='custom-radio'
                  value='false'
                  checked={formik.values.deductible === false}
                  onChange={() => formik.setFieldValue('deductible', false)}
                />
                <label className='custom-radio-label' htmlFor='no'>
                  No
                </label>
              </div>
            </div>
            {formik.errors.deductible && formik.touched.deductible && (
              <div className='text-danger'>{formik.errors.deductible}</div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='formNotes'>
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              name='notes'
              value={formik.values.notes}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.notes && formik.errors.notes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{String(formik.errors.notes)}</div>
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn cancel-btn border' onClick={handleClose}>
            Cancel
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          >
            Update
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditDonateEventModal
