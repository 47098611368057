import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
import DropzoneComponent from '../DropZone'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { imageMimeType } from 'config'
import Loader from 'App/Loaders/BeatLoader'
import { useParams } from 'react-router'
import { useAddPhotosMutation } from 'App/Modules/Services/AssetPhotos'
import { toast } from 'react-toastify'
interface MultiplesPhotoModalProps {
  multiplesPhotoUploadModal: boolean
  handleClose: () => void
  handlePhotosClose: () => void
}

const MultiplePhotoUploadModal: React.FC<MultiplesPhotoModalProps> = ({
  multiplesPhotoUploadModal,
  handleClose,
  handlePhotosClose,
}) => {
  const path = localStorage.getItem('path')
  const [files, setFiles] = useState<File[]>([])
  const [disabled, setDisabled] = useState<boolean>(true)
  const { id } = useParams()
  const { saveAuth, setCurrentUser } = useAuth()
  const [AddPhotos, { isLoading }] = useAddPhotosMutation()
  const [edit, setEdit] = useState<boolean>(false)

  useEffect(() => {
    if (path?.includes('/asset/edit/')) setEdit(true)
    else setEdit(false)
  }, [path])

  useEffect(() => {
    if (files.length > 0 && files?.length <= 10) setDisabled(false)
    else setDisabled(true)
  }, [files])

  const handleDrop = (acceptedFiles: File[]) => {
    if (files?.length + acceptedFiles?.length <= 10)
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles])
    else toast.info('You cannot upload more then 10 images')
  }

  const handleSubmit = async () => {
    const data: any = new FormData()
    files.forEach((file) => {
      if (file && file?.type?.match(imageMimeType)) {
        data.append('photos', file)
      }
    })
    data.append('uploadType', 1)
    try {
      const { data: resData, error }: any = await AddPhotos({ data, id })
      if (resData?.success) {
        toast.success(resData?.message)
      } else {
        toast.error(error?.data?.message)
        checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
      }
      setFiles([])
      handleClose()
      if (!edit) {
        handlePhotosClose()
      }
    } catch (error: any) {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
      setFiles([])
      handleClose()
      if (!edit) {
        handlePhotosClose()
      }
    }
  }

  return (
    <>
      <Modal
        className='multiple-upload-photo-modal'
        show={multiplesPhotoUploadModal}
        onHide={handleClose}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Mass Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body className='m-0 p-2'>
          <DropzoneComponent onDrop={handleDrop} />
          {files.length > 0 && (
            <>
              <h3>Accepted Files</h3>
              <div className='mass-images'>
                {files.map((file, index) => (
                  <div className='mass-image' key={index}>
                    <Image src={URL.createObjectURL(file)} thumbnail />
                  </div>
                ))}
              </div>
            </>
          )}
          <Loader loading={isLoading} />
        </Modal.Body>
        <Modal.Footer>
          <button disabled={disabled} className='btn btn-primary mx-2' onClick={handleSubmit}>
            Save
          </button>
          <button className='btn cancel-btn border' onClick={handleClose}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MultiplePhotoUploadModal
