import React from 'react'
import CustomersListing from './Components/CustomersListing'

function Customers() {
  return (
    <div>
      <CustomersListing />
    </div>
  )
}

export default Customers
