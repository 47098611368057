import React from 'react'
import './RadioButton.css'

interface CustomRadioButtonProps {
  id: string
  name: string
  value: string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  label: string
}

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({
  id,
  name,
  value,
  checked,
  onChange,
  label,
}) => {
  return (
    <div className='custom-radio-button'>
      <input type='radio' id={id} name={name} value={value} checked={checked} onChange={onChange} />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export default CustomRadioButton
