export interface CAccount {
  logo: any
  name: string
  address: string
  city: string
  country: string
  state: string
  postalCode: string
  suite: string
  timeZone: string
  currency: string
  dateFormat: string
  financialYearDate: number
  financialYearMonth: string
  dateOptions: string[]
}

export const defaultCompanyDetail: CAccount = {
  logo: '',
  name: '',
  address: '',
  city: '',
  country: '',
  state: '',
  suite: '',
  postalCode: '',
  timeZone: '',
  currency: '',
  dateFormat: '',
  financialYearDate: 1,
  financialYearMonth: '',
  dateOptions: [],
}
