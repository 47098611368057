import { Link } from 'react-router-dom'

const SitesHeader = (props: any) => {
  return (
    <div
      className='card-body d-flex border-bottom border-bottom-gray-200 justify-content-between mb-3 align-items-center flex-wrap'
      data-kt-user-table-toolbar='base'
    >
      <h3 className='card-title align-items-start flex-column mb-xl-0 mb-3 me-3'>
        <span className='me-2 align-middle'>
          <i className='la la-list fs-1' aria-hidden='true'></i>
        </span>
        <span className='card-label fw-bold fs-3 mb-1'>List of Sites</span>
      </h3>
      <div className='d-flex flex-wrap'>
        {!props.permission?.addSite && props.staffUser ? (
          ''
        ) : (
          <button
            type='button'
            className='btn btn-light-primary m-1'
            onClick={() => props.handleShow()}
          >
            <i className='la la-plus fs-3' />
            Add <span className='d-xl-inline d-none'>New Site</span>
          </button>
        )}
        {!props.permission?.importSite && props.staffUser ? (
          ''
        ) : (
          <Link
            className='btn btn-light-primary main-btn-style d-flex align-items-center m-1'
            to='/import'
            state={{ importType: 'sites' }}
          >
            <i className='la la-cloud-upload fs-2 me-1'></i>
            <span>Import</span>
            <span className='d-xl-inline d-none ps-1'>Sites</span>
          </Link>
        )}
        {props.deleteDataArr.length > 0 && (
          <button
            type='button'
            className='btn btn-danger m-1'
            onClick={() => {
              props.setDeleteModal(true)
              props.setCheck(props.deleteDataArr)
            }}
          >
            Delete Selected ({props.deleteDataArr.length})
          </button>
        )}
      </div>
    </div>
  )
}

export { SitesHeader }
