import { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function EmailFrequency(frequencyAllDays) {
  const [weekDays, setWeekDays] = useState([
    { name: 'Sunday', checked: false },
    { name: 'Monday', checked: false },
    { name: 'Tuesday', checked: false },
    { name: 'Wednesday', checked: false },
    { name: 'Thursday', checked: false },
    { name: 'Friday', checked: false },
    { name: 'Saturday', checked: false },
  ])
  const [allDaysChecked, setAllDaysChecked] = useState(false)
  const handleAllDaysCheckboxChange = () => {
    const updatedWeekDays = weekDays.map((day) => ({
      ...day,
      checked: !allDaysChecked,
    }))
    setWeekDays(updatedWeekDays)
    setAllDaysChecked(!allDaysChecked)
  }

  const handleWeekDayCheckboxChange = (index) => {
    const updatedWeekDays = [...weekDays]
    updatedWeekDays[index].checked = !updatedWeekDays[index].checked
    setWeekDays(updatedWeekDays)

    const allChecked = updatedWeekDays.every((day) => day.checked)
    setAllDaysChecked(allChecked)
  }

  return (
    <div>
      <Row className='mb-6'>
        <Col lg="2">
          <div className='col-form-label fw-bold fs-6'>Email Frequency</div>
        </Col>
        <Col lg="10">
          <div className='fv-row'>
            <p className='mb-2 text-muted'>
              {' '}
              <i className='la la-info-circle fs-5 text-muted'></i> How often do you want an alert
              email sent to you?
            </p>
            <div className='input-group'>
              {frequencyAllDays.frequencyAllDays.frequencyAllDays ? (
                <select className='form-select form-control form-control-solid form-select-solid'>
                  <option value='everyday'>Everyday</option>
                  <option value='alternateDay'>Alternate day</option>
                  <option value='every3rdDay'>Every 3rd day</option>
                  <option value='every4thDay'>Every 4th day</option>
                  <option value='every5thDay'>Every 5th day</option>
                  <option value='every6thDay'>Every 6th day</option>
                  <option value='everyWeek'>Every week</option>
                </select>
              ) : (
                <div className='w-100'>
                  <label
                    className='m-1 mb-md-5 mb-3 border-bottom pb-3 d-block'
                    htmlFor='allDaysCheckbox'
                  >
                    <input
                      id='allDaysCheckbox'
                      className='form-check-input custom-form-check-input'
                      type='checkbox'
                      checked={allDaysChecked}
                      onChange={handleAllDaysCheckboxChange}
                    />
                    <span className='ps-3'>All days</span>
                  </label>
                  <ul className='list-unstyled d-flex flex-wrap'>
                    {weekDays.map((day, index) => (
                      <li key={index} className='m-1 w-30'>
                        <label htmlFor={`weekDayCheckbox-${index}`}>
                          <input
                            id={`weekDayCheckbox-${index}`}
                            className='form-check-input custom-form-check-input'
                            type='checkbox'
                            checked={day.checked}
                            onChange={() => handleWeekDayCheckboxChange(index)}
                          />
                          <span className='ps-3'>{day.name}</span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default EmailFrequency
