import React, { useState, useEffect, useRef } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import AddReservationModal from '../Modals/AddReservationModal'
import EditReservationModal from '../Modals/EditReservationModal'
import { useGetReserveAssetsQuery } from 'App/Modules/Services/Reserve'
import { useParams } from 'react-router'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { dateFormat, defaultDateFormat } from 'config'
import { toast } from 'react-toastify'

interface CalendarEvent {
  title: string
  start: string
  end: string
  color?: string
  reserveId: any
}

interface Props {
  refreshKey: any
  activeTab: any
}

const CalendarComponent: React.FC<Props> = ({ refreshKey, activeTab }) => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const permission = currentUser?.SecurityGroup
  const ownerUser = currentUser?.userType === 1
  const staffUser = currentUser?.userType === 2
  const personUser = currentUser?.userType === 3
  const customerUser = currentUser?.userType === 4
  const { id } = useParams()
  const calendarRef = useRef<any>(null)
  const [selectedReserveId, setSelectedReserveId] = useState<any>(null)

  const [editReservationModal, setEditReservationModal] = useState<boolean>(false)
  const toggleEditReservationModal = (): void => setEditReservationModal(!editReservationModal)

  const [addReservationModal, setAddReservationModal] = useState<boolean>(false)
  const toggleAddReservationModal = (): void => setAddReservationModal(!addReservationModal)

  const [events, setEvents] = useState<CalendarEvent[]>([])

  const handleSave = (data: any) => {}

  const {
    data: reserveData,
    refetch,
    error,
  }: any = useGetReserveAssetsQuery(
    { assetId: id },
    { skip: personUser || customerUser || (staffUser && !permission?.viewReserve) }
  )

  useEffect(() => {
    if (reserveData) {
      const mappedEvents: CalendarEvent[] = reserveData?.reservations?.map((item: any) => {
        const title = item.person
          ? `${item.person.firstName} ${item.person.lastName}`
          : item.site
            ? item.site.name
            : item.customer
              ? `${item.customer.firstName} ${item.customer.lastName}`
              : 'No Title'

        const startDate = new Date(item.startDate)
        const endDate = new Date(item.endDate)

        endDate.setDate(endDate.getDate() + 1)

        return {
          title,
          start: startDate,
          end: endDate,
          color: 'blue',
          reserveId: item?.id,
        }
      })

      setEvents(mappedEvents)
    } else if (error) {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
    }
  }, [reserveData])

  useEffect(() => {
    if (ownerUser || permission?.viewReserve) {
      refetch()
    }
  }, [refreshKey])

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.updateSize()
    }
  }, [activeTab])

  const handleEventClick = (info: any) => {
    if (ownerUser || (staffUser && permission?.updateReserve)) {
      setSelectedReserveId(info.event.extendedProps.reserveId)
      toggleEditReservationModal()
    }
  }

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right:
            ownerUser || (staffUser && permission?.addReserve)
              ? 'dayGridMonth,timeGridWeek,timeGridDay,listMonth addReservationButton'
              : 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        }}
        initialView='dayGridMonth'
        customButtons={
          ownerUser || (staffUser && permission?.addReserve)
            ? {
                addReservationButton: {
                  text: 'Add Reservation',
                  click: toggleAddReservationModal,
                },
              }
            : {}
        }
        events={events}
        eventClick={handleEventClick}
        eventColor='lightcoral'
      />
      {addReservationModal && (
        <AddReservationModal
          show={addReservationModal}
          handleClose={toggleAddReservationModal}
          refetch={refetch}
        />
      )}
      {editReservationModal && (
        <EditReservationModal
          show={editReservationModal}
          handleSave={handleSave}
          editReservationModal={editReservationModal}
          handleCloseEdit={toggleEditReservationModal}
          reserveId={selectedReserveId}
          refetch={refetch}
        />
      )}
    </>
  )
}

export default CalendarComponent
