import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'

interface DropzoneComponentProps {
  onDrop: (acceptedFiles: File[]) => void
}

const DropzoneComponent: React.FC<DropzoneComponentProps> = ({ onDrop }) => {
  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles) onDrop(acceptedFiles)
    },
    [onDrop]
  )

  const onDropRejected = useCallback(
    (fileRejections) => {
      if (fileRejections?.length > 10) {
        toast.info('You cannot upload more then 10 images')
      } else if (fileRejections?.length > 0 && fileRejections?.length < 10) {
        toast.info('Only upload the allowed files (JPG, PNG, GIF)!')
      }
    },
    [onDrop]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropAccepted,
    onDropRejected: onDropRejected,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': [],
    },
    multiple: true,
    maxFiles: 10,
  })

  return (
    <div className='dropzone-container' {...getRootProps()}>
      <input {...getInputProps()} />
      <p>Upload up to 10 photos at a time.</p>
      <div className='dropzone-content'>
        <p>
          <span>➔</span> <strong>Drop files</strong> to upload <em>(or click)</em>
        </p>
      </div>
      <p className='text-center p-0 m-0'>Only (JPG, GIF, PNG) are allowed</p>
    </div>
  )
}

export default DropzoneComponent
