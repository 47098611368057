import CategoryListing from './Components/CategoryListing'

const Categories = () => {
  return (
    <>
      <CategoryListing />
    </>
  )
}

export default Categories
