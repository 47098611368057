import React, { useState, useEffect } from 'react'
import { useAddMaintenanceMutation } from '../Services/Maintenance'
import { useGetAllAssetsQuery } from 'App/Modules/Services/Assets'
import { useGetCompanyQuery } from '../Services/Company'
import { MaintenanceInterface, defaultMaintenanceDetail as initialValues } from './Core/Model'
import MaintenanceModal from './Modal/MaintenanceModal'
import { PageTitle } from '../../../Template/layout/core'
import { useAuth } from '../Auth'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import getSymbolFromCurrency from 'currency-symbol-map'
import { useNavigate, Link } from 'react-router-dom'
import { checkUserUnauthorized } from '../Auth'
import SplashScreen from 'App/Loaders/SplashScreen'
import {
  dateOptions,
  days,
  decimalPlacesRegex,
  getAssetStatusClass,
  months,
  multiSpace,
  nonNumericKeys,
} from 'config'

const accountDetailsSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required('Title is required')
    .max(20, 'Title should not exceed 20 characters')
    .test('no-empty-spaces', 'Title should not be empty', (value = '') => {
      return !/^\s*$/.test(value)
    }),
  recurOnEvery: Yup.string(),
  dueDate: Yup.date()
    .nullable()
    .min(new Date(new Date().setHours(0, 0, 0, 0)), 'Due Date cannot be a past date'),
  dateCompleted: Yup.date()
    .nullable()
    .min(new Date(new Date().setHours(0, 0, 0, 0)), 'Completed Date cannot be a past date'),
  details: Yup.string().max(250, 'Note should not exceed 250 characters').nullable(),
})

const Maintenance = () => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const permission = currentUser?.SecurityGroup
  const staffUser = currentUser?.userType === 2
  const navigate = useNavigate()
  const [assets, setAssets] = useState<any>([])
  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false)
  const [pendingAssets, setPendingAssets] = useState<any>([])
  const [selectedAssets, setSelectedAssets] = useState<any>([])
  const [selectAll, setSelectAll] = useState(false)
  const [repeatingMaintenance, setRepeatingMaintenance] = useState(false)
  const [repeatingFrequency, setRepeatingFrequency] = useState('')
  const [selected, setSelected] = useState<any>([])
  const [skip, setSkip] = useState<boolean>(false)
  const [siteId, setSiteId] = useState<boolean>(false)
  const [submitButton, setSubmitButton] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [deleteDataArr, setDeleteDataArr] = useState<number[]>([])
  const [addMaintenance, { isLoading: isAdding }] = useAddMaintenanceMutation()
  const {
    data: assetsData,
    isLoading,
    error,
    isSuccess,
  } = useGetAllAssetsQuery({
    body: new URLSearchParams({}).toString(),
    page,
    limit,
    keys: '',
  })
  const [currencyLogo, setCurrencyLogo] = useState<any>()
  const { data: companyData, isSuccess: company } = useGetCompanyQuery(
    {},
    { skip: staffUser && !permission?.viewCompanyInfo }
  )

  useEffect(() => {
    const res = getSymbolFromCurrency(companyData?.company?.currency || currentUser?.currency)
    setCurrencyLogo(res)
  }, [company])

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: accountDetailsSchema,
    onSubmit: async (values) => {
      try {
        handleMaintenanceSubmit(values)
      } catch (error: any) {
        throw new Error('Something went wrong', error)
      }
    },
  })

  useEffect(() => {
    setSkip(false)
  }, [])

  const handleSiteClick = (e: any) => {
    const id = e.target.value
    setSiteId(id)
    setSkip(false)
  }
  useEffect(() => {
    if (assetsData) {
      getAssetListing()
    }
  }, [assetsData])

  useEffect(() => {
    if (assets && pendingAssets && showMaintenanceModal == false) {
      handlePendingList()
    }
  }, [assets, showMaintenanceModal])

  useEffect(() => {
    formik.setFieldValue('recurOnEvery', '')
  }, [repeatingFrequency])

  const getAssetListing = async () => {
    setAssets(assetsData?.assets)
  }
  const handlePendingList = () => {
    const data = assets?.filter((asset: any) => pendingAssets.includes(asset.id))
    setSelectedAssets(data)
    setDeleteDataArr([])
  }

  const handleShowMaintenanceModal = () => {
    setShowMaintenanceModal(true)
  }

  const handleCloseMaintenanceModal = () => {
    setShowMaintenanceModal(false)
  }

  const handleMaintenanceSubmit = async (values: MaintenanceInterface) => {
    const costOnValue: any = values?.cost
    const parsedCostnEvery = costOnValue === '' ? null : costOnValue

    const data = {
      title: values?.title ? values?.title?.trim().replace(multiSpace, ' ') : '',
      details: values?.details === '' ? null : values?.details?.trim().replace(multiSpace, ' '),
      dueDate: values?.dueDate === '' ? null : values?.dueDate,
      maintenanceBy:
        values?.maintenanceBy === ''
          ? null
          : values?.maintenanceBy?.trim().replace(multiSpace, ' '),
      status: values?.status === '' ? null : values?.status,
      dateCompleted: values?.dateCompleted === '' ? null : values?.dateCompleted,
      cost: parsedCostnEvery,
      repeating: repeatingFrequency ? true : false,
      frequency: repeatingFrequency ? repeatingFrequency : null,
      recurOnEvery: values?.recurOnEvery,
      recurOn: values?.recurOn,
      assetId: pendingAssets,
    }
    try {
      const result = await addMaintenance(data).unwrap()
      if (result) {
        toast.dismiss()
        toast.success(result?.message ? result?.message : result?.data?.message)
        setRepeatingMaintenance(false)
        setRepeatingFrequency('')
        setSelectedAssets([])
        formik.resetForm()
      }
    } catch (error: any) {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
    }
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = assets?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setDeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setDeleteDataArr([])
    }
  }

  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected) => {
      const updatedSelected = prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
      const isAllSelected = updatedSelected.length === assets?.length
      setSelectAll(isAllSelected)
      return updatedSelected
    })
    setDeleteDataArr((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  const deleteSelectedAssests = async () => {
    const data = selectedAssets?.filter((asset: any) => !deleteDataArr.includes(asset.id))
    setSelectedAssets(data)
    setDeleteDataArr([])
    setSelectAll(false)
    setSelected([])
  }

  const handleCancel = () => {
    setSelectedAssets([])
    setPendingAssets([])
    setShowMaintenanceModal(false)
    setRepeatingMaintenance(false)
    setRepeatingFrequency('')
    formik.resetForm()
  }

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoading} />
      <PageTitle breadcrumbs={[]}>Maintenance</PageTitle>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0 '>
            <h3 className='fw-bolder m-0'>
              <span className='me-2 align-middle'>
                <i className='la la-cogs fs-1'></i>
              </span>{' '}
              Maintenance
            </h3>
          </div>
        </div>
        <div id='kt_account_profile_details' className='collapse show'>
          <div className='form'>
            <div className='card-body border-top p-lg-9 p-md-7 p-6'>
              <div>
                {' '}
                <p className='fw-bolder m-0 mb-3'>Bulk define maintenance of assets.</p>
                <div className='card-footer d-flex justify-content-start border-0 p-0'>
                  <button
                    type='button'
                    className='btn btn-light-primary me-3'
                    onClick={() => handleShowMaintenanceModal()}
                  >
                    <i className='la la-plus fs-3' />
                    Select Assets
                  </button>
                  <MaintenanceModal
                    show={showMaintenanceModal}
                    setPendingAssets={setPendingAssets}
                    selectedAssets={selectedAssets}
                    handleClose={handleCloseMaintenanceModal}
                    handleCancel={handleCancel}
                    setPage={setPage}
                    setLimit={setLimit}
                    page={page}
                    limit={limit}
                  />
                </div>
                {selectedAssets && selectedAssets?.length > 0 ? (
                  <>
                    <div className='separator border-2 my-10'></div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='fw-bolder m-0'>Assets Pending Maintenance</p>
                      {deleteDataArr.length > 0 && (
                        <button
                          type='button'
                          className='btn btn-danger  mb-2'
                          onClick={() => {
                            deleteSelectedAssests()
                          }}
                        >
                          Delete Selected
                        </button>
                      )}
                    </div>
                    <div className='checkout-listing-table mb-5 table-responsive pb-4'>
                      <table
                        id='kt_table_users'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        role='table'
                      >
                        <thead>
                          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th role='columnheader' className='w-10px pe-2'>
                              <div className='me-3'>
                                <input
                                  type='checkbox'
                                  className='form-check-input custom-form-check-input'
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </div>
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              {' '}
                              Asset Tag ID{' '}
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              {' '}
                              Description{' '}
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              {' '}
                              Status{' '}
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              {' '}
                              Assigned to{' '}
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              {' '}
                              Site{' '}
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              {' '}
                              Location{' '}
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              {' '}
                              Lease to{' '}
                            </th>
                          </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                          {selectedAssets?.length > 0 ? (
                            selectedAssets?.map((item: any) => {
                              return (
                                <tr role='row'>
                                  <td role='cell'>
                                    <div>
                                      <input
                                        type='checkbox'
                                        className='form-check-input custom-form-check-input'
                                        checked={selected.includes(item.id)}
                                        onChange={() => handleCheckboxChange(item.id)}
                                      />
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <Link to='/dummy-link'>{item?.assetTagId}</Link>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <Link to='/dummy-link'>{item?.description}</Link>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <span
                                        className={`d-inline-block align-items-center px-3 py-2 rounded ${getAssetStatusClass(item?.status)}`}
                                      >
                                        {item?.status}
                                      </span>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        {item?.assigneeType === 1 ? (
                                          <span>
                                            {`${item?.assignedPerson?.firstName || ''} ${
                                              item?.assignedPerson?.lastName || ''
                                            }`}
                                          </span>
                                        ) : item?.assigneeType === 2 ? (
                                          <span>
                                            {`${item?.assignedSite?.name || ''} ${
                                              item?.assignedSite?.name && item?.location?.location
                                                ? '/'
                                                : ''
                                            } ${item?.location?.location || ''}`}
                                          </span>
                                        ) : (
                                          <span></span>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <Link to='/dummy-link'>{item?.site?.name}</Link>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>
                                        {item?.location?.location}
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>
                                        {item?.statusType === 'lease' && item?.assetslease
                                          ? `${item?.assetslease?.user?.firstName} ${item?.assetslease?.user?.lastName}`
                                          : ''}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan={12}>
                                <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                  No records found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className='separator border-2 my-10'></div>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                      <div className='row my-3'>
                        <div className='col-xl-6'>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                              Maintenance Title
                            </label>
                            <div className='col-lg-8 fv-row'>
                              <div>
                                <input
                                  type='text'
                                  className='form-control form-control-solid mb-3 mb-lg-0'
                                  autoComplete='off'
                                  {...formik.getFieldProps('title')}
                                  onChange={(e) => {
                                    formik.handleChange(e)
                                  }}
                                />
                              </div>
                              {formik.touched.title && formik.errors.title && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.title}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                              Maintenance Details
                            </label>
                            <div className='col-lg-8 fv-row'>
                              <textarea
                                className='form-control'
                                {...formik.getFieldProps('details')}
                                onChange={(e) => {
                                  formik.handleChange(e)
                                }}
                                data-val-length='Maximum length is 1000.'
                                data-val-length-max='1000'
                                cols={20}
                                rows={4}
                                data-val='true'
                                id='Notes'
                                name='details'
                              ></textarea>
                              {formik.touched.details && formik.errors.details && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    {String(formik.errors.details)}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                              Maint. Due Date
                            </label>
                            <div className='col-lg-8 fv-row'>
                              <div className='input-group'>
                                <input
                                  type='date'
                                  className='form-control  form-control-solid'
                                  aria-label='Amount (to the nearest dollar)'
                                  {...formik.getFieldProps('dueDate')}
                                  onChange={(e) => {
                                    formik.handleChange(e)
                                  }}
                                />
                                <span className='input-group-text border-0'>
                                  <i className='fa fa-calendar'></i>
                                </span>
                              </div>
                              {formik.touched.dueDate && formik.errors.dueDate && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.dueDate}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                              Maintenance By
                            </label>
                            <div className='col-lg-8 fv-row'>
                              <textarea
                                className='form-control'
                                data-val-length='Maximum length is 1000.'
                                data-val-length-max='1000'
                                cols={20}
                                rows={4}
                                data-val='true'
                                id='Notes'
                                {...formik.getFieldProps('maintenanceBy')}
                                onChange={(e) => {
                                  formik.handleChange(e)
                                }}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className='col-xl-6'>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                              <span>Maintenance Status</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                              <select
                                {...formik.getFieldProps('status')}
                                onChange={(e) => {
                                  formik.handleChange(e)
                                }}
                                className='form-select form-control form-control-solid form-select-solid  '
                              >
                                <option>Select ...</option>
                                <option value='Scheduled'>Scheduled</option>
                                <option value='In Progress'>In Progress</option>
                                <option value='On Hold'>On Hold</option>
                                <option value='Cancelled'>Cancelled</option>
                                <option value='Completed'>Completed</option>
                              </select>
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                              Date Completed
                            </label>
                            <div className='col-lg-8 fv-row'>
                              <div className='input-group'>
                                <input
                                  type='date'
                                  className='form-control  form-control-solid'
                                  aria-label='Amount (to the nearest dollar)'
                                  {...formik.getFieldProps('dateCompleted')}
                                  onChange={(e) => {
                                    formik.handleChange(e)
                                  }}
                                />
                                <span className='input-group-text border-0'>
                                  <i className='fa fa-calendar'></i>
                                </span>
                              </div>
                              {formik.touched.dateCompleted && formik.errors.dateCompleted && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.dateCompleted}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                              Maintenance Cost
                            </label>
                            <div className='col-lg-8 fv-row'>
                              <div className='input-group currency-field'>
                                <span className='input-group-text'>{currencyLogo}</span>
                                <input
                                  type='number'
                                  min={'1'}
                                  className='form-control  form-control-solid'
                                  aria-label='Amount (to the nearest dollar)'
                                  {...formik.getFieldProps('cost')}
                                  onChange={(e) => {
                                    const value = e.target.value
                                    if (decimalPlacesRegex.test(value)) {
                                      formik.setFieldValue('cost', value)
                                    }
                                  }}
                                  onKeyDown={(e) => {
                                    nonNumericKeys(e)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='row mb-6 align-items-center'>
                            <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                              Repeating
                            </label>
                            <div className='col-lg-8 fv-row'>
                              <div className='d-flex flex-wrap'>
                                <div className='m-2 form-check-custom fw-bold'>
                                  <div>
                                    <input
                                      className='form-check-input custom-form-check-input'
                                      {...formik.getFieldProps('maintenanceReapting')}
                                      onClick={() => {
                                        setRepeatingFrequency('1')
                                        setRepeatingMaintenance(true)
                                      }}
                                      type='checkbox'
                                      value={'yes'}
                                      checked={repeatingMaintenance == false ? false : true}
                                      name={'checkedTo'}
                                      id='person'
                                    />
                                  </div>
                                  <label className='form-check-label' htmlFor='person'>
                                    Yes
                                  </label>
                                </div>
                                <div className='m-2 form-check-custom fw-bold'>
                                  <div>
                                    <input
                                      className='form-check-input custom-form-check-input'
                                      type='checkbox'
                                      {...formik.getFieldProps('maintenanceReapting')}
                                      onClick={() => {
                                        setRepeatingMaintenance(false)
                                        setRepeatingFrequency('')
                                      }}
                                      value={'no'}
                                      checked={repeatingMaintenance == false ? true : false}
                                      name={'checkedTo'}
                                      id='location'
                                    />
                                  </div>
                                  <label className='form-check-label' htmlFor='location'>
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              repeatingMaintenance ? 'row mb-6 align-items-center' : 'd-none'
                            }
                          >
                            <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                              Frequency
                            </label>
                            <div className='col-lg-8 fv-row'>
                              <div className='d-flex flex-wrap'>
                                <div className='m-2 form-check-custom fw-bold'>
                                  <div>
                                    <input
                                      className='form-check-input custom-form-check-input'
                                      {...formik.getFieldProps('frequency')}
                                      onClick={() => {
                                        setRepeatingFrequency('1')
                                      }}
                                      type='checkbox'
                                      value={'1'}
                                      checked={repeatingFrequency == '1' ? true : false}
                                      name={'frequency'}
                                      id='person'
                                    />
                                  </div>
                                  <label className='form-check-label' htmlFor='person'>
                                    Daily
                                  </label>
                                </div>
                                <div className='m-2 form-check-custom fw-bold'>
                                  <div>
                                    <input
                                      className='form-check-input custom-form-check-input'
                                      {...formik.getFieldProps('frequency')}
                                      onClick={() => {
                                        setRepeatingFrequency('2')
                                      }}
                                      type='checkbox'
                                      value={'2'}
                                      checked={repeatingFrequency == '2' ? true : false}
                                      name={'frequency'}
                                      id='person'
                                    />
                                  </div>
                                  <label className='form-check-label' htmlFor='person'>
                                    Weekly
                                  </label>
                                </div>
                                <div className='m-2 form-check-custom fw-bold'>
                                  <div>
                                    <input
                                      className='form-check-input custom-form-check-input'
                                      {...formik.getFieldProps('frequency')}
                                      onClick={() => {
                                        setRepeatingFrequency('3')
                                      }}
                                      type='checkbox'
                                      value={'3'}
                                      checked={repeatingFrequency == '3' ? true : false}
                                      name={'frequency'}
                                      id='person'
                                    />
                                  </div>
                                  <label className='form-check-label' htmlFor='person'>
                                    Monthly
                                  </label>
                                </div>
                                <div className='m-2 form-check-custom fw-bold'>
                                  <div>
                                    <input
                                      className='form-check-input custom-form-check-input'
                                      {...formik.getFieldProps('frequency')}
                                      onClick={() => {
                                        setRepeatingFrequency('4')
                                      }}
                                      type='checkbox'
                                      value={'4'}
                                      checked={repeatingFrequency == '4' ? true : false}
                                      name={'frequency'}
                                      id='person'
                                    />
                                  </div>
                                  <label className='form-check-label' htmlFor='person'>
                                    Yearly
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {repeatingFrequency == '2' ||
                            repeatingFrequency == '3' ||
                            (repeatingFrequency == '4' && (
                              <div className={repeatingMaintenance ? 'row mb-6' : 'd-none'}>
                                <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                                  Recur on:
                                </label>
                              </div>
                            ))}
                          <div
                            className={
                              repeatingMaintenance &&
                              (repeatingFrequency == '2' || repeatingFrequency == '3')
                                ? 'row mb-6'
                                : 'd-none'
                            }
                          >
                            <label className='col-lg-4 col-form-label  fw-bold fs-6'>Every</label>
                            <div className='col-lg-8 fv-row'>
                              <div className='input-group'>
                                <input
                                  className='form-control  form-control-solid'
                                  {...formik.getFieldProps('recurOnEvery')}
                                  onChange={(e) => {
                                    formik.handleChange(e)
                                  }}
                                />
                              </div>
                              {formik.touched.recurOnEvery && formik.errors.recurOnEvery && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.recurOnEvery}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              repeatingMaintenance && repeatingFrequency == '4'
                                ? 'row mb-6'
                                : 'd-none'
                            }
                          >
                            <label className='col-lg-4 col-form-label  fw-bold fs-6'>Every</label>
                            <div className='col-lg-8 fv-row'>
                              <div className='input-group'>
                                <select
                                  {...formik.getFieldProps('recurOnEvery')}
                                  onChange={(e) => {
                                    formik.handleChange(e)
                                  }}
                                  className='form-select form-control form-control-solid form-select-solid  '
                                >
                                  <option value=''>Select Month</option>
                                  {months.map((month) => (
                                    <option key={month} value={month.toLowerCase()}>
                                      {month}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              repeatingMaintenance && repeatingFrequency == '2'
                                ? 'row mb-6'
                                : 'd-none'
                            }
                          >
                            <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                              on week(s)
                            </label>
                            <div className='col-lg-8 fv-row'>
                              <div className='input-group'>
                                <select
                                  {...formik.getFieldProps('recurOn')}
                                  onChange={(e) => {
                                    formik.handleChange(e)
                                  }}
                                  className='form-select form-control form-control-solid form-select-solid  '
                                >
                                  <option value=''>Select Day</option>
                                  {days.map((day) => (
                                    <option key={day} value={day.toLowerCase()}>
                                      {day}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              repeatingFrequency == '4' || repeatingFrequency == '3'
                                ? 'row mb-6'
                                : 'd-none'
                            }
                          >
                            <label className='col-lg-4 col-form-label  fw-bold fs-6'>on</label>
                            <div className='col-lg-8 fv-row'>
                              <div className='input-group'>
                                <select
                                  {...formik.getFieldProps('recurOn')}
                                  onChange={(e) => {
                                    formik.handleChange(e)
                                  }}
                                  className='form-select form-control form-control-solid form-select-solid  '
                                >
                                  <option value=''>Select date</option>
                                  {dateOptions.map((date) => (
                                    <option key={date} value={date}>
                                      {date}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=' d-flex align-items-center justify-content-end'>
                        <button
                          onClick={handleCancel}
                          className='btn btn-light-primary   main-btn-style me-2'
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary main-btn-style'
                          disabled={isAdding}
                        >
                          {!isAdding && 'Add'}
                          {isAdding && (
                            <span className='indicator-progress'>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Maintenance
