import { FC } from 'react'
import { useLayout } from '../../core'
import { AsideMenu } from './AsideMenu'
import { toAbsoluteUrlImage } from '../../../helpers'
import { useAuth } from 'App/Modules/Auth'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import { Images } from 'Template/assets/Assets'

const AsideDefault: FC = () => {
  const { classes } = useLayout()
  const { currentUser } = useAuth()
  const permission = currentUser?.SecurityGroup
  const staffUser = currentUser?.userType === 2
  const personUser = currentUser?.userType === 3
  const customerUser = currentUser?.userType === 4

  const { data: companyData } = useGetCompanyQuery(
    {},
    { skip: personUser || customerUser || (staffUser && !permission?.viewCompanyInfo) }
  )

  return (
    <aside
      id='kt_aside'
      className='aside'
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      <div className='aside-inner'>
        <div className='aside-toolbar flex-column-auto px-3 py-2' id='kt_aside_toolbar'>
          <div className='symbol symbol-125px text-center pt-lg-0 pt-3'>
            {companyData?.company?.logo || currentUser?.companyLogo ? (
              <img
                src={toAbsoluteUrlImage(companyData?.company?.logo || currentUser?.companyLogo)}
                alt='company logo'
                crossOrigin='anonymous'
                className='img-fluid w-100 text-light object-fit-contain'
              />
            ) : companyData?.company?.name || currentUser?.companyName ? (
              <h1 className='company-name fs-2'>
                {companyData?.company?.name || currentUser?.companyName}
              </h1>
            ) : (
              <img src={Images.Avatar} alt='profile' className='img-fluid w-100' />
            )}
          </div>
        </div>
        <div className='aside-menu flex-column-fluid'>
          <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
        </div>
        <div className='aside-footer flex-column-auto py-5' id='kt_aside_footer'></div>
      </div>
    </aside>
  )
}

export { AsideDefault }
