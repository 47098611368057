export type Site = {
  name: string
  description?: string | ''
  address?: string | ''
  suite?: string | ''
  city?: string | ''
  state?: string | ''
  postalCode?: string | ''
  country?: string | ''
}

export const initialSites: Site = {
  name: '',
  description: ' ',
  address: ' ',
  suite: ' ',
  city: '',
  state: ' ',
  postalCode: ' ',
  country: ' ',
}

export type SiteResponse = {
  id: number
  name: string
  description?: string | ' '
  address?: string | ' '
  suite?: string | ' '
  city?: string | ''
  state?: string | ' '
  postalCode?: string | ' '
  country?: string | ' '
  createdAt?: string | ' '
  updatedAT?: string | ' '
}
