import { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  useGetSingleAssetQuery,
  useGetAllAssetsQuery,
  useDeleteAssetsMutation,
  useGeneratePDFMutation,
} from '../../../../Services/Assets'
import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrlImage } from 'Template/helpers'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import { checkUserUnauthorized, useAuth } from '../../../../Auth'
import { Images } from 'Template/assets/Assets'
import {
  baseURL,
  convertToJSON,
  dateFormat,
  defaultDateFormat,
  getAssetStatusClass,
  originalName,
  setupKeys,
} from 'config'
import { PageTitle } from 'Template/layout/core'
import {
  useDeleteWarrantyMutation,
  useGetAssetAllWarrantiesQuery,
} from 'App/Modules/Services/Warranty'
import { assetSearchFields } from '../Core/Model'
import getSymbolFromCurrency from 'currency-symbol-map'
import SplashScreen from 'App/Loaders/SplashScreen'
import WarrantyModal from './Modals/WarrantyModal'
import CheckedoutModal from './Modals/CheckedoutModal'
import ViewAssetModal from './Modals/ViewAssetModal'
import DeleteConfirmationModal from './Modals/DeleteConfirmationModal'
import ConfirmationPopup from 'Template/helpers/components/ConfirmationPopup'
import Loader from 'App/Loaders/BeatLoader'
import ActionIcon from 'Template/assets/icons/ActionIcon'
import LostMissingModal from './Modals/LostMissingModal'
import FoundModal from './Modals/FoundModal'
import SellModal from './Modals/SellModal'
import RepairModal from './Modals/RepairModal'
import BrokenModal from './Modals/BrokenModal'
import UndoBrokenModal from './Modals/UndoBrokenModal'
import UndoSellModal from './Modals/UndoSellModal'
import DonateModal from './Modals/DonateModal'
import DeleteSingleAssetModal from './Modals/DeleteModal'
import EmailModal from './Modals/EmailModal'
import ViewSiteModal from '../../Sites/Components/ViewSiteModal'
import ViewCustomerModal from '../../Customers/Components/ViewCustomerModal'
import ViewPersonModal from '../../Persons/Components/ViewPersonModal'
import MoreDetails from './TabComponents/MoreDetails'
import EventHistory from './TabComponents/EventHistory'
import DetachModal from './Modals/DetachModal'
import UploadDocumentModal from './Modals/UploadDocument'
import { useGetAllDocumentsQuery } from 'App/Modules/Services/Documents'
import EditEventModal from './Modals/EditEventModal'
import AssetHistory from './TabComponents/AssetHistory'
import UndoDonateModal from './Modals/UndoDonateModal'
import AddPhotosModal from './Modals/AddPhotosModal'
import Photos from './TabComponents/Photos'
import ReplicateModal from './Modals/ReplicateModal'
import { useGetAuditByAssetIdQuery } from 'App/Modules/Services/Audit'
import AssetAuditModal from 'App/Modules/Audit/modals/AssetAuditModal'
import DeleteAssetAuditModal from 'App/Modules/Audit/modals/DeleteAssetAuditModal'
import ReserveCalender from './TabComponents/Reserve'
import MaintenanceAssetDeleteModal from './Modals/MaintenanceAssetDelete'
import {
  useGetAssetMaintenanceQuery,
  useGetMaintenanceQuery,
} from 'App/Modules/Services/Maintenance'
import MaintenanceAssetViewModal from './Modals/MaintenanceAssetViewModal'
import { useDispatch, useSelector } from 'react-redux'
import { refresh, selectValue } from 'App/Store'
import { currencies } from 'Data/Currency'
import MaintenancesEditModal from 'App/Modules/Maintenance/Components/Modals/MaintenancesEditModal'
import AddMaintenanceModel from './Modals/AddMaintenanceModal'
import AssetTagPrint from 'Template/helpers/components/AssetTagPrint'
import PrintAssetTagModal from './Modals/PrintAssetTagModal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../CSS/AssetsStyle.css'

const AssetDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const value = useSelector(selectValue)
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const navigate = useNavigate()
  const permission = currentUser?.SecurityGroup
  const personUser = currentUser?.userType === 3
  const customerUser = currentUser?.userType === 4
  const ownerUser = currentUser?.userType === 1
  const staffUser = currentUser?.userType === 2
  const [activeTab, setActiveTab] = useState(0)
  const [searchAssets, setSearchAssets] = useState<any>(assetSearchFields)
  const [page, setPage] = useState<number>(1)
  const [assetIds, setAssetIds] = useState<any>([])
  const [deleteDataArr, setDeleteDataArr] = useState<any>([])
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [currentAssetIndex, setCurrentAssetIndex] = useState<any>(0)
  const [limit, setLimit] = useState<number>(10)
  const [warrantyId, setWarrantyId] = useState<number>(0)
  const [checkoutId, setCheckoutId] = useState<number>(0)
  const [skip, setSkip] = useState<boolean>(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [showCheckout, setShowCheckout] = useState(false)
  const [refreshHistory, setRefreshHistory] = useState(false)
  const [dataId, setDataId] = useState<number>()
  const [showPrintTag, setShowPrintTag] = useState<boolean>(false)

  const {
    data: maintenanceData,
    refetch: refetchMaintenance,
    error: maintenanceErr,
  }: any = useGetAssetMaintenanceQuery(
    {
      id,
    },
    {
      skip:
        personUser ||
        customerUser ||
        (staffUser && !permission?.viewMaintenance) ||
        !(activeTab === 5),
    }
  )
  const {
    data: singleMaintenanceData,
    isLoading: lodingMaintenance,
    error: singleMaintenenceErr,
  }: any = useGetMaintenanceQuery({ id: dataId }, { skip: !dataId })
  const [repeatingMaintenance, setRepeatingMaintenance] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [modalCheck, setModalCheck] = useState(false)

  const editMaintenanceDetails = (id: any) => {
    if (dataId == id) {
      setRepeatingMaintenance(singleMaintenanceData?.maintenance?.repeating)
      setLoading(false)
      return setShowEditModal(true)
    }
    setRepeatingMaintenance(singleMaintenanceData?.maintenance?.repeating)
    setDataId(id)
    setModalCheck(true)
    setShowModal(false)
  }
  useEffect(() => {
    if (singleMaintenanceData) {
      if (showModal) {
        return setLoading(false)
      }
      if (modalCheck === true) {
        setRepeatingMaintenance(singleMaintenanceData?.maintenance?.repeating)
        setLoading(false)
        return setShowEditModal(true)
      }
      setLoading(false)
    }
  }, [singleMaintenanceData])
  const { data: warrantyData, error: warrantyErr }: any = useGetAssetAllWarrantiesQuery(
    { id },
    {
      skip:
        !id ||
        personUser ||
        customerUser ||
        (staffUser && !permission?.viewWarranties) ||
        !(activeTab === 4),
    }
  )
  const {
    data: assetDetail,
    isLoading: isLoadingViewAsset,
    refetch,
    error,
    isError,
  } = useGetSingleAssetQuery({
    assetId: id,
  })
  const {
    data: companyData,
    isSuccess: company,
    error: companyErr,
  }: any = useGetCompanyQuery(
    {},
    {
      skip: personUser || customerUser || (staffUser && !permission?.viewCompanyInfo),
    }
  )
  const { data: assetsData, error: assetError }: any = useGetAllAssetsQuery({
    body: new URLSearchParams(searchAssets).toString(),
    page,
    limit,
    keys: '',
  })
  const {
    data: documentData,
    refetch: refetchDocument,
    error: docsErr,
  }: any = useGetAllDocumentsQuery(
    { assetId: id },
    {
      skip:
        personUser ||
        customerUser ||
        (staffUser && !permission?.viewDocument) ||
        !(activeTab === 3),
    }
  )

  const {
    data: auditData,
    refetch: refetchAudit,
    error: auditErr,
  }: any = useGetAuditByAssetIdQuery(
    { assetId: id },
    {
      skip:
        personUser || customerUser || (staffUser && !permission?.viewAudit) || !(activeTab === 7),
    }
  )

  const [generatePDF] = useGeneratePDFMutation()
  const [currencyLogo, setCurrencyLogo] = useState<any>()
  const [warrantyModal, setShowWarrantyModal] = useState<boolean>(false)
  const [checkoutModal, setCheckoutModal] = useState<boolean>(false)
  const [assetViewModal, setAssetViewModal] = useState<any>('')
  const [deletedId, setDeletedId] = useState(0)
  const [showSite, setShowSite] = useState(false)
  const [siteId, setSiteId] = useState(null)
  const [personId, setPersonId] = useState(null)
  const [customerId, setCustomerId] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [showView, setShowView] = useState<boolean>(false)
  const [showPerson, setShowPerson] = useState<boolean>(false)
  const [showCustomer, setShowCustomer] = useState<boolean>(false)
  const [deleteWarranty, { isLoading: isDeleted }] = useDeleteWarrantyMutation()
  const ref = useRef<HTMLDivElement>(null)
  const [deleteAssets, { isLoading: isLoadingDelete }] = useDeleteAssetsMutation()
  const [lostMissingModal, setLostMissingModal] = useState<boolean>(false)
  const toggleLostMissingModal = (): void => setLostMissingModal(!lostMissingModal)
  const [foundModal, setFoundModal] = useState<boolean>(false)
  const toggleFoundModal = (): void => setFoundModal(!foundModal)
  const [sellModal, setSellModal] = useState<boolean>(false)
  const toggleSellModal = (): void => setSellModal(!sellModal)
  const [repairModal, setRepairModal] = useState<boolean>(false)
  const toggleRepairModal = (): void => setRepairModal(!repairModal)
  const [replicateModal, setReplicateModal] = useState<boolean>(false)
  const toggleReplicateModal = (): void => setReplicateModal(!replicateModal)
  const [brokenModal, setBrokenModal] = useState<boolean>(false)
  const toggleBrokenModal = (): void => setBrokenModal(!brokenModal)
  const [undoBrokenModal, setUndoBrokenModal] = useState<boolean>(false)
  const toggleUndoBrokenModal = (): void => setUndoBrokenModal(!undoBrokenModal)
  const [undoSellModal, setUndoSellModal] = useState<boolean>(false)
  const toggleUndoSellModal = (): void => setUndoSellModal(!undoSellModal)
  const [donateModal, setDonateModal] = useState<boolean>(false)
  const toggleDonateModal = (): void => setDonateModal(!donateModal)
  const [undoDonateModal, setUndoDonateModal] = useState<boolean>(false)
  const toggleUndoDonateModal = (): void => setUndoDonateModal(!undoDonateModal)
  const [detachModal, setDetachModal] = useState<boolean>(false)
  const [deleteSingleAssetModal, setDeleteSingleAssetModal] = useState<boolean>(false)
  const toggleDeleteModal = (): void => setDeleteSingleAssetModal(!deleteSingleAssetModal)
  const [emailModal, setEmailModal] = useState<boolean>(false)
  const toggleEmailModal = (): void => setEmailModal(!emailModal)
  const [uploadDocumentModal, setUploadDocumentModal] = useState<boolean>(false)
  const toggleUploadDocumentModal = (): void => setUploadDocumentModal(!uploadDocumentModal)
  const [editEventModal, setEditEventModal] = useState<boolean>(false)
  const toggleEditEventModal = (): void => setEditEventModal(!editEventModal)
  const [addPhotosModal, setAddPhotosModal] = useState<boolean>(false)
  const toggleAddPhotosModal = (): void => setAddPhotosModal(!addPhotosModal)
  const [assetAuditModal, setAssetAuditModal] = useState<boolean>(false)
  const [assetDeleteAuditModal, setAssetDeleteAuditModal] = useState<boolean>(false)
  const toggleDeleteAuditModal = (): void => setAssetDeleteAuditModal(!assetDeleteAuditModal)
  const [maintenanceAssetEditModal, setMaintenanceAssetEditModal] = useState<boolean>(false)
  const [maintenanceAssetViewModal, setMaintenanceAssetViewModal] = useState<boolean>(false)
  const toggleViewMaintenanceModal = (id: any) => {
    setMaintenanceAssetViewModal(true)
    if (dataId === id) {
      return setMaintenanceAssetViewModal(true)
    }
    setDataId(id)
  }

  const [assetMaintenance, setAssetMaintenance] = useState({
    title: '',
    details: '',
    dueDate: '',
    maintenanceBy: '',
    status: '',
    dateCompleted: '',
    cost: '',
    frequency: '',
    recurOnEvery: '',
    recurOn: '',
  })
  const [maintenanceAssetDeleteModal, setMaintenanceAssetDeleteModal] = useState<boolean>(false)
  const toggleMaintenanceAssetDeleteModal = (): void =>
    setMaintenanceAssetDeleteModal(!maintenanceAssetDeleteModal)

  const [auditId, setAuditId] = useState(null)
  const moreActionsPermission =
    permission?.hasLost ||
    permission?.hasSell ||
    permission?.hasBroken ||
    permission?.hasRepair ||
    permission?.hasDonate ||
    permission?.hasReplicate ||
    permission?.hasEmail ||
    permission?.hasFound ||
    permission?.hasUndoSell ||
    permission?.hasUndoBroken ||
    permission?.hasUndoRepair ||
    permission?.deleteAsset

  const fileType = (fileName) => fileName.split('.').pop().toUpperCase()
  const getFileIcon = (filename: string) => {
    const extension = filename.split('.').pop()?.toLowerCase()

    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'bmp':
      case 'gif':
        return 'fas fa-file-image text-primary fs-3 me-1'
      case 'rtf':
      case 'doc':
      case 'docx':
        return 'fas fa-file-word text-primary fs-3 me-1'
      case 'pdf':
        return 'fas fa-file-pdf text-primary fs-3 me-1'
      case 'ppt':
      case 'pptx':
        return 'fas fa-file-powerpoint text-primary fs-3 me-1'
      case 'xls':
      case 'xlsx':
        return 'fas fa-file-excel text-primary fs-3 me-1'
      case 'txt':
        return 'fas fa-file-alt text-primary fs-3 me-1'
      case 'msg':
        return 'fas fa-envelope text-primary fs-3 me-1'
      default:
        return 'fas fa-file text-primary fs-3 me-1'
    }
  }

  useEffect(() => {
    if (value) {
      refetch()
      dispatch(refresh(!value))
    }
  }, [value])

  useEffect(() => {
    if (isError) {
      const errorData: any = error
      if (errorData?.status === 404) navigate('/error/404')
      else {
        toast.error(errorData?.data?.message)
        checkUserUnauthorized(errorData?.data, saveAuth, setCurrentUser, toast)
      }
    }
    if (
      maintenanceErr ||
      singleMaintenenceErr ||
      warrantyErr ||
      companyErr ||
      docsErr ||
      auditErr ||
      assetError
    ) {
      if (maintenanceErr)
        checkUserUnauthorized(maintenanceErr?.data, saveAuth, setCurrentUser, toast)
      if (singleMaintenenceErr)
        checkUserUnauthorized(singleMaintenenceErr?.data, saveAuth, setCurrentUser, toast)
      if (warrantyErr) checkUserUnauthorized(warrantyErr?.data, saveAuth, setCurrentUser, toast)
      if (companyErr) checkUserUnauthorized(companyErr?.data, saveAuth, setCurrentUser, toast)
      if (docsErr) checkUserUnauthorized(docsErr?.data, saveAuth, setCurrentUser, toast)
      if (auditErr) checkUserUnauthorized(auditErr?.data, saveAuth, setCurrentUser, toast)
      if (assetError) checkUserUnauthorized(assetError?.data, saveAuth, setCurrentUser, toast)
    }
  }, [
    isError,
    maintenanceErr,
    singleMaintenenceErr,
    assetError,
    warrantyErr,
    companyErr,
    docsErr,
    auditErr,
  ])

  const toggleAssetAuditModal = (): void => setAssetAuditModal(!assetAuditModal)

  const handleEditAuditModel = (audit: any) => {
    setAuditId(audit?.id)
    setAssetAuditModal(true)
  }

  const handleDeleteAuditModel = (audit: any) => {
    setAuditId(audit?.id)
    setAssetDeleteAuditModal(true)
  }

  const toggleDetachModal = () => {
    setDetachModal(!detachModal)
    setSelectedDocument(null) // Reset selected document on close
  }

  const handleDetachButtonClick = (document: any) => {
    setSelectedDocument(document)
    setDetachModal(true)
  }

  useEffect(() => {
    const res = getSymbolFromCurrency(companyData?.company?.currency || currentUser?.currency)
    setCurrencyLogo(res)
  }, [company])

  useEffect(() => {
    if (assetsData) {
      const ids = assetsData?.assets?.map((asset) => asset.id)
      setAssetIds(ids)
      const initialAssetId: any = id
      const initialAssetIndex = ids?.indexOf(parseInt(initialAssetId))
      if (initialAssetIndex !== -1) {
        setCurrentAssetIndex(initialAssetIndex)
      }
    }
  }, [assetsData, id])

  const handleCloseWarrantyModal = () => {
    setShowWarrantyModal(false)
    setWarrantyId(0)
  }

  const handleCloseCheckoutModal = () => {
    setCheckoutModal(false)
    setCheckoutId(0)
  }

  const handleCloseViewWarrantyModal = () => {
    setShowView(false)
  }

  const handleClosePersonModal = () => {
    setShowPerson(false)
  }

  const handleCloseCustomerModal = () => {
    setShowCustomer(false)
  }

  const deleteSelectedAssets = async () => {
    try {
      const deleteIds = { deleteIds: [id] }
      const res = await deleteAssets({ id: deleteIds }).unwrap()
      toast.dismiss()
      toast.success(res?.message)
      setSkip(false)
      setLoading(true)
      navigate('/assets')
    } catch (err: any) {
      toast.error(err?.data?.message)
    }
    setDeleteDataArr([])
    setDeleteModal(false)
  }
  const cancel = () => setDeleteModal(false)

  const confirm = () => deleteSelectedAssets()

  const handleDelete = async () => {
    try {
      const res: any = await deleteWarranty(deletedId)
      if (res?.data?.success) {
        toast.dismiss()
        toast.success(res?.data?.message)
      } else {
        toast.dismiss()
        toast.error(res?.error?.data?.message)
        checkUserUnauthorized(res?.error?.data, saveAuth, setCurrentUser, toast)
      }
    } catch (error: any) {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error, saveAuth, setCurrentUser, toast)
    }
  }

  const handleShowSiteModalClose = () => {
    setShowSite(false)
  }

  const handleConfirmDelete = () => {
    setConfirmDelete(false)
  }

  const goToNextAsset = () => {
    if (currentAssetIndex < assetIds.length - 1) {
      const nextAssetIndex = currentAssetIndex + 1
      setCurrentAssetIndex(nextAssetIndex)
      const nextAssetId = assetIds[nextAssetIndex]
      navigate(`/asset/view/${nextAssetId}`)
    } else {
      toast.info('No more assets found!')
    }
  }

  const goToPreviousAsset = () => {
    if (currentAssetIndex > 0) {
      const previousAssetIndex = currentAssetIndex - 1
      setCurrentAssetIndex(previousAssetIndex)
      const previousAssetId = assetIds[previousAssetIndex]
      navigate(`/asset/view/${previousAssetId}`)
    } else {
      toast.info('No more assets found!')
    }
  }

  const handleDownload = (filename: string) => {
    const link = document.createElement('a')
    link.href = `${process.env.REACT_APP_PUBLIC_URL}documents/${filename}`
    link.target = '_blank'
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleGeneratePDF = async () => {
    try {
      setLoading(true)
      const result = await generatePDF(assetDetail?.asset?.id).unwrap()
      if (result.success) {
        const pdfUrl = `${process.env.REACT_APP_ASSET_URL}pdf/${result.pdfPath}`
        const link = document.createElement('a')
        link.href = pdfUrl
        link.target = '_blank'
        link.setAttribute('download', `asset-${assetDetail?.asset?.id}.pdf`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (err: any) {
      throw Error(err)
    } finally {
      setLoading(false)
    }
  }

  const currencyObject = currencies.find(
    (currency) => currency.value === (companyData?.company?.currency || currentUser?.currency)
  )

  const handleClosePrintTag = () => {
    setShowPrintTag(false)
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>Asset View</PageTitle>
      <h3 className='fw-bolder mb-4'>
        <span className='me-2 align-middle'>
          <i className='la la-puzzle-piece fs-1' aria-hidden='true'></i>
        </span>{' '}
        Asset View
      </h3>
      <SplashScreen isLoadingTemplate={isLoadingViewAsset} />
      {!isLoadingViewAsset ? (
        <div id='kt_content' className='d-flex flex-column flex-column-fluid asset-details'>
          <div className='post d-flex flex-column-fluid' id='kt_post' ref={ref}>
            <div id='kt_content_container' className={`w-100 ${showSearch && 'd-none'}`}>
              <div className='card mb-5'>
                <ConfirmationPopup
                  deleteModal={deleteModal}
                  cancel={cancel}
                  confirm={confirm}
                  setSkip={setSkip}
                  setupName={setupKeys.asset}
                />
                <div className='card-body py-9'>
                  <div className='card_title_inner'>
                    <div className='card_caption mb-6 d-flex align-items-center justify-content-between'>
                      <h3 className='form-title'>{assetDetail?.asset?.brand}</h3>
                      <div className='d-flex align-items-center justify-content-end flex-wrap'>
                        <div>
                          <button
                            className='btn btn-light-primary me-2'
                            onClick={() => setShowPrintTag(true)}
                          >
                            <i className='la la-print me-1 fs-4'></i> Print Tag
                          </button>
                        </div>
                        {(ownerUser || (staffUser && permission?.updateAsset)) &&
                          !(personUser || customerUser) && (
                            <Link className='btn btn-light-primary me-2' to={`/asset/edit/${id}`}>
                              <i className='fa fa-pencil-alt me-1 fs-6'></i> Edit
                            </Link>
                          )}
                        {(ownerUser || (staffUser && permission?.updateAsset)) &&
                          !(personUser || customerUser) && (
                            <button
                              className='btn btn-light-primary me-2'
                              onClick={handleGeneratePDF}
                            >
                              <i className='la la-print me-1 fs-4'></i> Print PDF
                            </button>
                          )}
                        {currentAssetIndex > 0 && (
                          <button
                            className='btn btn-light-primary me-2'
                            onClick={goToPreviousAsset}
                          >
                            Previous
                          </button>
                        )}
                        {currentAssetIndex < assetIds.length - 1 && (
                          <button
                            className={`btn btn-light-primary me-2 ${currentAssetIndex === 0 && 'me-2'}`}
                            onClick={goToNextAsset}
                          >
                            Next
                          </button>
                        )}
                        {(ownerUser || (staffUser && moreActionsPermission)) &&
                          !(personUser || customerUser) && (
                            <div className='dropdown'>
                              <button
                                className='btn btn-light-primary'
                                type='button'
                                id='more_actions_dropdown'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                              >
                                More Actions
                                <i className='fas fa-chevron-down ms-2'></i>
                              </button>
                              <ul className='dropdown-menu' aria-labelledby='more_actions_dropdown'>
                                <li>
                                  {assetDetail?.asset?.status === 'Lost/Missing' ? (
                                    !permission?.hasFound && staffUser ? (
                                      ''
                                    ) : (
                                      <span
                                        className='dropdown-item btn'
                                        onClick={toggleFoundModal}
                                      >
                                        Found
                                      </span>
                                    )
                                  ) : !permission?.hasLost && staffUser ? (
                                    ''
                                  ) : (
                                    <span
                                      className='dropdown-item btn'
                                      onClick={toggleLostMissingModal}
                                    >
                                      Lost / Missing
                                    </span>
                                  )}
                                </li>
                                <li>
                                  {assetDetail?.asset?.status === 'Sold' ? (
                                    !permission?.hasUndoSell && staffUser ? (
                                      ''
                                    ) : (
                                      <span
                                        className='dropdown-item btn'
                                        onClick={toggleUndoSellModal}
                                      >
                                        Undo Sell
                                      </span>
                                    )
                                  ) : !permission?.hasSell && staffUser ? (
                                    ''
                                  ) : (
                                    <span className='dropdown-item btn' onClick={toggleSellModal}>
                                      Sell
                                    </span>
                                  )}
                                </li>
                                {!permission?.hasRepair && staffUser ? (
                                  ''
                                ) : (
                                  <li>
                                    <span className='dropdown-item btn' onClick={toggleRepairModal}>
                                      Repair
                                    </span>
                                  </li>
                                )}
                                <li>
                                  {assetDetail?.asset?.status === 'Broken' ? (
                                    !permission?.hasUndoBroken && staffUser ? (
                                      ''
                                    ) : (
                                      <span
                                        className='dropdown-item btn'
                                        onClick={toggleUndoBrokenModal}
                                      >
                                        Undo Broken
                                      </span>
                                    )
                                  ) : !permission?.hasBroken && staffUser ? (
                                    ''
                                  ) : (
                                    <span className='dropdown-item btn' onClick={toggleBrokenModal}>
                                      Broken
                                    </span>
                                  )}
                                </li>
                                <li>
                                  {assetDetail?.asset?.status === 'Donated' ? (
                                    !permission?.hasUndoDonate && staffUser ? (
                                      ''
                                    ) : (
                                      <span
                                        className='dropdown-item btn'
                                        onClick={toggleUndoDonateModal}
                                      >
                                        Undo Donate
                                      </span>
                                    )
                                  ) : !permission?.hasDonate && staffUser ? (
                                    ''
                                  ) : (
                                    <span className='dropdown-item btn' onClick={toggleDonateModal}>
                                      Donate
                                    </span>
                                  )}
                                </li>
                                {!permission?.deleteAsset && staffUser ? (
                                  ''
                                ) : (
                                  <li>
                                    <span className='dropdown-item btn' onClick={toggleDeleteModal}>
                                      Delete
                                    </span>
                                  </li>
                                )}
                                {!permission?.hasEmail && staffUser ? (
                                  ''
                                ) : (
                                  <li>
                                    <span className='dropdown-item btn' onClick={toggleEmailModal}>
                                      Email
                                    </span>
                                  </li>
                                )}
                                {!permission?.hasReplicate && staffUser ? (
                                  ''
                                ) : (
                                  <li>
                                    <span
                                      className='dropdown-item btn'
                                      onClick={toggleReplicateModal}
                                    >
                                      Replicate
                                    </span>
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}
                      </div>
                      {showPrintTag && (
                        <PrintAssetTagModal
                          handleClose={handleClosePrintTag}
                          printAssetTagModal={showPrintTag}
                          assetId={id}
                        />
                      )}
                      {lostMissingModal && (
                        <LostMissingModal
                          lostMissingModal={lostMissingModal}
                          handleClose={toggleLostMissingModal}
                          assetId={id}
                          refetchAssetDetails={refetch}
                          setRefreshHistory={setRefreshHistory}
                        />
                      )}
                      {foundModal && (
                        <FoundModal
                          foundModal={foundModal}
                          handleClose={toggleFoundModal}
                          assetId={id}
                          refetchAssetDetails={refetch}
                          setRefreshHistory={setRefreshHistory}
                        />
                      )}
                      {sellModal && (
                        <SellModal
                          sellModal={sellModal}
                          handleClose={toggleSellModal}
                          assetId={id}
                          refetchAssetDetails={refetch}
                          setRefreshHistory={setRefreshHistory}
                          currencyObject={currencyObject}
                        />
                      )}
                      {repairModal && (
                        <RepairModal
                          repairModal={repairModal}
                          handleClose={toggleRepairModal}
                          assetId={id}
                          refetchAssetDetails={refetch}
                          setRefreshHistory={setRefreshHistory}
                          currencyObject={currencyObject}
                        />
                      )}
                      {brokenModal && (
                        <BrokenModal
                          brokenModal={brokenModal}
                          handleClose={toggleBrokenModal}
                          assetId={id}
                          refetchAssetDetails={refetch}
                          setRefreshHistory={setRefreshHistory}
                        />
                      )}
                      {undoBrokenModal && (
                        <UndoBrokenModal
                          undoBrokenModal={undoBrokenModal}
                          handleClose={toggleUndoBrokenModal}
                          assetId={id}
                          refetchAssetDetails={refetch}
                          setRefreshHistory={setRefreshHistory}
                        />
                      )}
                      {undoSellModal && (
                        <UndoSellModal
                          undoSellModal={undoSellModal}
                          handleClose={toggleUndoSellModal}
                          assetId={id}
                          refetchAssetDetails={refetch}
                          setRefreshHistory={setRefreshHistory}
                        />
                      )}
                      {donateModal && (
                        <DonateModal
                          donateModal={donateModal}
                          handleClose={toggleDonateModal}
                          assetId={id}
                          refetchAssetDetails={refetch}
                          setRefreshHistory={setRefreshHistory}
                          currencyObject={currencyObject}
                        />
                      )}
                      {undoDonateModal && (
                        <UndoDonateModal
                          undoDonateModal={undoDonateModal}
                          handleClose={toggleUndoDonateModal}
                          assetId={id}
                          refetchAssetDetails={refetch}
                          setRefreshHistory={setRefreshHistory}
                        />
                      )}
                      {deleteSingleAssetModal && (
                        <DeleteSingleAssetModal
                          deleteSingleAssetModal={deleteSingleAssetModal}
                          handleClose={toggleDeleteModal}
                          document={id}
                        />
                      )}
                      {replicateModal && (
                        <ReplicateModal
                          replicateModal={replicateModal}
                          handleClose={toggleReplicateModal}
                          asset={assetDetail?.asset}
                        />
                      )}
                      {emailModal && (
                        <EmailModal
                          emailModal={emailModal}
                          handleClose={toggleEmailModal}
                          assetId={id}
                        />
                      )}
                      {detachModal && (
                        <DetachModal
                          detachModal={detachModal}
                          handleClose={toggleDetachModal}
                          document={selectedDocument}
                          refetchDocument={refetchDocument}
                        />
                      )}
                      {uploadDocumentModal && (
                        <UploadDocumentModal
                          uploadDocumentModal={uploadDocumentModal}
                          handleClose={toggleUploadDocumentModal}
                          assetId={id}
                        />
                      )}
                      {editEventModal && (
                        <EditEventModal
                          editEventModal={editEventModal}
                          handleClose={toggleEditEventModal}
                        />
                      )}
                      {addPhotosModal && (
                        <AddPhotosModal
                          addPhotosModal={addPhotosModal}
                          handleClose={toggleAddPhotosModal}
                        />
                      )}
                      {assetAuditModal && (
                        <AssetAuditModal
                          assetAuditModal={assetAuditModal}
                          handleClose={toggleAssetAuditModal}
                          auditId={auditId}
                        />
                      )}
                      {assetDeleteAuditModal && (
                        <DeleteAssetAuditModal
                          assetDeleteAuditModal={assetDeleteAuditModal}
                          handleClose={toggleDeleteAuditModal}
                          auditId={auditId}
                          refetchAudit={refetchAudit}
                        />
                      )}
                      <AddMaintenanceModel
                        show={showAddModal}
                        setShowModal={setShowModal}
                        setShowEditModal={setShowAddModal}
                        onHide={() => {
                          setLoading(false)
                          setShowAddModal(false)
                        }}
                        assetId={assetDetail?.asset?.id}
                        currencyLogo={currencyLogo}
                      />
                      <MaintenancesEditModal
                        show={showEditModal}
                        assetMaintenance={singleMaintenanceData?.maintenance}
                        setShowModal={setShowModal}
                        setShowEditModal={setShowEditModal}
                        onHide={() => {
                          setLoading(false)
                          setShowEditModal(false)
                        }}
                        setLoading={setLoading}
                        currencyLogo={currencyLogo}
                        setRepeatingMaintenance={setRepeatingMaintenance}
                        repeatingMaintenance={repeatingMaintenance}
                      />
                      {maintenanceAssetDeleteModal && (
                        <MaintenanceAssetDeleteModal
                          maintenanceAssetDeleteModal={maintenanceAssetDeleteModal}
                          handleClose={toggleMaintenanceAssetDeleteModal}
                          dataId={dataId}
                        />
                      )}
                      {maintenanceAssetViewModal && (
                        <MaintenanceAssetViewModal
                          maintenanceAssetViewModal={maintenanceAssetViewModal}
                          handleClose={() => {
                            setLoading(false)
                            setMaintenanceAssetViewModal(false)
                          }}
                          assetMaintenance={singleMaintenanceData?.maintenance}
                          currencyLogo={currencyLogo}
                        />
                      )}
                    </div>
                    <Row>
                      <Col xl='3'>
                        <div className='text-center mb-4 mb-md-0'>
                          <div className='card card-custom overlay d-inline-block mb-xl-0 mb-8'>
                            <div className='card-body p-0'>
                              <div className='overlay-wrapper asset-image'>
                                {assetDetail?.asset?.photo?.length > 0 ? (
                                  <img
                                    id='assetDefaultImg'
                                    src={
                                      assetDetail?.asset?.photo[0]?.uploadType === 1
                                        ? toAbsoluteUrlImage(assetDetail?.asset?.photo[0]?.photo)
                                        : assetDetail?.asset?.photo[0]?.stockImage?.imgUrl
                                    }
                                    alt='Asset Image'
                                    title='assets-img'
                                    crossOrigin='anonymous'
                                    className='img-fluid center-block asset_img'
                                  />
                                ) : (
                                  <img
                                    src={Images.AssetsPlaceholder}
                                    alt='Placeholder'
                                    className='img-fluid center-block asset_img'
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xl='9'>
                        <Row>
                          <Col xl='7' lg='6'>
                            <table
                              className='assets-details table table-bordered table-view-detail table-sm'
                              aria-label='Assets View'
                              role='presentation'
                            >
                              <tbody>
                                <tr>
                                  <td className='label_col w-50'>Asset Tag ID</td>
                                  <td className='detail-column fw-bold w-50'>
                                    {assetDetail?.asset?.assetTagId}
                                  </td>
                                </tr>
                                {!(personUser || customerUser) && (
                                  <tr>
                                    <td className='label_col w-50'>Purchase Date</td>
                                    <td className='detail-column fw-bold w-50'>
                                      {assetDetail?.asset?.purchasedDate
                                        ? dateFormat(
                                            assetDetail?.asset?.purchasedDate,
                                            currentUser?.dateFormat || defaultDateFormat
                                          )
                                        : ''}
                                    </td>
                                  </tr>
                                )}
                                {(ownerUser || (staffUser && !permission?.hidePriceInfo)) &&
                                  !(personUser || customerUser) && (
                                    <tr>
                                      <td className='label_col w-50'>Cost</td>
                                      <td className='detail-column fw-bold w-50'>
                                        {assetDetail?.asset?.cost}
                                        {assetDetail?.asset?.cost &&
                                          assetDetail?.asset?.cost >= 0 &&
                                          ' ' + currencyLogo}
                                      </td>
                                    </tr>
                                  )}
                                <tr>
                                  <td className='label_col w-50'>Brand</td>
                                  <td className='detail-column fw-bold w-50'>
                                    {assetDetail?.asset?.brand}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='label_col w-50'>Model</td>
                                  <td className='detail-column fw-bold w-50'>
                                    <span className='d-block text-break pre-line'>
                                      {assetDetail?.asset?.model}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='label_col w-50'>Restricted</td>
                                  <td className='detail-column fw-bold w-50'>
                                    {assetDetail?.asset?.isRestricted ? (
                                      <div className='d-inline-block align-items-center px-3 py-2 rounded text-white bg-danger'>
                                        Yes
                                      </div>
                                    ) : (
                                      <div className='d-inline-block align-items-center px-3 py-2 rounded'>
                                        No
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                          <Col xl='5' lg='6'>
                            <table
                              className='assets-details table table-bordered table-view-detail table-sm mb-0'
                              aria-label='Assets View'
                              role='presentation'
                            >
                              <tbody>
                                {!(personUser || customerUser) && (
                                  <tr>
                                    <td className='label_col text-none w-50'>Site</td>
                                    <td>
                                      <span
                                        className='link-like fw-bold'
                                        onClick={() => {
                                          setShowSite(true)
                                          setSiteId(assetDetail?.asset?.site?.id)
                                        }}
                                      >
                                        {' '}
                                        {assetDetail?.asset?.site?.name}
                                      </span>
                                    </td>
                                  </tr>
                                )}
                                {!(personUser || customerUser) && (
                                  <tr>
                                    <td className='label_col w-50'>Location</td>
                                    <td className='detail-column fw-bold w-50'>
                                      {assetDetail?.asset?.location?.location}
                                    </td>
                                  </tr>
                                )}
                                {!(personUser || customerUser) && (
                                  <tr>
                                    <td className='label_col w-50'>Category</td>
                                    <td className='detail-column fw-bold w-50'>
                                      {assetDetail?.asset?.category?.category}
                                    </td>
                                  </tr>
                                )}
                                {!(personUser || customerUser) && (
                                  <tr>
                                    <td className='label_col w-50'>Department</td>
                                    <td className='detail-column fw-bold w-50'>
                                      {assetDetail?.asset?.department?.department}
                                    </td>
                                  </tr>
                                )}
                                {!customerUser && (
                                  <tr>
                                    <td className='label_col w-50'>Assigned To</td>
                                    <td className='detail-column fw-bold w-50'>
                                      {assetDetail?.asset?.assigneeType === 1 ? (
                                        <span>
                                          {`${assetDetail?.asset?.assignedPerson?.firstName || ''} ${
                                            assetDetail?.asset?.assignedPerson?.lastName || ''
                                          }`}
                                        </span>
                                      ) : assetDetail?.asset?.assigneeType === 2 ? (
                                        <span>
                                          {`${assetDetail?.asset?.assignedSite?.name || ''} ${
                                            assetDetail?.asset?.assignedSite?.name &&
                                            assetDetail?.asset?.location?.location
                                              ? '/'
                                              : ''
                                          } ${assetDetail?.asset?.location?.location || ''}`}
                                        </span>
                                      ) : (
                                        <span></span>
                                      )}
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td className='label_col w-50'>Status</td>
                                  <td className='detail-column w-50'>
                                    <div
                                      className={`d-inline-block align-items-center px-3 py-2 rounded ${getAssetStatusClass(assetDetail?.asset?.status)}`}
                                    >
                                      {assetDetail?.asset?.status}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  {assetDetail?.asset?.reservations &&
                                    assetDetail?.asset?.reservations?.length > 0 && (
                                      <>
                                        <td className='label_col'>Reservation</td>
                                        <td className='detail-column success'>
                                          <div>
                                            {assetDetail?.asset?.reservations[0]?.startDate
                                              ? dateFormat(
                                                  assetDetail?.asset?.reservations[0]?.startDate,
                                                  currentUser?.dateFormat || defaultDateFormat
                                                ) + ' to '
                                              : ''}
                                            {assetDetail?.asset?.reservations[0]?.endDate
                                              ? dateFormat(
                                                  assetDetail?.asset?.reservations[0]?.endDate,
                                                  currentUser?.dateFormat || defaultDateFormat
                                                )
                                              : ''}
                                          </div>
                                          <div className='pe-2'>By </div>
                                          <div
                                            className='link-like fw-bold'
                                            onClick={() => {
                                              switch (
                                                assetDetail?.asset?.reservations[0]?.reserveFor
                                              ) {
                                                case 1: {
                                                  setPersonId(
                                                    assetDetail?.asset?.reservations[0]?.person?.id
                                                  )
                                                  setShowPerson(true)
                                                  break
                                                }
                                                case 2: {
                                                  setSiteId(
                                                    assetDetail?.asset?.reservations[0]?.site?.id
                                                  )
                                                  setShowSite(true)
                                                  break
                                                }
                                                case 3: {
                                                  setCustomerId(
                                                    assetDetail?.asset?.reservations[0]?.customer
                                                      ?.id
                                                  )
                                                  setShowCustomer(true)
                                                  break
                                                }
                                                default:
                                                  break
                                              }
                                            }}
                                          >
                                            {assetDetail?.asset?.reservations[0]?.reserveFor === 1
                                              ? `${assetDetail?.asset?.reservations[0]?.person?.firstName} ${assetDetail?.asset?.reservations[0]?.person?.lastName}`
                                              : assetDetail?.asset?.reservations[0]?.reserveFor ===
                                                  2
                                                ? `${assetDetail?.asset?.reservations[0]?.site?.name} ${assetDetail?.asset?.reservations[0]?.location?.location ? `/ ${assetDetail?.asset?.reservations[0]?.location?.location}` : ''}`
                                                : assetDetail?.asset?.reservations[0]
                                                      ?.reserveFor === 3
                                                  ? `${assetDetail?.asset?.reservations[0]?.customer?.firstName || ''} ${assetDetail?.asset?.reservations[0]?.customer?.lastName || ''}`
                                                  : ''}
                                          </div>
                                        </td>
                                      </>
                                    )}
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              {!(personUser || customerUser) && (
                <div className='card'>
                  <div className='card-body py-9'>
                    <div className='assets-tabs-wrapper mb-5'>
                      <ul
                        className='nav nav-tabs view-assets-tabs mb-lg-3 mb-2'
                        id='myTab'
                        role='tablist'
                      >
                        <li className='nav-item me-sm-2 m-sm-0 m-1' role='presentation'>
                          <button
                            className='nav-link fs-6 fw-bold col-form-label active'
                            id='details-tab'
                            data-bs-toggle='tab'
                            data-bs-target='#details'
                            type='button'
                            role='tab'
                            aria-controls='details'
                            aria-selected='true'
                            onClick={() => setActiveTab(0)}
                          >
                            Details
                          </button>
                        </li>
                        {(ownerUser || (staffUser && permission?.viewEvent)) &&
                          !(personUser || customerUser) && (
                            <li className='nav-item me-sm-2 m-sm-0 m-1' role='presentation'>
                              <button
                                onClick={() => setActiveTab(1)}
                                className='nav-link fs-6 fw-bold col-form-label'
                                id='events-tab'
                                data-bs-toggle='tab'
                                data-bs-target='#events'
                                type='button'
                                role='tab'
                                aria-controls='events'
                                aria-selected='false'
                              >
                                Events
                              </button>
                            </li>
                          )}
                        {(ownerUser || (staffUser && permission?.viewAsset)) &&
                          !(personUser || customerUser) && (
                            <li className='nav-item me-sm-2 m-sm-0 m-1 ' role='presentation'>
                              <button
                                className='nav-link fs-6 fw-bold col-form-label'
                                id='photos-tab'
                                data-bs-toggle='tab'
                                data-bs-target='#photos'
                                type='button'
                                role='tab'
                                aria-controls='photos'
                                aria-selected='false'
                                onClick={() => {
                                  setActiveTab(2)
                                }}
                              >
                                Photos
                              </button>
                            </li>
                          )}
                        {(ownerUser || (staffUser && permission?.viewDocument)) &&
                          !(personUser || customerUser) && (
                            <li className='nav-item me-sm-2 m-sm-0 m-1' role='presentation'>
                              <button
                                className='nav-link fs-6 fw-bold col-form-label'
                                id='docs-tab'
                                data-bs-toggle='tab'
                                data-bs-target='#docs'
                                type='button'
                                role='tab'
                                aria-controls='docs'
                                aria-selected='false'
                                onClick={() => setActiveTab(3)}
                              >
                                Docs
                              </button>
                            </li>
                          )}
                        {(ownerUser || (staffUser && permission?.viewWarranties)) &&
                          !(personUser || customerUser) && (
                            <li className='nav-item me-sm-2 m-sm-0 m-1' role='presentation'>
                              <button
                                className='nav-link fs-6 fw-bold col-form-label'
                                id='warranty-tab'
                                data-bs-toggle='tab'
                                data-bs-target='#warranty'
                                type='button'
                                role='tab'
                                aria-controls='warranty'
                                aria-selected='false'
                                onClick={() => setActiveTab(4)}
                              >
                                Warranty
                              </button>
                            </li>
                          )}
                        <li className='nav-item me-sm-2 m-sm-0 m-1 d-none' role='presentation'>
                          <button
                            className='nav-link fs-6 fw-bold col-form-label'
                            id='linking-tab'
                            data-bs-toggle='tab'
                            data-bs-target='#linking'
                            type='button'
                            role='tab'
                            aria-controls='linking'
                            aria-selected='false'
                          >
                            Linking
                          </button>
                        </li>
                        {(ownerUser || (staffUser && permission?.viewMaintenance)) &&
                          !(personUser || customerUser) && (
                            <li className='nav-item me-sm-2 m-sm-0 m-1' role='presentation'>
                              <button
                                className='nav-link fs-6 fw-bold col-form-label'
                                id='maint-tab'
                                data-bs-toggle='tab'
                                data-bs-target='#maint'
                                type='button'
                                role='tab'
                                aria-controls='maint'
                                aria-selected='false'
                                onClick={() => setActiveTab(5)}
                              >
                                Maint.
                              </button>
                            </li>
                          )}
                        {(ownerUser || (staffUser && permission?.viewReserve)) &&
                          !(personUser || customerUser) && (
                            <li className='nav-item me-sm-2 m-sm-0 m-1' role='presentation'>
                              <button
                                className='nav-link fs-6 fw-bold col-form-label'
                                id='reserve-tab'
                                data-bs-toggle='tab'
                                data-bs-target='#reserve'
                                type='button'
                                role='tab'
                                aria-controls='reserve'
                                aria-selected='false'
                                onClick={() => setActiveTab(6)}
                              >
                                Reserve
                              </button>
                            </li>
                          )}
                        {(ownerUser || (staffUser && permission?.viewAudit)) &&
                          !(personUser || customerUser) && (
                            <li className='nav-item me-sm-2 m-sm-0 m-1' role='presentation'>
                              <button
                                className='nav-link fs-6 fw-bold col-form-label'
                                id='audit-tab'
                                data-bs-toggle='tab'
                                data-bs-target='#audit'
                                type='button'
                                role='tab'
                                aria-controls='audit'
                                aria-selected='false'
                                onClick={() => setActiveTab(7)}
                              >
                                Audit
                              </button>
                            </li>
                          )}
                        {(ownerUser || (staffUser && permission?.viewHistory)) &&
                          !(personUser || customerUser) && (
                            <li className='nav-item me-sm-2 m-sm-0 m-1' role='presentation'>
                              <button
                                className='nav-link fs-6 fw-bold col-form-label'
                                id='history-tab'
                                data-bs-toggle='tab'
                                data-bs-target='#history'
                                type='button'
                                role='tab'
                                aria-controls='history'
                                aria-selected='false'
                                onClick={() => setActiveTab(8)}
                              >
                                History
                              </button>
                            </li>
                          )}
                      </ul>
                    </div>
                    <div className='tab-content' id='myTabContent'>
                      <div
                        className='tab-pane fade show active'
                        id='details'
                        role='tabpanel'
                        aria-labelledby='details-tab'
                      >
                        {activeTab === 0 && (
                          <MoreDetails
                            assetDetail={assetDetail}
                            personUser={personUser}
                            customerUser={customerUser}
                            permission={permission}
                            ownerUser={ownerUser}
                            staffUser={staffUser}
                            refreshKey={refreshHistory}
                          />
                        )}
                      </div>
                      {(ownerUser || (staffUser && permission?.viewEvent)) &&
                        !(personUser || customerUser) && (
                          <div
                            className='tab-pane fade'
                            id='events'
                            role='tabpanel'
                            aria-labelledby='events-tab'
                          >
                            <div className='events'>
                              <h4 className='mb-3 ms-2'>Events</h4>
                              {activeTab === 1 ? (
                                <div className='table-responsive'>
                                  <table className='events-table'>
                                    <EventHistory
                                      refreshKey={refreshHistory}
                                      assetDetail={assetDetail}
                                      currencyObject={currencyObject}
                                      personUser={personUser}
                                      customerUser={customerUser}
                                      permission={permission}
                                      staffUser={staffUser}
                                    />
                                  </table>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        )}
                      <div
                        className='tab-pane fade'
                        id='photos'
                        role='tabpanel'
                        aria-labelledby='photos-tab'
                      >
                        {activeTab === 2 ? (
                          <div className='tab-content'>
                            <div className='d-flex align-items-center justify-content-between'>
                              <h4 className='mb-5'>Photos</h4>
                              {(ownerUser || (staffUser && permission?.updateAsset)) && (
                                <button className='btn btn-primary' onClick={toggleAddPhotosModal}>
                                  <i className='fas fa-plus fs-2 py-0'></i> Add Photo
                                </button>
                              )}
                            </div>
                            <Photos />
                          </div>
                        ) : null}
                      </div>
                      <div
                        className='tab-pane fade'
                        id='docs'
                        role='tabpanel'
                        aria-labelledby='docs-tab'
                      >
                        <div className='tab-content'>
                          <div className='d-flex align-items-center justify-content-between mb-5'>
                            <h4>Documents</h4>
                            {(ownerUser || (staffUser && permission?.addDocument)) && (
                              <button
                                className='btn btn-primary py-3'
                                onClick={toggleUploadDocumentModal}
                              >
                                <i className='fas fa-plus'></i>Add New
                              </button>
                            )}
                          </div>
                          <div className='lease-modal-table mb-3 table-responsive'>
                            <table
                              id='kt_table_users'
                              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                              role='table'
                            >
                              <thead>
                                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                  <th role='columnheader'> Description </th>
                                  <th role='columnheader'> File Type </th>
                                  <th role='columnheader'> File Name </th>
                                  <th role='columnheader'> Upload Date </th>
                                  <th role='columnheader'> Upload By </th>
                                  <th role='columnheader'> Actions </th>
                                </tr>
                              </thead>
                              <tbody className='text-gray-600 fw-bold' role='rowgroup'>
                                {documentData && documentData?.documents.length > 0 ? (
                                  <>
                                    {documentData?.documents.map((document: any, index: number) => (
                                      <tr key={index} role='row'>
                                        <td className='table-elipses'>
                                          <span>{document?.description}</span>
                                        </td>
                                        <td className='table-elipses'>
                                          <span>
                                            <i className={getFileIcon(document?.document)} />{' '}
                                            {fileType(document?.document)}
                                          </span>
                                        </td>
                                        <td className='table-elipses'>
                                          <span>{originalName(document?.document)}</span>
                                        </td>
                                        <td className='table-elipses'>
                                          <span>{document?.createdAt}</span>
                                        </td>
                                        <td className='table-elipses'>
                                          <span>{`${document?.creator?.firstName} ${document?.creator?.lastName}`}</span>
                                        </td>
                                        <td role='cell'>
                                          <div className='dropdown'>
                                            <button
                                              className='dropdown-toggle'
                                              type='button'
                                              id='documentDropdown'
                                              data-bs-toggle='dropdown'
                                              aria-expanded='false'
                                            >
                                              Actions
                                              <ActionIcon />
                                            </button>
                                            <ul
                                              className='dropdown-menu actions-dropdown'
                                              aria-labelledby='documentDropdown'
                                            >
                                              <li>
                                                <button
                                                  className='dropdown-item'
                                                  onClick={() => handleDownload(document.document)}
                                                >
                                                  Download
                                                </button>
                                              </li>
                                              <li>
                                                {(ownerUser ||
                                                  (staffUser && permission?.deleteDocument)) && (
                                                  <button
                                                    className='dropdown-item'
                                                    onClick={() =>
                                                      handleDetachButtonClick(document)
                                                    }
                                                  >
                                                    Detach
                                                  </button>
                                                )}
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                ) : (
                                  <tr role='row'>
                                    <td role='cell' colSpan={8} className='text-center'>
                                      <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                        No records found
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {!permission?.viewWarranties && staffUser ? (
                        ''
                      ) : (
                        <div
                          className='tab-pane fade'
                          id='warranty'
                          role='tabpanel'
                          aria-labelledby='warranty-tab'
                        >
                          <div className='tab-content'>
                            {!(personUser || customerUser) && (
                              <div className='d-flex justify-content-between align-items-center mb-3'>
                                <h4>Warranty</h4>
                                {(ownerUser || (staffUser && permission?.addWarranties)) && (
                                  <button
                                    className='btn btn-primary py-3'
                                    onClick={() => {
                                      setWarrantyId(0)
                                      setShowWarrantyModal(true)
                                    }}
                                  >
                                    <i className='la la-plus fs-3'></i>Add{' '}
                                    <span className='d-sm-inline d-none'>New</span>
                                  </button>
                                )}
                              </div>
                            )}
                            <div className='table-responsive'>
                              <table
                                id='kt_table_users'
                                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                                role='table'
                              >
                                <thead>
                                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                    <th role='columnheader'>Active</th>
                                    <th role='columnheader'>Expiration Date</th>
                                    <th role='columnheader'>Length</th>
                                    <th role='columnheader'>Note</th>
                                    {(ownerUser || (staffUser && permission?.viewWarranties)) && (
                                      <th role='columnheader' className='text-center'>
                                        Actions
                                      </th>
                                    )}
                                  </tr>
                                </thead>
                                <tbody className='text-gray-600 fw-bold' role='rowgroup'>
                                  {warrantyData?.warranty?.length ? (
                                    warrantyData?.warranty?.map((item: any, idx: any) => {
                                      return (
                                        <tr key={idx} role='row'>
                                          <td role='cell'>
                                            <div className='table-elipses'>
                                              {item?.status ? (
                                                <i className='fa fa-check text-success fs-4'></i>
                                              ) : (
                                                <i className='fa fa-close text-danger fs-4'></i>
                                              )}
                                            </div>
                                          </td>
                                          <td role='cell'>
                                            <div className='table-elipses'>
                                              {item?.expiryDate
                                                ? dateFormat(
                                                    item?.expiryDate,
                                                    currentUser?.dateFormat || defaultDateFormat
                                                  )
                                                : ''}
                                            </div>
                                          </td>
                                          <td role='cell'>
                                            <div>{item?.length}</div>
                                          </td>
                                          <td role='cell'>
                                            <div className='table-elipses'>{item?.notes}</div>
                                          </td>
                                          <td role='cell' className='text-center'>
                                            {(ownerUser ||
                                              (staffUser && permission?.updateWarranties)) && (
                                              <button
                                                onClick={() => {
                                                  setWarrantyId(item?.id)
                                                  setShowWarrantyModal(true)
                                                }}
                                                className='menu-link px-4 btn btn-light-secondary py-2 view-btn-style me-2'
                                              >
                                                Edit
                                              </button>
                                            )}
                                            <button
                                              onClick={() => {
                                                setWarrantyId(item?.id)
                                                setShowView(true)
                                              }}
                                              className='menu-link px-4 btn btn-light-secondary py-2 view-btn-style'
                                            >
                                              View
                                            </button>
                                          </td>
                                        </tr>
                                      )
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={8}>
                                        <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                          No records found
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className='tab-pane fade'
                        id='linking'
                        role='tabpanel'
                        aria-labelledby='linking-tab'
                      >
                        <div className='tab-content'>
                          <h4 className='mb-5'>Linking</h4>
                          <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                            No records found
                          </div>
                        </div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='maint'
                        role='tabpanel'
                        aria-labelledby='maint-tab'
                      >
                        <div className='tab-content'>
                          <div className='d-flex align-items-center justify-content-between mb-5'>
                            <h4>Maintenance</h4>
                            {(ownerUser || (staffUser && permission?.addMaintenance)) && (
                              <button
                                className='btn btn-primary py-3'
                                onClick={() => {
                                  setShowAddModal(true)
                                }}
                              >
                                <i className='fas fa-plus'></i>Add New
                              </button>
                            )}
                          </div>
                          <div className='table-responsive'>
                            <table
                              id='kt_table_users'
                              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                              role='table'
                            >
                              <thead>
                                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                  <th role='columnheader'> Schedule Date </th>
                                  <th role='columnheader'> Title </th>
                                  <th role='columnheader'> Maintenance By </th>
                                  <th role='columnheader'> Status </th>
                                  <th role='columnheader'> Completion Date </th>
                                  <th role='columnheader'> Maintenance Cost </th>
                                  <th role='columnheader'> Details </th>
                                  <th className='text-center' role='columnheader'>
                                    {' '}
                                    Actions{' '}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {maintenanceData?.maintenance?.length ? (
                                  maintenanceData?.maintenance?.map((item: any, idx: any) => {
                                    return (
                                      <tr key={idx}>
                                        <td role='cell'>
                                          <div className='table-elipses'>
                                            {item?.dueDate
                                              ? dateFormat(
                                                  item?.dueDate,
                                                  currentUser?.dateFormat || defaultDateFormat
                                                )
                                              : ''}
                                          </div>
                                        </td>
                                        <td role='cell'>
                                          <div className='table-elipses'>{item?.title}</div>
                                        </td>
                                        <td role='cell'>
                                          <div className='table-elipses'>{item?.maintenanceBy}</div>
                                        </td>
                                        <td role='cell'>
                                          <div className='table-elipses'>{item?.status}</div>
                                        </td>
                                        <td role='cell'>
                                          <div className='table-elipses'>
                                            {item?.dateCompleted
                                              ? dateFormat(
                                                  item?.dateCompleted,
                                                  currentUser?.dateFormat || defaultDateFormat
                                                )
                                              : ''}
                                          </div>
                                        </td>
                                        <td role='cell'>
                                          <div className='table-elipses'>
                                            {item?.cost != null
                                              ? `${currencyLogo} ${item.cost}`
                                              : ''}
                                          </div>
                                        </td>
                                        <td role='cell'>
                                          <div className='table-elipses'>{item?.details}</div>
                                        </td>
                                        <td className='d-flex align-items-center justify-content-between maintenance-action-btns'>
                                          {(ownerUser ||
                                            (staffUser && permission?.deleteMaintenance)) && (
                                            <button
                                              className='me-2 me-xxl-1'
                                              onClick={() => {
                                                toggleMaintenanceAssetDeleteModal()
                                                setDataId(item?.id)
                                                setModalCheck(false)
                                              }}
                                            >
                                              <i className='fa fa-trash fs-6'></i>
                                            </button>
                                          )}
                                          {(ownerUser ||
                                            (staffUser && permission?.updateMaintenance)) && (
                                            <button
                                              className='me-2 me-xxl-1'
                                              onClick={() => {
                                                editMaintenanceDetails(item?.id)
                                                setDataId(item?.id)
                                              }}
                                            >
                                              <i className='fa fa-pencil-alt fs-6'></i>
                                            </button>
                                          )}
                                          <button
                                            className='me-2 me-xxl-1'
                                            onClick={() => {
                                              toggleViewMaintenanceModal(item?.id)
                                              setModalCheck(false)
                                            }}
                                          >
                                            <i className='fa fa-eye fs-6'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={8}>
                                      <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                        No records found
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='reserve'
                        role='tabpanel'
                        aria-labelledby='reserve-tab'
                      >
                        <div className='tab-content'>
                          <h4 className='mb-5'>Reserve</h4>
                          {activeTab === 6 && (
                            <ReserveCalender activeTab={activeTab} refreshKey={refreshHistory} />
                          )}
                          {/* <div className='table-responsive'>
                            <table
                              id='kt_table_users'
                              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                              role='table'
                            >
                              <thead>
                                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                  <th role='columnheader'> Reserved For </th>
                                  <th role='columnheader'> Reserved By </th>
                                  <th role='columnheader'> Start Date </th>
                                  <th role='columnheader'> End Date </th>
                                </tr>
                              </thead>
                              <tbody className='text-gray-600 fw-bold' role='rowgroup'>
                                {assetDetail?.asset?.reserveassets?.length ? (
                                  assetDetail?.asset?.reserveassets?.map((item: any, idx: any) => {
                                    return (
                                      <tr key={idx} role='row'>
                                        <td role='cell'>
                                          <div className='table-elipses'>{item?.reserveFor}</div>
                                        </td>
                                        <td role='cell'>
                                          <div className='table-elipses'>
                                            {item?.customer
                                              ? item?.customer?.fullName
                                              : item?.person
                                                ? item?.person?.fullName
                                                : ''}
                                            {item?.site ? item?.site?.name : ''}
                                            {item?.location ? ' / ' + item?.location?.location : ''}
                                          </div>
                                        </td>
                                        <td role='cell'>
                                          <div className='table-elipses'>
                                            {item?.startDate
                                              ? dateFormat(
                                                item?.startDate,
                                                currentUser?.dateFormat || defaultDateFormat
                                              )
                                              : ''}
                                          </div>
                                        </td>
                                        <td role='cell'>
                                          <div className='table-elipses'>
                                            {item?.endDate
                                              ? dateFormat(
                                                item?.endDate,
                                                currentUser?.dateFormat || defaultDateFormat
                                              )
                                              : ''}
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={8}>
                                      <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                        No records found
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div> */}
                        </div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='audit'
                        role='tabpanel'
                        aria-labelledby='audit-tab'
                      >
                        <div className='tab-content'>
                          <h4 className='mb-5'>Audit</h4>
                          <div className='lease-modal-table mb-3 table-responsive'>
                            <table
                              id='kt_table_users'
                              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                              role='table'
                            >
                              <thead>
                                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                  <th role='columnheader'> Audit Name </th>
                                  <th role='columnheader'> Last Audit By </th>
                                  <th role='columnheader'> Audit Date </th>
                                  <th role='columnheader'> Site </th>
                                  <th role='columnheader'> Location </th>
                                  <th role='columnheader'> Notes </th>
                                  <th role='columnheader text-center'> Map </th>
                                  {(ownerUser ||
                                    (staffUser &&
                                      (permission?.updateAudit || permission?.deleteAudit))) && (
                                    <th className='text-center' role='columnheader'>
                                      {' '}
                                      Actions{' '}
                                    </th>
                                  )}
                                </tr>
                              </thead>
                              <tbody className='text-gray-600 fw-bold' role='rowgroup'>
                                {auditData && auditData?.audits.length > 0 ? (
                                  auditData?.audits?.map((audit: any, index: number) => (
                                    <tr key={index} role='row'>
                                      <td className='table-elipses'>
                                        <span>{audit?.auditname?.name}</span>
                                      </td>
                                      <td className='table-elipses'>
                                        <span>{`${audit?.creator?.firstName} ${audit?.creator?.lastName}`}</span>
                                      </td>
                                      <td className='table-elipses'>
                                        <span>
                                          {audit?.auditDate
                                            ? dateFormat(
                                                audit?.auditDate,
                                                currentUser?.dateFormat || defaultDateFormat
                                              )
                                            : ''}
                                        </span>
                                      </td>
                                      <td className='table-elipses'>
                                        <span>{audit?.site?.name}</span>
                                      </td>
                                      <td className='table-elipses'>
                                        <span>{audit?.location?.location}</span>
                                      </td>
                                      <td className='table-elipses'>
                                        <span>{audit?.notes}</span>
                                      </td>
                                      <td className='table-elipses'>
                                        <span>{`${audit?.latitude} ${audit?.longitude}`}</span>
                                      </td>
                                      {(ownerUser ||
                                        (staffUser &&
                                          (permission?.updateAudit ||
                                            permission?.deleteAudit))) && (
                                        <td role='cell' className='text-center'>
                                          <div className='dropdown'>
                                            <button
                                              className='dropdown-toggle'
                                              type='button'
                                              id='auditdropdown'
                                              data-bs-toggle='dropdown'
                                              aria-expanded='false'
                                            >
                                              Action
                                              <ActionIcon />
                                            </button>
                                            <ul
                                              className='dropdown-menu actions-dropdown'
                                              aria-labelledby='auditdropdown'
                                            >
                                              {(ownerUser ||
                                                (staffUser && permission?.updateAudit)) && (
                                                <li>
                                                  <button
                                                    className='dropdown-item d-flex align-items-center'
                                                    onClick={() => handleEditAuditModel(audit)}
                                                  >
                                                    Edit
                                                  </button>
                                                </li>
                                              )}
                                              {(ownerUser ||
                                                (staffUser && permission?.deleteAudit)) && (
                                                <li>
                                                  <button
                                                    className='dropdown-item'
                                                    onClick={() => handleDeleteAuditModel(audit)}
                                                  >
                                                    Delete
                                                  </button>
                                                </li>
                                              )}
                                            </ul>
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  ))
                                ) : (
                                  <tr role='row'>
                                    <td role='cell' colSpan={8} className='text-center'>
                                      <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                        No records found
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='history'
                        role='tabpanel'
                        aria-labelledby='history-tab'
                      >
                        <div className='tab-content'>
                          <div className='table-responsive'>
                            <table
                              id='kt_table_users'
                              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                              role='table'
                            >
                              <thead>
                                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                  <th className='event-cell ps-3' role='columnheader'>
                                    {' '}
                                    Date{' '}
                                  </th>
                                  <th className='event-cell' role='columnheader'>
                                    {' '}
                                    Event{' '}
                                  </th>
                                  <th className='event-cell' role='columnheader'>
                                    {' '}
                                    Field{' '}
                                  </th>
                                  <th className='event-cell' role='columnheader'>
                                    {' '}
                                    Changed from{' '}
                                  </th>
                                  <th className='event-cell' role='columnheader'>
                                    {' '}
                                    Changed to{' '}
                                  </th>
                                  <th className='event-cell' role='columnheader'>
                                    {' '}
                                    Action by{' '}
                                  </th>
                                </tr>
                              </thead>
                              {activeTab === 8 ? (
                                <AssetHistory
                                  refreshKey={refreshHistory}
                                  assetDetail={assetDetail}
                                />
                              ) : (
                                <></>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <ViewSiteModal show={showSite} handleClose={handleShowSiteModalClose} siteId={siteId} />
          <ViewCustomerModal
            show={showCustomer}
            handleClose={handleCloseCustomerModal}
            customerId={customerId}
          />
          <ViewPersonModal
            show={showPerson}
            handleClose={handleClosePersonModal}
            personId={personId}
          />
          <WarrantyModal
            show={warrantyModal}
            handleClose={handleCloseWarrantyModal}
            warrantyId={warrantyId}
            assetId={id}
            setWarrantyId={setWarrantyId}
            assetUserId={assetDetail?.asset?.userId}
            setDeletedId={setDeletedId}
            setConfirmDelete={setConfirmDelete}
            staffUser={staffUser}
            ownerUser={ownerUser}
            permission={permission}
          />
          {showCheckout && (
            <CheckedoutModal
              show={checkoutModal}
              assetViewModal={assetViewModal}
              handleClose={handleCloseCheckoutModal}
              checkoutId={checkoutId}
              assetId={id}
              setCheckoutId={setCheckoutId}
              assetUserId={assetDetail?.asset?.userId}
              setDeletedId={setDeletedId}
              setConfirmDelete={setConfirmDelete}
            />
          )}
          <ViewAssetModal
            show={showView}
            handleClose={handleCloseViewWarrantyModal}
            warrantyId={warrantyId}
          />
          <DeleteConfirmationModal
            isDeleted={isDeleted}
            isSetup={'warranty'}
            handleDelete={handleDelete}
            handleClose={handleConfirmDelete}
            show={confirmDelete}
          />
        </div>
      ) : null}
      <Loader loading={loading || isDeleted} />
    </>
  )
}

export default AssetDetails
