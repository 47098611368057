import Pricing from './Pricing/Pricing'
import Subscription from './Subscription/Subscription'
import './PricingAndSubscription.css'

function PricingAndSubsrcription() {
  return (
    <div className='pricing-subscription-wrap position-relative' id='pricing'>
      <div className='position-relative z-2'>
        <Pricing />
        <Subscription />
      </div>
    </div>
  )
}

export default PricingAndSubsrcription
