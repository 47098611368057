import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: customFetchBaseQuery({ path: 'user' }),
  tagTypes: ['Profile'],
  endpoints: (build) => ({
    getProfile: build.query<any, void>({
      query: () => `/`,
      providesTags: ['Profile'],
    }),
    updateProfile: build.mutation<any, any>({
      query: (formData) => ({
        url: `profile`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: ['Profile'],
    }),
    updateSetup: build.mutation<any, { isCompleted?: boolean; onStep?: number }>({
      query: ({ isCompleted, onStep }) => ({
        url: `updateSetup`,
        method: 'PUT',
        body: { isCompleted, onStep },
      }),
      invalidatesTags: ['Profile'],
    }),
    updateTwoFactor: build.mutation<any, any>({
      query: (data) => ({
        url: `two-factor-authentication`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Profile'],
    }),
    deleteProfileImage: build.mutation<any, any>({
      query: () => ({
        url: `delete-image`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Profile'],
    }),
    deleteAccount: build.mutation<any, void>({
      query: () => ({
        url: `delete`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetProfileQuery,
  useUpdateTwoFactorMutation,
  useUpdateSetupMutation,
  useUpdateProfileMutation,
  useDeleteProfileImageMutation,
  useDeleteAccountMutation,
} = profileApi
