import { PageTitle } from 'Template/layout/core'
import AssetsListing from './Components/AssetsListing'

const Assets = () => {
  return (
    <>
      <PageTitle>List of Assets</PageTitle>
      <AssetsListing />
    </>
  )
}

export default Assets
