import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDeleteSingleAssetMutation } from 'App/Modules/Services/Assets'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

interface DeleteModalProps {
  deleteSingleAssetModal: boolean
  handleClose: () => void
  document: any
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  deleteSingleAssetModal,
  handleClose,
  document,
}) => {
  const navigate = useNavigate()
  const [deleteAsset]: any = useDeleteSingleAssetMutation()
  const { saveAuth, setCurrentUser } = useAuth()

  const handleDelete = async () => {
    try {
      const result: any = await deleteAsset({ assetId: document })
      if (result?.data?.success) {
        handleClose()
        navigate('/assets')
      } else {
        toast.error(result?.error?.data?.message)
        checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
      }
    } catch (error: any) {
      toast.error(error?.message)
    }
  }

  return (
    <Modal show={deleteSingleAssetModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Delete Asset</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className='mb-5'>Are you sure you want to delete this asset?</h5>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Cancel
        </button>
        <button className='btn btn-danger' onClick={handleDelete}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteModal
