import { dateFormat, defaultDateFormat } from 'config'
import Modal from 'react-bootstrap/Modal'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-router-dom'
import { toAbsoluteUrlImage } from 'Template/helpers'
import { Images } from 'Template/assets/Assets'
import { useAuth } from 'App/Modules/Auth'

function MaintenancesDetailModal(props) {
  const { currentUser } = useAuth()
  const permission = currentUser?.SecurityGroup
  const staffUser = currentUser?.userType === 2
  return (
    <>
      <Modal
        className='px-2'
        size='lg'
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        backdrop='static'
      >
        <Modal.Header closeButton onClick={props.onHide}>
          <Modal.Title>Asset Maintenance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey='tab1'
            id='uncontrolled-tab-example'
            className='mb-5 view-assets-tabs justify-content-start'
          >
            <Tab eventKey='tab1' title='Maintenance Details' className='me-2'>
              <div className='assetProduct_detail mb-5'>
                <div className='asset_detail_table table-responsive'>
                  <table
                    className='table table-bordered detail-table table-sm mb-0'
                    aria-label='Quick View'
                    role='presentation'
                  >
                    <tbody>
                      <tr>
                        <td className='label_col bg-light'>Title</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.title}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Details</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.details}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Due Date</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.dueDate
                            ? dateFormat(
                                props.assetMaintenance?.dueDate,
                                currentUser?.dateFormat || defaultDateFormat
                              )
                            : ''}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Maintenance By</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.maintenanceBy}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Maintenance Status</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.status}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Date completed</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.dateCompleted
                            ? dateFormat(
                                props.assetMaintenance?.dateCompleted,
                                currentUser?.dateFormat || defaultDateFormat
                              )
                            : ''}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Maintenance Cost</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.cost !== null
                            ? `${props?.currencyLogo} ${props?.assetMaintenance?.cost}`
                            : ''}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Frequency</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.frequency}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Recur on every</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.recurOnEvery !== null &&
                          props.assetMaintenance?.frequency !== null
                            ? `${props.assetMaintenance?.recurOnEvery} ${props.assetMaintenance?.frequency}`
                            : ''}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='pt-4 p-0 border-top d-flex justify-content-end'>
                <button
                  onClick={props.onHide}
                  type='button'
                  className='btn btn-light-primary   main-btn-style me-2'
                  data-bs-dismiss='modal'
                >
                  Close
                </button>
              </div>
            </Tab>
            <Tab eventKey='tab2' title='Asset Details'>
              <div className='assetProduct_detail asset-details mb-5'>
                <div className='row'>
                  <div className='col-12'>
                    {props.assetMaintenance?.Asset?.photo &&
                    props.assetMaintenance?.Asset?.photo?.length > 0 ? (
                      <div className='d-flex justify-content-center align-items-center mb-5'>
                        <div className='overlay-wrapper asset_image rounded overflow-hidden mb-lg-0 mb-5'>
                          <img
                            src={
                              props.assetMaintenance?.Asset?.photo[0]?.uploadType === 1
                                ? toAbsoluteUrlImage(props.assetMaintenance?.Asset?.photo[0]?.photo)
                                : props.assetMaintenance?.Asset?.photo[0]?.stockImage?.imgUrl
                            }
                            className='img-fluid'
                            alt='Asset'
                            crossOrigin='anonymous'
                          />
                        </div>
                      </div>
                    ) : (
                      <div className='overlay-wrapper asset_image rounded overflow-hidden mb-lg-0 mb-5'>
                        <img src={Images.AssetsPlaceholder} className='img-fluid' alt='Asset' />
                      </div>
                    )}
                  </div>
                  <div className='col-12'>
                    <div className='asset_detail_table table-responsive'>
                      <table
                        className='table table-bordered detail-table table-sm mb-0'
                        aria-label='Quick View'
                        role='presentation'
                      >
                        <tbody>
                          <tr className='align-middle'>
                            <td className='label_col bg-light'> Asset Photo </td>
                            <td>
                              {props.assetMaintenance?.Asset?.photo &&
                              props.assetMaintenance?.Asset?.photo?.length > 0 ? (
                                <div className='overlay-wrapper asset_image rounded overflow-hidden mb-lg-0 mb-5'>
                                  <img
                                    src={
                                      props.assetMaintenance?.Asset?.photo[0]?.uploadType === 1
                                        ? toAbsoluteUrlImage(
                                            props.assetMaintenance?.Asset?.photo[0]?.photo
                                          )
                                        : props.assetMaintenance?.Asset?.photo[0]?.stockImage
                                            ?.imgUrl
                                    }
                                    className='img-fluid'
                                    alt='Asset'
                                    crossOrigin='anonymous'
                                  />
                                </div>
                              ) : (
                                <div className='overlay-wrapper asset_image rounded overflow-hidden mb-lg-0 mb-5'>
                                  <img
                                    src={Images.AssetsPlaceholder}
                                    className='img-fluid'
                                    alt='Asset'
                                  />
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Asset Tag ID </td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.Asset?.assetTagId}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Description</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.Asset?.description}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Purchase Date </td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.Asset?.purchasedDate
                                ? dateFormat(
                                    props.assetMaintenance?.Asset?.purchasedDate,
                                    currentUser?.dateFormat || defaultDateFormat
                                  )
                                : ''}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Purchased From</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.Asset?.purchasedFrom}
                            </td>
                          </tr>
                          {!permission?.hidePriceinfo && staffUser ? (
                            ''
                          ) : (
                            <tr>
                              <td className='label_col bg-light'>Cost</td>
                              <td className='fw-bold detail_col table-elipses'>
                                {props.assetMaintenance?.Asset?.cost !== null
                                  ? `${props?.currencyLogo} ${props.assetMaintenance?.Asset?.cost}`
                                  : ''}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td className='label_col bg-light'>Brand</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.Asset?.brand}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Model</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.Asset?.model}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Serial No</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.Asset?.serialNo}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Site</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.Asset?.site?.name}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Location</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.Asset?.location?.location}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Category </td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.Asset?.category?.category}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Department </td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.Asset?.department?.department}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Assigned to</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.Asset?.site?.name}{' '}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Status</td>
                            <td className='detail_col table-elipses'>
                              <div
                                className={`d-inline-block align-items-center px-3 py-2 rounded ${
                                  props.assetMaintenance?.Asset?.status === 'Available'
                                    ? 'bg-light-green'
                                    : props.assetMaintenance?.Asset?.status === 'Lease'
                                      ? 'bg-light-blue'
                                      : props.assetMaintenance?.Asset?.status === 'Dispose'
                                        ? 'bg-light-pink'
                                        : props.assetMaintenance?.Asset?.status === 'Check_out'
                                          ? 'bg-light-blue'
                                          : ''
                                }`}
                              >
                                {props.assetMaintenance?.Asset?.status}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className='pt-4 p-0 border-top d-flex justify-content-end'>
                <button
                  onClick={props.onHide}
                  type='button'
                  className='btn btn-light-primary   main-btn-style me-2'
                  data-bs-dismiss='modal'
                >
                  Close
                </button>
                <Link to={'/maintenances'} className='btn btn-primary main-btn-style' id='eventUrl'>
                  More Details
                </Link>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MaintenancesDetailModal
