import { baseURL } from 'config'
import React from 'react'
import Barcode from 'react-barcode'

const BarcodeComponent: React.FC<any> = ({ asset }) => {
  const url = `${baseURL}/asset/view/${asset}`
  return <Barcode value={url} displayValue={false} />
}

export default BarcodeComponent
