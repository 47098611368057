import Loader from 'App/Loaders/BeatLoader'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { useAddPhotosMutation } from 'App/Modules/Services/AssetPhotos'
import { imageMimeType } from 'config'
import { useState, useEffect, SetStateAction, Dispatch } from 'react'
import Modal from 'react-bootstrap/Modal'
import Cropper from 'react-easy-crop'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

interface NewAuditModalProps {
  singlePhotoUploadModal: boolean
  handleClose: () => void
  handlePhotosClose?: () => void
  setFile?: Dispatch<SetStateAction<any>>
}

const SinglePhotoUploadModal: React.FC<NewAuditModalProps> = ({
  singlePhotoUploadModal,
  handleClose,
  handlePhotosClose,
  setFile,
}) => {
  const path = localStorage.getItem('path')
  const { id } = useParams()
  const { saveAuth, setCurrentUser } = useAuth()
  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [filename, setFileName] = useState<any>(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedPixel, setCroppedPixel] = useState<any>(null)
  const [aspect, setAspect] = useState<number | undefined>(undefined)
  const [AddPhotos, { isLoading }] = useAddPhotosMutation()
  const [edit, setEdit] = useState<boolean>(false)
  const [add, setAdd] = useState<boolean>(false)

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      if (!file.type.match(imageMimeType)) {
        toast.error('This file format is not allowed!')
        return
      } else {
        setFileName(file?.name)
        const reader = new FileReader()
        reader.onload = () => {
          const img = new Image()
          img.onload = () => {
            setAspect(img.width / img.height)
          }
          img.onerror = () => {
            toast.error('There is some issue in uploading this image!')
            setImageSrc(null)
          }
          img.src = reader.result as string
          setImageSrc(reader.result as string)
        }
        reader.readAsDataURL(file)
      }
    }
  }

  const createImage = () =>
    new Promise((resolve, reject) => {
      const image: any = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', (error) => reject(error))
      image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
      image.src = imageSrc
    })

  function getRadianAngle() {
    return (rotation * Math.PI) / 180
  }

  function rotateSize(width, height) {
    const rotRad = getRadianAngle()

    return {
      width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
  }

  const getImage = async (flip = { horizontal: false, vertical: false }) => {
    if (imageSrc) {
      const image: any = await createImage()

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      if (!ctx) {
        return null
      }
      const rotRad = getRadianAngle()

      const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height)

      canvas.width = bBoxWidth
      canvas.height = bBoxHeight

      ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
      ctx.rotate(rotRad)
      ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
      ctx.translate(-image.width / 2, -image.height / 2)

      ctx.drawImage(image, 0, 0)

      const croppedCanvas = document.createElement('canvas')

      const croppedCtx = croppedCanvas.getContext('2d')

      if (!croppedCtx) {
        return null
      }

      // Set the size of the cropped canvas
      croppedCanvas.width = croppedPixel.width
      croppedCanvas.height = croppedPixel.height

      // Draw the cropped image onto the new canvas
      croppedCtx.drawImage(
        canvas,
        croppedPixel.x,
        croppedPixel.y,
        croppedPixel.width,
        croppedPixel.height,
        0,
        0,
        croppedPixel.width,
        croppedPixel.height
      )

      // As Base64 string
      return croppedCanvas.toDataURL()
    }
  }
  const urlToFile = (src) => {
    const [header, base64String] = src.split(',')
    const mimeMatch = header.match(imageMimeType)
    if (mimeMatch) {
      const mimeType = mimeMatch ? `image/${mimeMatch[1]}` : 'application/octet-stream'
      const byteCharacters = atob(base64String)
      const byteArrays: any = []
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i))
      }
      const byteArray = new Uint8Array(byteArrays)
      const blob = new Blob([byteArray], { type: mimeType })
      const file = new File([blob], filename, { type: mimeType })
      return file
    } else {
      toast.error('Please select the image with required format!')
    }
  }

  useEffect(() => {
    if (path?.includes('/asset/edit/')) {
      setEdit(true)
      setAdd(false)
    } else if (path?.includes('/asset/add')) {
      setEdit(false)
      setAdd(true)
    } else {
      setEdit(false)
      setAdd(false)
    }
  }, [path])

  const rotateImage = (direction: 'left' | 'right') => {
    setRotation((prev) => (direction === 'left' ? prev - 90 : prev + 90))
  }

  const zoomImage = (zooming: 'in' | 'out') => {
    if (zooming === 'out' && zoom >= 1) {
      setZoom((prev) => prev - 0.1)
    }
    if (zooming === 'in' && zoom <= 3) {
      setZoom((prev) => prev + 0.1)
    }
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedPixel(croppedAreaPixels)
  }

  const handleSubmit = async () => {
    const croppedImage: any = await getImage()
    const file: any = await urlToFile(croppedImage)
    if (add) {
      setFile?.(file)
      handleClose()
    } else {
      const data: any = new FormData()
      data.append('photos', file)
      data.append('uploadType', 1)
      try {
        const { data: resData, error }: any = await AddPhotos({ data, id })
        if (resData?.success) {
          toast.success(resData?.message)
        } else {
          toast.error(error?.data?.message)
          checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
        }
        setImageSrc(null)
        setFileName(null)
        handleClose()
        if (!edit) {
          handlePhotosClose?.()
        }
      } catch (error: any) {
        toast.error(error?.data?.message)
        checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
        setImageSrc(null)
        setFileName(null)
        handleClose()
        if (!edit) {
          handlePhotosClose?.()
        }
      }
    }
  }

  return (
    <>
      <Modal
        className='single-upload-photo-modal'
        show={singlePhotoUploadModal}
        onHide={handleClose}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Single Image Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='single-upload-photo-content d-flex align-items-center justify-content-md-between justify-content-center flex-wrap flex-column flex-md-row'>
            {!imageSrc && (
              <>
                <h3>Upload Your Photo</h3>
                <div className='file-input'>
                  <input
                    type='file'
                    name='file-input'
                    id='file-input'
                    className='file-input__input'
                    accept='image/*' // Restrict file input to images only
                    onChange={onFileChange}
                  />
                  <label htmlFor='file-input' className='file-input__label'>
                    <i className='fa-solid fa-image text-white fs-2'></i>
                    <span className='ms-3'>Single Upload</span>
                  </label>
                </div>
              </>
            )}
            {imageSrc && (
              <div className='crop-container'>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  rotation={rotation}
                  zoom={zoom}
                  aspect={aspect}
                  onCropChange={setCrop}
                  onRotationChange={setRotation}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                  showGrid={true}
                />
              </div>
            )}
          </div>
          <p className='text-center p-0 m-0'>Only (JPG, GIF, PNG) are allowed</p>
          <Loader loading={isLoading} />
        </Modal.Body>
        <Modal.Footer>
          {imageSrc && (
            <div className='photo-single-btn d-flex flex-wrap'>
              <button className='btn btn-primary mx-2' onClick={() => zoomImage('in')}>
                Zoom In
              </button>
              <button className='btn btn-primary mx-2' onClick={() => zoomImage('out')}>
                Zoom out
              </button>
              <button className='btn btn-primary mx-2' onClick={() => rotateImage('left')}>
                Rotate Left
              </button>
              <button className='btn btn-primary mx-2' onClick={() => rotateImage('right')}>
                Rotate Right
              </button>
              <button className='btn btn-primary mx-2' onClick={handleSubmit}>
                Save
              </button>
            </div>
          )}
          <button className='btn cancel-btn border' onClick={handleClose}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SinglePhotoUploadModal
