export interface CheckInInterface {
  returnDate: string
  checkinType: number
  sendEmail: boolean
  personId: number | null
  notes: string | ''
  locationId: number | null
  siteId: number | null
  departmentId: number | null
  assetId: number[]
}

export const defaultCheckInDetail: CheckInInterface = {
  returnDate: new Date().toISOString().split('T')[0],
  checkinType: 1,
  personId: null,
  notes: '',
  sendEmail: false,
  locationId: null,
  siteId: null,
  departmentId: null,
  assetId: [],
}
