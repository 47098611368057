import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAuth } from '../../Auth'

const API_URL = process.env.REACT_APP_API_URL

export const customFetchBaseQuery = ({ path }) => {
  return fetchBaseQuery({
    baseUrl: `${API_URL}/${path}`,
    prepareHeaders: (headers) => {
      const auth = getAuth()
      if (auth?.token) {
        headers.set('Authorization', `Bearer ${auth?.token}`)
      }
      return headers
    },
  })
}
