import { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import { useGetSingleCustomerQuery } from 'App/Modules/Services/Customers'
import Loader from 'App/Loaders/BeatLoader'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

const ViewCustomerModal = (props) => {
  const { data, isSuccess, isError, error, isLoading } = useGetSingleCustomerQuery(
    {
      id: props?.customerId,
    },
    { skip: !props?.customerId }
  )
  const { saveAuth, setCurrentUser } = useAuth()
  const [customer, setCustomer] = useState({})

  useEffect(() => {
    if (isSuccess && data) {
      setCustomer(data?.customer)
    } else {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error, saveAuth, setCurrentUser, toast)
    }
  }, [data, isSuccess, isError, error])

  return (
    <>
      {!isLoading && (
        <Modal
          className='px-2'
          size='lg'
          {...props}
          onHide={props.handleClose}
          aria-labelledby='contained-modal-title-vcenter'
          backdrop='static'
        >
          <Modal.Header closeButton>
            <Modal.Title>{customer?.firstName || ''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='assetProduct_detail mb-5'>
              <div className='asset_detail_table table-responsive'>
                <table
                  className='table table-bordered detail-table table-sm mb-0'
                  aria-label='Quick View'
                  role='presentation'
                >
                  <tbody>
                    <tr>
                      <td className='label_col bg-light'>Full Name</td>
                      <td className='fw-bold detail_col table-elipses'>
                        {`${customer?.firstName || ''} ${customer?.lastName || ''}`}
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Company</td>
                      <td className='fw-bold detail_col table-elipses'>
                        {customer?.company || ''}
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Address 1</td>
                      <td className='fw-bold detail_col table-elipses'>
                        {customer?.address1 || ''}
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Address 2</td>
                      <td className='fw-bold detail_col table-elipses'>
                        {customer?.address2 || ''}
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>City</td>
                      <td className='fw-bold detail_col table-elipses'>{customer?.city || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>State</td>
                      <td className='fw-bold detail_col table-elipses'>{customer?.state || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Zip</td>
                      <td className='fw-bold detail_col table-elipses'>{customer?.zip || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Country</td>
                      <td className='fw-bold detail_col table-elipses'>
                        {customer?.country || ''}
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Phone</td>
                      <td className='fw-bold detail_col table-elipses'>{customer?.phone || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Cell</td>
                      <td className='fw-bold detail_col table-elipses'>{customer?.cell || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Email</td>
                      <td className='fw-bold detail_col table-elipses'>{customer?.email || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Notes</td>
                      <td className='fw-bold detail_col table-elipses'>{customer?.notes || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Created By</td>
                      <td className='fw-bold detail_col table-elipses'>
                        {`${customer?.creator?.firstName || ''} ${customer?.creator?.lastName || ''}` ||
                          ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='pt-4 p-0 border-top d-flex justify-content-end'>
              <button
                onClick={props.handleClose}
                type='button'
                className='btn btn-light-primary   main-btn-style me-2'
                data-bs-dismiss='modal'
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <Loader loading={isLoading} />
    </>
  )
}

export default ViewCustomerModal
