import { toast } from 'react-toastify'
import { SampleColumnData } from './RenderColumnData'
import { useEffect, useState } from 'react'
import { useGetSetupColumnsQuery, useUpdateSetupColumnsMutation,} from 'App/Modules/Services/setupColumn'
import { useAuth } from 'App/Modules/Auth'

interface Field {
  key: string
  value: boolean
  type: string
}

const SetupCoulmnListing = (props: any) => {
  const {
    data: setupColumnsData,
    isError,
    error,
  } = useGetSetupColumnsQuery(['Asset', 'Linking', 'Event', 'Maintenance'])
  const { currentUser } = useAuth()
  const permission = currentUser?.SecurityGroup
  const ownerUser = currentUser?.userType === 1
  const staffUser = currentUser?.userType === 2
  const [columnsError, setColumnsErr] = useState<any>(null)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [fields, setFields] = useState<Field[]>(props.selectedColumns) // Update state type
  const [updateSetupColumnsBulk] = useUpdateSetupColumnsMutation()
  const setupColumnsRender = fields.filter((column) => column.value === true)

  useEffect(() => {
    setFields(props.selectedColumns)
  }, [props.selectedColumns])

  useEffect(() => {
    if (setupColumnsData) {
      const initialSelectedColumns = setupColumnsData.setupColumns.reduce((acc, setup) => {
        const columns = setup.selectedColumns.map((col) => {
          const key = Object.keys(col)[0]
          return {
            key: key,
            value: col[key],
            type: setup.type,
          }
        })
        return [...acc, ...columns]
      }, [])
      const filteredColumns = initialSelectedColumns.filter((column) => {
        if (column.key === 'Cost' && column.type === 'Asset') {
          return ownerUser || (staffUser && !permission?.hidePriceInfo)
        }
        return true
      })
      props.setSelectedColumns(filteredColumns)
      setFields(filteredColumns)
    } else if (isError) {
      setColumnsErr(error)
    }
  }, [setupColumnsData, isError, error])

  useEffect(() => {
    const isSelectedAll = fields.every((field) => {
      return field.value === true
    })
    setSelectAll(isSelectedAll)
  }, [fields])

  const handleSelectAll = () => {
    const isSelectAll = !selectAll
    setSelectAll(isSelectAll)
    setFields((prevSelectedColumns) => {
      const updatedColumns = prevSelectedColumns.map((column) => {
        return { ...column, value: isSelectAll }
      })
      return updatedColumns
    })
  }

  const handleCheckboxChange = (key) => {
    setFields((prevSelectedColumns) => {
      const updatedColumns = prevSelectedColumns.map((column) => {
        if (column.key === key) {
          return { ...column, value: !column.value }
        }
        return column
      })
      return updatedColumns
    })
  }

  const handleSaveSetup = async () => {
    const maintenances = (fields: any[]) => {
      return fields.reduce((acc: any, field: any) => {
        if (!acc[field.type]) {
          acc[field.type] = []
        }
        acc[field.type].push({ [field.key]: field.value })
        return acc
      }, {})
    }

    setLoading(true)
    try {
      const { data } = await updateSetupColumnsBulk({
        setupColumnsTypes: ['Asset', 'Linking', 'Event', 'Maintenance'],
        fields: maintenances(fields),
      })
      toast.success(data?.message)
      props.setShowSetupColumns(false)
    } catch (error) {
      toast.error('Failed to update setup columns')
    } finally {
      setLoading(false)
    }
  }

  const groupedFields = fields.reduce((acc: any, field: any) => {
    if (!acc[field.type]) {
      acc[field.type] = []
    }
    acc[field.type].push(field)
    return acc
  }, {})

  return (
    <div className={`${!props.showSetupColumns && 'd-none'}`}>
      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          <div id='kt_content_container' className={`container`}>
            <div className='card card-custom gutter-b'>
              <div className='card-body'>
                <div className='form_section'>
                  <div className='form-title fw-bold fs-4 pb-3 d-flex align-items-center'>
                    <span className='fs-5 pb-1 me-2'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='17px'
                        height='17px'
                        viewBox='0 0 16 16'
                      >
                        <path
                          fill='#080D42'
                          fill-rule='evenodd'
                          d='M5.29289,3.70711 C4.90237,3.31658 4.90237,2.68342 5.29289,2.29289 C5.68342,1.90237 6.31658,1.90237 6.70711,2.29289 L11.7071,7.29289 C12.0976,7.68342 12.0976,8.31658 11.7071,8.70711 L6.70711,13.7071 C6.31658,14.0976 5.68342,14.0976 5.29289,13.7071 C4.90237,13.3166 4.90237,12.6834 5.29289,12.2929 L9.58579,8 L5.29289,3.70711 Z'
                        />
                      </svg>
                    </span>
                    <span>Select Table Columns</span>
                  </div>
                  <p className='mb-5'>Check the columns you want to see in the List.</p>
                  <label className='radio mb-3' htmlFor='selectAll'>
                    <input
                      type='checkbox'
                      id='selectAll'
                      checked={selectAll}
                      onChange={() => handleSelectAll()}
                      className='custom-form-check-input form-check-input sig_input m_5'
                    />
                    <span>Select All</span>
                  </label>
                  <div className='row'>
                    {Object.entries(groupedFields).map(([type, columns]: [any, any]) => (
                      <div className='col-lg-3 col-md-4 col-sm-6' key={type}>
                        <div className='form-title fw-bold fs-5 mb-4 pb-0'>{type} Fields</div>
                        <ul className='list-unstyled'>
                          {columns.map((column: any, index) => (
                            <li key={index}>
                              <label
                                className='radio mb-3'
                                htmlFor={column.key.toLowerCase().replace(/\s/g, '')}
                              >
                                <input
                                  type='checkbox'
                                  id={column.key.toLowerCase().replace(/\s/g, '')}
                                  checked={column.value}
                                  onChange={() => handleCheckboxChange(column.key)}
                                  className='custom-form-check-input form-check-input sig_input m_5'
                                />
                                <span>{column.key.replace(/_/g, ' ')}</span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='card-body'>
                <div className='form_section'>
                  <div className='form-title fw-bold fs-4 pb-3 d-flex align-items-center'>
                    <span className='fs-5 pb-1 me-2'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='17px'
                        height='17px'
                        viewBox='0 0 16 16'
                      >
                        <path
                          fill='#080D42'
                          fill-rule='evenodd'
                          d='M5.29289,3.70711 C4.90237,3.31658 4.90237,2.68342 5.29289,2.29289 C5.68342,1.90237 6.31658,1.90237 6.70711,2.29289 L11.7071,7.29289 C12.0976,7.68342 12.0976,8.31658 11.7071,8.70711 L6.70711,13.7071 C6.31658,14.0976 5.68342,14.0976 5.29289,13.7071 C4.90237,13.3166 4.90237,12.6834 5.29289,12.2929 L9.58579,8 L5.29289,3.70711 Z'
                        />
                      </svg>
                    </span>
                    <span>Order Table Columns</span>
                  </div>
                  <div className='table-responsive mb-5 pb-4'>
                    <table id='preview-table' className='table table-bordered'>
                      <thead className='thead-light light-yellow-table text-nowrap'>
                        <tr>
                          {setupColumnsRender.map((column, index) => (
                            <th key={index} className='align-middle'>
                              {column.key.replace(/_/g, ' ')}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {setupColumnsRender.map((column, colIndex) => (
                            <td key={colIndex}>
                              <SampleColumnData column={column.key.replace(/_/g, ' ')} />
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='card-footer text-end'>
                <button
                  className='btn btn-light-primary   main-btn-style me-2'
                  onClick={() => {
                    setFields(props.selectedColumns)
                    props.setShowSetupColumns(false)
                  }}
                >
                  Cancel
                </button>
                <button
                  className='btn btn-primary main-btn-style'
                  disabled={loading}
                  onClick={handleSaveSetup}
                >
                  {!loading && 'Save Setup'}
                  {loading && (
                    <span className='indicator-progress d-block'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetupCoulmnListing
