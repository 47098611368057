import { useAuth } from 'App/Modules/Auth'
import { useGetSingleAssetQuery } from 'App/Modules/Services/Assets'
import { dateFormat, defaultDateFormat } from 'config'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'

function AssetMinimalDetailModal(props) {
  const { currentUser } = useAuth()
  const { data: assetData, isLoading: isLoadingViewAsset } = useGetSingleAssetQuery(
    { assetId: props.assetId },
    { skip: !props.assetId }
  )

  return (
    <>
      {!isLoadingViewAsset && (
        <Modal
          className='px-2'
          {...props}
          size='md'
          aria-labelledby='contained-modal-title-vcenter'
          backdrop='static'
        >
          <Modal.Header closeButton>
            <Modal.Title>{assetData?.asset?.assetTagId}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='modal-title fw-bolder mb-4'>{assetData?.asset?.description}</div>
            <div className='assetProduct_detail'>
              <div className='asset_detail_table table-responsive'>
                <table
                  className='table table-bordered detail-table table-sm mb-0'
                  aria-label='Quick View'
                  role='presentation'
                >
                  <tbody>
                    <tr>
                      <td className='label_col bg-light'>Purchase Date</td>
                      <td className='fw-bold detail_col table-elipses'>
                        <span>
                          {dateFormat(
                            assetData?.asset?.purchasedDate,
                            currentUser?.dateFormat || defaultDateFormat
                          )}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Purchased From</td>
                      <td className='fw-bold detail_col table-elipses'>
                        <span>{assetData?.asset?.purchasedFrom}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Cost</td>
                      <td className='fw-bold detail_col table-elipses'>
                        <span>{assetData?.asset?.cost}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Brand</td>
                      <td className='fw-bold detail_col table-elipses'>
                        <span>{assetData?.asset?.brand}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Model</td>
                      <td className='fw-bold detail_col table-elipses'>
                        <span>{assetData?.asset?.model}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Serial No</td>
                      <td className='fw-bold detail_col table-elipses'>
                        <span>{assetData?.asset?.serialNo}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Site</td>
                      <td className='fw-bold detail_col table-elipses'>
                        <span>{assetData?.asset?.site?.name}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Location</td>
                      <td className='fw-bold detail_col table-elipses'>
                        <span>{assetData?.asset?.location?.location}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Category</td>
                      <td className='fw-bold detail_col table-elipses'>
                        <span>{assetData?.asset?.category?.category}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Department</td>
                      <td className='fw-bold detail_col table-elipses'>
                        <span>{assetData?.asset?.department?.department}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Assigned to</td>
                      <td className='fw-bold detail_col table-elipses'>
                        <span>{assetData?.asset?.site?.name}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Status</td>
                      <td className='detail_col table-elipses'>
                        <div
                          className={`d-inline-block align-items-center px-3 py-2 rounded ${
                            assetData?.asset?.status === ('available' || 'Available')
                              ? 'bg-light-green'
                              : assetData?.asset?.status === 'lease'
                                ? 'bg-light-blue'
                                : assetData?.asset?.status === 'dispose'
                                  ? 'bg-light-pink'
                                  : assetData?.asset?.status === 'check_out'
                                    ? 'bg-light-blue'
                                    : ''
                          }`}
                        >
                          {assetData?.asset?.status === 'check_out'
                            ? 'Checked out'
                            : assetData?.asset?.status === 'lease'
                              ? 'Leased'
                              : assetData?.asset?.status === 'dispose'
                                ? 'Disposed'
                                : assetData?.asset?.status === 'available'
                                  ? 'Available'
                                  : assetData?.asset?.status}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={props.onHide}
              type='button'
              className='btn btn-light-primary main-btn-style me-2'
              data-bs-dismiss='modal'
            >
              Close
            </button>
            <Link
              to={`/asset/view/${assetData?.asset?.id}`}
              className='btn btn-primary main-btn-style'
              href='/assets/detail/29962946'
              id='eventUrl'
            >
              More Details
            </Link>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default AssetMinimalDetailModal
