import AssetTagPrint from 'Template/helpers/components/AssetTagPrint'
import Modal from 'react-bootstrap/Modal'
import { useLocation, useNavigate } from 'react-router-dom'

interface PrintAssetTagModalProps {
  printAssetTagModal: boolean
  handleClose: () => void
  assetId: any
}

const PrintAssetTagModal: React.FC<PrintAssetTagModalProps> = ({
  printAssetTagModal,
  handleClose,
  assetId,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location

  const handleViewAsset = () => {
    handleClose()
    navigate(`/asset/view/${assetId}`)
  }

  const handlePrint = () => {
    handleClose()
  }

  const path = pathname === `/asset/view/${assetId}`

  return (
    <Modal show={printAssetTagModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{path ? 'Print Tag' : 'Asset Added'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {path ? (
          ''
        ) : (
          <p className='mb-5'>
            Your asset has been added successfully. Would you like to print the asset tag?
          </p>
        )}

        <p className={`${path ? 'mb-0' : 'mt-5'}`}>
          Please note: To set up tag formats and related settings for printing, ensure you have
          <a
            href='https://www.seagullscientific.com/30-day-trial/'
            target='_blank'
            className='fw-bold'
          >
            {' '}
            Bartender
          </a>{' '}
          installed and configured appropriately.
        </p>
        {path ? (
          ''
        ) : (
          <div onClick={handlePrint}>
            <AssetTagPrint assetId={assetId} />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Close
        </button>
        {path ? (
          <div onClick={handlePrint}>
            <AssetTagPrint assetId={assetId} />
          </div>
        ) : (
          <button className='btn btn-primary' onClick={handleViewAsset}>
            View Asset
          </button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default PrintAssetTagModal
