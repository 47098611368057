import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'
export interface Company {
  userId: string
  name: string
}

export const optionApi = createApi({
  reducerPath: 'optionApi',
  baseQuery: customFetchBaseQuery({ path: '' }),
  tagTypes: ['Option'],
  endpoints: (build) => ({
    getOption: build.query<void, void>({
      query: () => ({
        url: `option`,
        method: 'GET',
      }),
      providesTags: ['Option'],
    }),
    addOption: build.mutation<any, { data: any }>({
      query: ({ data }) => ({
        url: `option`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Option' }],
    }),
  }),
})

export const { useGetOptionQuery, useAddOptionMutation } = optionApi
