import { createApi } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { toast } from 'react-toastify'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const maintenanceApi = createApi({
  reducerPath: 'maintenanceApi',
  baseQuery: customFetchBaseQuery({ path: 'maintenance' }),
  tagTypes: ['Maintenance'],
  endpoints: (build) => ({
    addMaintenance: build.mutation<any, any>({
      query: (data) => ({
        url: '/',
        method: 'POST',
        body: data,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(assetsApi.util.invalidateTags(['Assets']))
          })
          .catch((err) => toast.error(err?.message))
      },
      invalidatesTags: () => [{ type: 'Maintenance' }],
    }),
    getAllMaintenances: build.query<any, { body: any; page: any; limit: any; keys: any }>({
      query: ({ body, page, limit, keys }) => ({
        url: `list/?${body}&page=${page}&limit=${limit}&keys=${keys}`,
        method: 'GET',
      }),
      providesTags: ['Maintenance'],
    }),
    getMaintenance: build.query<any, any>({
      query: ({ id }) => ({
        url: `/get/${id}`,
        method: 'GET',
      }),
      providesTags: ['Maintenance'],
    }),
    getAssetMaintenance: build.query<any, any>({
      query: ({ id }) => ({
        url: `/get-asset-maintenance/${id}`,
        method: 'GET',
      }),
      providesTags: ['Maintenance'],
    }),
    updateMaintenance: build.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/update/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => [{ type: 'Maintenance' }],
    }),
    deleteMaintenance: build.mutation<any, any>({
      query(id) {
        return {
          url: `/delete/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: () => [{ type: 'Maintenance' }],
    }),
  }),
})

export const {
  useAddMaintenanceMutation,
  useGetAssetMaintenanceQuery,
  useGetAllMaintenancesQuery,
  useUpdateMaintenanceMutation,
  useGetMaintenanceQuery,
  useDeleteMaintenanceMutation,
} = maintenanceApi
