import { createApi } from '@reduxjs/toolkit/query/react'
import { dashboardApi } from './Dashboard'
import { toast } from 'react-toastify'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const assetsApi = createApi({
  reducerPath: 'assetsApi',
  baseQuery: customFetchBaseQuery({ path: 'user-assets' }),
  tagTypes: ['Assets', 'Asset'],
  endpoints: (build) => ({
    getAllAssets: build.query<any, { body: any; page: any; limit: any; keys: any }>({
      query: ({ body, page, limit, keys }) => ({
        url: `list/?${body}&page=${page}&limit=${limit}&keys=${keys}`,
        method: 'GET',
      }),
      providesTags: ['Assets'],
    }),
    getSingleAsset: build.query<any, { assetId: any }>({
      query: ({ assetId }) => ({
        url: `get/${assetId}`,
        method: 'GET',
      }),
      providesTags: ['Assets'],
    }),
    addAsset: build.mutation<any, FormData>({
      query: (formData) => ({
        url: `create`,
        method: 'POST',
        body: formData,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
          })
          .catch((err) => toast.error(err.message))
      },
      invalidatesTags: () => [{ type: 'Assets' }],
    }),
    updateAsset: build.mutation<any, { assetId: any; formData: FormData }>({
      query: ({ assetId, formData }) => ({
        url: `update/${assetId}`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: (result, error, { assetId }) => [{ type: 'Assets' }],
    }),
    deleteAssets: build.mutation<any, { id: any }>({
      query({ id }) {
        return {
          url: `delete-all`,
          method: 'DELETE',
          body: id,
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
          })
          .catch((err) => toast.error(err.message))
      },
      invalidatesTags: (userId, id) => [{ type: 'Assets' }],
    }),
    generatePDF: build.mutation({
      query: (assetId) => ({
        url: 'print-asset',
        method: 'POST',
        body: { assetId },
      }),
    }),
    deleteSingleAsset: build.mutation<any, { assetId: any }>({
      query({ assetId }) {
        return {
          url: `delete-single/${assetId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: () => [{ type: 'Assets' }],
    }),
    replicateAsset: build.mutation<
      any,
      { assetId: number; copies: number; prefix: string; consecutiveNumber: string; suffix: string }
    >({
      query: ({ assetId, copies, prefix, consecutiveNumber, suffix }) => ({
        url: `replicate`,
        method: 'POST',
        body: { assetId, copies, prefix, consecutiveNumber, suffix },
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
          })
          .catch((err) => toast.error(err.message))
      },
      invalidatesTags: () => [{ type: 'Assets' }],
    }),
    sendAssetEmail: build.mutation<
      any,
      { assetId: number; email: string; notes: string; document: boolean }
    >({
      query: ({ assetId, email, notes, document }) => ({
        url: `sendEmail`,
        method: 'POST',
        body: { assetId, email, notes, document },
      }),
    }),
    deleteAssetEvent: build.mutation<any, { assetId: any; eventId: any }>({
      query({ assetId, eventId }) {
        return {
          url: `delete-event`,
          method: 'DELETE',
          body: { assetId, eventId },
        }
      },
    }),
    updateAssetEvent: build.mutation<any, { eventId: any; data: any }>({
      query({ eventId, data }) {
        return {
          url: `updateEvent`,
          method: 'PUT',
          body: { eventId, data },
        }
      },
    }),
  }),
})

export const {
  useGetAllAssetsQuery,
  useGetSingleAssetQuery,
  useAddAssetMutation,
  useUpdateAssetMutation,
  useDeleteAssetsMutation,
  useReplicateAssetMutation,
  useDeleteSingleAssetMutation,
  useSendAssetEmailMutation,
  useGeneratePDFMutation,
  useDeleteAssetEventMutation,
  useUpdateAssetEventMutation,
} = assetsApi
