import { nameRegex } from 'config'
import * as Yup from 'yup'

type ContactUs = {
  name: string
  email: string
  message: string
}

export const initialContactUs: ContactUs = {
  name: '',
  email: '',
  message: '',
}

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(3, 'Name must be at least 3 characters')
    .max(20, 'Name should not exceed 20 characters')
    .matches(nameRegex, 'Only letters allowed')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  message: Yup.string()
    .trim()
    .required('Message is required')
    .max(250, 'Message should not exceed 250 characters')
    .test('no-empty-spaces', 'Message should not be empty spaces', (value) => {
      return typeof value === 'string' && value.trim() !== ''
    }),
})
