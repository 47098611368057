import { createApi } from '@reduxjs/toolkit/query/react'
import { dashboardApi } from './Dashboard'
import { customFetchBaseQuery } from './Headers/CustomHeaders'
import { toast } from 'react-toastify'

export const assetsReportsApi = createApi({
  reducerPath: 'assetsReportsApi',
  baseQuery: customFetchBaseQuery({ path: 'reports' }),
  tagTypes: ['AssetsReports'],
  endpoints: (build) => ({
    getAllAssetsReports: build.query<any, { body: any; page: any; limit: any; keys: any }>({
      query: ({ body, page, limit, keys }) => ({
        url: `asset-reports/?${body}&page=${page}&limit=${limit}&keys=${keys}`,
        method: 'GET',
      }),
      providesTags: ['AssetsReports'],
    }),
    getSingleAsset: build.query<any, { assetId: any }>({
      query: ({ assetId }) => `get/${assetId}`,
      providesTags: ['AssetsReports'],
    }),
    addAsset: build.mutation<any, FormData>({
      query: (formData) => ({
        url: `create`,
        method: 'POST',
        body: formData,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
          })
          .catch((err) => toast.error(err?.message))
      },
      invalidatesTags: () => [{ type: 'AssetsReports' }],
    }),
    updateAsset: build.mutation<any, { assetId: any; formData: FormData }>({
      query: ({ assetId, formData }) => ({
        url: `update/${assetId}`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: () => [{ type: 'AssetsReports' }],
    }),
    deleteAssets: build.mutation<any, { id: any }>({
      query({ id }) {
        return {
          url: `delete-all`,
          method: 'DELETE',
          body: id,
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
          })
          .catch((err) => toast.error(err?.message))
      },
      invalidatesTags: (userId, id) => [{ type: 'AssetsReports' }],
    }),
  }),
})

export const {
  useGetAllAssetsReportsQuery,
  useGetSingleAssetQuery,
  useAddAssetMutation,
  useUpdateAssetMutation,
  useDeleteAssetsMutation,
} = assetsReportsApi
