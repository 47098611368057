import React, { useEffect, useRef } from 'react'
import { toCanvas } from 'qrcode'
import { baseURL } from 'config'

const QRCodeComponent: React.FC<any> = ({ asset }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const generateQRCode = async () => {
      if (canvasRef.current) {
        const url = `${baseURL}/asset/view/${asset}`
        await toCanvas(canvasRef.current, url)
      }
    }

    generateQRCode()
  }, [asset])

  return <canvas ref={canvasRef} />
}

export default QRCodeComponent
