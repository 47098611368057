import { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { useUpdateAssetEventMutation } from 'App/Modules/Services/Assets'
import { useDispatch } from 'react-redux'
import { refresh } from 'App/Store'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

interface NewAuditModalProps {
  editDisposeEventModal: boolean
  handleClose: () => void
  event: any
  assetId: any
}

const EditDisposeEventModal: React.FC<NewAuditModalProps> = ({
  editDisposeEventModal,
  handleClose,
  event,
  assetId,
}) => {
  const { saveAuth, setCurrentUser } = useAuth()
  const [disposeFormData, setDisposeFormData] = useState({
    disposedDate: event.dispose
      ? new Date(event.dispose.disposedDate).toISOString().slice(0, 10)
      : '',
    disposedTo: event.dispose?.disposedTo || '',
    notes: event.dispose?.notes || '',
  })

  const [updateAssetEvent, { isLoading }] = useUpdateAssetEventMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (event.dispose) {
      setDisposeFormData({
        disposedDate: new Date(event.dispose.disposedDate).toISOString().slice(0, 10),
        disposedTo: event.dispose.disposedTo,
        notes: event.dispose.notes,
      })
    }
  }, [event.dispose])

  const handleChange = (e) => {
    const { name, value } = e.target
    setDisposeFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const isFormValid = () => {
    return disposeFormData.disposedDate !== ''
  }

  const handleSubmit = async () => {
    if (isFormValid()) {
      const dataToUpdate = {
        eventId: event.id,
        data: {
          disposedDate: disposeFormData.disposedDate,
          disposedTo: disposeFormData.disposedTo,
          notes: disposeFormData.notes,
        },
      }
      const result: any = await updateAssetEvent(dataToUpdate)
      if (result?.data?.success) {
        dispatch(refresh(true))
        toast.success(result.data.message)
        handleClose()
        navigate(`/asset/view/${assetId}`)
      } else {
        toast.error('Failed to update dispose event')
        checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
      }
      handleClose()
    } else {
      toast.error('Disposed Date is required')
    }
  }

  return (
    <Modal show={editDisposeEventModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{event?.event}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-3' controlId='formDate'>
            <Form.Label className='required-label'>Date Disposed</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                name='disposedDate'
                value={disposeFormData.disposedDate || ''}
                onChange={handleChange}
                required
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formDisposedTo'>
            <Form.Label>Dispose to</Form.Label>
            <Form.Control
              type='text'
              name='disposedTo'
              value={disposeFormData.disposedTo || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='formNotes'>
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              maxLength={250}
              name='notes'
              value={disposeFormData.notes || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Cancel
        </button>
        <button
          className='btn btn-primary'
          onClick={handleSubmit}
          disabled={disposeFormData.disposedDate === ''}
        >
          {isLoading ? 'Updating...' : 'Update'}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditDisposeEventModal
