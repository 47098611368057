import DepartmentsListing from './Components/DepartmentsListing'

const Departments = () => {
  return (
    <>
      <DepartmentsListing />
    </>
  )
}

export default Departments
