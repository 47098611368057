const PreviewTable = ({ columnsData }) => {
  const activeColumns = columnsData?.selectedColumns
    ?.filter((col) => {
      const key = Object.keys(col)[0]
      const value = col[key]
      return value === true
    })
    .map((col) => Object.keys(col)[0])

  return (
    <div className='table-responsive'>
      <table id='preview-table' className='table table-bordered table-sm'>
        <thead className='thead-light light-yellow-table text-nowrap'>
          <tr>
            {activeColumns.map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {activeColumns.map((key) => (
              <td key={key}>Sample</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default PreviewTable
