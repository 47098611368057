import { createApi } from '@reduxjs/toolkit/query/react'
import { dashboardApi } from './Dashboard'
import { toast } from 'react-toastify'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const subUserApi = createApi({
  reducerPath: 'subUserApi',
  baseQuery: customFetchBaseQuery({ path: 'sub-user' }),
  tagTypes: ['SubUser'],
  endpoints: (build) => ({
    getSubUsers: build.query<any, any>({
      query: ({ limit = 10, page = 1 }) => ({
        url: `getAll?page=${page}&limit=${limit}`,
        method: 'GET',
      }),
      providesTags: ['SubUser'],
    }),
    getSubUser: build.query<any, any>({
      query: ({ id }) => `get/${id}`,
      providesTags: ['SubUser'],
    }),
    addSubUser: build.mutation<any, any>({
      query: (formData) => ({
        url: `create`,
        method: 'POST',
        body: formData,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
          })
          .catch((err) => toast.error(err?.message))
      },
      invalidatesTags: () => [{ type: 'SubUser' }],
    }),
    sendEmailSubUser: build.mutation<any, any>({
      query: ({ id, userType }) => ({
        url: `send-email-user`,
        method: 'POST',
        body: { id, userType },
      }),
      invalidatesTags: () => [{ type: 'SubUser' }],
    }),
    deleteSubUser: build.mutation<any, any>({
      query: (id) => ({
        url: `delete`,
        method: 'DELETE',
        body: { id },
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
          })
          .catch((err) => toast.error(err?.message))
      },
      invalidatesTags: () => [{ type: 'SubUser' }],
    }),
    updateSubUser: build.mutation<any, { id: any; formData: FormData }>({
      query: ({ id, formData }) => ({
        url: `update/${id}`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: ['SubUser'],
    }),
    deleteProfileImage: build.mutation<any, any>({
      query: () => ({
        url: `delete-image`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SubUser'],
    }),
  }),
})

export const {
  useGetSubUsersQuery,
  useGetSubUserQuery,
  useAddSubUserMutation,
  useSendEmailSubUserMutation,
  useDeleteSubUserMutation,
  useUpdateSubUserMutation,
  useDeleteProfileImageMutation,
} = subUserApi
