import { PageTitle } from 'Template/layout/core'
import MaintenancesListing from './Components/MaintenancesListing'

const Maintenances = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>List of Maintenances</PageTitle>
      <MaintenancesListing />
    </>
  )
}

export default Maintenances
