import { toAbsoluteUrlImage } from 'Template/helpers'
import { dateFormat, defaultDateFormat, getAssetStatusClass } from 'config'
import { Images } from 'Template/assets/Assets'
import { useAuth } from 'App/Modules/Auth'

interface ColumnDataProps {
  column: string
  assetsData?: any
  currencyLogo?: any
  assetMainteneces?: any
}
const date = new Date()
export const ColumnData: React.FC<ColumnDataProps> = ({ column, assetsData, currencyLogo }) => {
  const { currentUser } = useAuth()
  const renderData = () => {
    switch (column) {
      case 'Asset Photo':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <div className='d-inline align-items-center asset-img-table'>
            {asset?.Asset?.photo && asset?.Asset?.photo?.length > 0 ? (
              <img
                src={
                  asset?.Asset?.photo[0]?.uploadType === 1
                    ? toAbsoluteUrlImage(asset?.Asset?.photo[0]?.photo)
                    : asset?.Asset?.photo[0]?.stockImage?.imgUrl
                }
                alt='asset'
                className='img-fluid'
                crossOrigin='anonymous'
              />
            ) : (
              <img src={Images?.AssetsPlaceholder} alt='asset' className='img-fluid' />
            )}
          </div>
        ))
      case 'Asset Tag ID':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.assetTagId}</span>
        ))
      case 'Description':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.description}</span>
        ))
      case 'Brand':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.brand}</span>
        ))
      case 'Purchased Date':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.Asset?.purchasedDate
              ? dateFormat(
                  asset?.Asset?.purchasedDate,
                  currentUser?.dateFormat || defaultDateFormat
                )
              : ''}
          </span>
        ))
      case 'Cost':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.Asset?.cost ? currencyLogo + ' ' + asset?.Asset?.cost : ''}
          </span>
        ))
      case 'Status':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>
           <div
              className={`d-inline align-items-center px-3 py-2 rounded ${getAssetStatusClass(asset?.Asset?.status)}`}
            >
              {asset?.Asset?.status}
            </div>
          </span>
        ))
      case 'Created By':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{`${asset?.User?.firstName} ${asset?.User?.lastName}`}</span>
        ))
      case 'Date Created':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.Asset?.createdAt
              ? dateFormat(asset?.Asset?.createdAt, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Model':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.model}</span>
        ))
      case 'Purchased From':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.purchasedFrom}</span>
        ))
      case 'Serial No':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.serialNo}</span>
        ))
      case 'Reservation':
        return assetsData?.allWarranties?.map((asset: any, index: number) => {
          const currentDate = new Date()
          return (
            <>
              {asset?.Asset?.reserveassets?.map((reserveAsset, index) => {
                const endDate = new Date(reserveAsset.endDate)
                if (endDate >= currentDate) {
                  return (
                    <span key={index}>
                      Res.{' '}
                      {dateFormat(
                        reserveAsset.endDate,
                        currentUser?.dateFormat || defaultDateFormat
                      )}
                    </span>
                  )
                } else {
                  return null
                }
              })}
            </>
          )
        })
      case 'Category':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.category?.category}</span>
        ))
      case 'Department':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.department?.department}</span>
        ))
      case 'Location':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.location?.location}</span>
        ))
      case 'Site':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.site?.name}</span>
        ))
      case 'Assigned to':
        return assetsData?.allWarranties?.map((asset: any, index: number) =>
          asset?.Asset?.assigneeType === 1 ? (
            <span key={index}>
              {`${asset?.Asset?.assignedPerson?.firstName || ''} ${asset?.Asset?.assignedPerson?.lastName || ''}`}
            </span>
          ) : asset?.Asset?.assigneeType === 2 ? (
            <span key={index}>
              {`${asset?.Asset?.assignedSite?.name || ''} ${
                asset?.Asset?.assignedSite?.name && asset?.Asset?.location?.location ? '/' : ''
              } ${asset?.Asset?.location?.location || ''}`}
            </span>
          ) : null
        )
      case 'Event Date':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.Asset?.eventDate
              ? dateFormat(asset?.Asset?.eventDate, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Event Due Date':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.Asset?.eventDueDate
              ? dateFormat(asset?.Asset?.eventDueDate, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Event Notes':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.eventNotes || ''}</span>
        ))
      case 'Leased To':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{`${asset?.Asset?.assetslease?.user?.firstName || ''} ${
            asset?.Asset?.assetslease?.user?.lastName || ''
          }`}</span>
        ))
      case 'Expires':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.expiryDate
              ? dateFormat(asset?.expiryDate, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Length (months)':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.length}</span>
        ))
      case 'Notes':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.notes}</span>
        ))
      case 'Warranty Created by':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{`${asset?.User?.firstName} ${asset?.User?.lastName}`}</span>
        ))
      case 'Warranty Status':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <>
            <span className='d-block text-center'>
              {asset?.status ? (
                <i className='fa fa-check text-success fs-4'></i>
              ) : (
                <i className='fa fa-times text-danger fs-4'></i>
              )}
            </span>
            <span key={index}>{asset?.status}</span>
          </>
        ))
      case 'RAM':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.ram}</span>
        ))
      case 'Storage':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.storage}</span>
        ))
      case 'IMEI':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.imei}</span>
        ))
      case 'Ports':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.ports}</span>
        ))
      case 'Mbps':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.mbps}</span>
        ))
      case 'Stands':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.stands}</span>
        ))
      case 'Batteries':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.batteries}</span>
        ))
      case 'Bulbs':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.bulbs}</span>
        ))
      case 'Lens':
        return assetsData?.allWarranties?.map((asset: any, index: number) => (
          <span key={index}>{asset?.Asset?.lens}</span>
        ))
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}

export const SampleColumnData: React.FC<ColumnDataProps> = ({ column }) => {
  const { currentUser } = useAuth()
  const renderData = () => {
    switch (column) {
      case 'Asset Photo':
        return 'Sample Photo'
      case 'Asset Tag ID':
        return 'Sample TagId'
      case 'Description':
        return 'Sample Description'
      case 'Brand':
        return 'Sample Brand'
      case 'Purchased Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Cost':
        return 'RS 1000'
      case 'Relation':
        return 'Sample'
      case 'Status':
        return 'Sample Status'
      case 'Transact as a whole':
        return 'yes'
      case 'Created By':
        return 'Sample userName'
      case 'Date_Created':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Model':
        return 'Sample Model'
      case 'Purchased From':
        return 'Sample Purchased Location'
      case 'Serial No':
        return 'Sr-12'
      case 'Date Created':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Reservation':
        return 'Sample Reservation'
      case 'Category':
        return 'Sample Category'
      case 'Department':
        return 'Sample Department'
      case 'Location':
        return 'Sample Location'
      case 'Site':
        return 'Sample Site'
      case 'Assigned to':
        return 'Sample Assigned'
      case 'Event Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Event Due Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Event Notes':
        return 'Sample Event Notes'
      case 'Leased to':
        return 'Sample Customer Name'
      case 'Title':
        return 'Sample Title'
      case 'Expires':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Length (months)':
        return '2 months'
      case 'Notes':
        return 'Sample Description'
      case 'Warranty Created by':
        return 'Sample User Name'
      case 'Warranty Status':
        return 'Active'
      case 'RAM':
        return 'Sample RAM'
      case 'Storage':
        return 'Sample Storage'
      case 'IMEI':
        return 'Sample IMEI'
      case 'Ports':
        return 'Sample Ports'
      case 'Mbps':
        return 'Sample Mbps'
      case 'Stands':
        return 'Sample Stands'
      case 'Batteries':
        return 'Sample Batteries'
      case 'Bulbs':
        return 'Sample Bulbs'
      case 'Lens':
        return 'Sample Lens'
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}
