import Loader from 'App/Loaders/BeatLoader'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { useDeleteDocumentMutation } from 'App/Modules/Services/Documents'
import { originalName } from 'config'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'

interface DetachModalProps {
  detachModal: boolean
  handleClose: () => void
  document: any
  refetchDocument
}

const DetachModal: React.FC<DetachModalProps> = ({
  detachModal,
  handleClose,
  document,
  refetchDocument,
}) => {
  const { saveAuth, setCurrentUser } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteDocument] = useDeleteDocumentMutation()
  const handleDeleteDocument = async () => {
    try {
      setLoading(true)
      const result: any = await deleteDocument({ documentId: document?.id })
      if (result?.data?.success) {
        handleClose()
        refetchDocument()
        toast.success(result.data.message)
        setLoading(false)
      } else {
        setLoading(false)
        toast.error('Failed to delete document')
        checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
      }
    } catch (error) {
      toast.error('Failed to delete document')
    }
  }

  return (
    <Modal show={detachModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Detach Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className='mb-5'>Are you sure you want to detach this Document?</h5>
        <h5>
          <strong className='fw-bolder  fs-2 me-2'>{document?.description}</strong>
          {originalName(document?.document)}
        </h5>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={handleDeleteDocument}>
          Detach
        </button>
      </Modal.Footer>
      <Loader loading={loading} />
    </Modal>
  )
}

export default DetachModal
