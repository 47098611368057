import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import { checkIsActive, toAbsoluteUrlImage, WithChildren } from '../../../helpers'
import { Images } from 'Template/assets/Assets'
import { useAuth } from 'App/Modules/Auth'

type Props = {
  to: string
  title: string
  icon?: any
  fontIcon?: string
  hasBullet?: boolean
  photo?: string
  badge?: string
  color?: string
  notification?: any
}

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  badge,
  color,
  fontIcon,
  hasBullet,
  photo,
  notification,
}) => {
  const { pathname } = useLocation()
  const { currentUser } = useAuth()
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  useEffect(() => {
    const isChildActive =
      children &&
      React.Children.toArray(children).some((child: any) => {
        const childPath = child.props.to
        return checkIsActive(pathname, childPath) || false
      })
    const mainRouteActive = checkIsActive(pathname, to) || false
    setIsActive(mainRouteActive || isChildActive || false)
    setIsMenuOpen(mainRouteActive || isChildActive || false)
  }, [pathname, children, to])

  return (
    <div
      className={clsx('menu-item', { 'here show': isMenuOpen }, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <i className={icon}></i>
          </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        {photo && (
          <div className='aside-user-avatar'>
            <img
              src={currentUser?.photo ? toAbsoluteUrlImage(currentUser?.photo) : Images.Avatar_JPG}
              alt={title}
              className='rounded-circle w-35px h-35px me-3'
              crossOrigin='anonymous'
            />
          </div>
        )}
          <span className='d-block text-break pre-line menu-title'>{title} </span>
        {badge && (
          <span className='menu-link-badge'>
            <span className={`badge badge-circle badge-${badge}`}>{notification}</span>
          </span>
        )}
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', { 'menu-active-bg': isActive })}>
        {children}
      </div>
    </div>
  )
}

export { AsideMenuItemWithSub }
