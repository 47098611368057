import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { Images } from 'Template/assets/Assets'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './Reviews.css'

function Reviews() {
  const settings = {
    dots: true,
    arrows: false,
    loop: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <section className='reviews-section position-relative' id='review'>
      <div className='custom-container container'>
        <div className='d-xl-flex position-relative'>
          <div className='reviews-intro'>
            <div className='z-2'>
              <span className='ultra-mini-heading  mb-0'>Reviews</span>
              <h2 className='mini-heading mb-0'>Client Words</h2>
              <h3 className='ultra-bold'>About Us</h3>
            </div>
            <Link to='/' className='main-btn main-btn-white d-inline-block'>
              Check All Reviews
            </Link>
          </div>
          <div className='reviews-container position-relative'>
            <div className='text'>
              <h2 className='h-main mb-xl-5 mb-3'>What is Asset Rack? </h2>
              <p className='mb-5'>
                Trusted by businesses and individuals for seamless asset management.
              </p>
            </div>
            <Slider {...settings}>
              <div className='silder-section px-3'>
                <div className='box'>
                  <div className='card-top mb-lg-5 mb-2'>
                    <div className='image mb-xl-3 mb-lg-2 mb-1'>
                      <img
                        src={Images.Client_1}
                        alt='client'
                        width='100%'
                        height='100%'
                        loading='lazy'
                      />
                    </div>
                    <strong className='name mb-2 d-block'>John M.</strong>
                    <p className='date-time mb-0'>Written on Mar. 25, 2019</p>
                    <ul className='stars list-unstyled d-flex mb-2'>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                    </ul>
                  </div>
                  <div className='card-bottom'>
                    <p className='review-para mb-3'>
                      "Asset Rack transformed our inventory process. It's efficient and easy to use!"
                    </p>
                  </div>
                  <div className='text-end'>
                    <Link to='/' className='review-link ms-auto'>
                      <i className='las la-arrow-right fs-2 fw-bold'></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='silder-section px-3'>
                <div className='box'>
                  <div className='card-top mb-lg-5 mb-2'>
                    <div className='image mb-xl-3 mb-lg-2 mb-1'>
                      <img
                        src={Images.Client_1}
                        alt='client'
                        width='100%'
                        height='100%'
                        loading='lazy'
                      />
                    </div>
                    <strong className='name mb-2 d-block'>Linda S.</strong>
                    <p className='date-time mb-0'>Written on Mar. 25, 2019</p>
                    <ul className='stars list-unstyled d-flex mb-2'>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                    </ul>
                  </div>
                  <div className='card-bottom'>
                    <p className='review-para mb-3'>
                      "Managing assets has never been easier. The reporting feature is a game-changer!"
                    </p>
                  </div>
                  <div className='text-end'>
                    <Link to='/' className='review-link ms-auto'>
                      <i className='las la-arrow-right fs-2 fw-bold'></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='silder-section px-3'>
                <div className='box'>
                  <div className='card-top mb-lg-5 mb-2'>
                    <div className='image mb-xl-3 mb-lg-2 mb-1'>
                      <img
                        src={Images.Client_1}
                        alt='client'
                        width='100%'
                        height='100%'
                        loading='lazy'
                      />
                    </div>
                    <strong className='name mb-2 d-block'>Michael T.</strong>
                    <p className='date-time mb-0'>Written on Mar. 25, 2019</p>
                    <ul className='stars list-unstyled d-flex mb-2'>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                    </ul>
                  </div>
                  <div className='card-bottom'>
                    <p className='review-para mb-3'>
                      "Asset Rack’s mobile access lets me manage assets on the go. Highly recommend!"
                    </p>
                  </div>
                  <div className='text-end'>
                    <Link to='/' className='review-link ms-auto'>
                      <i className='las la-arrow-right fs-2 fw-bold'></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='silder-section px-3'>
                <div className='box'>
                  <div className='card-top mb-lg-5 mb-2'>
                    <div className='image mb-xl-3 mb-lg-2 mb-1'>
                      <img
                        src={Images.Client_1}
                        alt='client'
                        width='100%'
                        height='100%'
                        loading='lazy'
                      />
                    </div>
                    <strong className='name mb-2 d-block'>Sarah K.</strong>
                    <p className='date-time mb-0'>Written on Mar. 25, 2019</p>
                    <ul className='stars list-unstyled d-flex mb-2'>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                    </ul>
                  </div>
                  <div className='card-bottom'>
                    <p className='review-para mb-3'>
                      "The security and peace of mind Asset Rack provides are unmatched."
                    </p>
                  </div>
                  <div className='text-end'>
                    <Link to='/' className='review-link ms-auto'>
                      <i className='las la-arrow-right fs-2 fw-bold'></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='silder-section px-3'>
                <div className='box'>
                  <div className='card-top mb-lg-5 mb-2'>
                    <div className='image mb-xl-3 mb-lg-2 mb-1'>
                      <img
                        src={Images.Client_1}
                        alt='client'
                        width='100%'
                        height='100%'
                        loading='lazy'
                      />
                    </div>
                    <strong className='name mb-2 d-block'>David L.</strong>
                    <p className='date-time mb-0'>Written on Mar. 25, 2019</p>
                    <ul className='stars list-unstyled d-flex mb-2'>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                      <li>
                        <i className='las la-star'></i>
                      </li>
                    </ul>
                  </div>
                  <div className='card-bottom'>
                    <p className='review-para mb-3'>
                      "Our team loves the reserve and checkout feature. It streamlines our workflow!"
                    </p>
                  </div>
                  <div className='text-end'>
                    <Link to='/' className='review-link ms-auto'>
                      <i className='las la-arrow-right fs-2 fw-bold'></i>
                    </Link>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Reviews
