export interface CheckoutInterface {
  assetId: number[] | undefined
  checkoutDate: string | null
  assigneeType: number | null
  personId: number | null
  dueDate: string | null
  notes: string | ''
  sendEmail: boolean
  siteId?: number | ''
  departmentId: number | null
  locationId: number | null
  overrideReservation: boolean
}

export const defaultCheckoutDetail: CheckoutInterface = {
  assetId: [],
  checkoutDate: new Date().toISOString().split('T')[0],
  assigneeType: 1,
  personId: null,
  dueDate: new Date().toISOString().split('T')[0],
  notes: '',
  sendEmail: false,
  siteId: '',
  departmentId: null,
  locationId: null,
  overrideReservation: false,
}
