import { useEffect, useState } from 'react'
import { IProfileDetails, profileDetailsInitValues as initialValues } from '../SettingsModel'
import { useFormik } from 'formik'
import { useAuth } from '../../../../Auth'
import { toast } from 'react-toastify'
import { useUpdateProfileMutation } from 'App/Modules/Services/Profile'
import { checkUserUnauthorized } from '../../../../Auth'
import * as Yup from 'yup'
import Profile from './Profile'
import { nameRegex, phoneRegex } from 'config'

const profileDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'First name must be at least 3 characters')
    .max(20, 'First name should not exceed 20 characters')
    .matches(nameRegex, 'Only letters, numbers and no extra spaces allowed')
    .required('First name is required')
    .nullable(),
  lastName: Yup.string()
    .min(3, 'Last name must be at least 3 characters')
    .max(20, 'Last name should not exceed 20 characters')
    .matches(nameRegex, 'Only letters, numbers and no extra spaces allowed')
    .required('Last name is required')
    .nullable(),
  title: Yup.string()
    .min(5, 'Title must be at least 5 characters')
    .max(20, 'Title should not exceed 20 characters')
    .nullable(),
  phone: Yup.string()
    .min(10, 'Number should be 10 or more digits')
    .max(15, 'Number should be less then or equal to 15 digits')
    .matches(phoneRegex, 'Only digits and plus sign on start are allowed')
    .nullable(),
})

const CustomerProfileDetail = ({ userData }) => {
  const { currentUser, setCurrentUser, saveAuth } = useAuth()
  const [loading, setLoading] = useState(false)
  const [userUpdate, setUserUpdate] = useState(false)
  const [image, setImage] = useState<string | null>(null)
  const [updateProfile] = useUpdateProfileMutation()

  const setUserData = (userData) => {
    if (currentUser && userData) {
      setCurrentUser(userData.user)
      setImage(userData.user?.photo ?? userData.user?.photo)
      formik.setValues({
        image: userData.user?.photo,
        firstName: userData.user?.firstName,
        lastName: userData.user?.lastName,
        title: userData.user?.title || '',
        phone: userData.user?.phone,
        email: userData.user?.email,
      })
    }
  }

  useEffect(() => {
    setUserData(userData)
  }, [userData])

  useEffect(() => {
    if (
      currentUser &&
      userData &&
      userData.user?.timeZone &&
      userData.user?.dateFormat &&
      userData.user?.timeFormat
    ) {
      setUserData(userData)
    }
  }, [userUpdate, userData])

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        delete values['email']
        if (!values.image) {
          delete values['image']
        }
        const formData = new FormData()
        for (const key of Object.keys(values)) {
          if (Object.prototype.hasOwnProperty.call(values, key)) {
            if (values) {
              const value = values[key as keyof IProfileDetails]
              formData.append(key, value)
            }
          }
        }
        const { data }: any = await updateProfile(formData)
        setUserUpdate(true)
        toast.dismiss()
        toast.success(data?.message)
      } catch (error: any) {
        setUserUpdate(false)
        toast.dismiss()
        toast.error(error?.data?.message)
        checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div>
            <Profile formik={formik} image={image} />
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='submit'
              className='btn btn-primary main-btn-style'
              disabled={loading || !formik.isValid}
            >
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress d-block'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export { CustomerProfileDetail }
