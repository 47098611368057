import { useState } from 'react'
import { useDeleteProfileImageMutation } from 'App/Modules/Services/Profile'
import { toAbsoluteUrlImage } from 'Template/helpers'
import { Images } from 'Template/assets/Assets'
import { imageMimeType } from 'config'
import { MoonLoader } from 'react-spinners'
import { toast } from 'react-toastify'

const Profile = ({ formik, image }) => {
  const [deleteProfileImage] = useDeleteProfileImageMutation()
  const [file, setFile] = useState(null)
  const [loadingAvatar, setLoadingAvatar] = useState(false)
  const [fileDataURL, setFileDataURL] = useState<string | ArrayBuffer | null | undefined>(null)

  const changeHandler = (e) => {
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      return toast.error('This file format is not allowed!')
    } else {
      setFile(file)
      const reader = new FileReader()
      reader.onload = (event) => {
        setFileDataURL(event?.target?.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const deleteImage = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e?.preventDefault()
    setLoadingAvatar(true)
    try {
      const data = await deleteProfileImage('').unwrap()
      if (data?.success) {
        setFileDataURL(null)
        toast.success(data?.message)
      }
    } catch (error: any) {
      if (!error?.data?.success) {
        toast.error(error?.data?.message)
      }
    } finally {
      setLoadingAvatar(false)
    }
  }
  return (
    <div className='card-body border-top p-lg-9 p-md-7 p-6'>
      <h3 className='card-title mb-4'>
        <span className='me-2 align-middle'>
          <i className='las la-user fs-1' aria-hidden='true'></i>
        </span>
        <span className='card-label fw-bold fs-3 mb-1'>User</span>
      </h3>
      <div className='ms-md-9'>
        <div className='row mb-6'>
          <label className='col-lg-3 col-form-label fw-bold fs-6'>Avatar</label>
          <div className='col-lg-9 fv-row'>
            {loadingAvatar ? (
              <MoonLoader color='#0d145c' loading={loadingAvatar} />
            ) : (
              <div className='image-input image-input-outline' data-kt-image-input='true'>
                <label htmlFor='photo' className='cursor-pointer position-relative'>
                  <img
                    className='image-input-wrapper w-125px h-125px'
                    src={
                      fileDataURL
                        ? fileDataURL.toString()
                        : image
                          ? toAbsoluteUrlImage(image)
                          : Images.Avatar_JPG
                    }
                    alt='profile'
                    crossOrigin='anonymous'
                  />
                  <span className='edit-icon'>
                    <i className='las la-camera fs-2'></i>
                  </span>
                  {image ? (
                    <span onClick={(e) => deleteImage(e)} className='delete-icon'>
                      <i className='las la-trash-alt fs-2'></i>
                    </span>
                  ) : null}
                </label>
                <input
                  className='form-control d-none'
                  type='file'
                  name='image'
                  id='photo'
                  accept='.png, .jpg, .jpeg'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    changeHandler(e)
                    formik.setFieldValue(
                      'image',
                      e?.target?.files && e?.target?.files[0]?.type?.match(imageMimeType)
                        ? e.target.files![0]
                        : image
                    )
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-3 col-form-label required fw-bold fs-6'>Full Name</label>
          <div className='col-lg-9 fv-row'>
            <div className='row'>
              <div className='col-sm-6 fv-row'>
                <input
                  type='text'
                  className='form-control  form-control-solid mb-3 mb-lg-0'
                  placeholder='First name'
                  {...formik.getFieldProps('firstName')}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.firstName}</div>
                  </div>
                )}
              </div>

              <div className='col-sm-6 fv-row'>
                <input
                  type='text'
                  className='form-control  form-control-solid'
                  placeholder='Last name'
                  {...formik.getFieldProps('lastName')}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.lastName}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-3 col-form-label fw-bold fs-6'>Title</label>
          <div className='col-lg-9 fv-row'>
            <input
              type='text'
              className='form-control  form-control-solid'
              placeholder='Title'
              {...formik.getFieldProps('title')}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.title}</div>
              </div>
            )}
          </div>
        </div>
        {formik?.values?.phone ? (
          <div className='row mb-6'>
            <label className='col-lg-3 col-form-label fw-bold fs-6'>
              <span className='required'>Phone</span>
            </label>
            <div className='col-lg-9 fv-row'>
              <input
                type='tel'
                className='form-control  form-control-solid'
                placeholder='Phone number'
                {...formik.getFieldProps('phone')}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              )}
            </div>
          </div>
        ) : null}
        <div className='row mb-6'>
          <label className='col-lg-3 col-form-label fw-bold fs-6'>
            <span className='required'>Email</span>
          </label>
          <div className='col-lg-9 fv-row'>
            <input
              type='tel'
              className='form-control  form-control-solid'
              placeholder='Email Address'
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.email}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
