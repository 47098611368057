import Modal from 'react-bootstrap/Modal'

function UserAccountModal(props) {
  return (
    <Modal className='custom-modal-sm' {...props} backdrop='static' keyboard={false}>
      <Modal.Header closeButton className='align-items-baseline'>
        <Modal.Title>
          <h4 className='modal-title'>
            Read this if you need to be added as a user to an existing account.
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          If you wish to be added as a user to an existing account, please do not proceed to
          register.
        </p>
        <p>
          Registering will create a new account with a separate database and the Administrator of
          the prior existing account will be unable to add you as a user using the same registered
          email address.
        </p>
        <p>
          Instead, please allow the Administrator of the existing account to add you as a user,
          after which you will receive a verification email to setup your password to access the
          existing database.
        </p>
      </Modal.Body>
    </Modal>
  )
}

export default UserAccountModal
