import { useAuth } from 'App/Modules/Auth'
import { useNavigate } from 'react-router-dom'
interface HeaderListingProps {
  assetsData: any
  fileName: any
  userAssets: any
  deleteDataArr: number[]
  setDeleteModal: (value: boolean) => void
  setCheck: (ids: number[]) => void
  setShowSearch: (value: boolean) => void
  setShowSetupColumns: (value: boolean) => void
  handleExport: () => void
  setResetAssetsSearchStates: (value: boolean) => void
}

const AssetsHeader = (props: HeaderListingProps) => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const permission = currentUser?.SecurityGroup
  const personUser = currentUser?.userType === 3
  const customerUser = currentUser?.userType === 4
  const ownerUser = currentUser?.userType === 1
  const staffUser = currentUser?.userType === 2

  return (
    <div
      className='d-flex justify-content-between mb-3 flex-wrap'
      data-kt-user-table-toolbar='base'
    >
      <div className='d-flex align-items-center mb-xl-0 mb-4'>
        <h3 className='card-title me-4 mb-0'>
          <span className='me-2 align-middle'>
            <i className='la la-list fs-1' aria-hidden='true'></i>
          </span>
          <span className='card-label fw-bold fs-3 mb-1'>List of Assets</span>
        </h3>
      </div>
      <div className='d-flex flex-wrap'>
        {!permission?.viewAsset && staffUser ? (
          ''
        ) : (
          <button
            type='button'
            className='btn btn-light-primary main-btn-style m-1'
            onClick={() => {
              props.setShowSearch(true)
              props.setResetAssetsSearchStates(true)
            }}
          >
            <i className='fa fa-search fs-xl-5 fs-6' /> Search
            <span className='d-xl-inline d-none'> Criteria</span>
          </button>
        )}
        {!permission?.exportAsset && staffUser ? (
          ''
        ) : (
          <button
            className='btn btn-light-primary main-btn-style m-1'
            onClick={() => {
              props.handleExport()
            }}
          >
            <i className='far fa-file-excel fs-5'></i> Export
            <span className='d-xl-inline d-none'> to Excel</span>
          </button>
        )}
        {(ownerUser || (staffUser && permission?.addAsset)) && !(personUser || customerUser) && (
          <button
            className='btn btn-light-primary main-btn-style m-1'
            onClick={() => {
              navigate('/asset/add')
            }}
          >
            <i className='far fa-plus fs-1 align-bottom'></i> Add
            <span className='d-xl-inline d-none'> an Asset</span>
          </button>
        )}
        {props.deleteDataArr.length > 0 && (
          <button
            type='button'
            className='btn btn-danger main-btn-style m-1'
            onClick={() => {
              props.setDeleteModal(true)
              props.setCheck(props.deleteDataArr)
            }}
          >
            Delete Selected ({props.deleteDataArr.length})
          </button>
        )}
      </div>
    </div>
  )
}

export { AssetsHeader }
