import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import { useFormik } from 'formik'
import { initialContactUs, validationSchema } from './Core/Model'
import { multiSpace } from 'config'
import { conatactUs } from 'App/Modules/Auth/Core/Requests'
import { toast } from 'react-toastify'
import './ContactUs.css'

function ContactUs() {
  const [loading, setLoading] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: initialContactUs,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        const result: any = await conatactUs(
          values?.name?.trim().replace(multiSpace, ' '),
          values?.email?.trim(),
          values?.message?.trim().replace(multiSpace, ' ')
        )
        if (result?.data?.success) {
          toast.success(result?.data?.message)
          formik.resetForm()
          setLoading(false)
        } else {
          toast.error(result?.response?.data?.message)
          setLoading(false)
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message)
        setLoading(false)
      }
    },
  })

  return (
    <div className='contact-us' id='contact'>
      <h2 className='mb-lg-10 mb-5'>Contact Us</h2>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group className='input-wrap position-relative mb-lg-4 mb-3' controlId='formBasicName'>
          <Form.Control
            type='text'
            placeholder='Your name'
            name='name'
            value={formik?.values?.name}
            onChange={formik.handleChange}
            isInvalid={!!formik?.errors?.name && formik?.touched?.name}
          />
          <span className='icon'>
            <i className='las la-user'></i>
          </span>
        </Form.Group>
        <Form.Control.Feedback type='invalid'>{formik?.errors?.name}</Form.Control.Feedback>
        <Form.Group
          className='input-wrap position-relative mb-lg-4 mb-3'
          controlId='formBasicEmail'
        >
          <Form.Control
            type='email'
            placeholder='Your email'
            name='email'
            value={formik?.values?.email}
            onChange={formik.handleChange}
            isInvalid={!!formik?.errors?.email && formik?.touched?.email}
          />
          <span className='icon'>
            <i className='las la-envelope'></i>
          </span>
        </Form.Group>
        <Form.Control.Feedback type='invalid'>{formik?.errors?.email}</Form.Control.Feedback>
        <FloatingLabel
          controlId='floatingTextarea2'
          label='Your message'
          className='input-wrap mb-lg-5 mb-4'
        >
          <Form.Control
            as='textarea'
            placeholder='Your message'
            name='message'
            value={formik?.values?.message}
            onChange={formik.handleChange}
            isInvalid={!!formik?.errors?.message && formik?.touched?.message}
          />
        </FloatingLabel>
        <Form.Control.Feedback type='invalid'>{formik?.errors?.message}</Form.Control.Feedback>
        <div className='text-end mb-lg-5 mb-4'>
          <button
            className='btn main-btn main-btn-yellow'
            type='submit'
            disabled={!formik.isValid || loading}
          >
            {loading ? 'Please wait ...' : 'Submit'}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default ContactUs
