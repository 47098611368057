import React, { useState, useEffect } from 'react'
import '../../CSS/AssetsStyle.css'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This CSS file is required and should be included
import { useDeleteAssetPhotoMutation, useGetAllPhotosQuery, usePinnedPhotoMutation,} from 'App/Modules/Services/AssetPhotos'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import Loader from 'App/Loaders/BeatLoader'
import { toAbsoluteUrlImage } from 'Template/helpers'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Photos: React.FC = () => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const permission = currentUser?.SecurityGroup
  const ownerUser = currentUser?.userType === 1
  const staffUser = currentUser?.userType === 2
  const { id } = useParams()
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [minSrc, setMinSrc] = useState<string>('')
  const [nextSrc, setNextSrc] = useState<string>('')
  const [prevSrc, setPrevSrc] = useState<string>('')
  const [photos, setPhotos] = useState<any>([])
  const [loading, setLoading] = useState<any>(true)

  const [pinnedPhoto, { isLoading: isPinned }] = usePinnedPhotoMutation()
  const [deleteAssetPhoto, { isLoading: isDeleting }] = useDeleteAssetPhotoMutation()

  const { data, isLoading, refetch, isRefeching, error, isSuccess, isError }: any =
    useGetAllPhotosQuery(id)

  useEffect(() => {
    refetch()
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  useEffect(() => {
    const minSrc =
      photos[photoIndex]?.uploadType === 1
        ? toAbsoluteUrlImage(photos[photoIndex]?.photo)
        : photos[photoIndex]?.stockImage?.imgUrl
    const nextSrc =
      photos[photoIndex + 1]?.uploadType === 1
        ? toAbsoluteUrlImage(photos[(photoIndex + 1) % photos.length]?.photo)
        : photos[(photoIndex + 1) % photos.length]?.stockImage?.imgUrl
    const prevSrc =
      photos[photoIndex - 1]?.uploadType === 1
        ? toAbsoluteUrlImage(photos[(photoIndex + photos.length - 1) % photos.length]?.photo)
        : photos[(photoIndex + photos.length - 1) % photos.length]?.stockImage?.imgUrl
    setMinSrc(minSrc)
    setNextSrc(nextSrc)
    setPrevSrc(prevSrc)
  }, [photoIndex])

  useEffect(() => {
    if (isSuccess && data) {
      setPhotos(data?.photos)
    } else if (isError && error) {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error, saveAuth, setCurrentUser, toast)
    }
  }, [data, refetch, error, isSuccess, isError])

  useEffect(() => {
    let timerId
    if (isPlaying) {
      timerId = setInterval(() => {
        setPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length)
      }, 3000)
    }

    return () => clearInterval(timerId)
  }, [isPlaying])

  const openLightbox = (index: number) => {
    setPhotoIndex(index)
    setIsOpen(true)
  }

  const togglePlay = () => {
    setIsPlaying(!isPlaying)
  }

  const pinHandler = async (id: number) => {
    try {
      const res: any = await pinnedPhoto({ id })
      if (res?.data?.success) {
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.error?.data?.message)
        checkUserUnauthorized(res?.error, saveAuth, setCurrentUser, toast)
      }
    } catch (error: any) {
      toast.error(error?.data?.message)
    }
  }

  const handleDelete = async (id) => {
    try {
      const res: any = await deleteAssetPhoto({ id })
      if (res?.data?.success) {
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.error?.data?.message)
        checkUserUnauthorized(res?.error, saveAuth, setCurrentUser, toast)
      }
    } catch (error: any) {
      toast.error(error?.data?.message)
    }
  }

  return (
    <>
      <Loader loading={isLoading || loading || isRefeching || isPinned || isDeleting} />
      <section className='add-photos-section'>
        <Row>
          {photos.length > 0 ? (
            photos.map((photo, index) => (
              <Col mg="3" sm="4" >
                <div className='photo-card' key={index}>
                  <div className='photo-wrapper'>
                    <img
                      src={
                        photo?.uploadType === 1
                          ? toAbsoluteUrlImage(photo?.photo)
                          : photo?.stockImage?.imgUrl
                      }
                      crossOrigin='anonymous'
                      className='photo-image'
                      alt='photo'
                    />
                    {photo?.pinned && (
                      <div className='photo-badge ribbon'>
                        <i className='fa fa-check-circle text-white fs-5' aria-hidden='true'></i>
                      </div>
                    )}
                    <div className='photo-overlay'>
                      <span>
                        {photo?.uploadType === 1
                          ? 'Your Uploads'
                          : `${photo?.stockImage?.category?.name}`}
                      </span>
                      <div className='photo-overlay-icons'>
                        <i
                          className='fa fa-search'
                          aria-hidden='true'
                          onClick={() => openLightbox(index)}
                        ></i>
                        {(ownerUser || (staffUser && permission?.deleteAsset)) && (
                          <i
                            className='fa fa-trash'
                            aria-hidden='true'
                            onClick={() => {
                              handleDelete(photo?.id)
                            }}
                          ></i>
                        )}
                        {(ownerUser || (staffUser && permission?.updateAsset)) && (
                          <i
                            className='fa-solid fa-thumbtack'
                            aria-hidden='true'
                            onClick={() => pinHandler(photo?.id)}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
              no photos found
            </div>
          )}
        </Row>
        {isOpen && (
          <Lightbox
            mainSrc={minSrc}
            nextSrc={nextSrc}
            prevSrc={prevSrc}
            onCloseRequest={() => {
              setIsOpen(false)
              setIsPlaying(false)
            }}
            imageCrossOrigin='anonymous'
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
            }
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photos.length)}
            toolbarButtons={[
              <button onClick={togglePlay} className='play-pause-button'>
                <i className={`fa ${isPlaying ? 'fa-pause' : 'fa-play'}`} aria-hidden='true'></i>
              </button>,
            ]}
          />
        )}
      </section>
    </>
  )
}

export default Photos
