import React, { useEffect, useState } from 'react'
import { Modal, Form, InputGroup } from 'react-bootstrap'
import { useGetAllSitesQuery } from 'App/Modules/Services/Sites'
import { useGetAllLocationsBySiteQuery } from 'App/Modules/Services/Locations'
import { useUpdateAssetEventMutation } from 'App/Modules/Services/Assets'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { refresh } from 'App/Store'

interface NewAuditModalProps {
  editCheckInEventModal: boolean
  handleClose: () => void
  event: any
  assetId: any
}

const EditCheckInEventModal: React.FC<NewAuditModalProps> = ({
  editCheckInEventModal,
  handleClose,
  event,
  assetId,
}) => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [siteId, setSiteId] = useState<any>(null)
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [selectedLocation, setSelectedLocation] = useState<any>(null)
  const [checkoutTo, setCheckoutTo] = useState(event.checkIn.checkinType === 1 ? 'person' : 'site')
  const [formData, setFormData] = useState<any>({
    returnDate: event.checkIn ? new Date(event.checkIn.returnDate).toISOString().slice(0, 10) : '',
    checkinType: event.checkIn?.checkinType || null,
    notes: event.checkIn ? event.checkIn.notes : '',
    siteId: event.checkIn?.Site?.id || null,
    locationId: event.checkIn?.Location?.id || null,
    personId: event.checkIn?.Person?.id || null,
  })

  const [updateAssetEvent, { isLoading }] = useUpdateAssetEventMutation()
  const { data: userSitesList } = useGetAllSitesQuery({ userId: currentUser?.id })
  const { data: userLocationList } = useGetAllLocationsBySiteQuery(
    { userId: currentUser?.id, id: siteId },
    { skip: !siteId }
  )
  const [locationOptions, setLocationOptions] = useState<any>([])

  useEffect(() => {
    if (event.checkIn?.Site) {
      setSelectedOption({
        label: event.checkIn.Site.name,
        value: event.checkIn.Site.id,
      })
      setSiteId(event.checkIn.Site.id)
    }
  }, [event.checkIn])

  useEffect(() => {
    if (event.checkIn?.Location) {
      setSelectedLocation({
        label: event.checkIn.Location.location,
        value: event.checkIn.Location.id,
      })
    }
  }, [event.checkIn])

  useEffect(() => {
    if (userLocationList?.locations) {
      const options = userLocationList.locations.map((location) => ({
        label: location.location,
        value: location.id,
      }))
      setLocationOptions(options)
    }
  }, [userLocationList])

  const handleRadioChange = (e) => {
    const value = e.target.value
    setCheckoutTo(value)
    setFormData((prev) => ({
      ...prev,
      checkinType: value === 'person' ? 1 : 2,
      siteId: null,
      locationId: null,
      personId: value === 'person' ? event.checkIn?.Person?.id : null,
    }))
    setSelectedOption(null)
    setSelectedLocation(null)
    setSiteId(null)
  }

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value
    setSelectedOption({
      label: e.target.options[e.target.selectedIndex].text,
      value: selectedValue,
    })

    if (checkoutTo === 'site') {
      setSiteId(selectedValue)
      if (!userLocationList || userLocationList.locations.length === 0) {
        setSelectedLocation(null)
        setFormData((prev) => ({
          ...prev,
          siteId: selectedValue,
          locationId: null,
        }))
      } else {
        setFormData((prev) => ({
          ...prev,
          siteId: selectedValue,
          locationId: prev.locationId,
        }))
      }
    }
  }

  const handleLocationChange = (e) => {
    const selectedValue = e.target.value
    setSelectedLocation({
      label: e.target.options[e.target.selectedIndex].text,
      value: selectedValue,
    })
    setFormData((prev) => ({
      ...prev,
      locationId: selectedValue,
    }))
  }

  const isFormValid = () => {
    return formData.returnDate !== ''
  }

  const handleSubmit = async () => {
    if (isFormValid()) {
      const dataToUpdate = {
        eventId: event.id,
        data: {
          returnDate: formData.returnDate,
          checkinType: formData.checkinType,
          notes: formData.notes,
          personId: formData.personId || null,
          siteId: formData.siteId || null,
          locationId: formData.siteId ? formData.locationId || null : null,
        },
      }
      const result: any = await updateAssetEvent(dataToUpdate)
      if (result?.data?.success) {
        dispatch(refresh(true))
        toast.success(result.data.message)
        handleClose()
        navigate(`/asset/view/${assetId}`)
      } else {
        toast.error(result?.error?.data?.message)
        checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
      }
      handleClose()
    } else {
      toast.error('Return Date is required')
    }
  }

  return (
    <Modal show={editCheckInEventModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{event?.event}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-3' controlId='formcheckInTo'>
            <Form.Label>Check-in from</Form.Label>
            <div className='d-flex align-items-center '>
              <Form.Check
                type='radio'
                label='Person'
                name='checkInTo'
                id='checkInToPerson'
                value='person'
                onChange={handleRadioChange}
                checked={checkoutTo === 'person'}
              />
              <Form.Check
                type='radio'
                label='Site'
                name='checkInTo'
                className='ms-4'
                id='checkInToSite'
                value='site'
                onChange={handleRadioChange}
                checked={checkoutTo === 'site'}
              />
            </div>
          </Form.Group>
          {checkoutTo === 'site' && (
            <>
              <Form.Group className='mb-3' controlId='formSite'>
                <Form.Label className='required-label'>Site</Form.Label>
                <Form.Control
                  as='select'
                  value={selectedOption?.value || ''}
                  onChange={handleSelectChange}
                >
                  <option value=''>Select a Site</option>
                  {userSitesList?.sites.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formLocation'>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  as='select'
                  value={selectedLocation?.value || ''}
                  onChange={handleLocationChange}
                >
                  <option value=''>Select a location</option>
                  {locationOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </>
          )}
          <Form.Group className='mb-3' controlId='formDate'>
            <Form.Label className='required-label'>Return Date</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                value={formData.returnDate}
                onChange={(e) => setFormData((prev) => ({ ...prev, returnDate: e.target.value }))}
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formTextArea'>
            <Form.Label>Check-in Notes</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              value={formData.notes}
              onChange={(e) => setFormData((prev) => ({ ...prev, notes: e.target.value }))}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Cancel
        </button>
        <button
          className='btn btn-primary'
          onClick={handleSubmit}
          disabled={formData.returnDate === ''}
        >
          {isLoading ? 'Updating...' : 'Update'}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditCheckInEventModal
