import { PageTitle } from 'Template/layout/core'
import WarrantiesListing from './Components/WarrantiesListing'

const Warranties = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>List of Warranties</PageTitle>
      <WarrantiesListing />
    </>
  )
}

export default Warranties
