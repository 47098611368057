export interface LeaseReturnInterface {
  notes: string | null
  returnDate: string | Date
  sendEmail: boolean
  customerId: number | null
  assetId: number[]
}

export const defaultLeaseReturnDetail: LeaseReturnInterface = {
  notes: null,
  returnDate: new Date().toISOString().split('T')[0],
  sendEmail: false,
  customerId: null,
  assetId: [],
}
