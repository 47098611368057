import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: customFetchBaseQuery({ path: '' }),
  tagTypes: ['Events'],
  endpoints: (build) => ({
    getAllEvents: build.query<void, void>({
      query: () => ({
        url: `/events`,
        method: 'GET',
      }),
      providesTags: ['Events'],
    }),
    getCustomEvents: build.query<void, void>({
      query: () => ({
        url: `/customevents`,
        method: 'GET',
      }),
      providesTags: ['Events'],
    }),
    getCustomEvent: build.query<any, any>({
      query: ({ id }) => ({
        url: `/customevents/${id}`,
        method: 'GET',
      }),
      providesTags: ['Events'],
    }),
    addEvent: build.mutation<any, any>({
      query: ({ data }) => ({
        url: `/events`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Events' }],
    }),
    addCustomEvent: build.mutation<any, any>({
      query: ({ data }) => ({
        url: `/customevents`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Events' }],
    }),
    updateEvent: build.mutation<any, { eventId: any; values: any }>({
      query: ({ eventId, values }) => ({
        url: `events/${eventId}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (result, error, { eventId }) => [{ type: 'Events', eventId }],
    }),
    updateCustomEvent: build.mutation<any, any>({
      query: ({ id, values }) => ({
        url: `customevents/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: () => [{ type: 'Events' }],
    }),
    deleteEvents: build.mutation<any, any>({
      query({ id }) {
        return {
          url: `customevents/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, userId) => [{ type: 'Events', userId }],
    }),
  }),
})

export const {
  useGetAllEventsQuery,
  useGetCustomEventsQuery,
  useGetCustomEventQuery,
  useAddEventMutation,
  useAddCustomEventMutation,
  useUpdateEventMutation,
  useUpdateCustomEventMutation,
  useDeleteEventsMutation,
} = eventsApi
