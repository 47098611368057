import { useDeleteMaintenanceMutation } from 'App/Modules/Services/Maintenance'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'

interface DeleteModalProps {
  maintenanceAssetDeleteModal: boolean
  handleClose: () => void
  dataId: any
}

const MaintenanceAssetDeleteModal: React.FC<DeleteModalProps> = ({
  maintenanceAssetDeleteModal,
  handleClose,
  dataId,
}) => {
  const [deleteMaintenance, { isLoading: isDeleted }] = useDeleteMaintenanceMutation()
  const handleDelete = async () => {
    try {
      const res: any = await deleteMaintenance(dataId)
      if (res?.data?.success) {
        toast.dismiss()
        toast.success(res?.data?.message)
        handleClose()
      } else {
        toast.dismiss()
        toast.error(res?.data?.message)
      }
    } catch (error: any) {
      toast.error(error?.data?.message)
    }
  }

  return (
    <Modal show={maintenanceAssetDeleteModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Delete Maintenance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className='mb-5'>Are you sure you want to delete this maintenance?</h5>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Cancel
        </button>
        {!isDeleted ? (
          <span className='btn btn-danger' onClick={handleDelete}>
            Delete
          </span>
        ) : (
          <span className='indicator-progress d-block'>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default MaintenanceAssetDeleteModal
