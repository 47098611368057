import { createApi } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { toast } from 'react-toastify'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const locationsApi = createApi({
  reducerPath: 'locationsApi',
  baseQuery: customFetchBaseQuery({ path: 'location' }),
  tagTypes: ['Locations'],
  endpoints: (build) => ({
    getAllLocations: build.query<any, any>({
      query: () => `list`,
      providesTags: ['Locations'],
    }),
    getAllLocationsBySite: build.query<any, any>({
      query: ({ id, page = 0, limit = 0 }) => `search/${id}?page=${page}&limit=${limit}`,
      providesTags: ['Locations'],
    }),
    addLocation: build.mutation<any, any>({
      query: ({ data }) => ({
        url: `create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Locations' }],
    }),
    updateLocation: build.mutation<any, { id: any; values: any }>({
      query: ({ id, values }) => ({
        url: `update/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: () => [{ type: 'Locations' }],
    }),
    deleteLocation: build.mutation<any, { cascade: number; id: any }>({
      query({ cascade, id }) {
        return {
          url: `delete?cascade=${cascade}`,
          method: 'DELETE',
          body: id,
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(assetsApi.util.invalidateTags(['Assets']))
          })
          .catch((err) => toast.error(err.message))
      },
      invalidatesTags: () => [{ type: 'Locations' }],
    }),
  }),
})

export const {
  useGetAllLocationsQuery,
  useGetAllLocationsBySiteQuery,
  useAddLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
} = locationsApi
