import { useEffect, useState } from 'react'
import ManageGroup from './ManageGroup'
import { useGetSubUserQuery, useUpdateSubUserMutation } from 'App/Modules/Services/SubUser'
import { useNavigate, useParams } from 'react-router'
import { EditUserDetails } from './EditUserDetails'
import SplashScreen from 'App/Loaders/SplashScreen'
import { useFormik } from 'formik'
import { IProfileDetails, profileDetailsInitValues as initialValues } from './SettingsModel'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { nameRegex, titleRegex } from 'config'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

const profileDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(3, 'First name must be at least 3 characters')
    .max(20, 'First name should not exceed 20 characters')
    .matches(nameRegex, 'Only letters are allowed')
    .required('First name is required')
    .nullable(),
  lastName: Yup.string()
    .trim()
    .min(3, 'Last name must be at least 3 characters')
    .max(20, 'Last name should not exceed 20 characters')
    .matches(nameRegex, 'Only letters are allowed')
    .required('Last name is required')
    .nullable(),
  title: Yup.string()
    .trim()
    .min(5, 'Title must be at least 5 characters')
    .max(20, 'Title should not exceed 20 characters')
    .matches(titleRegex, 'Only alphabets and special characters are allowed')
    .nullable(),
  phone: Yup.string()
    .matches(
      /^(\+)?[0-9]*$/,
      'Phone number must contain only numeric characters without spaces and may start with a plus sign'
    )
    .test('is-valid-number', 'Phone number must be between 10 and 15 digits', (value) => {
      if (value && value.trim().length > 0) {
        const stringValue = String(value).replace(/\D/g, '')
        return stringValue.length >= 10 && stringValue.length <= 15
      }
      return true
    })
    .nullable(),
  email: Yup.string()
    .email('Invalid email address')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  timezone: Yup.string().required('Time zone is required').nullable(),
  dateFormat: Yup.string().required('Date Format is required').nullable(),
  timeFormat: Yup.string().required('Time Format is required').nullable(),
})

const EditUser = () => {
  const { id } = useParams()
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const permission = currentUser?.SecurityGroup
  const staffUser = currentUser?.userType === 2
  const {
    data: userData,
    isLoading: isLoadingGetSubUser,
    error,
  }: any = useGetSubUserQuery({ id: id })
  const [selectedGroupName, setSelectedGroupName] = useState('')
  const [loading, setLoading] = useState(false)
  const [updateSubUser] = useUpdateSubUserMutation()
  const navigate = useNavigate()
  const handleGroupSelect = (groupName) => {
    setSelectedGroupName(groupName)
  }

  useEffect(() => {
    if (error) checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
  }, [error])

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        if (!values.image) {
          delete values['image']
        }
        const formData = new FormData()
        for (const key of Object.keys(values)) {
          if (Object.prototype.hasOwnProperty.call(values, key)) {
            if (values) {
              const value = values[key as keyof IProfileDetails]
              formData.append(key, value)
            }
          }
        }
        const { data, error }: any = await updateSubUser({ formData, id })
        if (data?.success) {
          toast.dismiss()
          navigate('/users')
          toast.success(data?.message)
        } else {
          toast.dismiss()
          navigate('/users')
          toast.error(error?.data?.message)
          checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
        }
      } catch (error: any) {
        toast.dismiss()
        toast.error(error?.data?.message)
        checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
      } finally {
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    formik.setValues({
      image: userData?.user?.photo || '',
      firstName: userData?.user?.firstName || '',
      lastName: userData?.user?.lastName || '',
      title: userData?.user?.title || '',
      phone: userData?.user?.phone || '',
      email: userData?.user?.email || '',
      timezone: userData?.user?.timezone || '',
      dateFormat: userData?.user?.dateFormat || '',
      timeFormat: userData?.user?.timeFormat || '',
      groupId: userData?.user?.groupId || '',
    })
  }, [userData])

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoadingGetSubUser} />
      <ManageGroup
        formik={formik}
        onSelectGroup={handleGroupSelect}
        userData={userData}
        selectedGroupName={selectedGroupName}
      />
      <EditUserDetails
        loading={loading}
        formik={formik}
        userData={userData}
        id={id}
        selectedGroupName={selectedGroupName}
      />
    </>
  )
}

export default EditUser
