import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth, checkUserUnauthorized } from 'App/Modules/Auth'
import { useDeleteAssetsMutation, useGetAllAssetsQuery } from 'App/Modules/Services/Assets'
import { Link, useLocation } from 'react-router-dom'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import moment from 'moment'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import getSymbolFromCurrency from 'currency-symbol-map'
import { toAbsoluteUrlImage } from 'Template/helpers'
import { JsonToExcel } from 'react-json-to-excel'
import { exportToExcel } from 'react-json-to-excel'
import {
  Grid,
  TableHeaderRow,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
  DragDropProvider,
  TableColumnReordering,
  VirtualTable,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'
import SplashScreen from 'App/Loaders/SplashScreen'
import { nameKeys, setupKeys } from 'config'
import Columns from '../../../../../../../App/Modules/Warranties/Components/Columns.json'
import Loader from 'App/Loaders/BeatLoader'
import { useGetAllWarrantiesQuery, useGetWarrantyQuery } from 'App/Modules/Services/Warranty'
import { Images } from 'Template/assets/Assets'
import { currencies } from 'App/Modules/Warranties/Components/Currency'
import SetupColumns from 'App/Modules/Warranties/Components/SetupColumns'
import { ColumnData } from 'App/Modules/Warranties/Components/RenderColumnData'
import { AssetsReportHeader } from '../AssetsReportHeader'
import SetupCoulmnListing from 'App/Modules/Warranties/Components/SetupColumnListing'
import { AssetResponse } from '../../../Asset/Core/Model'

const ReportByWarrantyInfo = () => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const [deleteDataArr, setDeleteDataArr] = useState<any>([])
  const [selectAllData, setSelectAllData] = useState<boolean>(false)
  const [selectedData, setSelectedData] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [check, setCheck] = useState<number[]>([])
  const [data, setData] = useState({})
  const [show, setShow] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [skip, setSkip] = useState<boolean>(false)
  const [selected, setSelected] = useState<any>([])
  const [currencyLogo, setCurrencyLogo] = useState<any>()
  const [selectAll, setSelectAll] = useState<any>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [pagination, setPagination] = useState<any>({})
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [criteria, setCriteria] = useState([])
  const [showSetupColumns, setShowSetupColumns] = useState<boolean>(false)
  const [selectedColumns, setSelectedColumns] = useState<{ key: any; value: any }[]>(Columns)
  const selectedColumnsRender = selectedColumns.filter((column) => column.value === true)
  const [dataId, setDataId] = useState<number>()
  const [showViewModal, setShowViewModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalCheck, setModalCheck] = useState(false)
  const [searchAssets, setSearchAssets] = useState<any>({
    keyword: '',
    searchField: '',
    siteId: '',
    locationId: '',
    categoryId: '',
    departmentId: '',
    person: '',
    customer: '',
    status: '',
    groupBy: '',
    recordCount: '',
    dateRangeBy: 'purchasedDate',
    quickDateRange: '',
    startDate: '',
    endDate: '',
    exportToExcel: false,
    warrantyStatus: '',
  })
  const location = useLocation()
  const { pathname } = location

  const selectRef = useRef<HTMLSelectElement>(null)

  useEffect(() => {
    const selectElement = selectRef?.current
    if (!selectElement) return

    const options = selectElement?.options

    for (let i = 0; i < options?.length; i++) {
      switch (pathname) {
        case '/warranties':
          if (options[i].textContent === 'All Warranties') {
            options[i].selected = true
          }
          break

        case '/warranties/expire':
          if (options[i].textContent === 'Expired Warranties') {
            options[i].selected = true
          }
          break

        default:
          break
      }
    }
  }, [pathname])
  const { data: singleWarrantyData } = useGetWarrantyQuery({ id: dataId }, { skip: !dataId })
  const {
    data: companyData,
    isSuccess: company,
    isLoading: isLoadingGetCompany,
    refetch: refetchComapnyData,
  } = useGetCompanyQuery({})
  const {
    data: assetsData,
    isLoading: isLoadingGetAssets,
    error,
    isSuccess,
    isError,
  } = useGetAllAssetsQuery(
    { body: new URLSearchParams(searchAssets).toString(), page, limit, keys: '' },
    { skip }
  )
  const excelKeys = selectedColumnsRender.map((column) => column.key.replace(/_/g, ' '))
  const { data: warrantyData, isLoading: isLoadingGetWarranties } = useGetAllWarrantiesQuery(
    { body: new URLSearchParams(searchAssets).toString(), page, limit, keys: excelKeys },
    { skip }
  )

  useEffect(() => {
    refetchComapnyData()
  }, [])
  const [deleteAssets, { isLoading: isLoadingDelete }] = useDeleteAssetsMutation()
  useEffect(() => {
    const res = getSymbolFromCurrency(companyData?.company?.currency)
    setCurrencyLogo(res)
  }, [company])

  const initialColumns = [
    {
      name: 'selections',
      title: (
        <input
          type='checkbox'
          className='form-check-input custom-form-check-input'
          onChange={(e) => handleSelectAll(e)}
          name={'multicheckSelect'}
        />
      ),
      getCellValue: (row) => (
        <div>
          <input
            type='checkbox'
            className='form-check-input custom-form-check-input'
            onChange={() => {
              handleCheckboxChange(row.id)
            }}
            name={'multicheckSelect'}
          />
        </div>
      ),
    },
    { name: 'assetTagId', title: 'Asset Tag ID' },
    { name: 'description', title: 'Description' },
    { name: 'brand', title: 'Brand' },
    {
      name: 'purchasedDate',
      title: 'Purchase Date',
      getCellValue: (row) => {
        if (row.purchasedDate) {
          return moment(row.purchasedDate).format('DD-MM-YYYY')
        } else {
          return ''
        }
      },
    },
    {
      name: 'leasedTo',
      title: 'Leased To',
      getCellValue: (row) => {
        if (row?.statusType == 'lease' && row?.assetslease) {
          return row.assetslease?.customer?.fullName
        } else {
          return ''
        }
      },
    },
    {
      name: 'assignTo',
      title: 'Assigned To',
      getCellValue: (row) => {
        if (row?.statusType !== 'lease') {
          if (row?.site) {
            if (row?.site && row?.location) {
              return row?.site?.name + ' / ' + row?.location?.location
            } else {
              return row?.site?.name
            }
          }
        } else {
          return ''
        }
      },
    },
    {
      name: 'cost',
      title: 'Cost',
      getCellValue: (row) => {
        if (row?.cost) {
          const currencyObject = currencies.find(
            (currency) => currency.value === companyData?.company?.currency
          )
          if (currencyObject) {
            const currencyLogo = currencyObject.name.slice(-1)
            return `${currencyLogo + ' '}${row.cost}`
          } else {
            return row.cost
          }
        } else {
          return ''
        }
      },
    },

    {
      name: 'statusType',
      title: 'Status',
      getCellValue: (row) => (
        <div
          className={`d-inline align-items-center px-3 py-2 rounded ${
            row?.statusType === 'available'
              ? 'bg-light-green'
              : row?.statusType === 'lease'
                ? 'bg-light-blue'
                : row?.statusType === 'dispose'
                  ? 'bg-light-pink'
                  : row?.statusType === 'check_out'
                    ? 'bg-light-blue'
                    : ''
          }`}
        >
          {row?.statusType === 'check_out'
            ? 'Checked out'
            : row?.statusType === 'lease'
              ? 'Leased'
              : row?.statusType === 'dispose'
                ? 'Disposed'
                : row?.statusType === 'available'
                  ? 'Available'
                  : row?.statusType}
        </div>
      ),
    },
    {
      name: 'image',
      title: '',
      getCellValue: (row) => (
        <div className='d-inline align-items-center asset-img-table'>
          {row.photo ? (
            <img
              src={toAbsoluteUrlImage(row.photo)}
              alt='asset'
              className='img-fluid'
              crossOrigin='anonymous'
            />
          ) : (
            <img src={Images.AssetsPlaceholder} alt='asset' className='img-fluid' />
          )}
        </div>
      ),
    },
  ]
  const [columns, setColumns] = useState<any>(initialColumns)
  const [hiddenColumnNames, setHiddenColumnNames] = useState(['image'])
  const [columnOrder, setColumnOrder] = useState([
    'selections',
    'image',
    'selection',
    'assetTagId',
    'description',
    'brand',
    'purchasedDate',
    'cost',
    'assignTo',
    'leasedTo',
    'statusType',
    'actions',
  ])
  const [selection, setSelection] = useState<any>([])
  const [tableColumnExtensions] = useState([{ columnName: 'selections', width: 50 }])
  const [defaultHiddenColumnNames] = useState([])

  const handleWarrantyStatusChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchAssets((prevState) => ({
      ...prevState,
      warrantyStatus: value,
    }))
    handleSearchSubmit()
  }

  useEffect(() => {
    const savedColumnVisibility = localStorage.getItem('columnVisibility')
    const savedColumnOrder = localStorage.getItem('gridColumnOrder')
    if (savedColumnVisibility) {
      const visibilityMap = JSON.parse(savedColumnVisibility)
      const updatedColumns = initialColumns.map((column) => ({
        ...column,
        hidden: !visibilityMap[column.name],
      }))
      setColumns(updatedColumns)
      const falseKeys = Object.keys(visibilityMap).filter((key) => visibilityMap[key] === false)

      setHiddenColumnNames(falseKeys)
    }
    if (savedColumnOrder) {
      setColumnOrder(JSON.parse(savedColumnOrder))
    }
  }, [])

  useEffect(() => {
    if (isError) {
      const errorData = error as { data: any }
      checkUserUnauthorized(errorData?.data, saveAuth, setCurrentUser, toast)
    }
  }, [isError])

  const handleColumnVisibilityChange = (hiddenColumns) => {
    setHiddenColumnNames(hiddenColumns)
    const updatedColumns = columns.map((column) => ({
      ...column,
      hidden: hiddenColumns.includes(column.name),
    }))
    setColumns(updatedColumns)

    const visibilityMap = {}
    updatedColumns.forEach((column) => {
      visibilityMap[column.name] = !column.hidden
    })
    localStorage.setItem('columnVisibility', JSON.stringify(visibilityMap))
  }

  const handleColumnOrderChange = (newOrder) => {
    setColumnOrder(newOrder)
    localStorage.setItem('gridColumnOrder', JSON.stringify(newOrder))
  }

  const handleSearchAssets = (e) => {
    const { name, value, update, target } = e
    switch (name) {
      case nameKeys.searchField:
      case nameKeys.status: {
        const valuesArray = value.map((obj) => obj.value)
        setSearchAssets({ ...searchAssets, searchField: valuesArray.toString() })
        setCriteria((prevCriteria) => ({
          ...prevCriteria,
          searchField: valuesArray.toString(),
        }))
        break
      }
      case nameKeys.customDate: {
        const startDate = update[0] ? moment(update[0]).format('YYYY-MM-DD') : ''
        const endDate = update[1] ? moment(update[1]).format('YYYY-MM-DD') : ''
        setSearchAssets({ ...searchAssets, startDate, endDate })
        setCriteria((prevCriteria) => ({
          ...prevCriteria,
          startDate,
          endDate,
        }))
        break
      }
      case nameKeys.recordCount: {
        const { value: recordCount } = target
        setLimit(recordCount)
        setCriteria((prevCriteria) => ({
          ...prevCriteria,
          recordCount,
        }))
        break
      }
      default: {
        const { name: targetName, value: targetValue, selectedOptions } = target
        setSearchAssets({ ...searchAssets, [targetName]: targetValue })
        break
      }
    }
  }

  const clearSearch = (keyToRemove) => {
    const updatedCriteria = { ...criteria }
    delete updatedCriteria[keyToRemove]
    setCriteria(updatedCriteria)

    const updatedSearchAssets = { ...searchAssets }
    delete updatedSearchAssets[keyToRemove]

    setSearchAssets(updatedSearchAssets)
    handleSearchSubmit()
    setSearchAssets({
      keyword: '',
      searchField: '',
      siteId: '',
      locationId: '',
      categoryId: '',
      departmentId: '',
      person: '',
      customer: '',
      status: '',
      groupBy: '',
      recordCount: '',
      dateRangeBy: 'purchasedDate',
      quickDateRange: '',
      startDate: '',
      endDate: '',
      exportToExcel: false,
      warrantyStatus: '',
    })
    setLoading(false)
  }

  const handleSearchSubmit = () => {
    setLoading(true)
    setPage(1)
    setSkip(false)
    setShowSearch(false)
  }

  useEffect(() => {
    if (isSuccess) {
      setSkip(true)
    }
  }, [showSearch])

  useEffect(() => {
    setSkip(false)
  }, [])

  useEffect(() => {
    if (currentUser && warrantyData) {
      setPagination(warrantyData?.pagination)
      setLoading(false)
    }
  }, [warrantyData])

  const handleShow = (item: AssetResponse) => {
    setData(item)
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected) => {
      const updatedSelected = prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
      const isAllSelected = updatedSelected.length === warrantyData?.allAssets?.length
      setSelectAll(isAllSelected)
      return updatedSelected
    })
    setDeleteDataArr((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = warrantyData?.allAssets?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setDeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setDeleteDataArr([])
    }
  }

  useEffect(() => {
    if (selectAllData) {
      const newSelecteds = warrantyData?.userAssets?.map((item: any) => item.id) || []
      setSelected(newSelecteds)
      setDeleteDataArr(newSelecteds)
    }
    setSelectAllData(false)
  }, [selectedData])

  useEffect(() => {
    switch (true) {
      case deleteDataArr && deleteDataArr.length === 0:
        {
          const multicheckSelectCheckboxes = document.querySelectorAll(
            nameKeys.multiCheckSelectName
          )
          multicheckSelectCheckboxes.forEach((checkbox) => {
            if (checkbox instanceof HTMLInputElement) {
              checkbox.checked = false
            }
          })
        }
        break

      case selectAll === true:
        {
          const multicheckSelectCheckboxes = document.querySelectorAll(
            nameKeys.multiCheckSelectName
          )
          multicheckSelectCheckboxes.forEach((checkbox) => {
            if (checkbox instanceof HTMLInputElement) {
              checkbox.checked = true
            }
          })
        }
        break

      case selectAll === false:
        {
          const multicheckSelectCheckbox = document.querySelector(
            nameKeys.multiCheckSelectName
          ) as HTMLInputElement
          if (multicheckSelectCheckbox) {
            multicheckSelectCheckbox.checked = false
          }
        }
        break

      default:
        break
    }
  }, [deleteDataArr])

  const deleteSelectedAssets = async (id: number[]) => {
    try {
      const deleteIds = { deleteIds: id }
      const res = await deleteAssets({ id: deleteIds }).unwrap()
      toast.success(res?.message)
      setSkip(false)
    } catch (err: any) {
      toast.error(err?.data?.message)
    }
    setCheck([])
    setDeleteDataArr([])
    setSelectAll(false)
    setDeleteModal(false)
    setSelected([])
    setLoading(true)
  }

  const cancel = () => setDeleteModal(false)

  const confirm = () => deleteSelectedAssets(check)

  const handlePageChange = (currentPage: any) => {
    setLoading(true)
    setPage(currentPage)
    setSkip(false)
  }

  const removeFilter = (fieldName) => {
    setSearchAssets((prevSearchAssets) => ({
      ...prevSearchAssets,
      [fieldName]: '',
    }))
  }

  const handleLimitChange = (newLimit: number) => {
    setLoading(true)
    setLimit(Number(newLimit))
    setPage(1)
    setSkip(false)
  }

  const handleExport = () => {
    setSearchAssets((prevSearchAssets) => ({
      ...prevSearchAssets,
      exportToExcel: true,
    }))
    handleSearchSubmit()
  }

  useEffect(() => {
    if (warrantyData?.fileName && searchAssets?.exportToExcel) {
      downloadAndRefresh()
    }
  }, [warrantyData])

  const viewMaintenanceDetails = (id: any) => {
    if (dataId === id) {
      return setShowViewModal(true)
    }
    setLoading(true)
    setDataId(id)
    setModalCheck(false)
    setShowModal(false)
  }

  const editMaintenanceDetails = (id: any) => {
    if (dataId === id) {
      return setShowEditModal(true)
    }
    setLoading(true)
    setDataId(id)
    setModalCheck(true)
    setShowModal(false)
  }

  useEffect(() => {
    if (singleWarrantyData) {
      if (showModal) {
        return setLoading(false)
      }
      if (modalCheck === true) {
        setLoading(false)
        return setShowEditModal(true)
      }
      setLoading(false)
      setShowViewModal(true)
    }
  }, [singleWarrantyData])

  const downloadAndRefresh = () => {
    const link = document.createElement('a')
    link.href = process.env.REACT_APP_PUBLIC_URL + warrantyData?.fileName
    link.target = `_blank`
    link.download = `downloaded-file-name`

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
    setSearchAssets((prevSearchAssets) => ({
      ...prevSearchAssets,
      exportToExcel: false,
    }))
  }

  const currentPage = pagination?.page || 0
  const totalRecords = pagination?.total || 0
  const pageSize = limit || 10
  const startRecord = (currentPage - 1) * pageSize + 1
  const endRecord = Math.min(currentPage * pageSize, totalRecords)
  return (
    <>
      {!isLoadingGetWarranties ? (
        <>
          <SetupCoulmnListing
            showSetupColumns={showSetupColumns}
            setShowSetupColumns={setShowSetupColumns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            assetsData={warrantyData}
          />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <div
                id='kt_content_container'
                className={`container ${(showSearch || showSetupColumns) && 'd-none'}`}
              >
                <div className='card'>
                  <div className='d-inline-block mx-2'></div>
                  <div className='card-body'>
                    <AssetsReportHeader
                      handleExport={handleExport}
                      title='Assets by Warranty Information'
                    />
                    <div className='d-flex align-items-center justify-content-between flex-wrap mb-4'>
                      <div className='d-flex limit-options align-items-center mb-sm-0 mb-3'>
                        <span className='text-muted'>Showing</span>
                        <select
                          className='form-select form-select-sm fw-bold mx-3 border-1'
                          onChange={(e) => handleLimitChange(Number(e.target.value))}
                          value={limit}
                        >
                          <option value='10'>10</option>
                          <option value='15'>15</option>
                          <option value='20'>20</option>
                          <option value='25'>25</option>
                          <option value='50'>50</option>
                        </select>
                        <span className='text-muted'>entries</span>
                      </div>
                      <SetupColumns setShowSetupColumns={setShowSetupColumns} />
                    </div>
                    <div className='react-grid-card'>
                      <div className='table-responsive pb-4'>
                        <table
                          id='kt_table_users'
                          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        >
                          <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                              <th role='columnheader' className='w-10px pe-2'>
                                <div className='header-checkbox me-3'>
                                  <input
                                    type='checkbox'
                                    className='form-check-input custom-form-check-input'
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                  />
                                </div>
                              </th>
                              {selectedColumnsRender.map((column, index) => (
                                <th key={index}>{column.key.replace(/_/g, ' ')}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {warrantyData?.allAssets?.length > 0 ? (
                              warrantyData?.allAssets?.map((asset: any, rowIndex: number) => (
                                <tr key={rowIndex} role='row'>
                                  <td role='cell'>
                                    <div className='form-check form-check-sm form-check-custom '>
                                      <input
                                        type='checkbox'
                                        className='form-check-input'
                                        checked={selected.includes(asset?.id)}
                                        onChange={() => handleCheckboxChange(asset?.id)}
                                      />{' '}
                                    </div>
                                  </td>
                                  {selectedColumnsRender.map((column, colIndex) => (
                                    <td key={colIndex} className='table-elipses'>
                                      <ColumnData
                                        column={column.key.replace(/_/g, ' ')}
                                        assetsData={{ allAssets: [asset] }}
                                        currencyLogo={currencyLogo}
                                      />
                                    </td>
                                  ))}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={selectedColumnsRender?.length + 2}
                                  className='text-center'
                                >
                                  <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                    No records found
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {warrantyData?.allAssets?.length ? (
                      <div className='d-sm-flex align-items-center justify-content-between mt-3'>
                        <div className='m-1'>
                          Showing {startRecord} to {endRecord} of {totalRecords} records
                        </div>
                        {pagination && (
                          <Pagination
                            className='m-1 custom-pagination'
                            defaultCurrent={1}
                            pageSize={limit}
                            current={pagination?.page}
                            total={pagination?.total}
                            onChange={handlePageChange}
                            locale={localeInfo}
                          />
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Loader loading={loading} />
          </div>
        </>
      ) : null}
    </>
  )
}

export default ReportByWarrantyInfo
