import { Link } from 'react-router-dom'
import { useGetProfileQuery } from 'App/Modules/Services/Profile'
import SplashScreen from 'App/Loaders/SplashScreen'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export function Overview() {
  const { data, isLoading } = useGetProfileQuery()

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoading} />
      {!isLoading ? (
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-body d-flex border-bottom border-bottom-gray-200 justify-content-between align-items-center pb-3'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Profile Details</h3>
            </div>
            <Link to='/account/settings' className='btn btn-light-primary align-self-center'>
              Edit Profile
            </Link>
          </div>
          <div className='card-body p-9'>
            <Row className='mb-7'>
              <Col lg="4">
                <label className='fw-bold'>Full Name</label>
              </Col>
              <Col lg="8">
                <div>
                  <span className='fw-bolder fs-6 text-muted'>
                    {data?.user?.firstName || '-'} {data?.user?.lastName}
                  </span>
                </div>
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col lg="4">
                <label className='fw-bold'>Title</label>
              </Col>
              <Col lg="8">
                <div>
                  <span className='fw-bolder fs-6 text-muted'>{data?.user?.title || '-'}</span>
                </div>
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col lg="4">
                <label className='fw-bold'>Email</label>
              </Col>
              <Col lg="8">
                <div>
                  <span className='fw-bolder fs-6 text-muted'>{data?.user?.email || '-'}</span>
                </div>
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col lg="4">
                <label className='fw-bold'>Phone</label>
              </Col>
              <Col lg="8">
                <div className='d-flex align-items-center'>
                  <span className='fw-bolder fs-6 me-2 text-muted'>{data?.user?.phone || '-'}</span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : null}
    </>
  )
}
