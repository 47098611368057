import { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { useGetPersonsQuery } from 'App/Modules/Services/Persons'
import { useGetAllSitesQuery } from 'App/Modules/Services/Sites'
import { useGetAllLocationsBySiteQuery } from 'App/Modules/Services/Locations'
import { useUpdateAssetEventMutation } from 'App/Modules/Services/Assets'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { useDispatch } from 'react-redux'
import { refresh } from 'App/Store'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

interface NewAuditModalProps {
  editCheckoutEventModal: boolean
  handleClose: () => void
  event: any
  assetId: any
}

const EditCheckoutEventModal: React.FC<NewAuditModalProps> = ({
  editCheckoutEventModal,
  handleClose,
  event,
  assetId,
}) => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const permission = currentUser?.SecurityGroup
  const staffUser = currentUser?.userType === 2
  const personUser = currentUser?.userType === 3
  const customerUser = currentUser?.userType === 4
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [siteId, setSiteId] = useState<any>(null)
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [selectedLocation, setSelectedLocation] = useState<any>(null)
  const [checkoutTo, setCheckoutTo] = useState(
    event.checkOut.assigneeType === 1 ? 'person' : 'site'
  )
  const [checkOutFormData, setCheckOutFormData] = useState<any>({
    checkoutDate: event.checkOut
      ? new Date(event.checkOut.checkoutDate).toISOString().slice(0, 10)
      : '',
    assigneeType: event.checkOut?.assigneeType || null,
    dueDate: event.checkOut ? new Date(event.checkOut.dueDate).toISOString().slice(0, 10) : '',
    notes: event.checkOut?.notes || '',
    personId: event.checkOut?.Person?.id || null,
    siteId: event.checkOut?.Site?.id || null,
    locationId: event.checkOut?.Location?.id || null,
  })
  const [updateAssetEvent, { isLoading }] = useUpdateAssetEventMutation()
  const { data: personData } = useGetPersonsQuery({
    skip: personUser || customerUser || (staffUser && !permission?.viewPerson),
  })
  const { data: userSitesList } = useGetAllSitesQuery({ userId: currentUser?.id })
  const { data: userLocationList } = useGetAllLocationsBySiteQuery(
    { userId: currentUser?.id, id: siteId },
    { skip: !siteId }
  )
  const [assignOptions, setAssignOptions] = useState<any>([])
  const [locationOptions, setLocationOptions] = useState<any>([])

  useEffect(() => {
    if (checkoutTo === 'person' && event.checkOut.Person) {
      setSelectedOption({
        label: `${event.checkOut.Person.firstName} ${event.checkOut.Person.lastName}`,
        value: event.checkOut.Person.id,
      })
      setCheckOutFormData((prev) => ({
        ...prev,
        personId: event.checkOut.Person.id,
      }))
    } else if (checkoutTo === 'site' && event.checkOut.Site) {
      setSelectedOption({
        label: event.checkOut.Site.name,
        value: event.checkOut.Site.id,
      })
      setSiteId(event.checkOut.Site.id)
      setCheckOutFormData((prev) => ({
        ...prev,
        siteId: event.checkOut.Site.id,
      }))
    }
  }, [event.checkOut, checkoutTo])

  useEffect(() => {
    if (checkoutTo === 'site' && event.checkOut.Location) {
      setSelectedLocation({
        label: event.checkOut.Location.location,
        value: event.checkOut.Location.id,
      })
      setCheckOutFormData((prev) => ({
        ...prev,
        locationId: event.checkOut.Location.id,
      }))
    }
  }, [event.checkOut.Location, checkoutTo])

  useEffect(() => {
    if (personData?.persons) {
      const options = personData.persons.map((person) => ({
        label: `${person.firstName} ${person.lastName}`,
        value: person.id,
      }))
      setAssignOptions(options)
    }
  }, [personData])

  useEffect(() => {
    if (userLocationList?.locations) {
      const options = userLocationList.locations.map((location) => ({
        label: location.location,
        value: location.id,
      }))
      setLocationOptions(options)
    }
  }, [userLocationList])

  const handleRadioChange = (e) => {
    const value = e.target.value
    setCheckoutTo(value)
    setSelectedOption(null)
    setSelectedLocation(null)
    setSiteId(null)
    setCheckOutFormData((prev) => ({
      ...prev,
      assigneeType: value === 'person' ? 1 : 2,
      personId: null,
      siteId: null,
      locationId: null,
    }))
  }

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value
    setSelectedOption({
      label: e.target.options[e.target.selectedIndex].text,
      value: selectedValue,
    })
    if (checkoutTo === 'site') {
      setSiteId(selectedValue)
    }
    setCheckOutFormData((prev) => ({
      ...prev,
      personId: checkoutTo === 'person' ? selectedValue : prev.personId,
      siteId: checkoutTo === 'site' ? selectedValue : prev.siteId,
    }))
  }

  const handleLocationChange = (e) => {
    const selectedValue = e.target.value
    setSelectedLocation({
      label: e.target.options[e.target.selectedIndex].text,
      value: selectedValue,
    })
    setCheckOutFormData((prev) => ({
      ...prev,
      locationId: selectedValue,
    }))
  }

  const isFormValid = () => {
    const { assigneeType, personId, siteId } = checkOutFormData
    const hasAssignee = assigneeType === 1 ? personId : siteId
    return hasAssignee
  }

  const handleSubmit = async () => {
    if (isFormValid()) {
      const dataToUpdate = {
        eventId: event.id,
        data: {
          checkoutDate: checkOutFormData.checkoutDate,
          assigneeType: checkOutFormData.assigneeType,
          dueDate: checkOutFormData.dueDate,
          notes: checkOutFormData.notes,
          personId: checkOutFormData.personId || null,
          siteId: checkOutFormData.siteId || null,
          locationId: checkOutFormData.siteId ? checkOutFormData.locationId || null : null,
        },
      }
      const result: any = await updateAssetEvent(dataToUpdate)
      if (result?.data?.success) {
        dispatch(refresh(true))
        toast.success(result.data.message)
        handleClose()
        navigate(`/asset/view/${assetId}`)
      } else {
        toast.error('Failed to delete event')
        checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
      }
      handleClose()
    }
  }

  return (
    <Modal show={editCheckoutEventModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{event?.event}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-3' controlId='formDate'>
            <Form.Label>Check-out Date</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                value={checkOutFormData.checkoutDate || ''}
                onChange={(e) =>
                  setCheckOutFormData((prev) => ({ ...prev, checkoutDate: e.target.value }))
                }
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formCheckoutTo'>
            <Form.Label>Check-out To</Form.Label>
            <div className='d-flex align-items-center '>
              <Form.Check
                type='radio'
                label='Person'
                name='checkoutTo'
                id='checkoutToPerson'
                value='person'
                onChange={handleRadioChange}
                checked={checkoutTo === 'person'}
              />
              <Form.Check
                type='radio'
                label='Site'
                name='checkoutTo'
                className='ms-4'
                id='checkoutToSite'
                value='site'
                onChange={handleRadioChange}
                checked={checkoutTo === 'site'}
              />
            </div>
          </Form.Group>
          {checkoutTo === 'person' && (
            <Form.Group className='mb-3' controlId='formAssignTo'>
              <Form.Label className='required-label'>Assign to</Form.Label>
              <Form.Control
                as='select'
                defaultValue={selectedOption?.label}
                value={selectedOption?.value || ''}
                onChange={handleSelectChange}
              >
                <option value={selectedOption?.value || ''}>
                  {selectedOption?.label || 'Select a Person'}
                </option>
                {assignOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          {checkoutTo === 'site' && (
            <>
              <Form.Group className='mb-3' controlId='formSite'>
                <Form.Label className='required-label'>Site</Form.Label>
                <Form.Control
                  as='select'
                  value={selectedOption?.value || ''}
                  onChange={handleSelectChange}
                >
                  <option value=''>Select a Site</option>
                  {userSitesList?.sites.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formLocation'>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  as='select'
                  value={selectedLocation?.value || ''}
                  onChange={handleLocationChange}
                >
                  <option value=''>Select a location</option>
                  {locationOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </>
          )}
          <Form.Group className='mb-3' controlId='formDueDate'>
            <Form.Label>Due Date</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                value={checkOutFormData.dueDate || ''}
                onChange={(e) =>
                  setCheckOutFormData((prev) => ({ ...prev, dueDate: e.target.value }))
                }
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formNotes'>
            <Form.Label>Check-out Notes</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              value={checkOutFormData.notes || ''}
              onChange={(e) => setCheckOutFormData((prev) => ({ ...prev, notes: e.target.value }))}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={handleSubmit} disabled={selectedOption === ''}>
          {isLoading ? 'Updating...' : 'Update'}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditCheckoutEventModal
