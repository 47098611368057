import { createApi } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { toast } from 'react-toastify'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: customFetchBaseQuery({ path: 'category' }),
  tagTypes: ['Categories'],
  endpoints: (build) => ({
    getAllCategories: build.query<any, any>({
      query: ({ page = 0, limit = 0 }) => ({
        url: 'list',
        method: 'GET',
        params: { page, limit },
      }),
      providesTags: ['Categories'],
    }),
    addCategory: build.mutation<any, any>({
      query: (values) => ({
        url: `create`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: () => [{ type: 'Categories' }],
    }),
    updateCategory: build.mutation<any, { id: number; values: any }>({
      query: ({ id, values }) => ({
        url: `update/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: () => [{ type: 'Categories' }],
    }),
    deleteCategory: build.mutation<any, { cascade: number; id: any }>({
      query({ cascade, id }) {
        return {
          url: `delete?cascade=${cascade}`,
          method: 'DELETE',
          body: id,
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(assetsApi.util.invalidateTags(['Assets']))
          })
          .catch((err) => toast.error(err?.message))
      },
      invalidatesTags: (result, error, userId) => [{ type: 'Categories', userId }],
    }),
  }),
})

export const {
  useGetAllCategoriesQuery,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoriesApi
