import { Suspense, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { LayoutProvider, LayoutSplashScreen } from '../Template/layout/core'
import { MasterInit } from '../Template/layout/MasterInit'
import { AuthInit } from './Modules/Auth'
import './App.css'

const App = () => {
  const location = useLocation()
  const { pathname } = location

  useEffect(() => {
    localStorage.setItem(
      'path',
      pathname[pathname.length - 1] === '/' ? pathname.substring(0, pathname.length - 1) : pathname
    )
  }, [pathname])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <LayoutProvider>
        <AuthInit>
          <Outlet />
          <MasterInit />
        </AuthInit>
      </LayoutProvider>
    </Suspense>
  )
}

export { App }
