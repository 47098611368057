export interface ReserveInterface {
  startDate: Date | string
  endDate: Date | string
  reserveFor: number | undefined
  personId?: number | undefined
  siteId?: number | undefined
  locationId?: number | undefined
  customerId?: number | undefined
  sendEmail?: boolean | null
  reminder1?: number | undefined
  reminder2?: number | undefined
  notes?: string | undefined
  assetId: any
}

export const defaultReserveDetail: ReserveInterface = {
  startDate: new Date(),
  endDate: new Date(),
  reserveFor: 1,
  personId: undefined,
  siteId: undefined,
  locationId: undefined,
  customerId: undefined,
  sendEmail: null,
  reminder1: undefined,
  reminder2: undefined,
  notes: undefined,
  assetId: undefined,
}
