import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const assetEventsApi = createApi({
  reducerPath: 'assetEvents',
  baseQuery: customFetchBaseQuery({ path: 'events' }),
  tagTypes: ['Events'],
  endpoints: (build) => ({
    getAssetEvents: build.query<any, any>({
      query: ({ id }) => ({
        url: `/get/${id}`,
        method: 'GET',
      }),
      providesTags: ['Events'],
    }),
  }),
})

export const { useGetAssetEventsQuery } = assetEventsApi
