import Loader from 'App/Loaders/BeatLoader'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { useUndoActionMutation } from 'App/Modules/Services/AssetActions'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'

interface NewAuditModalProps {
  undoSellModal: boolean
  handleClose: () => void
  refetchAssetDetails: () => void
  assetId: any
  setRefreshHistory: any
}

const UndoSellModal: React.FC<NewAuditModalProps> = ({
  undoSellModal,
  handleClose,
  assetId,
  refetchAssetDetails,
  setRefreshHistory,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [undoStatus] = useUndoActionMutation()
  const { saveAuth, setCurrentUser } = useAuth()

  const handleChangeUndoSell = async () => {
    try {
      setLoading(true)
      const result: any = await undoStatus({ assetId, type: 'Sell' })
      if (result?.data?.success) {
        refetchAssetDetails()
        setRefreshHistory((prev) => !prev)
        toast.success(result?.data?.message)
        handleClose()
        setLoading(false)
      } else {
        toast.error(result?.error?.data?.message)
        checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }
  return (
    <Modal show={undoSellModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Delete 'Sell' Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>Are you sure you want to remove this event?</h6>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Cancel
        </button>
        <button className='btn btn-danger' onClick={handleChangeUndoSell}>
          Delete
        </button>
      </Modal.Footer>
      <Loader loading={loading} />
    </Modal>
  )
}

export default UndoSellModal
