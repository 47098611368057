import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const emailTemplateApi = createApi({
  reducerPath: 'emailTemplateApi',
  baseQuery: customFetchBaseQuery({ path: 'customize-email' }),
  tagTypes: ['CustomizeEmail'],
  endpoints: (build) => ({
    getEmailTypes: build.query({
      query: () => `get-email-types`,
      providesTags: ['CustomizeEmail'],
    }),
    getEmailDefaultTemplate: build.query({
      query: (id) => `get-email-template/${id}`,
      providesTags: ['CustomizeEmail'],
    }),
    updateEmailTemplate: build.mutation({
      query: (body) => ({
        url: `update`,
        method: 'PUT',
        body: body,
      }),
    }),
    getEmailTemplatesss: build.mutation({
      query: (templateName) => ({
        url: `get-template`,
        method: 'POST',
        body: { templateName: templateName },
      }),
    }),
    getUserEmailTemplate: build.query<any, any>({
      query: (id) => `get-user-email-template/${id}`,
      providesTags: ['CustomizeEmail'],
    }),
  }),
})

export const {
  useGetEmailTemplatesssMutation,
  useUpdateEmailTemplateMutation,
  useGetEmailTypesQuery,
  useGetEmailDefaultTemplateQuery,
  useGetUserEmailTemplateQuery,
} = emailTemplateApi
