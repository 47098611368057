import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { checkUserUnauthorized, useAuth } from '../../../../Auth'
import { GroupResponse } from '../Core/Model'
import { SecurityGroupHeader } from './SecurityGroupHeader'
import ConfirmationPopup from '../../../../../../Template/helpers/components/ConfirmationPopup'
import { useDeleteSitesMutation } from '../../../../Services/Sites'
import { useGetAllGroupsQuery, useDeleteGroupMutation } from 'App/Modules/Services/groups'
import { useNavigate } from 'react-router-dom'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import { PageTitle } from 'Template/layout/core'
import ActionIcon from 'Template/assets/icons/ActionIcon'
import SplashScreen from 'App/Loaders/SplashScreen'
import Loader from 'App/Loaders/BeatLoader'
import Pagination from 'rc-pagination'
import { setupKeys } from 'config'

const SecurityGroupListing = () => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const currentUserGoup = currentUser?.SecurityGroup?.id
  const permission = currentUser?.SecurityGroup
  const staffUser = currentUser?.userType === 2
  const navigate = useNavigate()
  const userId = currentUser?.id
  const [check, setCheck] = useState<number>()
  const [show, setShow] = useState<boolean>(false)
  const [data, setData] = useState({})
  const [selectAll, setSelectAll] = useState(false)
  const [selected, setSelected] = useState<number>()
  const [deleteDataArr, setdeleteDataArr] = useState<number[]>([])
  const [deleteData, setDeleteData] = useState<number>(0)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [isSitesExist, setIsSitesExist] = useState<any>(false)
  const [deleteCascade, setDeleteCascade] = useState(false)
  const [pagination, setPagination] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)

  const [deleteSites] = useDeleteSitesMutation()

  const [deleteGroup] = useDeleteGroupMutation()

  const {
    data: groupsList,
    isLoading: isLoadingGetGroups,
    error: groupListErr,
  }: any = useGetAllGroupsQuery({ page, limit })

  const handleClose = () => {
    setShow(false)
  }

  useEffect(() => {
    if (groupListErr) checkUserUnauthorized(groupListErr?.data, saveAuth, setCurrentUser, toast)
  }, [groupListErr])

  const handleEditGroup = (groupId: number) => {
    navigate(`/group-manager/edit-group/${groupId}`)
  }

  const handlePageChange = (currentPage: any) => {
    setLoading(true)
    setPage(currentPage)
  }

  const handleShow = (item: GroupResponse) => {
    setData(item)
    setShow(true)
  }

  const checkVistingPage = (dataList: any) => {
    if (isSitesExist === false) {
      if (dataList && dataList?.length > 0) {
        setIsSitesExist(dataList?.length)
      } else {
        setIsSitesExist(0)
      }
    }
  }

  const cancel = () => {
    setDeleteModal(false)
    setDeleteCascade(false)
  }

  const handleCheckboxChange = (id: number) => {
    setSelected(id)
    setDeleteData(id)
  }

  useEffect(() => {
    if (currentUser && groupsList) {
      checkVistingPage(groupsList?.groups)
      setPagination(groupsList?.pagination)
      setLoading(false)
    }
  }, [groupsList])

  const deleteSelectedGroup = async (id: number) => {
    try {
      setLoading(true)
      const res = await deleteGroup(id).unwrap()
      toast.success(res.message)
      setDeleteModal(false)
    } catch (error: any) {
      if (!error?.data?.success) {
        toast.error(error?.data?.message)
        checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
      }
    }
  }

  const handleLimitChange = (newLimit: number) => {
    setLoading(true)
    setLimit(Number(newLimit))
    setPage(1)
  }

  const confirm = () => deleteSelectedGroup(deleteData)
  const currentPage = groupsList?.pagination?.page || 0
  const totalRecords = groupsList?.pagination?.total || 0
  const pageSize = limit || 10
  const startRecord = (currentPage - 1) * pageSize + 1
  const endRecord = Math.min(currentPage * pageSize, totalRecords)

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoadingGetGroups} />
      <PageTitle breadcrumbs={[]}>Security Groups</PageTitle>
      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          <div id='kt_content_container' className='container'>
            <div className='card'>
              <ConfirmationPopup
                deleteModal={deleteModal}
                cancel={cancel}
                confirm={confirm}
                deleteCascade={deleteCascade}
                setupName={setupKeys.group}
              />
              <SecurityGroupHeader
                deleteDataArr={deleteDataArr}
                setDeleteModal={setDeleteModal}
                setCheck={setCheck}
                permission={permission}
                staffUser={staffUser}
              />
              <div className='card-body py-4'>
                <p className={'text-dark'}>
                  Decide which parts of <strong>Asset Rack</strong> you want accessible to your
                  users by assigning them to Security Groups. You can use and edit the predetermined
                  groups or you can create your own custom security groups.
                </p>
                <div className='d-flex limit-options align-items-center mb-3'>
                  <span className='text-muted'>Showing</span>
                  <select
                    className='form-select form-select-sm fw-bold mx-3 border-1'
                    onChange={(e) => handleLimitChange(Number(e.target.value))}
                    value={limit}
                  >
                    <option value='10'>10</option>
                    <option value='15'>15</option>
                    <option value='20'>20</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                  </select>
                  <span className='text-muted'>entries</span>
                </div>
                <div className='table-responsive pb-4 custom-table'>
                  <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    role='table'
                  >
                    <thead>
                      <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        <th role='columnheader'> Group Name </th>
                        <th role='columnheader'> Group Description </th>
                        <th role='columnheader'> Active Users </th>
                        {!(permission?.updateGroup || permission?.deleteGroup) && staffUser ? (
                          ''
                        ) : (
                          <th role='columnheader'> Actions </th>
                        )}
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                      {groupsList?.groups?.length ? (
                        groupsList?.groups?.map((item: GroupResponse, idx: number) => {
                          const userCountItem = groupsList.userCount.find((group) => {
                            return Number(group.groupId) === item.id
                          })
                          return (
                            <tr key={idx} role='row'>
                              <td role='cell'>
                                <div className='text-truncate'>{item.name}</div>
                              </td>
                              <td role='cell'>
                                <div className='d-flex flex-column table-elipses-description'>
                                  <span>{item.description}</span>
                                </div>
                              </td>
                              <td role='cell'>
                                <div className='d-flex flex-column'>
                                  <span>
                                    {userCountItem?.count ?? 0}
                                    {' Users'}
                                  </span>
                                </div>
                              </td>
                              <td role='cell'>
                                {!(permission?.updateGroup || permission?.deleteGroup) &&
                                staffUser ? (
                                  ''
                                ) : (
                                  <div className='dropdown'>
                                    <button
                                      className='dropdown-toggle'
                                      type='button'
                                      id='dropdownMenuButton1'
                                      data-bs-toggle='dropdown'
                                      aria-expanded='false'
                                    >
                                      Action
                                      <ActionIcon />
                                    </button>
                                    <ul
                                      className='dropdown-menu actions-dropdown'
                                      aria-labelledby='dropdownMenuButton1'
                                    >
                                      {!permission?.updateGroup && staffUser ? (
                                        ''
                                      ) : (
                                        <li>
                                          <button
                                            className='dropdown-item'
                                            onClick={() => handleEditGroup(item.id)}
                                            disabled={item.id === currentUserGoup}
                                          >
                                            Edit
                                          </button>
                                        </li>
                                      )}
                                      {!permission?.deleteGroup && staffUser ? (
                                        ''
                                      ) : (
                                        <li
                                          onClick={() => {
                                            setDeleteModal(true)
                                            setDeleteData(item.id)
                                          }}
                                        >
                                          <button className='dropdown-item'>Delete</button>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={12}>
                            <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                              No records found
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {pagination && groupsList?.groups?.length > 0 && (
                  <div className='d-sm-flex align-items-center justify-content-between flex-wrap pt-3'>
                    <div className='m-1'>
                      Showing {startRecord} to {endRecord} of {totalRecords} records
                    </div>
                    <Pagination
                      className='m-1 custom-pagination'
                      defaultCurrent={1}
                      pageSize={limit}
                      current={pagination?.page}
                      total={groupsList?.pagination?.total}
                      onChange={handlePageChange}
                      locale={localeInfo}
                    />
                  </div>
                )}
              </div>
              {isSitesExist === 0 ? (
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button
                    onClick={() => {
                      navigate(`/apps/setup/location`)
                    }}
                    className='btn btn-primary'
                  >
                    Continue
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <Loader loading={loading} />
      </div>
    </>
  )
}

export default SecurityGroupListing
