import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const auditApi = createApi({
  reducerPath: 'auditApi',
  baseQuery: customFetchBaseQuery({ path: 'audit' }),
  tagTypes: ['Assets'],
  endpoints: (build) => ({
    addAudit: build.mutation<any, any>({
      query: (body) => ({
        url: 'create',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Assets' }],
    }),
    updateAuditById: build.mutation<any, any>({
      query: ({ id, values }) => ({
        url: `update/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: () => [{ type: 'Assets' }],
    }),
    deleteAuditById: build.mutation<any, any>({
      query: ({ id }) => ({
        url: `delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'Assets' }],
    }),
    getAuditByAssetId: build.query<any, any>({
      query: ({ assetId }) => ({
        url: `get-audit/${assetId}`,
        method: 'GET',
      }),
      providesTags: ['Assets'],
    }),
    getAuditByAuditId: build.query<any, any>({
      query: ({ auditId }) => ({
        url: `get/${auditId}`,
        method: 'GET',
      }),
      providesTags: ['Assets'],
    }),
    getAllPendingAsset: build.query<any, { body: any }>({
      query: ({ body }) => ({
        url: `get-pending-assets/?${body}`,
        method: 'GET',
      }),
      providesTags: ['Assets'],
    }),
  }),
})

export const {
  useAddAuditMutation,
  useGetAllPendingAssetQuery,
  useUpdateAuditByIdMutation,
  useDeleteAuditByIdMutation,
  useGetAuditByAssetIdQuery,
  useGetAuditByAuditIdQuery,
} = auditApi
