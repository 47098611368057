const AlertsSettingsData = [
  {
    checkbox: true, // t/f
    heading: 'Asset Past Due Alerts',
    text: 'Show alerts for assets that are past due.',
    FrequencyAllDays: false,
    stopEmails: {
      stopEmailPara_1: 'We stop sending email when the asset gets Checked-in.',
      stopEmailPara_2: 'Even if asset is not checked in, stop sending alert email after ',
      stopEmailDays: 22,
      checkbox: false,
    },
  },
  {
    checkbox: true, // t/f
    heading: 'Lease Expiration Alerts',
    text: 'Show alerts for upcoming lease.',
    leadTime: {
      defaultTime: 30, // (in days)
    },
    FrequencyAllDays: true,
    stopEmails: {
      stopEmailPara_1: null,
      stopEmailPara_2: 'Stop sending email after event date',
      stopEmailDays: null,
      checkbox: true,
    },
  },
  {
    checkbox: true, // t/f
    heading: 'Maintenance Alerts',
    text: 'Show alerts for upcoming maintenance.',
    leadTime: {
      defaultTime: 30, // (in days)
      text: 'The lead time determines how many days before the user is notified about planned maintenance.',
    },
    overdueTime: {
      defaultTime: 7, // (in days)
      text: 'The Overdue time specifies how many days after the scheduled maintenance it is put to the overdue list.',
    },
    FrequencyAllDays: true,

    stopEmails: {
      stopEmailPara_1: 'We stop sending email when maintenance is completed.',
      stopEmailPara_2:
        'Even if maintenance is not marked completed, stop sending alert email after',
      stopEmailDays: 30,
      checkbox: false,
    },
  },
  {
    checkbox: true, // t/f
    heading: 'Warranty Expiration Alerts',
    text: 'Show alerts for expiring warranty.',
    leadTime: {
      defaultTime: 30, // (in days)
    },
    FrequencyAllDays: true,
    stopEmails: {
      stopEmailPara_1: null,
      stopEmailPara_2: 'Stop sending email after event date',
      stopEmailDays: null,
      checkbox: true,
    },
  },
]

export default AlertsSettingsData
