import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'
export interface Company {
  userId: string
  name: string
}

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: customFetchBaseQuery({ path: 'company' }),
  tagTypes: ['Company'],
  endpoints: (build) => ({
    getCompany: build.query<any, any>({
      query: () => 'list',
      providesTags: ['Company'],
    }),
    updateCompany: build.mutation<any, { formData?: FormData }>({
      query: ({ formData }) => ({
        url: `upsert`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: () => [{ type: 'Company' }],
    }),
    deleteCompany: build.mutation<{ success: boolean }, number>({
      query() {
        return {
          url: `delete`,
          method: 'DELETE',
        }
      },
      invalidatesTags: () => [{ type: 'Company' }],
    }),
    deleteImage: build.mutation<any, any>({
      query: () => ({
        url: `delete-image`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'Company' }],
    }),
  }),
})

export const {
  useGetCompanyQuery,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useDeleteImageMutation,
} = companyApi
