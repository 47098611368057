import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function EmailSubject() {
  return (
    <div>
      <Row className="mb-6">
        <Col lg="2">
          <div className='col-form-label fw-bold fs-6'>Email Subject</div>
        </Col>
        <Col lg="10">
          <div className='fv-row'>
            <p className='mb-1 text-muted'>
              {' '}
              <i className='la la-info-circle fs-5 text-muted'></i> Text that will appear in the email
              subject line.
            </p>
            <input type='text' className='form-control form-control-solid' name='emailSubject' />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default EmailSubject
