import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDeleteAssetEventMutation } from 'App/Modules/Services/Assets'
import { useDispatch } from 'react-redux'
import { refresh } from 'App/Store'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

interface DeleteModalProps {
  deleteEventModal: boolean
  handleClose: () => void
  document: any
  event: any
  assetId: any
}

const DeleteEventModal: React.FC<DeleteModalProps> = ({
  deleteEventModal,
  handleClose,
  document,
  event,
  assetId,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { saveAuth, setCurrentUser } = useAuth()
  const [deleteAssetEvent] = useDeleteAssetEventMutation()

  const handleDelete = async () => {
    try {
      const result: any = await deleteAssetEvent({ assetId, eventId: document })
      if (result.data.success) {
        dispatch(refresh(true))
        toast.success(result.data.message)
        handleClose()
        navigate(`/asset/view/${assetId}`)
      } else {
        toast.error('Failed to delete event')
        checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
      }
    } catch (error) {
      toast.error('Failed to delete event')
    }
  }

  return (
    <Modal show={deleteEventModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Delete {event} Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className='mb-5'>Are you sure you want to remove this event?</h5>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Cancel
        </button>
        <button className='btn btn-danger' onClick={handleDelete}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteEventModal
