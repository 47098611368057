import UsersListing from './Components/UsersListing'

const Users = () => {
  return (
    <div>
      <UsersListing />
    </div>
  )
}

export default Users
