import { createApi } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { customFetchBaseQuery } from './Headers/CustomHeaders'
import { toast } from 'react-toastify'

export const moveApi = createApi({
  reducerPath: 'moveApi',
  baseQuery: customFetchBaseQuery({ path: '' }),
  tagTypes: ['Move'],
  endpoints: (build) => ({
    addMove: build.mutation<any, any>({
      query: ({ data }) => ({
        url: `move`,
        method: 'PUT',
        body: data,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(assetsApi.util.invalidateTags(['Assets']))
          })
          .catch((err) => toast.error(err?.message))
      },
      invalidatesTags: () => [{ type: 'Move' }],
    }),
  }),
})

export const { useAddMoveMutation } = moveApi
