import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'
import { assetsApi } from './Assets'

export const assetPhotosApi = createApi({
  reducerPath: 'assetPhotosApi',
  baseQuery: customFetchBaseQuery({ path: 'asset-photos' }),
  tagTypes: ['Photos'],
  endpoints: (build) => ({
    getAllPhotos: build.query<any, any>({
      query: (id) => ({
        url: `/${id}`,
        method: 'GET',
      }),
      providesTags: ['Photos'],
    }),
    addPhotos: build.mutation<any, any>({
      query: ({ data, id }) => ({
        url: `/${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Photos' }],
    }),
    pinnedPhoto: build.mutation<any, any>({
      query: ({ id }) => ({
        url: `/pinned/${id}`,
        method: 'POST',
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(assetsApi.util.invalidateTags(['Assets']))
          })
          .catch((err) => {})
      },
      invalidatesTags: () => [{ type: 'Photos' }],
    }),
    getStockImageCategories: build.query<void, void>({
      query: () => ({
        url: `/stock-images/categories`,
        method: 'GET',
      }),
    }),
    getStockImageByCategory: build.query<any, any>({
      query: ({ categoryId }) => ({
        url: `/stock-images/categories/${categoryId}`,
        method: 'GET',
      }),
    }),
    addStockImage: build.mutation<any, any>({
      query: ({ id, assetId }) => ({
        url: `/stock-image/add`,
        method: 'POST',
        body: { id, assetId },
      }),
      invalidatesTags: () => [{ type: 'Photos' }],
    }),
    deleteAssetPhoto: build.mutation<any, any>({
      query({ id }) {
        return {
          url: `/delete/${id}`,
          method: 'DELETE',
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(assetsApi.util.invalidateTags(['Assets']))
          })
          .catch((err) => {})
      },
      invalidatesTags: () => [{ type: 'Photos' }],
    }),
  }),
})

export const {
  useAddPhotosMutation,
  useGetAllPhotosQuery,
  usePinnedPhotoMutation,
  useGetStockImageCategoriesQuery,
  useGetStockImageByCategoryQuery,
  useAddStockImageMutation,
  useDeleteAssetPhotoMutation,
} = assetPhotosApi
