import React, { useEffect, useState } from 'react'
import { CustomEvent, CustomEventWithId } from '../Core/Model'
import { getAuth } from '../../../../Auth'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetCustomEventQuery, useGetCustomEventsQuery, useAddCustomEventMutation, useUpdateCustomEventMutation,} from '../../../../Services/Events'
import { Link } from 'react-router-dom'

const AddCustomEvents = () => {
  const { id } = useParams()
  const auth = getAuth()
  const navigate = useNavigate()
  const [customEvent, setCustomEvent] = useState<CustomEvent>({
    name: 'listner',
    icon: 'la la-calendar-check-o',
    color: '#fcf8e3',
    description: '',
    hasDropdownList: true,
    hasListOfPerson: true,
    isListOfPersonRequired: true,
    hasListOfCustomers: true,
    isListOfCustomersRequired: true,
    hasListOfSitesOrLocations: true,
    isListOfSitesOrLocationsRequired: true,
    hasFields: true,
    hasDateOne: true,
    hasDateOneEnableTime: true,
    dateOneCustomizeLabel: 'Event date',
    isDateOneDataRequired: true,
    hasDateTwo: true,
    hasDateTwoEnableTime: true,
    dateTwoCustomizeLabel: 'Due date',
    isDateTwoDataRequired: true,
    hasTextField: true,
    text: 'Text field',
    isTextFieldDataRequired: true,
    hasCurrency: true,
    amount: 'Amount',
    isCurrencyDataRequired: true,
    hasBoolean: true,
    booleanText: 'Question',
    hasNotes: true,
    notesText: 'Notes',
    isNotesDataRequired: true,
    hasSignaturePad: true,
    signatureRequired: true,
    returnEvent: true,
    enableEvent: true,
    primaryEventId: null,
    signatureDisclaimer:
      'I hereby acknowledge that I have received the assets. I understand that this asset is under my possession for carrying out my work. I assure that I will take care of the asset to the best possible extent and will be solely responsible in case of any damage or theft.',
  })

  const [events, setEvents] = useState<{ id: number; name: string; returnEvent: boolean }[]>([])
  const [error, setError] = useState<{ eventName: string }>({ eventName: '' })
  const [eventIcons, setEventIcons] = useState(true)
  const [skip, setSkip] = useState(true)
  const [addCustomEvents, { isLoading: isAdding }] = useAddCustomEventMutation()
  const [updateCustomEvent, { isLoading: isUpdating }] = useUpdateCustomEventMutation()
  const { data: customEventResponse } = useGetCustomEventQuery({ id }, { skip })
  const { data: customEventsResponse }: any = useGetCustomEventsQuery()

  useEffect(() => {
    if (id) setSkip(false)
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      if (customEventResponse?.event) {
        setCustomEvent(customEventResponse?.event)
      }
      if (customEventsResponse && customEventsResponse?.userEvents) {
        const events = customEventsResponse?.userEvents
          ?.map((item: CustomEventWithId) => ({
            id: item.id,
            name: item.name,
            returnEvent: item.returnEvent,
          }))
          .filter((event: CustomEventWithId) => event.returnEvent === false)
        setEvents(events)
      }
    }

    fetchData()
  }, [customEventResponse, customEventsResponse])

  const handleAllDropdownLists = () => {
    customEvent?.hasListOfPerson &&
    customEvent?.hasListOfCustomers &&
    customEvent?.hasListOfSitesOrLocations
      ? setCustomEvent({
          ...customEvent,
          hasListOfPerson: false,
          hasListOfCustomers: false,
          hasListOfSitesOrLocations: false,
        })
      : setCustomEvent({
          ...customEvent,
          hasListOfPerson: true,
          hasListOfCustomers: true,
          hasListOfSitesOrLocations: true,
        })
  }

  const handleAllDataRequirementFields = () => {
    customEvent?.hasDateOne &&
    customEvent?.hasDateTwo &&
    customEvent?.hasTextField &&
    customEvent?.hasCurrency &&
    customEvent?.hasBoolean &&
    customEvent?.hasNotes
      ? setCustomEvent({
          ...customEvent,
          hasDateOne: false,
          hasDateTwo: false,
          hasTextField: false,
          hasCurrency: false,
          hasBoolean: false,
          hasNotes: false,
        })
      : setCustomEvent({
          ...customEvent,
          hasDateOne: true,
          hasDateTwo: true,
          hasTextField: true,
          hasCurrency: true,
          hasBoolean: true,
          hasNotes: true,
        })
  }

  const submitCustomEvent = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()
    if (customEvent?.name) {
      setError({ eventName: '' })
      if (id) {
        try {
          const updateResponse = await updateCustomEvent({
            values: customEvent,
            token: auth?.token,
            id: id,
          }).unwrap()
          if (updateResponse?.status == 200) {
            toast.success('Database updated successfully')
            navigate('/apps/setup/events')
          }
        } catch (error: any) {
          toast.error(error || 'Something went Wrong!')
        }
      } else {
        try {
          const addResponse = await addCustomEvents({
            data: customEvent,
            token: auth?.token,
          }).unwrap()
          if (addResponse?.status == 201) {
            toast.success('Custom Events added successfully')
            navigate('/apps/setup/events')
          }
        } catch (error: any) {
          toast.error(error?.data?.message)
        }
      }
    } else {
      setError({ eventName: 'Event Name is required.' })
    }
  }

  return (
    <div className='card readyPanel2'>
      <form action='/setupevents/add' method='post' noValidate>
        <input
          name='__RequestVerificationToken'
          type='hidden'
          value='pSIh0oSKBUjWYl_MGiGECS5XTmDQubDMRe8Zxzm7SPvHCIU3BLH3H8pvD-nFkBgxgL_-yf0HOT0-qD2WpnA5K5RU1kI3l2u309qZHrii5GVFUQC7z5tanGHfLyPtkWLmuCSmxA2'
        />
        <div className='card-body'>
          <div className='form_section'>
            <div className='form-title'>
              <span className='add_custom_events'>
                <strong>Event Name</strong>
              </span>
            </div>
            <div className='form-group row mb-4'>
              <label className='col-sm-3 col-form-label text-sm-end required' htmlFor='Name'>
                {' '}
                Event Name{' '}
              </label>
              <div className='col-sm-5'>
                <input
                  onChange={(e) => setCustomEvent({ ...customEvent, name: e.target.value })}
                  value={customEvent?.name}
                  className='form-control valid'
                  data-val='true'
                  data-val-length='Maximum length is 50.'
                  data-val-length-max='50'
                  data-val-regex='Please enter a valid name'
                  data-val-regex-pattern='^[^.\\\\/:*?\<>|\"\&apos;]?[^\\\\/:*?\<>|\"\&apos;]*'
                  data-val-required='Event Name is required.'
                  id='Name'
                  maxLength={50}
                  name='Name'
                  type='text'
                />
                <span
                  className='text-danger mt-1'
                  data-valmsg-for='Name'
                  data-valmsg-replace='true'
                >
                  {error?.eventName}
                </span>
                <div className='clearfix'></div>
                <span className='text-muted fs-7'>For example, 'Retired', 'End of Life', etc.</span>
              </div>
            </div>
            <div className='form-group row mb-4'>
              <label className='col-sm-4 col-md-3 col-form-label text-sm-end'>
                {' '}
                Select event icon{' '}
              </label>
              <div className='col-10 col-sm-5 col-md-5 col-lg-3'>
                <button
                  tabIndex={0}
                  onClick={() => setEventIcons(!eventIcons)}
                  className='customEventstyle btn btn-light'
                  data-bs-content-id='popover-content'
                  data-bs-original-title='Popover title'
                >
                  <i className={`${customEvent?.icon}`}></i>
                  <span className='customEventName' id='spn_eventName'>
                    {customEvent?.name}
                  </span>
                </button>
                {eventIcons && (
                  <div id='popover-content' className='d-flex'>
                    <div className='row icons_row gx-2 gy-2' id='icons_row'>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'la la-calendar-check-o' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='la la-calendar-check-o' data-id='1'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-address-book' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-address-book' data-id='2'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-address-card' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-address-card' data-id='3'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-atom' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-atom' data-id='4'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-balance-scale' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-balance-scale' data-id='5'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-barcode' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-barcode' data-id='6'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-bell-slash' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-bell-slash' data-id='7'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-bell' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-bell' data-id='8'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-birthday-cake' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-birthday-cake' data-id='9'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-book' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-book' data-id='10'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-box' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-box' data-id='11'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-broom' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-broom' data-id='12'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-bug' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-bug' data-id='13'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-bullhorn' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-bullhorn' data-id='14'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-bullseye' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-bullseye' data-id='15'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-calendar' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-calendar' data-id='16'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-candy-cane' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-candy-cane' data-id='17'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-car' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-car' data-id='18'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-car-side' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-car-side' data-id='19'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-certificate' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-certificate' data-id='20'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-check-double' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-check-double' data-id='21'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-dice-d6' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-dice-d6' data-id='22'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-clipboard-check' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-clipboard-check' data-id='23'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-clipboard-list' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-clipboard-list' data-id='24'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-clock' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-clock' data-id='25'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-cog' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-cog' data-id='26'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-comment-dollar' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-comment-dollar' data-id='27'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-compass' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-compass' data-id='28'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-copyright' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-copyright' data-id='29'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-donate' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-donate' data-id='30'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-dove' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-dove' data-id='31'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-drum' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-drum' data-id='32'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-eraser' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-eraser' data-id='33'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-exclamation-circle' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-exclamation-circle' data-id='34'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-exclamation-triangle' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-exclamation-triangle' data-id='35'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-eye' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-eye' data-id='36'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-eye-slash' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-eye-slash' data-id='37'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-file-alt' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-file-alt' data-id='38'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-fire-alt' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-fire-alt' data-id='39'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-fire-extinguisher' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-fire-extinguisher' data-id='40'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-first-aid' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-first-aid' data-id='41'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-flag' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-flag' data-id='42'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-folder' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-folder' data-id='43'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-frown' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-frown' data-id='44'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-gem' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-gem' data-id='45'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-ghost' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-ghost' data-id='46'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-gift' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-gift' data-id='47'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-glasses' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-glasses' data-id='48'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-globe' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-globe' data-id='49'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-hammer' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-hammer' data-id='50'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-hand-holding-heart' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-hand-holding-heart' data-id='51'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-hand-holding-usd' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-hand-holding-usd' data-id='52'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-hand-point-right' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-hand-point-right' data-id='53'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-handshake' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-handshake' data-id='54'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-heart' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-heart' data-id='55'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-hourglass' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-hourglass' data-id='56'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-infinity' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-infinity' data-id='57'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-key' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-key' data-id='58'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-layer-group' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-layer-group' data-id='59'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-leaf' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-leaf' data-id='60'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-lemon' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-lemon' data-id='61'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-life-ring' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-life-ring' data-id='62'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-lightbulb' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-lightbulb' data-id='63'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-link' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-link' data-id='64'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-list' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-list' data-id='65'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-lock' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-lock' data-id='66'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-lock-open' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-lock-open' data-id='67'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-map-marker' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-map-marker' data-id='68'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-map-signs' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-map-signs' data-id='69'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-mars' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-mars' data-id='70'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-medal' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-medal' data-id='71'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-money-bill' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-money-bill' data-id='72'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-hand-pointer' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-hand-pointer' data-id='73'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-paper-plane' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-paper-plane' data-id='74'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-paperclip' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-paperclip' data-id='75'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-parachute-box' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-parachute-box' data-id='76'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-pencil-ruler' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-pencil-ruler' data-id='77'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-piggy-bank' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-piggy-bank' data-id='78'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-poo' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-poo' data-id='79'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-puzzle-piece' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-puzzle-piece' data-id='80'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-qrcode' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-qrcode' data-id='81'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-question-circle' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-question-circle' data-id='82'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-rainbow' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-rainbow' data-id='83'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-random' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-random' data-id='84'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-recycle' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-recycle' data-id='85'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-redo' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-redo' data-id='86'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-redo-alt' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-redo-alt' data-id='87'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-registered' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-registered' data-id='88'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-ribbon' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-ribbon' data-id='89'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-rocket' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-rocket' data-id='90'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-seedling' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-seedling' data-id='91'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-share-square' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-share-square' data-id='92'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-shield-alt' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-shield-alt' data-id='93'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-sign' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-sign' data-id='94'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-sitemap' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-sitemap' data-id='95'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-skull-crossbones' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-skull-crossbones' data-id='96'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-smile' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-smile' data-id='97'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-snowflake' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-snowflake' data-id='98'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-spa' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-spa' data-id='99'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-stream' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-stream' data-id='100'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-suitcase' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-suitcase' data-id='101'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-sun' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-sun' data-id='102'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-sync' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-sync' data-id='103'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-tachometer-alt' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-tachometer-alt' data-id='104'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-tag' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-tag' data-id='105'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-tags' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-tags' data-id='106'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-thumbs-down' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-thumbs-down' data-id='107'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-thumbs-up' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-thumbs-up' data-id='108'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-thumbtack' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-thumbtack' data-id='109'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-tools' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-tools' data-id='110'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-toolbox' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-toolbox' data-id='111'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-trash' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-trash' data-id='112'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-tree' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-tree' data-id='113'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-trophy' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-trophy' data-id='114'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-truck' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-truck' data-id='115'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-umbrella' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-umbrella' data-id='116'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-unlink' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-unlink' data-id='117'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-user' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user' data-id='118'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-user-alt' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user-alt' data-id='119'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-user-check' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user-check' data-id='120'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-user-circle' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user-circle' data-id='121'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-user-clock' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user-clock' data-id='122'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-user-cog' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user-cog' data-id='123'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-user-edit' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user-edit' data-id='124'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-user-friends' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user-friends' data-id='125'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-user-lock' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user-lock' data-id='126'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-user-minus' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user-minus' data-id='127'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-user-plus' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user-plus' data-id='128'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-user-shield' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user-shield' data-id='129'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-user-slash' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user-slash' data-id='130'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-user-tag' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user-tag' data-id='131'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-venus' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-venus' data-id='132'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-wallet' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-wallet' data-id='133'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-wrench' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-wrench' data-id='134'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() =>
                          setCustomEvent({ ...customEvent, icon: 'las la-user-times' })
                        }
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-user-times' data-id='135'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col'
                        onClick={() => setCustomEvent({ ...customEvent, icon: 'las la-gavel' })}
                      >
                        <div className='icon_box'>
                          <div className='preview_icon'>
                            <i className='las la-gavel' data-id='136'></i>
                          </div>
                        </div>
                      </div>
                      <input type='hidden' id='ATIconFkid' name='ATIconFkid' value='1' />
                      <input
                        type='hidden'
                        id='ATIconclassName'
                        name='ATIconclassName'
                        value='la la-calendar-check-o'
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='form-group row mb-4'>
              <label className='col-sm-4 col-md-3 col-form-label text-sm-end' htmlFor='eventColor'>
                {' '}
                Select event color{' '}
              </label>
              <div className=' col-2 col-sm-1 select_custom_color'>
                <select
                  id='eventColor'
                  name='colorpicker-picker-longlist'
                  onChange={(e) => setCustomEvent({ ...customEvent, color: e.target.value })}
                  value={customEvent?.color}
                  data-widget-name='colorCode_CustomEvent'
                  className='d-flex'
                >
                  <option
                    value='#fcf8e3'
                    data-color-id='92'
                    data-title='Corn Silk'
                    className='corn-silk'
                  ></option>
                  <option
                    value='#fce5cd'
                    data-color-id='93'
                    data-title='Bisque'
                    className='bisque'
                  ></option>
                  <option
                    value='#f4cccc'
                    data-color-id='94'
                    data-title='Vanilla Ice'
                    className='vanilla-ice'
                  ></option>
                  <option
                    value='#e6b8af'
                    data-color-id='95'
                    data-title='Cavern Pink'
                    className='cavern-pink'
                  ></option>
                  <option
                    value='#ead1dc'
                    data-color-id='96'
                    data-title='Prim'
                    className='prim'
                  ></option>
                  <option
                    value='#d9d2e9'
                    data-color-id='97'
                    data-title='Fog'
                    className='fog'
                  ></option>
                  <option
                    value='#d9edf7'
                    data-color-id='98'
                    data-title='Pattens Blue'
                    className='pattens-blue'
                  ></option>
                  <option
                    value='#c9daf8'
                    data-color-id='99'
                    data-title='Hawkes Blue'
                    className='hawkes-blue'
                  ></option>
                  <option
                    value='#d0e0e3'
                    data-color-id='100'
                    data-title='Mabel'
                    className='mabel'
                  ></option>
                  <option
                    value='#dff0d8'
                    data-color-id='101'
                    data-title='Hint Of Green'
                    className='hint-of-green'
                  ></option>
                </select>
                <span
                  className='simplecolorpicker icon'
                  title='Cavern Pink'
                  role='button'
                  tabIndex={0}
                >
                  <svg
                    viewBox='0 0 100 100'
                    className='icon shape-codepen-2'
                    style={{ backgroundColor: customEvent?.color }}
                  ></svg>
                </span>
                <input type='hidden' id='ATColorFkid' name='ATColorFkid' value='101' />
                <input type='hidden' id='ATColorSelected' name='ATColorSelected' value='#fcf8e3' />
              </div>
              <div className=' col-2 col-sm-6'>
                Color will set to default 'Green' for any return event.
              </div>
            </div>
            <div className='form-group row mb-4'>
              <label className='col-sm-3 col-form-label text-sm-end' htmlFor='Description'>
                Event Description
              </label>
              <div className='col-sm-5'>
                <input
                  onChange={(e) => setCustomEvent({ ...customEvent, description: e.target.value })}
                  value={customEvent?.description}
                  className='form-control'
                  data-val='true'
                  data-val-length='Maximum length is 1000.'
                  data-val-length-max='1000'
                  id='Description'
                  maxLength={1000}
                  name='Description'
                  type='text'
                />
                <span
                  className='field-validation-valid'
                  data-valmsg-for='Description'
                  data-valmsg-replace='true'
                ></span>
              </div>
            </div>
          </div>
          <div className='form_section'>
            <div className='separator separator-solid mb-6'></div>
            <div className='form-title mb-4'>
              <svg
                className='events_field_svg'
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                width='26'
                height='26'
                viewBox='0 0 32 32'
              >
                <path
                  fill='#b6b9c8'
                  d='M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z'
                ></path>
                <path
                  fill='#b6b9c8'
                  d='M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z'
                ></path>
                <path
                  fill='#b6b9c8'
                  d='M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z'
                ></path>
                <path
                  fill='#b6b9c8'
                  d='M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z'
                ></path>
              </svg>
              <span className='add_custom_events mb-3'>
                <strong>Event Fields</strong>
              </span>
            </div>
            <p className='pl-35'>
              <b>Dropdown List:</b> For this custom event, if you want to ‘assign’ the asset to a
              ‘Person’, ‘Customer’, or a ‘Site/Location’, select the list accordingly.
            </p>
            <p className='pl-35'>
              <b>Data required:</b> ‘Optional’ gives the user the option to skip selecting from the
              list. ‘Yes’ forces the user to select from the list.
            </p>
            <div className='table-responsive mb-3 pb-3'>
              <table
                className='table table-bordered table-sm vertical_middle mb-0'
                aria-label='Event Fields'
              >
                <thead className='thead-light'>
                  <tr className='light_orange'>
                    <th className='w-3'>
                      <label
                        className='checkbox has_nolabel m-auto'
                        htmlFor='standard_linking_field_all'
                      >
                        <input
                          type='checkbox'
                          onChange={handleAllDropdownLists}
                          checked={
                            customEvent?.hasListOfPerson &&
                            customEvent?.hasListOfCustomers &&
                            customEvent?.hasListOfSitesOrLocations
                          }
                          id='standard_linking_field_all'
                          className='form-check-input custom-form-check-input sig_input'
                        />

                        <span className='sr-only'>Field All</span>
                      </label>
                    </th>
                    <th className='w-40'>Dropdown List</th>
                    <th>Data Requried</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor='PersonsField' className='checkbox has_nolabel m-auto'>
                        <input
                          onChange={() =>
                            setCustomEvent({
                              ...customEvent,
                              hasListOfPerson: !customEvent?.hasListOfPerson,
                            })
                          }
                          checked={customEvent?.hasListOfPerson}
                          className='standard_linking_field form-check-input custom-form-check-input sig_input'
                          data-val='true'
                          data-val-required='The PersonsField field is required.'
                          id='PersonsField'
                          name='PersonsField'
                          type='checkbox'
                          value='true'
                        />
                        <input name='PersonsField' type='hidden' value='false' />
                        <span className='sr-only'>Field</span>
                      </label>
                    </td>
                    <td className='fw-bold'>List of Persons</td>
                    <td>
                      <div className='radio-inline flex-nowrap'>
                        <label htmlFor='PersonsRequired_True' className='radio mb-0'>
                          <input
                            id='PersonsRequired_True'
                            onChange={() =>
                              setCustomEvent({ ...customEvent, isListOfPersonRequired: true })
                            }
                            checked={customEvent?.isListOfPersonRequired}
                            name='PersonsRequired'
                            type='checkbox'
                            className='form-check-input custom-form-check-input sig_input'
                            value='True'
                          />
                          <span className='m_7'></span> Yes
                        </label>
                        <label htmlFor='PersonsRequired_False' className='radio mb-0'>
                          <input
                            id='PersonsRequired_False'
                            onChange={() =>
                              setCustomEvent({ ...customEvent, isListOfPersonRequired: false })
                            }
                            checked={!customEvent?.isListOfPersonRequired}
                            name='PersonsRequired'
                            type='checkbox'
                            value='False'
                            className='form-check-input custom-form-check-input sig_input'
                          />
                          <span className='m_7'></span> Optional
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='CustomersField' className='checkbox has_nolabel m-auto'>
                        <input
                          onChange={() =>
                            setCustomEvent({
                              ...customEvent,
                              hasListOfCustomers: !customEvent?.hasListOfCustomers,
                            })
                          }
                          checked={customEvent?.hasListOfCustomers}
                          className='standard_linking_field form-check-input custom-form-check-input sig_input'
                          data-val='true'
                          data-val-required='The CustomersField field is required.'
                          id='CustomersField'
                          name='CustomersField'
                          type='checkbox'
                          value='true'
                        />
                        <input name='CustomersField' type='hidden' value='false' />
                        <span className='sr-only'>Field</span>
                      </label>
                    </td>
                    <td className='fw-bold'>List of Customer</td>
                    <td>
                      <div className='radio-inline flex-nowrap'>
                        <label htmlFor='CustomersRequired_True' className='radio mb-0'>
                          <input
                            id='CustomersRequired_True'
                            onChange={() =>
                              setCustomEvent({ ...customEvent, isListOfCustomersRequired: true })
                            }
                            checked={customEvent?.isListOfCustomersRequired}
                            name='CustomersRequired'
                            type='checkbox'
                            value='True'
                            className='form-check-input custom-form-check-input sig_input'
                          />
                          <span className='m_7'></span> Yes
                        </label>
                        <label htmlFor='CustomersRequired_False' className='radio mb-0'>
                          <input
                            id='CustomersRequired_False'
                            onChange={() =>
                              setCustomEvent({ ...customEvent, isListOfCustomersRequired: false })
                            }
                            checked={!customEvent?.isListOfCustomersRequired}
                            name='CustomersRequired'
                            type='checkbox'
                            className='form-check-input custom-form-check-input sig_input'
                            value='False'
                          />
                          <span className='m_7'></span> Optional
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='LocationsField' className='checkbox has_nolabel m-auto'>
                        <input
                          onChange={() =>
                            setCustomEvent({
                              ...customEvent,
                              hasListOfSitesOrLocations: !customEvent?.hasListOfSitesOrLocations,
                            })
                          }
                          checked={customEvent?.hasListOfSitesOrLocations}
                          className='standard_linking_field form-check-input custom-form-check-input sig_input'
                          data-val='true'
                          data-val-required='The LocationsField field is required.'
                          id='LocationsField'
                          name='LocationsField'
                          type='checkbox'
                          value='true'
                        />
                        <input name='LocationsField' type='hidden' value='false' />
                        <span className='sr-only'>Field</span>
                      </label>
                    </td>
                    <td className='fw-bold'>List of Sites/Locations</td>
                    <td>
                      <div className='radio-inline flex-nowrap'>
                        <label htmlFor='LocationsRequired_True' className='radio mb-0'>
                          <input
                            id='LocationsRequired_True'
                            onChange={() =>
                              setCustomEvent({
                                ...customEvent,
                                isListOfSitesOrLocationsRequired: true,
                              })
                            }
                            checked={customEvent?.isListOfSitesOrLocationsRequired}
                            name='LocationsRequired'
                            type='checkbox'
                            className='form-check-input custom-form-check-input sig_input'
                            value='True'
                          />
                          <span className='m_7'></span> Yes
                        </label>
                        <label htmlFor='LocationsRequired_False' className='radio mb-0'>
                          <input
                            id='LocationsRequired_False'
                            onChange={() =>
                              setCustomEvent({
                                ...customEvent,
                                isListOfSitesOrLocationsRequired: false,
                              })
                            }
                            checked={!customEvent?.isListOfSitesOrLocationsRequired}
                            name='LocationsRequired'
                            type='checkbox'
                            className='form-check-input custom-form-check-input sig_input'
                            value='False'
                          />
                          <span className='m_7'></span> Optional
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className='pt-2 mb-2'>
              Select the fields that you want to display on the form for user input.
            </p>
            <p className='pl-35'>
              <strong>Data required:</strong> ‘Optional’ gives the user the option to skip data
              entry in the field. To force the user to enter data in the field, switch the button to
              ‘Yes’.
            </p>
            <div className='table-responsive mb-3 pb-3'>
              <table
                className='table table-bordered table-sm vertical_middle mb-0'
                aria-label='Event Fields'
              >
                <thead className='thead-light'>
                  <tr className='light_orange'>
                    <th className='w-3'>
                      <label htmlFor='standard_field_all' className='checkbox has_nolabel m-auto'>
                        <input
                          type='checkbox'
                          onChange={handleAllDataRequirementFields}
                          checked={
                            customEvent?.hasDateOne &&
                            customEvent?.hasDateTwo &&
                            customEvent?.hasTextField &&
                            customEvent?.hasCurrency &&
                            customEvent?.hasBoolean &&
                            customEvent?.hasNotes
                          }
                          id='standard_field_all'
                          className='form-check-input custom-form-check-input sig_input'
                        />
                        <span className='sr-only'>Field All</span>
                      </label>
                    </th>
                    <th className='w-17'> Field </th>
                    <th className='w-23'> Customize Label </th>
                    <th className='w-15'> Data Required </th>
                    <th className='w-30'> Explanation </th>
                    <th className='w-12'> Example </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='vertically_align_middle'>
                      <label htmlFor='Date1Field' className='checkbox has_nolabel m-auto'>
                        <input
                          className='standard_field form-check-input custom-form-check-input sig_input'
                          onChange={() =>
                            setCustomEvent({ ...customEvent, hasDateOne: !customEvent?.hasDateOne })
                          }
                          checked={customEvent?.hasDateOne}
                          data-val='true'
                          data-val-required='The Date1Field field is required.'
                          id='Date1Field'
                          name='Date1Field'
                          type='checkbox'
                          value='true'
                        />
                        <input name='Date1Field' type='hidden' value='false' />
                        <span className='sr-only'>Field</span>
                      </label>
                    </td>
                    <td className='vertically_align_middle'>
                      <span className='d-flex align-items-center'>
                        <span className='fw-bold'>Date 1</span>
                        {customEvent?.hasDateOne && (
                          <label
                            htmlFor='hasTime'
                            className='checkbox ms-4 standard_field_hastime standard_field_hastimeDate1'
                          >
                            <input
                              data-val='true'
                              onChange={() =>
                                setCustomEvent({
                                  ...customEvent,
                                  hasDateOneEnableTime: !customEvent?.hasDateOneEnableTime,
                                })
                              }
                              checked={customEvent?.hasDateOneEnableTime}
                              data-val-required='The Enable Time field is required.'
                              id='hasTime'
                              name='hasTime'
                              type='checkbox'
                              value='true'
                              className='form-check-input custom-form-check-input sig_input'
                            />
                            <input name='hasTime' type='hidden' value='false' />{' '}
                            <span className='m_2'></span>Enable Time
                          </label>
                        )}
                      </span>
                    </td>
                    <td>
                      {customEvent?.hasDateOne && (
                        <input
                          className='form-control form-control-sm standard_field_label min-w-260'
                          onChange={(e) =>
                            setCustomEvent({
                              ...customEvent,
                              dateOneCustomizeLabel: e.target.value,
                            })
                          }
                          value={customEvent?.dateOneCustomizeLabel}
                          data-val='true'
                          data-val-length='Maximum length is 50.'
                          data-val-length-max='50'
                          id='Date1Label'
                          maxLength={50}
                          name='Date1Label'
                          type='text'
                        />
                      )}
                      <span
                        className='field-validation-valid'
                        data-valmsg-for='Date1Label'
                        data-valmsg-replace='true'
                      ></span>
                    </td>
                    <td>
                      <div className='radio-inline flex-nowrap'>
                        <label htmlFor='Date1Required_True' className='radio mb-0'>
                          <input
                            id='Date1Required_True'
                            onChange={() =>
                              setCustomEvent({ ...customEvent, isDateOneDataRequired: true })
                            }
                            checked={customEvent?.isDateOneDataRequired}
                            name='Date1Required'
                            type='checkbox'
                            className='form-check-input custom-form-check-input sig_input'
                            value='True'
                          />
                          <span className='m_7'></span> Yes
                        </label>
                        <label htmlFor='Date1Required_False' className='radio mb-0'>
                          <input
                            id='Date1Required_False'
                            onChange={() =>
                              setCustomEvent({ ...customEvent, isDateOneDataRequired: false })
                            }
                            checked={!customEvent?.isDateOneDataRequired}
                            name='Date1Required'
                            type='checkbox'
                            className='form-check-input custom-form-check-input sig_input'
                            value='False'
                          />
                          <span className='m_7'></span> Optional
                        </label>
                      </div>
                    </td>
                    <td>Date 1 is generally the day the event occurs.</td>
                    <td>Check out date</td>
                  </tr>
                  <tr>
                    <td className='vertically_align_middle'>
                      <label className='checkbox has_nolabel m-auto' htmlFor='Date2Field'>
                        <input
                          className='standard_field form-check-input custom-form-check-input sig_input'
                          onChange={() =>
                            setCustomEvent({ ...customEvent, hasDateTwo: !customEvent?.hasDateTwo })
                          }
                          checked={customEvent?.hasDateTwo}
                          data-val='true'
                          data-val-required='The Date2Field field is required.'
                          id='Date2Field'
                          name='Date2Field'
                          type='checkbox'
                          value='true'
                        />
                        <input name='Date2Field' type='hidden' value='false' />
                        <span className='sr-only'>Field</span>
                      </label>
                    </td>
                    <td className='vertically_align_middle'>
                      <span className='d-flex align-items-center'>
                        <span className='fw-bold'>Date 2</span>
                        {customEvent?.hasDateTwo && (
                          <label
                            className='checkbox ms-4 standard_field_hastime standard_field_hastimeDate2'
                            htmlFor='hasTime2'
                          >
                            <input
                              type='checkbox'
                              className='form-check-input custom-form-check-input sig_input'
                              onChange={() =>
                                setCustomEvent({
                                  ...customEvent,
                                  hasDateTwoEnableTime: !customEvent?.hasDateTwoEnableTime,
                                })
                              }
                              checked={customEvent?.hasDateTwoEnableTime}
                              name='hasTime'
                              id='hasTime2'
                            />
                            <span className='m_7'></span>Enable Time
                          </label>
                        )}
                      </span>
                    </td>
                    <td>
                      {customEvent?.hasDateTwo && (
                        <input
                          className='form-control form-control-sm standard_field_label min-w-260'
                          onChange={(e) =>
                            setCustomEvent({
                              ...customEvent,
                              dateTwoCustomizeLabel: e.target.value,
                            })
                          }
                          value={customEvent?.dateTwoCustomizeLabel}
                          data-val='true'
                          data-val-length='Maximum length is 50.'
                          data-val-length-max='50'
                          id='Date2Label'
                          maxLength={50}
                          name='Date2Label'
                          type='text'
                        />
                      )}
                      <span
                        className='field-validation-valid'
                        data-valmsg-for='Date2Label'
                        data-valmsg-replace='true'
                      ></span>
                    </td>
                    <td>
                      <div className='radio-inline flex-nowrap'>
                        <label className='radio mb-0' htmlFor='Date2Required_True'>
                          <input
                            id='Date2Required_True'
                            onChange={() =>
                              setCustomEvent({ ...customEvent, isDateTwoDataRequired: true })
                            }
                            checked={customEvent?.isDateTwoDataRequired}
                            name='Date2Required'
                            type='checkbox'
                            className='form-check-input custom-form-check-input sig_input'
                            value='True'
                          />
                          <span className='m_7'></span> Yes
                        </label>
                        <label className='radio mb-0' htmlFor='Date2Required_False'>
                          <input
                            id='Date2Required_False'
                            onChange={() =>
                              setCustomEvent({ ...customEvent, isDateTwoDataRequired: false })
                            }
                            checked={!customEvent?.isDateTwoDataRequired}
                            name='Date2Required'
                            type='checkbox'
                            className='form-check-input custom-form-check-input sig_input'
                            value='False'
                          />
                          <span className='m_7'></span> Optional
                        </label>
                      </div>
                    </td>
                    <td>Use Date 2 if you expect the user to return the assets by the due date.</td>
                    <td>Due date</td>
                  </tr>
                  <tr>
                    <td className='vertically_align_middle'>
                      <label className='checkbox has_nolabel m-auto' htmlFor='TextField'>
                        <input
                          className='standard_field form-check-input custom-form-check-input sig_input'
                          onChange={() =>
                            setCustomEvent({
                              ...customEvent,
                              hasTextField: !customEvent?.hasTextField,
                            })
                          }
                          checked={customEvent?.hasTextField}
                          data-val='true'
                          data-val-required='The TextField field is required.'
                          id='TextField'
                          name='TextField'
                          type='checkbox'
                          value='true'
                        />
                        <input name='TextField' type='hidden' value='false' />

                        <span className='sr-only'>Field</span>
                      </label>
                    </td>
                    <td className='vertically_align_middle'>
                      <span className='fw-bold'>Text field</span>
                    </td>
                    <td>
                      {customEvent?.hasTextField && (
                        <input
                          className='form-control form-control-sm standard_field_label min-w-260'
                          onChange={(e) => setCustomEvent({ ...customEvent, text: e.target.value })}
                          value={customEvent?.text}
                          data-val='true'
                          data-val-length='Maximum length is 50.'
                          data-val-length-max='50'
                          id='TextLabel'
                          maxLength={50}
                          name='TextLabel'
                          type='text'
                        />
                      )}
                      <span
                        className='field-validation-valid'
                        data-valmsg-for='TextLabel'
                        data-valmsg-replace='true'
                      ></span>
                    </td>
                    <td>
                      <div className='radio-inline flex-nowrap'>
                        <label className='radio mb-0' htmlFor='TextRequired_True'>
                          <input
                            id='TextRequired_True'
                            onChange={() =>
                              setCustomEvent({ ...customEvent, isTextFieldDataRequired: true })
                            }
                            checked={customEvent?.isTextFieldDataRequired}
                            name='TextRequired'
                            type='checkbox'
                            className='form-check-input custom-form-check-input sig_input'
                            value='True'
                          />
                          <span className='m_7'></span> Yes
                        </label>
                        <label className='radio mb-0' htmlFor='TextRequired_False'>
                          <input
                            id='TextRequired_False'
                            onChange={() =>
                              setCustomEvent({ ...customEvent, isTextFieldDataRequired: false })
                            }
                            checked={!customEvent?.isTextFieldDataRequired}
                            name='TextRequired'
                            type='checkbox'
                            className='form-check-input custom-form-check-input sig_input'
                            value='False'
                          />
                          <span className='m_7'></span> Optional
                        </label>
                      </div>
                    </td>
                    <td>The text field can hold text up to 100 characters long.</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className='vertically_align_middle'>
                      <label className='checkbox has_nolabel m-auto' htmlFor='CurrencyField'>
                        <input
                          className='standard_field form-check-input custom-form-check-input sig_input'
                          onChange={() =>
                            setCustomEvent({
                              ...customEvent,
                              hasCurrency: !customEvent?.hasCurrency,
                            })
                          }
                          checked={customEvent?.hasCurrency}
                          data-val='true'
                          data-val-required='The CurrencyField field is required.'
                          id='CurrencyField'
                          name='CurrencyField'
                          type='checkbox'
                          value='true'
                        />
                        <input name='CurrencyField' type='hidden' value='false' />

                        <span className='sr-only'>Field</span>
                      </label>
                    </td>
                    <td className='vertically_align_middle'>
                      <span className='fw-bold'>Currency</span>
                    </td>
                    <td>
                      {customEvent?.hasCurrency && (
                        <input
                          className='form-control form-control-sm standard_field_label min-w-260'
                          onChange={(e) =>
                            setCustomEvent({ ...customEvent, amount: e.target.value })
                          }
                          value={customEvent?.amount}
                          data-val='true'
                          data-val-length='Maximum length is 50.'
                          data-val-length-max='50'
                          id='CurrencyLabel'
                          maxLength={50}
                          name='CurrencyLabel'
                          type='text'
                        />
                      )}
                      <span
                        className='field-validation-valid'
                        data-valmsg-for='CurrencyLabel'
                        data-valmsg-replace='true'
                      ></span>
                    </td>
                    <td>
                      <div className='radio-inline flex-nowrap'>
                        <label className='radio mb-0' htmlFor='CurrencyRequired_True'>
                          <input
                            id='CurrencyRequired_True'
                            onChange={() =>
                              setCustomEvent({ ...customEvent, isCurrencyDataRequired: true })
                            }
                            checked={customEvent?.isCurrencyDataRequired}
                            name='CurrencyRequired'
                            type='checkbox'
                            className='form-check-input custom-form-check-input sig_input'
                            value='True'
                          />
                          <span className='m_7'></span> Yes
                        </label>
                        <label className='radio mb-0' htmlFor='CurrencyRequired_False'>
                          <input
                            id='CurrencyRequired_False'
                            onChange={() =>
                              setCustomEvent({ ...customEvent, isCurrencyDataRequired: false })
                            }
                            checked={!customEvent?.isCurrencyDataRequired}
                            name='CurrencyRequired'
                            type='checkbox'
                            className='form-check-input custom-form-check-input sig_input'
                            value='False'
                          />
                          <span className='m_7'></span> Optional
                        </label>
                      </div>
                    </td>
                    <td>
                      Use the field to enter an amount. The currency symbol will be as defined in
                      the company setup.
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className='vertically_align_middle'>
                      <label className='checkbox has_nolabel m-auto' htmlFor='BooleanField'>
                        <input
                          className='standard_field form-check-input custom-form-check-input sig_input'
                          onChange={() =>
                            setCustomEvent({ ...customEvent, hasBoolean: !customEvent?.hasBoolean })
                          }
                          checked={customEvent?.hasBoolean}
                          data-val='true'
                          data-val-required='The BooleanField field is required.'
                          id='BooleanField'
                          name='BooleanField'
                          type='checkbox'
                          value='true'
                        />
                        <input name='BooleanField' type='hidden' value='false' />

                        <span className='sr-only'>Field</span>
                      </label>
                    </td>
                    <td className='vertically_align_middle'>
                      <span className='fw-bold'>Boolean</span>
                    </td>
                    <td>
                      {customEvent?.hasBoolean && (
                        <input
                          className='form-control form-control-sm standard_field_label min-w-260'
                          onChange={(e) =>
                            setCustomEvent({ ...customEvent, booleanText: e.target.value })
                          }
                          value={customEvent?.booleanText}
                          data-val='true'
                          data-val-length='Maximum length is 50.'
                          data-val-length-max='50'
                          id='BooleanLabel'
                          maxLength={50}
                          name='BooleanLabel'
                          type='text'
                        />
                      )}
                      <span
                        className='field-validation-valid'
                        data-valmsg-for='BooleanLabel'
                        data-valmsg-replace='true'
                      ></span>
                    </td>
                    <td></td>
                    <td>A bit field that can hold a true/false, yes/no value.</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className='vertically_align_middle'>
                      <label className='checkbox has_nolabel m-auto' htmlFor='NotesField'>
                        <input
                          className='standard_field form-check-input custom-form-check-input sig_input'
                          onChange={() =>
                            setCustomEvent({ ...customEvent, hasNotes: !customEvent?.hasNotes })
                          }
                          checked={customEvent?.hasNotes}
                          data-val='true'
                          data-val-required='The NotesField field is required.'
                          id='NotesField'
                          name='NotesField'
                          type='checkbox'
                          value='true'
                        />
                        <input name='NotesField' type='hidden' value='false' />

                        <span className='sr-only'>Field</span>
                      </label>
                    </td>
                    <td className='vertically_align_middle'>
                      <span className='fw-bold'>Notes</span>
                    </td>
                    <td>
                      {customEvent?.hasNotes && (
                        <input
                          className='form-control form-control-sm standard_field_label min-w-260'
                          onChange={(e) =>
                            setCustomEvent({ ...customEvent, notesText: e.target.value })
                          }
                          value={customEvent?.notesText}
                          data-val='true'
                          data-val-length='Maximum length is 50.'
                          data-val-length-max='50'
                          id='NotesLabel'
                          maxLength={50}
                          name='NotesLabel'
                          type='text'
                        />
                      )}
                      <span
                        className='field-validation-valid'
                        data-valmsg-for='NotesLabel'
                        data-valmsg-replace='true'
                      ></span>
                    </td>
                    <td>
                      <div className='radio-inline flex-nowrap vertically_align_middle'>
                        <label className='radio mb-0' htmlFor='NotesRequired_True'>
                          <input
                            id='NotesRequired_True'
                            onChange={() =>
                              setCustomEvent({ ...customEvent, isNotesDataRequired: true })
                            }
                            checked={customEvent?.isNotesDataRequired}
                            name='NotesRequired'
                            type='checkbox'
                            className='form-check-input custom-form-check-input sig_input'
                            value='True'
                          />
                          <span className='m_7'></span> Yes
                        </label>
                        <label className='radio mb-0' htmlFor='NotesRequired_False'>
                          <input
                            id='NotesRequired_False'
                            onChange={() =>
                              setCustomEvent({ ...customEvent, isNotesDataRequired: false })
                            }
                            checked={!customEvent?.isNotesDataRequired}
                            name='NotesRequired'
                            type='checkbox'
                            className='form-check-input custom-form-check-input sig_input'
                            value='False'
                          />
                          <span className='m_7'></span> Optional
                        </label>
                      </div>
                    </td>
                    <td>
                      A text area for the user to enter notes up to 1000 characters in length.
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='form_section'>
            <div className='separator separator-solid mb-6'></div>
            <div className='form-title mb-4'>
              <span className='add_custom_events'>
                <strong>Signature</strong>
              </span>
            </div>
            <p>Do you want to add a signature pad for the user to sign?</p>
            <div className='form-group mb-3'>
              <div className='checkbox-inline'>
                <label className='checkbox' htmlFor='Signature'>
                  <input
                    className='form-check-input custom-form-check-input sig_input'
                    data-val='true'
                    data-val-required='The Signature field is required.'
                    id='Signature'
                    name='Signature'
                    type='checkbox'
                    onChange={() =>
                      setCustomEvent({
                        ...customEvent,
                        hasSignaturePad: !customEvent?.hasSignaturePad,
                      })
                    }
                    checked={customEvent?.hasSignaturePad}
                    value='true'
                  />
                  <input name='Signature' type='hidden' value='false' />
                  <span className='m_2'></span> Show Signature Pad
                </label>
                {customEvent?.hasSignaturePad && (
                  <label
                    className='checkbox CheckoutSignatureEnableBlock m_12'
                    htmlFor='SignatureRequired'
                  >
                    <input
                      data-val='true'
                      data-val-required='The SignatureRequired field is required.'
                      id='SignatureRequired'
                      name='SignatureRequired'
                      type='checkbox'
                      onChange={() =>
                        setCustomEvent({
                          ...customEvent,
                          signatureRequired: !customEvent?.signatureRequired,
                        })
                      }
                      checked={customEvent?.signatureRequired}
                      value='true'
                      className='form-check-input custom-form-check-input sig_input'
                    />
                    <input name='SignatureRequired' type='hidden' value='false' />
                    <span className='m_2'></span> Make signature a required field (force person to
                    sign)
                  </label>
                )}
                <span
                  className='field-validation-valid'
                  data-valmsg-for='Signature'
                  data-valmsg-replace='true'
                ></span>
                <span
                  className='field-validation-valid'
                  data-valmsg-for='SignatureRequired'
                  data-valmsg-replace='true'
                ></span>
              </div>
            </div>
            {customEvent?.hasSignaturePad && (
              <div className='form-group mb-4 CheckoutSignatureEnableBlock'>
                <label htmlFor='SignatureDisclaimer mb-3'>Signature Disclaimer</label>
                <textarea
                  className='form-control max-w-750'
                  onChange={(e) =>
                    setCustomEvent({ ...customEvent, signatureDisclaimer: e.target.value })
                  }
                  value={customEvent?.signatureDisclaimer}
                  cols={20}
                  data-val='true'
                  data-val-length='Maximum length is 1000.'
                  data-val-length-max='1000'
                  id='SignatureDisclaimer'
                  maxLength={1000}
                  name='SignatureDisclaimer'
                  rows={3}
                ></textarea>
              </div>
            )}
          </div>
          <div className='form_section'>
            <div className='separator separator-solid mb-6'></div>
            <div className='form-title mb-4'>
              <span className='add_custom_events'>
                <strong className='mb-3 d-block'>Return Event</strong>
              </span>
            </div>
            <p>
              Events for which the user is expected to ‘return’ the asset, are considered ‘Return
              Events’. Two events — a primary event and a return event — are required for this.
              Check out - Check in, Lease - Lease Return, are a few examples of primary and return
              event combinations offered by Asset Rack under standard events. The primary events
              generally use the ‘Date2’ field as ‘Due date’ when the user is expected to ‘return’
              the assets using ‘return event’.
            </p>
            <p>
              This might be demonstrated with a primary event as "Loan asset" and the return event
              "Loan asset return." All custom events are ‘primary’ events. Before you can specify an
              event's "Return" event, you must first construct the primary event. Create a second
              event and check the box "Set as return event" after you have set your primary event.
              From the dropdown list, choose the primary event for this return event.
            </p>
            <div className='form-group row mb-4 align-items-center'>
              <label className='col-sm-4 col-md-3 col-form-label-sm mb-0 text-sm-end'>
                Set as return event
              </label>
              <div className='col-sm-2 col-md-2'>
                <div className='radio-inline'>
                  <label className='radio mb-0' htmlFor='isReturningEvent_false'>
                    <input
                      type='checkbox'
                      className='form-check-input custom-form-check-input sig_input'
                      name='isReturningEvent'
                      onChange={() => setCustomEvent({ ...customEvent, returnEvent: false })}
                      checked={!customEvent?.returnEvent}
                      value='False'
                      id='isReturningEvent_false'
                    />{' '}
                    <span className='m_7'></span>No
                  </label>
                  <label className='radio mb-0' htmlFor='isReturningEvent_true'>
                    <input
                      type='checkbox'
                      className='form-check-input custom-form-check-input sig_input'
                      name='isReturningEvent'
                      onChange={() => setCustomEvent({ ...customEvent, returnEvent: true })}
                      checked={customEvent?.returnEvent}
                      value='True'
                      id='isReturningEvent_true'
                    />{' '}
                    <span className='m_7'></span>Yes
                  </label>
                </div>
              </div>
            </div>
            {customEvent?.returnEvent && (
              <div className='form-group row mb-4 align-items-center divPreCustomEventId'>
                <label className='col-sm-4 col-md-3 col-form-label-sm mb-0 text-sm-end'>
                  Select Primary event
                </label>
                <div className='col-sm-3 col-md-3'>
                  <select
                    className='form-select'
                    onChange={(e) =>
                      setCustomEvent({ ...customEvent, primaryEventId: Number(e.target.value) })
                    }
                    value={customEvent?.primaryEventId?.toString() || ''}
                    id='PreCustomEventId'
                    name='PreCustomEventId'
                    data-val='true'
                    data-val-number='Primary event is required.'
                    data-val-required='Primary event is required.'
                  >
                    <option>Select</option>
                    {events.map((event) => (
                      <option key={event?.id} value={event?.id?.toString()}>
                        {event?.name}
                      </option>
                    ))}
                  </select>
                  <span
                    className='field-validation-valid'
                    data-valmsg-for='PreCustomEventId'
                    data-valmsg-replace='true'
                  ></span>
                </div>
              </div>
            )}
          </div>
          <div className='form_section'>
            <div className='separator separator-solid mb-6'></div>
            <div className='form-group mb-4'>
              <div className='checkbox-inline'>
                <label className='checkbox' htmlFor='isEnable'>
                  <input
                    onChange={() =>
                      setCustomEvent({ ...customEvent, enableEvent: !customEvent?.enableEvent })
                    }
                    checked={customEvent?.enableEvent}
                    data-val='true'
                    data-val-required='The isEnable field is required.'
                    id='isEnable'
                    name='isEnable'
                    type='checkbox'
                    value='true'
                    className='form-check-input custom-form-check-input sig_input'
                  />
                  <input name='isEnable' type='hidden' value='false' />
                  Enable this Event?
                </label>
                <span
                  className='field-validation-valid'
                  data-valmsg-for='isEnable'
                  data-valmsg-replace='true'
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div className='card-footer'>
          <div className='row'>
            <div className='col-md-12 text-end'>
              <Link to='/setupevents' className='btn btn-light-primary   main-btn-style me-2'>
                Cancel
              </Link>
              <button
                type='submit'
                onClick={(e) => submitCustomEvent(e)}
                className='btn btn-primary'
              >
                {isAdding || isUpdating ? (
                  <span className='indicator-progress d-block'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddCustomEvents
