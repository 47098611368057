import { useAuth } from 'App/Modules/Auth'
import { dateFormat, defaultDateFormat } from 'config'
import Modal from 'react-bootstrap/Modal'

interface ViewModalProps {
  maintenanceAssetViewModal: boolean
  handleClose: () => any
  assetMaintenance: any
  currencyLogo: string
}

const MaintenanceAssetViewModal: React.FC<ViewModalProps> = ({
  maintenanceAssetViewModal,
  handleClose,
  assetMaintenance,
  currencyLogo,
}) => {
  const { currentUser } = useAuth()
  return (
    <Modal show={maintenanceAssetViewModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Asset Maintenance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='assetProduct_detail mb-5'>
          <div className='asset_detail_table table-responsive'>
            <table
              className='table table-bordered detail-table table-sm mb-0'
              aria-label='Quick View'
              role='presentation'
            >
              <tbody>
                <tr>
                  <td className='label_col bg-light'>Title</td>
                  <td className='fw-bold detail_col table-elipses'>{assetMaintenance?.title}</td>
                </tr>
                <tr>
                  <td className='label_col bg-light'>Details</td>
                  <td className='fw-bold detail_col table-elipses'>{assetMaintenance?.details}</td>
                </tr>
                <tr>
                  <td className='label_col bg-light'>Due Date</td>
                  <td className='fw-bold detail_col table-elipses'>
                    {assetMaintenance?.dueDate
                      ? dateFormat(
                          assetMaintenance?.dueDate,
                          currentUser?.dateFormat || defaultDateFormat
                        )
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td className='label_col bg-light'>Maintenance By</td>
                  <td className='fw-bold detail_col table-elipses'>
                    {assetMaintenance?.maintenanceBy}
                  </td>
                </tr>
                <tr>
                  <td className='label_col bg-light'>Maintenance Status</td>
                  <td className='fw-bold detail_col table-elipses'>{assetMaintenance?.status}</td>
                </tr>
                <tr>
                  <td className='label_col bg-light'>Date completed</td>
                  <td className='fw-bold detail_col table-elipses'>
                    {assetMaintenance?.dateCompleted
                      ? dateFormat(
                          assetMaintenance?.dateCompleted,
                          currentUser?.dateFormat || defaultDateFormat
                        )
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td className='label_col bg-light'>Maintenance Cost</td>
                  <td className='fw-bold detail_col table-elipses'>
                    {assetMaintenance?.cost !== null
                      ? `${currencyLogo} ${assetMaintenance?.cost}`
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td className='label_col bg-light'>Frequency</td>
                  <td className='fw-bold detail_col table-elipses'>
                    {assetMaintenance?.frequency}
                  </td>
                </tr>
                <tr>
                  <td className='label_col bg-light'>Recur on every</td>
                  <td className='fw-bold detail_col table-elipses'>
                    <span>{`${assetMaintenance?.recurOnEvery || ''} ${assetMaintenance?.frequency || ''}`}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default MaintenanceAssetViewModal
