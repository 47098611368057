// AssetTagPrint.tsx
import React, { useRef } from 'react'
import QRCode from 'qrcode'
import { baseURL } from 'config'
import { useGetSingleAssetQuery } from 'App/Modules/Services/Assets'
import { useLocation } from 'react-router'

interface Asset {
  asset: {
    id: string
    assetTagId: string
    model: string
    ram: string
    serialNo: string
  }
}

interface AssetTagPrintProps {
  assetId
}

const AssetTagPrint: React.FC<AssetTagPrintProps> = ({ assetId }) => {
  const qrCodeRef = useRef<HTMLCanvasElement>(null)
  const location = useLocation()
  const { pathname } = location

  const { data: assetData, isLoading } = useGetSingleAssetQuery({ assetId }, { skip: !assetId })

  const handleGenerateTag = async () => {
    if (!assetId) {
      throw new Error('Asset ID is missing')
    }

    const canvas = qrCodeRef.current
    if (!canvas) {
      throw new Error('Canvas ref is not set')
    }

    const url = `${baseURL}/asset/view/${assetId}`

    QRCode.toCanvas(canvas, url, (error) => {
      if (error) {
        throw new Error(error)
      }
      const qrCodeDataUrl = canvas.toDataURL()

      const printContent = `
        <html>
  <head>
    <style>
      body { 
        font-family: Arial, sans-serif; 
        margin: 0; 
      }
      .container { 
        display: flex; 
        justify-content: space-between; 
        align-items: center; 
        width: fit-content; 
        margin: auto; 
      }
      .text-content { 
        text-align: left; 
      }
      .qr-code { 
        text-align: right; 
      }
      p {
        font-size: 20px;
        gap:5px;
        display: flex;
        flex-wrap: wrap;
        word-break: break-word;
      }
      @media print {
        @page { 
          margin: 0; 
        }
        body { 
          margin:0px 10px; 
        }
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="text-content">
        <p><strong>TAG: </strong> ${assetData?.asset.assetTagId || 'None'}</p>
        <p><strong>Model:</strong> ${assetData?.asset.model || 'None'}</p>
        <p><strong>RAM:</strong> ${assetData?.asset.ram || 'None'}</p>
        <p><strong>Serial No:</strong> ${assetData?.asset.serialNo || 'None'}</p>
      </div>
      <div class="qr-code">
        <img src="${qrCodeDataUrl}" alt="QR Code" />
      </div>
    </div>
  </body>
</html>
      `
      // Create a hidden iframe
      const iframe = document.createElement('iframe')
      iframe.style.position = 'absolute'
      iframe.style.width = '0'
      iframe.style.height = '0'
      iframe.style.border = 'none'
      document.body.appendChild(iframe)

      const doc = iframe.contentDocument || iframe.contentWindow?.document
      if (doc) {
        doc.open()
        doc.write(printContent)
        doc.close()

        iframe.onload = () => {
          iframe.contentWindow?.focus()
          iframe.contentWindow?.print()

          // Clean up iframe after printing
          setTimeout(() => {
            document.body.removeChild(iframe)
          }, 1000)
        }
      } else {
        throw new Error('Failed to access iframe document')
      }
    })
  }
  return (
    <div>
      <button
        className={`btn ${pathname === `/asset/view/${assetId}` ? 'btn-primary' : 'btn-light-primary'}  me-2`}
        onClick={handleGenerateTag}
      >
        {pathname === `/asset/view/${assetId}` ? (
          'Print'
        ) : (
          <>
            <i className='la la-print me-1 fs-4'></i>
            Print Tag
          </>
        )}
      </button>
      <canvas ref={qrCodeRef} style={{ display: 'none' }} />
    </div>
  )
}

export default AssetTagPrint
