export const toAbsoluteUrlFile = (pathname: string) => {
  let filePath = ''
  switch (pathname) {
    case 'assets':
      filePath = 'templates/ImportAssetTemplate.xlsx'
      break
    case 'categories':
      filePath = 'templates/ImportCategoriesTemplate.xlsx'
      break
    case 'customers':
      filePath = 'templates/ImportCustomersTemplate.xlsx'
      break
    case 'departments':
      filePath = 'templates/ImportDepartmentsTemplate.xlsx'
      break
    case 'locations':
      filePath = 'templates/ImportLocationsTemplate.xlsx'
      break
    case 'maintenances':
      filePath = 'templates/ImportMaintenancesTemplate.xlsx'
      break
    case 'person':
      filePath = 'templates/ImportPersonsTemplate.xlsx'
      break
    case 'sites':
      filePath = 'templates/ImportSitesTemplate.xlsx'
      break
    case 'warranties':
      filePath = 'templates/ImportWarrantiesTemplate.xlsx'
      break
    case 'fixedLimit':
      filePath = 'templates/FieldLimits.xlsx'
      break
    default:
      filePath = ''
      break
  }

  if (filePath) {
    return `${process.env.REACT_APP_ASSET_URL}${filePath}`
  } else {
    return ''
  }
}
