import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const setupApi = createApi({
  reducerPath: 'setupApi',
  baseQuery: customFetchBaseQuery({ path: 'setup-columns' }),
  tagTypes: ['SetupColumns', 'SetupColumns'],
  endpoints: (build) => ({
    getSetupColumns: build.query({
      query: (types) => ({
        url: 'get',
        method: 'GET',
        params: { setupColumnsTypes: types.join(',') },
      }),
      providesTags: ['SetupColumns'],
    }),
    updateSetupColumns: build.mutation({
      query: ({ setupColumnsTypes, fields }) => ({
        url: 'update',
        method: 'PUT',
        body: { setupColumnsTypes, fields },
      }),
      invalidatesTags: ['SetupColumns'],
    }),
  }),
})

export const { useGetSetupColumnsQuery, useUpdateSetupColumnsMutation } = setupApi
