import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const assetActionsApi = createApi({
  reducerPath: 'assetActionsApi',
  baseQuery: customFetchBaseQuery({ path: 'asset-action' }),
  tagTypes: ['AssetActions'],
  endpoints: (build) => ({
    lostAsset: build.mutation<any, any>({
      query: (body) => ({
        url: 'lost',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'AssetActions' }],
    }),
    foundAsset: build.mutation<any, any>({
      query: (body) => ({
        url: 'found',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'AssetActions' }],
    }),
    sellAsset: build.mutation<any, any>({
      query: (body) => ({
        url: 'sell',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'AssetActions' }],
    }),
    repairAsset: build.mutation<any, any>({
      query: (body) => ({
        url: 'repair',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'AssetActions' }],
    }),
    brokenAsset: build.mutation<any, any>({
      query: (body) => ({
        url: 'broken',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'AssetActions' }],
    }),
    donateAsset: build.mutation<any, any>({
      query: (body) => ({
        url: 'donate',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'AssetActions' }],
    }),
    undoAction: build.mutation<any, any>({
      query: (body) => ({
        url: 'undo-action',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'AssetActions' }],
    }),
  }),
})

export const {
  useLostAssetMutation,
  useFoundAssetMutation,
  useSellAssetMutation,
  useRepairAssetMutation,
  useBrokenAssetMutation,
  useDonateAssetMutation,
  useUndoActionMutation,
} = assetActionsApi
