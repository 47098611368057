import { toAbsoluteUrlImage } from 'Template/helpers'
import { Images } from 'Template/assets/Assets'
import { imageMimeType } from 'config'
import { MoonLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { useDeleteProfileImageMutation } from 'App/Modules/Services/Profile'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { checkUserUnauthorized, useAuth } from '../../../../Auth'

const Profile = ({ formik, image }) => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const [deleteProfileImage] = useDeleteProfileImageMutation()
  const [loadingAvatar, setLoadingAvatar] = useState(false)
  const [fileDataURL, setFileDataURL] = useState<string | ArrayBuffer | null | undefined>(null)
  const [imageErr, setImageErr] = useState(false)
  const userType = currentUser?.userType ?? 0

  useEffect(() => {
    setImageErr(false)
  }, [])

  const changeHandler = (e) => {
    const file = e.target.files[0]
    if (file) {
      if (file && !file.type.match(imageMimeType)) {
        toast.error('This file format is not allowed!')
        return
      } else {
        const reader = new FileReader()
        reader.onload = (event) => {
          setFileDataURL(event?.target?.result)
        }
        reader.readAsDataURL(file)
      }
    }
  }

  const deleteImage = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e?.preventDefault()
    setLoadingAvatar(true)
    try {
      const data = await deleteProfileImage('').unwrap()
      if (data?.success) {
        setFileDataURL(null)
        toast.success(data?.message)
      }
    } catch (error: any) {
      if (!error?.data?.success) {
        toast.error(error?.data?.message)
      }
      checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
    } finally {
      setLoadingAvatar(false)
    }
  }

  return (
    <div className='card-body border-top p-lg-9 p-md-7 p-6'>
      <h3 className='card-title mb-4'>
        <span className='me-2 align-middle'>
          <i className='las la-user fs-1' aria-hidden='true'></i>
        </span>
        <span className='card-label fw-bold fs-3 mb-1'>User</span>
      </h3>
      <div className='ms-md-9'>
        <Row className="mb-6">
          <Col lg="3">
            <label htmlFor='avatar' className='col-form-label fw-bold fs-6'>
              Avatar
            </label>
          </Col>
          <Col lg="9">
            <div className='fv-row'>
              {loadingAvatar ? (
                <MoonLoader color='#0d145c' loading={loadingAvatar} />
              ) : (
                <div className='image-input image-input-outline' data-kt-image-input='true'>
                  <label htmlFor='photo' className='cursor-pointer position-relative'>
                    <img
                      className='image-input-wrapper w-125px h-125px'
                      src={
                        fileDataURL
                          ? fileDataURL.toString()
                          : image
                            ? toAbsoluteUrlImage(image)
                            : Images.Avatar_JPG
                      }
                      alt='profile'
                      crossOrigin='anonymous'
                      onError={(e) => {
                        e.currentTarget.src = Images.Avatar
                        setImageErr(true)
                      }}
                    />
                    <span className='edit-icon'>
                      <i className='las la-camera fs-2'></i>
                    </span>
                    {image ? (
                      <span onClick={(e) => deleteImage(e)} className='delete-icon'>
                        <i className='las la-trash-alt fs-2'></i>
                      </span>
                    ) : null}
                  </label>
                  <input
                    className='form-control d-none'
                    type='file'
                    name='image'
                    id='photo'
                    accept='.png, .jpg, .jpeg'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      changeHandler(e)
                      formik.setFieldValue(
                        'image',
                        e?.target?.files && e?.target?.files[0]?.type?.match(imageMimeType)
                          ? e.target.files![0]
                          : image
                      )
                    }}
                  />
                  <>
                    {imageErr && (
                      <div className='text-danger py-2'>
                        Something went wrong while uploading image
                      </div>
                    )}
                  </>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className='mb-6'>
          <Col lg="3">
            <label htmlFor='fullName' className='col-form-label required fw-bold fs-6'>
              Full Name
            </label>
          </Col>
          <Col lg="9">
            <div className='fv-row'>
              <Row>
                <Col sm="6">
                  <div className='fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...formik.getFieldProps('firstName')}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.firstName}</div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm="6">
                  <div className='fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Last name'
                      {...formik.getFieldProps('lastName')}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.lastName}</div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {userType !== 4 ? (
          <Row className='mb-6'>
            <Col lg="3">
              <label htmlFor='title' className='col-form-label fw-bold fs-6'>
                Title
              </label>
            </Col>
            <Col lg="9">
              <div className='fv-row'>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Title'
                  {...formik.getFieldProps('title')}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        ) : null}
        <Row className='mb-6'>
          <Col lg="3">
            <label htmlFor='phone' className='col-form-label fw-bold fs-6'>
              <span>Phone</span>
            </label>
          </Col>
          <Col lg="9">
            <div className='fv-row'>
              <input
                type='tel'
                className='form-control form-control-solid'
                placeholder='Phone number'
                {...formik.getFieldProps('phone')}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className='mb-6'>
          <Col lg="3">
            <label htmlFor='email' className='col-form-label fw-bold fs-6'>
              <span className='required'>Email</span>
            </label>
          </Col>
          <Col lg="9">
            <div className='fv-row'>
              <input
                type='tel'
                className='form-control form-control-solid'
                placeholder='Email Address'
                {...formik.getFieldProps('email')}
                disabled
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Profile
