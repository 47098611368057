import { useState, useRef } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../Core/Requests'
import { toast } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'
import * as Yup from 'yup'

const initialValues = {
  email: '',
  reCaptcha: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required')
    .max(50, 'Email should not exceed 50 characters'),
  reCaptcha: Yup.string().required('ReCaptcha is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [captchaLoaded, setCaptchaLoaded] = useState(false)
  const recaptchaRef = useRef<any>(null)

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      try {
        const { data } = await requestPassword(values.email)
        toast.success(data?.message)
        setLoading(false)
        resetForm()
        resetRecaptcha()
      } catch (error: any) {
        toast.dismiss()
        if (!error?.response?.data?.success) {
          toast.error(error?.response?.data?.message)
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const handleCaptchaChange = (response: string | null): void => {
    if (response) {
      formik.setFieldValue('reCaptcha', response || '')
    }
  }

  const handleCaptchaLoad = () => {
    setCaptchaLoaded(true)
  }

  const resetRecaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset()
    }
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Forgot Password</h1>
        <div className='text-gray-700 fs-5'>Enter your email to reset your password.</div>
      </div>
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-gray-900 fs-6 required'>Email</label>
        <div>
          <input
            type='email'
            placeholder='Email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
        </div>
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-sm-7 mb-4 d-flex flex-column justify-content-center align-items-center'>
        <div className='site-recaptcha'>
          <div className='captcha'>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
              onChange={handleCaptchaChange}
              onExpired={() => formik.setFieldValue('reCaptcha', '')}
              onErrored={() => formik.setFieldValue('reCaptcha', '')}
              asyncScriptOnLoad={handleCaptchaLoad}
            />
            {!captchaLoaded ? <> Captcha Loading...</> : <></>}
          </div>
        </div>
        {formik.touched.reCaptcha && formik.errors.reCaptcha && (
          <div className='fv-plugins-message-container site-recaptcha-message'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.reCaptcha}</span>
            </div>
          </div>
        )}
      </div>
      <div>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-lg btn-primary w-100 mb-sm-4 mb-3'
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login' className='btn btn-lg btn-light-primary main-btn-style w-100'>
          Cancel
        </Link>{' '}
      </div>
    </form>
  )
}
