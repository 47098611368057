import { lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import './Template/assets/sass/Style.scss'
import './Template/assets/sass/Plugins.scss'
import './Template/assets/sass/StyleReact.scss'
import { AppRoutes } from './App/Routing/AppRoutes'
import { AuthProvider, setupAxios } from './App/Modules/Auth'
import { ThemeModeProvider } from './Template/partials/layout/theme-mode/ThemeModeProvider'
import { store } from './App/Store'
import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import 'nouislider/dist/nouislider.css'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'
import '../src/CustomStyles.css'

setupAxios(axios)
Chart.register(...registerables)
const ToastContainer = lazy(async () => {
  return import('react-toastify').then((module) => ({ default: module.ToastContainer }))
})
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <ThemeModeProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </ThemeModeProvider>
      <Suspense>
        <ToastContainer />
      </Suspense>
    </Provider>
  )
}
