import { useState, useEffect } from 'react'
import {
  useAddAssetMutation,
  useUpdateAssetMutation,
  useGetSingleAssetQuery,
} from '../../../../Services/Assets'
import { useGetAllSitesQuery } from '../../../../Services/Sites'
import { useGetAllCategoriesQuery } from '../../../../Services/Categories'
import { useGetAllLocationsBySiteQuery } from '../../../../Services/Locations'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import { useGetAllDepartmentsQuery } from '../../../../Services/Departments'
import { checkUserUnauthorized, useAuth } from '../../../../Auth'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { initialAssets, Asset as AssetLayout } from '../Core/Model'
import { useNavigate, useParams } from 'react-router-dom'
import { PageTitle } from 'Template/layout/core'
import { toAbsoluteUrlImage } from '../../../../../../Template/helpers'
import { Images } from 'Template/assets/Assets'
import { Link } from 'react-router-dom'
import { costRegex, digitsRegex, imageMimeType, multiSpace } from 'config'
import Loader from 'App/Loaders/BeatLoader'
import SplashScreen from 'App/Loaders/SplashScreen'
import getSymbolFromCurrency from 'currency-symbol-map'
import SitesModal from '../../Sites/Components/SitesModal'
import LocationModal from '../../Locations/Components/LocationModal'
import CategoryModel from '../../Categories/Components/CategoryModel'
import DepartmentsModel from '../../Departments/Components/DepartmentsModel'
import * as Yup from 'yup'
import AddPhotosModal from './Modals/AddPhotosModal'
import SinglePhotoUploadModal from './Modals/SinglePhotoUploadModal'
import PrintAssetTagModal from './Modals/PrintAssetTagModal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const AssetsSechma = Yup.object().shape({
  description: Yup.string()
    .trim()
    .required('Description is required')
    .max(250, 'Description should not exceed 250 characters')
    .test('no-empty-spaces', 'Description should not be empty spaces', (value) => {
      return typeof value === 'string' && value.trim() !== ''
    }),
  assetTagId: Yup.string()
    .trim()
    .required('Asset tag id is required')
    .max(20, 'Asset tag Id should not exceed 20 characters')
    .test('no-empty-spaces', 'Asset tag id should not be empty spaces', (value) => {
      return typeof value === 'string' && value.trim() !== ''
    }),
  purchasedFrom: Yup.string().max(20, 'Purchased from should not exceeds 20 characters').nullable(),
  model: Yup.string().max(20, 'Model should not exceeds 20 characters').nullable(),
  brand: Yup.string().max(20, 'Brand should not exceeds 20 characters').nullable(),
  serialNo: Yup.string().max(20, 'Serial no should not exceeds 20 characters').nullable(),
  ram: Yup.string().max(20, 'Ram should not exceeds 20 characters').nullable(),
  storage: Yup.string().max(20, 'Storage should not exceeds 20 characters').nullable(),
  batteries: Yup.string().max(20, 'Batteries should not exceeds 20 characters').nullable(),
  cost: Yup.string()
    .trim()
    .matches(costRegex, 'Cost must be a positive number')
    .max(15, 'Cost should not exceed 15 digits')
    .nullable(),
  ports: Yup.string()
    .trim()
    .matches(digitsRegex, 'Ports must be a positive number')
    .max(20, 'Ports should not exceed 20 digits')
    .nullable(),
  mbps: Yup.string()
    .trim()
    .matches(digitsRegex, 'Mbps must be a positive number')
    .max(20, 'Mbps should not exceed 20 digits')
    .nullable(),
  stands: Yup.string()
    .trim()
    .max(20, 'Stands should not exceed 20 digits')
    .max(20, 'Stands should not exceed 20 digits')
    .matches(digitsRegex, 'Stands must be a positive number')
    .nullable(),
  bulbs: Yup.string()
    .trim()
    .max(20, 'Bulbs should not exceed 20 characters')
    .matches(digitsRegex, 'Bulbs must be a positive number')
    .nullable(),
  lens: Yup.string()
    .trim()
    .max(20, 'Lens should not exceed 20 characters')
    .matches(digitsRegex, 'Lens must be a positive number')
    .nullable(),
  purchasedDate: Yup.date().nullable().max(new Date(), 'Purchase date cannot be a future date'),
  imei: Yup.string()
    .trim()
    .nullable()
    .matches(/^\d{15}$/, 'IMEI number must contain exactly 15 digits'),
})

const AddEditAssets = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const permission = currentUser?.SecurityGroup
  const staffUser = currentUser?.userType === 2
  const [loading, setLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const [skip, setSkip] = useState<boolean>(false)
  const [siteId, setSiteId] = useState<boolean>(false)
  const [isNewSiteAdded, setIsNewSiteAdded] = useState(false)
  const [isNewLocationAdded, setIsNewLocationAdded] = useState(false)
  const [isNewDepartmentAdded, setIsNewDepartmentAdded] = useState(false)
  const [isNewCategoryAdded, setIsNewCategoryAdded] = useState(false)
  const [showSiteModal, setShowSiteModal] = useState<boolean>(false)
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false)
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false)
  const [showDepartmentModal, setShowDepartmentModal] = useState<boolean>(false)
  const [fileDataURL, setFileDataURL] = useState(null)
  const [photo, setPhoto] = useState<string>('')
  const [editPhoto, setEditPhoto] = useState<any>(null)
  const [file, setFile] = useState<any>(null)
  const [currencyLogo, setCurrencyLogo] = useState<any>()
  const [imageModel, setImageModel] = useState<boolean>(false)
  const [formikKey, setFormikKey] = useState<number>(0)
  const [showPrintTag, setShowPrintTag] = useState<boolean>(false)
  const [newAssetId, setNewAssetId] = useState(null)
  const [addAsset] = useAddAssetMutation()
  const [updateAsset] = useUpdateAssetMutation()
  const [showSingleImage, setShowSingleImage] = useState<boolean>(false)
  const { data: siteData } = useGetAllSitesQuery({ userId: currentUser?.id })
  const { data: categoryData } = useGetAllCategoriesQuery({ userId: currentUser?.id, page: 1 })
  const { data: locationData } = useGetAllLocationsBySiteQuery(
    { userId: currentUser?.id, id: siteId },
    { skip: !siteId }
  )
  const { data: departmentData } = useGetAllDepartmentsQuery({ userId: currentUser?.id })
  const { data: assetData, isLoading } = useGetSingleAssetQuery({ assetId: id }, { skip: !id })
  const { data: companyData, isSuccess: company } = useGetCompanyQuery(
    {},
    {
      skip: staffUser && !permission?.viewCompanyInfo,
    }
  )

  const isEdit = location.pathname.includes('/asset/edit')
  const isCopy = location.pathname.includes('/asset/copy')

  const handleImageModelClose = () => setImageModel(false)

  const handleSingleImage = () => setShowSingleImage(false)

  useEffect(() => {
    const res = getSymbolFromCurrency(companyData?.company?.currency || currentUser?.currency)
    setCurrencyLogo(res)
  }, [company])

  useEffect(() => {
    if (isNewSiteAdded) {
      formik.setFieldValue('siteId', siteData?.sites ? siteData?.sites[0]?.id : '')
    }
    if (isNewDepartmentAdded) {
      formik.setFieldValue(
        'departmentId',
        departmentData?.department ? departmentData?.department[0]?.id : ''
      )
    }
    if (isNewCategoryAdded) {
      formik.setFieldValue(
        'categoryId',
        categoryData?.category ? categoryData?.category[0]?.id : ''
      )
    }
    if (isNewLocationAdded) {
      formik.setFieldValue(
        'locationId',
        locationData?.locations
          ? locationData?.locations[locationData?.locations?.length - 1]?.id
          : ''
      )
      formik.setFieldValue('siteId', siteId)
    }
    setDataLoading(false)
  }, [siteData, departmentData, categoryData, locationData])

  useEffect(() => {
    setDataLoading(true)
    Promise.all([siteData, departmentData, categoryData, locationData])
      .catch((error: any) => {
        toast.error(error)
      })
      .finally(() => {
        setDataLoading(false)
      })
  }, [currentUser?.id, skip])

  const formik = useFormik({
    initialValues: { ...initialAssets },
    validationSchema: AssetsSechma,
    onSubmit: async (values: AssetLayout, { resetForm }) => {
      if (currentUser) {
        setLoading(true)
        const formData = new FormData()
        Object.keys(values).map((key: any) => {
          if (values[key] !== undefined && values[key] !== null) {
            if (key === 'purchasedDate' && isNaN(Date.parse(values[key]))) {
              // left blank if key is purchasedDate and date is not a number.
            } else {
              if (key === 'photo') {
                formData.append(`photo`, values[key])
              } else {
                const keys = ['categoryId', 'siteId', 'departmentId', 'locationId', 'photoId']
                if (typeof key === 'string' && typeof values[key] === 'string') {
                  if (!keys.includes(key))
                    formData.append(key, values[key]?.trim().replace(multiSpace, ' '))
                  else formData.append(key, values[key])
                } else formData.append(key, values[key])
              }
            }
          }
        })
        const data = isEdit
          ? updateAsset({ assetId: id, formData }).unwrap()
          : isCopy
            ? addAsset(formData).unwrap() // Use addAsset for copy operation
            : addAsset(formData).unwrap()
        await data
          .then((res) => {
            if (res?.success) {
              toast.dismiss()
              toast.success(res?.message)
              resetForm({ values: initialAssets })
              setFormikKey((prev) => prev + 1)
              setFile(null)
              setFileDataURL(null)
              if (isEdit) {
                navigate(`/asset/view/${res?.newAssetId ? res?.newAssetId : id}`)
              } else {
                setNewAssetId(res?.newAssetId ? res?.newAssetId : id)
                setShowPrintTag(true)
              }
            }
          })
          .catch((error) => {
            if (!error?.data?.success) {
              toast.error(error.data.message)
            }
            checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
            formik.setSubmitting(false)
          })
          .finally(() => {
            setLoading(false)
          })
        setLoading(false)
      }
    },
    enableReinitialize: true,
  })

  const formSubmit = (e) => {
    e?.preventDefault()
    if (
      (Object.keys(formik.errors).length > 0 && !formik.values.assetTagId) ||
      !formik.values.description
    ) {
      toast.error('Please fill out the fields in required and valid format.')
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    formik.handleSubmit()
  }

  useEffect(() => {
    formik.resetForm({ values: initialAssets })
    setFormikKey((prev) => prev + 1)
    setFile(null)
    setFileDataURL(null)
    setPhoto('')
  }, [id])

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (file) {
      formik.setFieldValue('photo', file)
      fileReader = new FileReader()
      fileReader.onload = (e) => {
        const { result } = e.target
        if (result && !isCancel) {
          setFileDataURL(result)
        }
      }
      fileReader.readAsDataURL(file)
    }

    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [file])

  useEffect(() => {
    if (editPhoto) {
      setPhoto(
        editPhoto?.uploadType === 1
          ? toAbsoluteUrlImage(editPhoto?.photo)
          : editPhoto?.stockImage?.imgUrl
      )
      formik.setFieldValue('photoId', editPhoto?.id || null)
    }
  }, [editPhoto])

  const handleSiteClick = ({ target: { value } }: any) => {
    setDataLoading(true)
    if (!value) {
      setDataLoading(false)
    }
    setSiteId(value)
    formik.setFieldValue('locationId', '')
    setSkip(false)
  }

  const handleRestrictedClick = (value: boolean) => {
    formik.setFieldValue('isRestricted', value)
  }

  useEffect(() => {
    formik.resetForm({ values: initialAssets })
    let photo
    const isPhoto = assetData && assetData?.asset?.photo?.length > 0
    if (isPhoto) {
      photo = assetData?.asset?.photo[0]
      setPhoto(
        photo?.uploadType === 1 ? toAbsoluteUrlImage(photo?.photo) : photo?.stockImage?.imgUrl
      )
    }
    if (id) {
      const purchaseDateFromDatabase = assetData?.asset?.purchasedDate
      const purchasedDate = new Date(purchaseDateFromDatabase)
      const localPurchaseDate = purchasedDate.toLocaleDateString('en-CA')
      if (assetData?.asset?.site?.id) {
        setSiteId(assetData?.asset?.site?.id || '')
      }
      formik.setValues({
        assetTagId: isEdit ? assetData?.asset?.assetTagId : '',
        brand: assetData?.asset?.brand,
        categoryId: assetData?.asset?.category?.id || '',
        departmentId: assetData?.asset?.department?.id || '',
        description: assetData?.asset?.description,
        model: assetData?.asset?.model,
        purchasedDate: assetData?.asset?.purchasedDate == null ? '' : localPurchaseDate,
        purchasedFrom: assetData?.asset?.purchasedFrom,
        serialNo: assetData?.asset?.serialNo,
        siteId: assetData?.asset?.site?.id || '',
        locationId: assetData?.asset?.location?.id || '',
        cost: assetData?.asset?.cost,
        status: isCopy ? 'Available' : assetData?.asset?.status,
        photo,
        ram: assetData?.asset?.ram,
        storage: assetData?.asset?.storage,
        imei: assetData?.asset?.imei,
        ports: assetData?.asset?.ports,
        mbps: assetData?.asset?.mbps,
        stands: assetData?.asset?.stands,
        batteries: assetData?.asset?.batteries,
        bulbs: assetData?.asset?.bulbs,
        lens: assetData?.asset?.lens,
        isRestricted: assetData?.asset?.isRestricted || false,
      })
    }
  }, [assetData])

  const handleCloseSiteModal = () => {
    setShowSiteModal(false)
  }

  const handleShowSiteModal = () => {
    setShowSiteModal(true)
    setIsNewSiteAdded(true)
  }

  const handleCloseLocationModal = () => {
    setShowLocationModal(false)
  }

  const handleShowLocationModal = () => {
    setShowLocationModal(true)
    setIsNewLocationAdded(true)
  }

  const handleCloseCategoryModal = () => {
    setShowCategoryModal(false)
  }

  const handleShowCategoryModal = () => {
    setShowCategoryModal(true)
    setIsNewCategoryAdded(true)
  }

  const handleCloseDepartmentModal = () => {
    setShowDepartmentModal(false)
  }

  const handleShowDepartmentModal = () => {
    setShowDepartmentModal(true)
    setIsNewDepartmentAdded(true)
  }

  const handleClosePrintTag = () => {
    setShowPrintTag(false)
    formik.setFieldValue('assetTagId', '')
    formik.setFieldValue('brand', '')
    formik.setFieldValue('categoryId', '')
    formik.setFieldValue('departmentId', '')
    formik.setFieldValue('description', '')
    formik.setFieldValue('model', '')
    formik.setFieldValue('purchasedDate', '')
    formik.setFieldValue('purchasedFrom', '')
    formik.setFieldValue('serialNo', '')
    formik.setFieldValue('siteId', '')
    formik.setFieldValue('locationId', '')
    formik.setFieldValue('cost', '')
    formik.setFieldValue('status', '')
    formik.setFieldValue('photo', undefined)
    formik.setFieldValue('ram', '')
    formik.setFieldValue('storage', '')
    formik.setFieldValue('imei', '')
    formik.setFieldValue('ports', '')
    formik.setFieldValue('mbps', '')
    formik.setFieldValue('stands', '')
    formik.setFieldValue('batteries', '')
    formik.setFieldValue('bulbs', '')
    formik.setFieldValue('lens', '')
    formik.setFieldValue('isRestricted', false)
  }

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoading} />
      {!isLoading ? (
        <>
          <PageTitle breadcrumbs={[]}>{id ? `Edit an Asset` : `Add an Asset`}</PageTitle>
          <div className='card mb-5 mb-xl-10'>
            <div
              className='card-header border-0'
              data-bs-target='#kt_account_profile_details'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='card-title m-0 '>
                <span className='me-2 align-middle'>
                  <i className='la la-plus-circle fs-1'></i>
                </span>
                <h3 className='fw-bolder m-0'>{id ? `Edit` : `Add`} an Asset</h3>
              </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
              <form noValidate className='form' key={formikKey}>
                <div className='card-body border-top p-lg-9 p-md-7 p-6'>
                  <div className='pb-5'>
                    {' '}
                    <h3 className='fw-bolder m-0 mb-3'>Asset details</h3>
                  </div>
                  <Row className='mb-6'>
                    <Col xl='6'>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label
                            className='col-form-label required fw-bold fs-6'
                            htmlFor='description'
                          >
                            {' '}
                            Description{' '}
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='description'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('description')}
                            />
                            {formik.touched.description && formik.errors.description && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.description}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label
                            className='col-form-label required fw-bold fs-6'
                            htmlFor='assetTagId'
                          >
                            {' '}
                            Asset Tag ID{' '}
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='assetTagId'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('assetTagId')}
                            />
                            {formik.touched.assetTagId && formik.errors.assetTagId && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.assetTagId}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='purchaseDate'>
                            {' '}
                            Purchase Date{' '}
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <div className='input-group'>
                              <input
                                type='date'
                                id='purchaseDate'
                                className='form-control  form-control-solid'
                                aria-label='Amount (to the nearest dollar)'
                                {...formik.getFieldProps('purchasedDate')}
                                onChange={formik.handleChange}
                              />
                              <span className='input-group-text border-0'>
                                <i className='fa fa-calendar'></i>
                              </span>
                            </div>
                            {formik.touched.purchasedDate && formik.errors.purchasedDate && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.purchasedDate}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='cost'>
                            Cost
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <div className='input-group currency-field'>
                              <span className='input-group-text'>{currencyLogo}</span>
                              <input
                                type='string'
                                id='cost'
                                className='form-control  form-control-solid'
                                aria-label='Amount (to the nearest dollar)'
                                {...formik.getFieldProps('cost')}
                              />
                            </div>
                            {formik.touched.cost && formik.errors.cost && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.cost}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl='6'>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='purchaseFrom'>
                            {' '}
                            Purchased From{' '}
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='purchaseFrom'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('purchasedFrom')}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.purchasedFrom && formik.errors.purchasedFrom && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.purchasedFrom}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='brand'>
                            Brand
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='brand'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('brand')}
                            />
                            {formik.touched.brand && formik.errors.brand && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.brand}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='model'>
                            Model
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='model'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('model')}
                            />
                            {formik.touched.model && formik.errors.model && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.model}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='serialNum'>
                            Serial No
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='serialNum'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('serialNo')}
                            />
                            {formik.touched.serialNo && formik.errors.serialNo && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.serialNo}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className='pb-5 border-top pt-9'>
                    {' '}
                    <h3 className='fw-bolder m-0 mb-3'>Other Additional Details</h3>
                  </div>
                  <Row className='mb-6'>
                    <Col xl='6'>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='ram'>
                            RAM
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='ram'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('ram')}
                            />
                            {formik.touched.ram && formik.errors.ram && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.ram}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='storage'>
                            Storage
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='storage'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('storage')}
                            />
                            {formik.touched.storage && formik.errors.storage && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.storage}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='imeiNum'>
                            IMEI Number
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='imeiNum'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('imei')}
                            />
                            {formik.touched.imei && formik.errors.imei && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.imei}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='ports'>
                            Ports
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='ports'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('ports')}
                            />
                            {formik.touched.ports && formik.errors.ports && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.ports}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='mbps'>
                            Mbps
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='mbps'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('mbps')}
                            />
                            {formik.touched.mbps && formik.errors.mbps && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.mbps}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl='6'>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='stands'>
                            Stands
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='stands'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('stands')}
                            />
                            {formik.touched.stands && formik.errors.stands && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.stands}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='batteries'>
                            Batteries
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='batteries'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('batteries')}
                            />
                            {formik.touched.batteries && formik.errors.batteries && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.batteries}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='bulbs'>
                            Bulbs
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='bulbs'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('bulbs')}
                            />
                            {formik.touched.bulbs && formik.errors.bulbs && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.bulbs}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label  fw-bold fs-6' htmlFor='lens'>
                            Lens
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row'>
                            <input
                              type='text'
                              id='lens'
                              className='form-control  form-control-solid'
                              {...formik.getFieldProps('lens')}
                            />
                            {formik.touched.lens && formik.errors.lens && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.lens}</div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className='pb-5 border-top pt-9'>
                    {' '}
                    <h3 className='fw-bolder m-0 mb-3'>Site, Location, Category and Department</h3>
                  </div>
                  <Row className='mb-6'>
                    <Col xl='6'>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='siteId'>
                            <span>Site</span>
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row d-flex'>
                            <select
                              className='form-select form-control form-control-solid form-select-solid'
                              id='siteId'
                              {...formik.getFieldProps('siteId')}
                              onChange={(e) => {
                                formik.handleChange(e)
                                handleSiteClick(e)
                              }}
                            >
                              <option value=''>Select Site</option>
                              {siteData?.sites.map((item: any, idx: number) => {
                                return (
                                  <option key={idx} value={item?.id}>
                                    {item?.name}
                                  </option>
                                )
                              })}
                            </select>
                            <button
                              type='button'
                              className='btn btn-light-primary d-flex align-items-center ms-3'
                              onClick={() => handleShowSiteModal()}
                            >
                              <i className='la la-plus fs-3' />
                              New
                            </button>
                            <SitesModal
                              show={showSiteModal}
                              handleClose={handleCloseSiteModal}
                              SiteData={[]}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='locationId'>
                            <span>Location</span>
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row d-flex'>
                            <select
                              id='locationId'
                              className='form-select form-control form-control-solid form-select-solid  '
                              {...formik.getFieldProps('locationId')}
                            >
                              <option value=''>Select Location</option>
                              {locationData?.locations?.map((item: any, idx: number) => {
                                return (
                                  <option key={idx} value={item?.id}>
                                    {item?.location}
                                  </option>
                                )
                              })}
                            </select>
                            <button
                              type='button'
                              className='btn btn-light-primary d-flex align-items-center ms-3'
                              onClick={() => handleShowLocationModal()}
                            >
                              <i className='la la-plus fs-3' />
                              New
                            </button>
                            <LocationModal
                              show={showLocationModal}
                              setSiteId={setSiteId}
                              handleClose={handleCloseLocationModal}
                              locationData={[]}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl='6'>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='categoryId'>
                            <span>Category</span>
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row d-flex'>
                            <select
                              id='categoryId'
                              className='form-select form-control form-control-solid form-select-solid  '
                              {...formik.getFieldProps('categoryId')}
                            >
                              <option value=''>Select Category</option>
                              {categoryData?.category?.map((item: any, idx: number) => {
                                return (
                                  <option key={idx} value={item?.id}>
                                    {item?.category}
                                  </option>
                                )
                              })}
                            </select>
                            <button
                              type='button'
                              className='btn btn-light-primary d-flex align-items-center ms-3'
                              onClick={() => handleShowCategoryModal()}
                            >
                              <i className='la la-plus fs-3' />
                              New
                            </button>
                            <CategoryModel
                              show={showCategoryModal}
                              handleClose={handleCloseCategoryModal}
                              data={[]}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-6'>
                        <Col lg='3'>
                          <label className='col-form-label fw-bold fs-6' htmlFor='departmentId'>
                            <span>Department</span>
                          </label>
                        </Col>
                        <Col lg='9'>
                          <div className='fv-row d-flex'>
                            <select
                              id='departmentId'
                              className='form-select form-control form-control-solid form-select-solid  '
                              {...formik.getFieldProps('departmentId')}
                            >
                              <option value=''>Select Department</option>
                              {departmentData?.department?.map((item: any, idx: number) => {
                                return (
                                  <option key={idx} value={item?.id}>
                                    {item?.department}
                                  </option>
                                )
                              })}
                            </select>
                            <button
                              type='button'
                              className='btn btn-light-primary d-flex align-items-center ms-3'
                              onClick={() => handleShowDepartmentModal()}
                            >
                              <i className='la la-plus fs-3' />
                              New
                            </button>
                            <DepartmentsModel
                              show={showDepartmentModal}
                              handleClose={handleCloseDepartmentModal}
                              data={[]}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <div className='card-body border-top p-lg-9 p-md-7 p-6'>
                      <h3 className='fw-bolder m-0 mb-3'>Want to Restrict:</h3>
                      <p>This operation will restrict your asset which only visible to you only.</p>
                      <Row className='mb-1'>
                        <Col lg='3'>
                          <p className='mb-0 fw-bolder col-form-label'>Restrict Asset:</p>
                        </Col>
                        <Col lg='9'>
                          <div className='radio-inline standard_radio_can_hide hasLicense_radio col-form-label'>
                            <label className='radio mb-0 p_16' htmlFor='restrictYes'>
                              <input
                                id='restrictYes'
                                name='isRestricted'
                                type='radio'
                                className='m_5 custom-form-check-input form-check-input sig_input'
                                checked={formik.values.isRestricted === true}
                                onChange={() => handleRestrictedClick(true)}
                              />
                              Yes
                            </label>
                            <label className='radio mb-0' htmlFor='restrictNo'>
                              <input
                                id='restrictNo'
                                name='isRestricted'
                                type='radio'
                                className='m_5 custom-form-check-input form-check-input sig_input'
                                checked={formik.values.isRestricted === false}
                                onChange={() => handleRestrictedClick(false)}
                              />
                              No
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Row>
                  <div className='card-body border-top p-lg-9 p-md-7 p-6'>
                    <Row className='mb-6'>
                      <Col xl='6'>
                        <Row className='mb-6'>
                          <Col lg='3'>
                            <label
                              htmlFor={!id ? 'photo' : 'photoId'}
                              className='col-form-label fw-bold fs-6'
                            >
                              Asset Image
                            </label>
                          </Col>
                          <Col lg='9'>
                            <div
                              className='image-input image-input-outline mb-lg-4 mb-3'
                              data-kt-image-input='true'
                            >
                              <label
                                htmlFor={!id ? 'photo' : 'photoId'}
                                className='cursor-pointer position-relative'
                              >
                                <img
                                  className='image-input-wrapper w-125px h-125px'
                                  src={
                                    fileDataURL ? fileDataURL : photo ? photo : Images.Avatar_JPG
                                  }
                                  alt='asset profile'
                                  crossOrigin='anonymous'
                                />
                                <span className='edit-icon'>
                                  <i className='las la-camera fs-2'></i>
                                </span>
                              </label>
                              <div>
                                <input
                                  className='form-control d-none'
                                  type='button'
                                  name='photo'
                                  id='photo'
                                  onClick={() => setShowSingleImage(true)}
                                />
                              </div>
                              <div>
                                <input
                                  className='form-control d-none'
                                  type='button'
                                  name='photo'
                                  id='photoId'
                                  onClick={() => setImageModel(true)}
                                />
                              </div>
                            </div>
                            <p>
                              {' '}
                              Only (<strong>JPG, GIF, PNG</strong>) are allowed{' '}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <Link to='/assets' className='btn btn-light-primary   main-btn-style me-2'>
                    Cancel
                  </Link>
                  <button
                    onClick={formSubmit}
                    className='btn btn-primary'
                    disabled={!formik.values.assetTagId || !formik.values.description}
                  >
                    {!loading && 'Submit'}
                    {loading && (
                      <span className='indicator-progress d-block'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
            <Loader loading={dataLoading} />
          </div>
        </>
      ) : null}
      {id && (
        <AddPhotosModal
          handleClose={handleImageModelClose}
          addPhotosModal={imageModel}
          setEditPhoto={setEditPhoto}
        />
      )}
      {showSingleImage && (
        <SinglePhotoUploadModal
          singlePhotoUploadModal={showSingleImage}
          handleClose={handleSingleImage}
          setFile={setFile}
        />
      )}
      {newAssetId && showPrintTag && (
        <PrintAssetTagModal
          handleClose={handleClosePrintTag}
          printAssetTagModal={showPrintTag}
          assetId={newAssetId}
        />
      )}
    </>
  )
}

export default AddEditAssets
