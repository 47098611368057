import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { useDeleteAuditByIdMutation } from 'App/Modules/Services/Audit'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'

interface DeleteModalProps {
  assetDeleteAuditModal: boolean
  handleClose: () => void
  auditId: any
  refetchAudit
}

const DeleteAssetAuditModal: React.FC<DeleteModalProps> = ({
  assetDeleteAuditModal,
  handleClose,
  auditId,
  refetchAudit,
}) => {
  const [deleteAudit] = useDeleteAuditByIdMutation()
  const { saveAuth, setCurrentUser } = useAuth()

  const handleDeleteAudit = async () => {
    try {
      const res: any = await deleteAudit({ id: auditId })
      if (res?.data?.success) {
        handleClose()
        refetchAudit()
        toast?.success(res?.data?.message)
      } else {
        checkUserUnauthorized(res?.error?.data, saveAuth, setCurrentUser, toast)
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  return (
    <Modal show={assetDeleteAuditModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Delete Audit Entry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className='mb-5'>Are you sure you want to delete this audit entry?</h5>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Cancel
        </button>
        <button className='btn btn-danger' onClick={handleDeleteAudit}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteAssetAuditModal
