import { useNavigate, Link } from 'react-router-dom'
interface HeaderListingProps {
  assetsData: any
  fileName: any
  userAssets: any
  deleteDataArr: number[]
  permission: any
  staffUser: boolean
  setDeleteModal: (value: boolean) => void
  setCheck: (ids: number[]) => void
  setShowSearch: (value: boolean) => void
  setShowSetupColumns: (value: boolean) => void
  handleExport: () => void
  handleWarrantyStatusChange: any
  selectFilterRef: React.RefObject<HTMLSelectElement>
}
const WarrantiesHeader = (props: HeaderListingProps) => {
  const navigate = useNavigate()

  return (
    <>
      <div
        className='d-flex justify-content-between mb-3 flex-wrap'
        data-kt-user-table-toolbar='base'
      >
        <div className='d-flex align-items-center mb-xl-0 mb-4'>
          <h3 className='card-title me-4 mb-0'>
            <span className='me-2 align-middle'>
              <i className='la la-list fs-1' aria-hidden='true'></i>
            </span>
            <span className='card-label fw-bold fs-3 mb-1'>List of Warranties</span>
          </h3>
        </div>
        <div className='d-flex flex-wrap '>
          {/* commenting the piece of code temporarily */}
          {!props.permission?.viewAsset && props.staffUser ? (
            ''
          ) : (
            <button
              type='button'
              className='btn btn-light-primary main-btn-style m-1 d-none'
              onClick={() => props.setShowSearch(true)}
            >
              <i className='fa fa-search fs-xl-5 fs-6' /> Search
              <span className='d-xl-inline d-none'> Criteria</span>
            </button>
          )}
          {!props.permission?.exportWarranties && props.staffUser ? (
            ''
          ) : (
            <button
              className='btn btn-light-primary main-btn-style m-1'
              onClick={() => {
                props.handleExport()
              }}
            >
              <i className='far fa-file-excel fs-5'></i> Export
              <span className='d-xl-inline d-none'> to Excel</span>
            </button>
          )}
          {!props.permission?.importWarranties && props.staffUser ? (
            ''
          ) : (
            <Link
              className='btn btn-light-primary main-btn-style m-1'
              to='/import'
              state={{ importType: 'warranties' }}
            >
              <i className='la la-cloud-upload fs-2'></i>
              <span>Import</span>
              <span className='d-xl-inline d-none ps-1'> Warranties</span>
            </Link>
          )}
          {props.deleteDataArr?.length > 0 && (
            <button
              type='button'
              className='btn btn-danger main-btn-style m-1'
              onClick={() => {
                props.setDeleteModal(true)
                props.setCheck(props.deleteDataArr)
              }}
            >
              Delete Selected ({props.deleteDataArr?.length})
            </button>
          )}
        </div>
      </div>
      <div className='row'>
        <p>
          Link warranties to specific assets by choosing View next to the warranty you wish to edit.
          Then, add the required information.
        </p>
        <div className='col-xl-4 col-sm-6 col-12'>
          <div className='form-group mb-3'>
            <select
              ref={props.selectFilterRef}
              className='form-select'
              onChange={props.handleWarrantyStatusChange}
            >
              <option value=''>All Warranties</option>
              <option value='1'>Active Warranties</option>
              <option value='0'>Expired Warranties</option>
            </select>
          </div>
        </div>
      </div>
    </>
  )
}

export { WarrantiesHeader }
