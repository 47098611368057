import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../helpers'

const HelpDrawer = () => {
  return (
    <div
      id='kt_help'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='help'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_help_toggle'
      data-kt-drawer-close='#kt_help_close'
    >
      <div className='card shadow-none rounded-0 w-100'>
        <div className='card-header' id='kt_help_header'>
          <h5 className='card-title fw-bold text-gray-600'>Learn & Get Inspired</h5>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_help_close'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </button>
          </div>
        </div>
        <div className='card-body' id='kt_help_body'>
          <div
            id='kt_help_scroll'
            className='hover-scroll-overlay-y'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_help_body'
            data-kt-scroll-dependencies='#kt_help_header'
            data-kt-scroll-offset='5px'
          >
            <div className='rounded border border-dashed border-gray-300 p-6 p-lg-8 mb-10'>
              <h2 className='fw-bolder mb-5'>
                Support at <Link to='https://arhamsoft.com'>ArhamSoft.com</Link>
              </h2>
              <div className='fs-5 fw-bold mb-5'>
                <span className='text-gray-500'>
                  Join our developers community to find answer to your question and help others.
                </span>
                <Link
                  className='explore-link d-none'
                  to={`${process.env.REACT_APP_PUBLIC_URL}#faqs`}
                >
                  FAQs
                </Link>
              </div>
              <Link
                to={`${process.env.REACT_APP_PUBLIC_URL}#contact`}
                className='btn btn-lg explore-btn-primary w-100'
              >
                Get Support
              </Link>
            </div>
            <div className='d-flex align-items-center mb-7'>
              <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-warning'>
                <KTSVG
                  path='/media/icons/duotune/abstract/abs027.svg'
                  className='svg-icon-warning svg-icon-2x svg-icon-lg-3x'
                />
              </div>
              <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                <div className='d-flex flex-column me-2 me-lg-5'>
                  <Link to='/' className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'>
                    Documentation &amp; Videos
                  </Link>
                  <div className='text-muted fw-bold fs-7 fs-lg-6'>
                    From guides and video tutorials, to live demos and code examples to get started.
                  </div>
                </div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-gray-400 svg-icon-2'
                />
              </div>
            </div>
            <div className='d-flex align-items-center mb-7'>
              <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-primary'>
                <KTSVG
                  path='/media/icons/duotune/ecommerce/ecm007.svg'
                  className='svg-icon-primary svg-icon-2x svg-icon-lg-3x'
                />
              </div>
              <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                <div className='d-flex flex-column me-2 me-lg-5'>
                  <Link
                    to='https://preview.keenthemes.com/metronic8/react/docs/docs/utilities'
                    className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'
                  >
                    Plugins &amp; Components
                  </Link>
                  <div className='text-muted fw-bold fs-7 fs-lg-6'>
                    Check out our 300+ in-house components and customized 3rd-party plugins.
                  </div>
                </div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-gray-400 svg-icon-2'
                />
              </div>
            </div>
            <div className='d-flex align-items-center mb-7'>
              <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-info'>
                <KTSVG
                  path='/media/icons/duotune/art/art006.svg'
                  className='svg-icon-info svg-icon-2x svg-icon-lg-3x'
                />
              </div>
              <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                <div className='d-flex flex-column me-2 me-lg-5'>
                  <Link
                    to='/builder'
                    className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'
                  >
                    Layout Builder
                  </Link>
                  <div className='text-muted fw-bold fs-7 fs-lg-6'>
                    Dynamically modify and preview layout
                  </div>
                </div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-gray-400 svg-icon-2'
                />
              </div>
            </div>
            <div className='d-flex align-items-center mb-7'>
              <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-danger'>
                <KTSVG
                  path='/media/icons/duotune/electronics/elc009.svg'
                  className='svg-icon-danger svg-icon-2x svg-icon-lg-3x'
                />
              </div>
              <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                <div className='d-flex flex-column me-2 me-lg-5'>
                  <Link
                    to={`${process.env.REACT_APP_PUBLIC_URL}#features`}
                    className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'
                  >
                    What's New
                  </Link>
                  <div className='text-muted fw-bold fs-7 fs-lg-6'>
                    Latest features and improvements added with our users feedback in mind.
                  </div>
                </div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-gray-400 svg-icon-2'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { HelpDrawer }
