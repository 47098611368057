import React, { useState, useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { CAccount, defaultCompanyDetail as initialValues } from './Core/Model'
import { toAbsoluteUrlImage } from '../../../../../Template/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAuth } from '../../../Auth'
import { toast } from 'react-toastify'
import { Images } from 'Template/assets/Assets'
import { useDeleteCompanyMutation, useGetCompanyQuery, useUpdateCompanyMutation, useDeleteImageMutation, } from '../../../Services/Company'
import { useLocation, useNavigate } from 'react-router-dom'
import timezones from 'timezones-list'
import { countries } from 'country-list-json'
import { currencies } from '../../../../../Data/Currency'
import SplashScreen from 'App/Loaders/SplashScreen'
import { checkUserUnauthorized } from '../../../Auth'
import DeleteAccount from './DeleteAccount'
import { MoonLoader } from 'react-spinners'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
  addressRegex,
  companyName,
  dateFormat,
  dateOptions,
  imageMimeType,
  months,
  multiSpace,
  nameRegex,
  postalRegex,
} from 'config'
import Loader from 'App/Loaders/BeatLoader'
import moment from 'moment'

const accountDetailsSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Company name is required')
    .min(8, 'Company name must be at least 8 characters')
    .max(20, 'Company name must not exceed 20 characters')
    .matches(
      companyName,
      'Company name should allow alphabets, numbers and some special characters.'
    ),
  country: Yup.string().trim().required('Country is required'),
  suite: Yup.string().max(20, 'Suite should not exceed 20 characters'),
  address: Yup.string()
    .trim()
    .required('Address is required')
    .min(5, 'Address must be at least 5 characters')
    .max(50, 'Address must not exceed 50 characters'),
  city: Yup.string()
    .trim()
    .required('City is required')
    .min(4, 'City must be at least 4 characters')
    .max(20, 'City must not exceed 20 characters'),
  state: Yup.string()
    .trim()
    .required('State is required')
    .min(4, 'State must be at least 4 characters')
    .max(20, 'State must not exceed 20 characters'),
  postalCode: Yup.string()
    .trim()
    .required('Postal Code is required')
    .matches(postalRegex, 'Only digits are allowed in Postal Code')
    .min(4, 'Postal Code must be at least 4 characters')
    .max(10, 'Postal Code must not exceed 10 characters')
    .test('no-alphabets', 'Alphabets are not allowed in Postal Code', function (value) {
      if (value) {
        return !/[a-zA-Z]/.test(value)
      }
      return true
    }),
  timeZone: Yup.string().required('Time zone is required').trim().min(1, 'Time zone is required'),
  currency: Yup.string().required('Currency is required'),
  dateFormat: Yup.string().required('Date format is required'),
  financialYearDate: Yup.string().required('Date is required'),
  financialYearMonth: Yup.string().required('Month is required'),
})

const CompanyInfo = (props: any) => {
  const { pathname } = useLocation()
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const permission = currentUser?.SecurityGroup
  const staffUser = currentUser?.userType === 2
  const userType = currentUser?.userType
  const personUser = currentUser?.userType === 3
  const customerUser = currentUser?.userType === 4
  const date = Array.from({ length: 31 }, (_, index) => index + 1)
  const [logo, setLogo] = useState<string>('')
  const [isCompanyAdded, setIsCompanyAdded] = useState<boolean>(false)
  const [loadingAvatar, setLoadingAvatar] = useState(false)
  const {
    data: companyData,
    isError,
    error,
  } = useGetCompanyQuery({
    skip: personUser || customerUser,
  })
  const [updateCompany, { isLoading: isUpdating }] = useUpdateCompanyMutation()
  const [deleteCompanyImage] = useDeleteImageMutation()
  const [deleteCompany, { isLoading: isLoadingDeleteCompany }] = useDeleteCompanyMutation()
  const [file, setFile] = useState(null)
  const [fileDataURL, setFileDataURL] = useState<string | ArrayBuffer | null | undefined>(null)
  const [isDataExist, setIsDataExist] = useState<any>(false)
  const [imageErr, setImageErr] = useState(false)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const checkVistingPage = (dataList: any) => {
    if (isDataExist === false) {
      if (dataList && dataList?.length > 0) {
        setIsDataExist(dataList?.length)
      } else {
        setIsDataExist(0)
      }
    }
  }
  const isFieldEmpty = (fieldName) => !formik.values[fieldName]

  useEffect(() => {
    if (isFieldEmpty('dateFormat')) {
      formik.setFieldValue('dateFormat', 'MM/DD/YYYY')
    }
    if (timezones?.length > 0 && isFieldEmpty('timeZone')) {
      formik.setFieldValue('timeZone', timezones[0]?.label)
    }
    if (currencies?.length > 0 && isFieldEmpty('currency')) {
      formik.setFieldValue('currency', currencies[0]?.value)
    }
    if (months?.length > 0 && isFieldEmpty('financialYearMonth')) {
      formik.setFieldValue('financialYearMonth', months[0])
    }
    if (dateOptions?.length > 0 && isFieldEmpty('financialYearDate')) {
      formik.setFieldValue('financialYearDate', dateOptions[0])
    }
  }, [timezones, currencies, dateOptions, months, isFieldEmpty])

  useEffect(() => {
    setImageErr(false)
  }, [])

  useEffect(() => {
    if (isError) {
      const errorData = error as { data: any }
      setLoading(false)
      checkUserUnauthorized(errorData?.data, saveAuth, setCurrentUser, toast)
    }
  }, [companyData, isError])

  const changeHandler = (e) => {
    const file = e?.target?.files[0]
    if (!file?.type?.match(imageMimeType)) {
      toast.error('This file format is not allowed!')
      return
    } else {
      setFile(file)
      const reader = new FileReader()
      reader.onload = (event) => {
        setFileDataURL(event?.target?.result)
      }
      reader.readAsDataURL(file)
    }
  }
  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files ? e.target.files[0] : null

    if (file) {
      if (file.type.match(imageMimeType)) {
        const img = new Image()
        img.src = URL.createObjectURL(file)

        img.onload = () => {
          setImageErr(false)
          changeHandler(e)
          formik.setFieldValue('logo', file)
        }

        img.onerror = (error) => {
          setImageErr(true)
          formik.setFieldValue('logo', logo)
        }
      } else {
        setImageErr(true)
        formik.setFieldValue('logo', logo)
      }
    } else {
      setImageErr(false)
    }
  }

  const handleDelete = () => {
    if (currentUser) {
      deleteCompany(companyData?.company?.id)
        .then((res: any) => {
          if (res.data) {
            toast.success(res?.data?.message)
            window.location.reload()
          } else {
            toast.error(res?.error?.data?.message)
            checkUserUnauthorized(res?.error, saveAuth, setCurrentUser, toast)
          }
        })
        .catch((err: any) => {
          toast.error('Error in response!')
          checkUserUnauthorized(err?.data, saveAuth, setCurrentUser, toast)
        })
    }
  }

  const formik = useFormik<CAccount>({
    initialValues,
    validationSchema: accountDetailsSchema,
    onSubmit: async (values: CAccount) => {
      try {
        const formData = new FormData()
        for (const key of Object.keys(values)) {
          if (Object.prototype.hasOwnProperty.call(values, key)) {
            if (values) {
              const value = values[key as keyof CAccount]
              if (typeof values[key] === 'string') {
                formData.append(key, value.trim().replace(multiSpace, ' '))
              } else {
                formData.append(key, value)
              }
            }
          }
        }
        const data = await updateCompany({ formData }).unwrap()
        if (data?.success) {
          toast.success(data?.message)
        }
      } catch (error: any) {
        toast.error(error?.data?.message)
        checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
      }
    },
  })

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (file) {
      fileReader = new FileReader()
      fileReader.onload = (e) => {
        const { result } = e.target
        if (result && !isCancel) {
          setFileDataURL(result)
        }
      }
      fileReader.readAsDataURL(file)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [file])
  const [isFieldDisabled, setIsFieldDisabled] = useState(true)
  useEffect(() => {
    if (staffUser && !permission?.updateCompanyInfo) {
      setIsFieldDisabled(true)
    } else {
      setIsFieldDisabled(false)
    }
  }, [currentUser, permission])

  useEffect(() => {
    switch (true) {
      case !companyData:
        formik.setValues(initialValues)
        setIsCompanyAdded(false)
        setLoading(false)
        break

      case !!currentUser && !!companyData?.company: {
        if (
          typeof companyData?.company === 'object' &&
          companyData?.company !== null &&
          !Array.isArray(companyData?.company)
        ) {
          setIsCompanyAdded(true)
          setLogo(companyData?.company?.logo)
        }

        formik.setValues({
          dateOptions,
          name: companyData.company.name,
          address: companyData.company?.address,
          city: companyData.company?.city,
          country: companyData.company?.country,
          state: companyData.company?.state,
          postalCode: companyData.company?.postalCode,
          suite: companyData.company?.suite,
          timeZone: companyData.company?.timeZone,
          currency: companyData.company?.currency,
          dateFormat: companyData.company?.dateFormat,
          logo: companyData.company?.logo,
          financialYearDate: companyData.company?.financialYearDate,
          financialYearMonth: companyData.company?.financialYearMonth,
        })
        setLoading(false)
        break
      }
      default:
        break
    }
  }, [companyData])

  const deleteImage = async (e) => {
    e?.preventDefault()
    setLoadingAvatar(true)
    try {
      const data = await deleteCompanyImage(companyData?.company?.id).unwrap()
      if (data?.success) {
        setFileDataURL(null)
        toast.success(data?.message)
      }
    } catch (error: any) {
      if (!error?.data?.success) {
        toast.error(error?.data?.message)
      }
      checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
    } finally {
      setLoadingAvatar(false)
    }
  }

  const formSubmit = (e) => {
    e?.preventDefault()
    if (Object.keys(formik.errors).length > 0) {
      toast.error('Please fill out the fields in required and valid format.')
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    formik.handleSubmit()
  }

  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value
    const daysInMonth = new Date(
      new Date().getFullYear(),
      months.findIndex((month) => month === selectedMonth) + 1,
      0
    ).getDate()

    formik.setFieldValue('financialYearDate', '')
    formik.setFieldTouched('financialYearDate', false)

    const newDateOptions = Array.from({ length: daysInMonth }, (_, index) => index + 1)

    formik.setFieldError('financialYearDate', '')
    formik.setFieldError('financialYearMonth', '')
    formik.setFieldValue('financialYearDate', '')
    formik.setFieldValue('financialYearMonth', selectedMonth)
    formik.setFieldValue('dateOptions', newDateOptions)
  }

  return (
    <>
      <>
        <SplashScreen isLoadingTemplate={isLoadingDeleteCompany} />
        <div className={pathname == '/apps/setup/wizard' ? 'card' : 'card mb-xxl-10 mb-5'}>
          <div id='kt_account_profile_details' className='collapse show'>
            <form noValidate className='form'>
              <div className='card-body'>
                <div className='pb-5'>
                  {' '}
                  <h3 className='fw-bolder m-0 mb-3'>
                    <span className='me-2 align-middle'>
                      <i className='la la-briefcase fs-1' aria-hidden='true'></i>
                    </span>{' '}
                    Company Details
                  </h3>
                  <span className='fw-bolder m-0'>
                    Provide the name and site of the main office.
                  </span>
                </div>
                <Row>
                  <Col lg="10" xs="12" className='offset-lg-1'>
                    <Row className='mb-sm-6 mb-4'>
                      <Col lg="3">
                        <label className='col-form-label required fw-bold fs-6' htmlFor='name'>Company</label>
                      </Col>
                      <Col lg="9" className='fv-row'>
                        <input
                          id='name'
                          type='text'
                          className='form-control  form-control-solid'
                          placeholder='Company'
                          {...formik.getFieldProps('name')}
                          disabled={isFieldDisabled}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.name}</div>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className='mb-sm-6 mb-4'>
                      <Col lg="3">
                        <label className='col-form-label fw-bold fs-6' htmlFor='country'>
                          <span className='required'>Country</span>
                        </label>
                      </Col>
                      <Col lg="9" className='fv-row'>
                        <select
                          id='country'
                          className='form-select form-control form-control-solid form-select-solid  '
                          {...formik.getFieldProps('country')}
                          disabled={isFieldDisabled}
                        >
                          <option value=''>Select Country</option>
                          {countries?.map((country, index) => (
                            <option key={index} value={country?.code}>
                              {country?.name}
                            </option>
                          ))}
                        </select>
                        {formik.touched.country && formik.errors.country && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.country}</div>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className='mb-sm-6 mb-4'>
                      <Col lg="3">
                        <label className='col-form-label required fw-bold fs-6' htmlFor='address'>Address</label>
                      </Col>
                      <Col lg="9" className='fv-row'>
                        <input
                          type='text'
                          id='address'
                          className='form-control  form-control-solid'
                          placeholder='Address'
                          {...formik.getFieldProps('address')}
                          disabled={isFieldDisabled}
                        />
                        {formik.touched.address && formik.errors.address && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.address}</div>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className='mb-sm-6 mb-4'>
                      <Col lg="3">
                        <label className='col-form-label fw-bold fs-6' htmlFor='suite'>Apt./Suite</label>
                      </Col>
                      <Col lg="9" className='fv-row'>
                        <input
                          type='text'
                          id='suite'
                          className='form-control  form-control-solid'
                          placeholder='Apt./Suite'
                          {...formik.getFieldProps('suite')}
                          disabled={isFieldDisabled}
                        />
                        {formik.touched.suite && formik.errors.suite && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.suite}</div>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className='mb-sm-6 mb-4'>
                      <Col lg="3">
                        <label className='col-form-label required fw-bold fs-6' htmlFor='city'> City </label>
                      </Col>
                      <Col lg="9" className='fv-row'>
                        <input
                          type='text'
                          id='city'
                          className='form-control  form-control-solid'
                          placeholder='City'
                          {...formik.getFieldProps('city')}
                          disabled={isFieldDisabled}
                        />
                        {formik.touched.city && formik.errors.city && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.city}</div>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className='mb-sm-6 mb-4'>
                      <Col lg="3">
                        <label className='col-form-label required fw-bold fs-6' htmlFor='state' >State</label>
                      </Col>
                      <Col lg="9" className='fv-row'>
                        <input
                          type='text'
                          id='state'
                          className='form-control  form-control-solid'
                          placeholder='State'
                          {...formik.getFieldProps('state')}
                          disabled={isFieldDisabled}
                        />
                        {formik.touched.state && formik.errors.state && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.state}</div>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className='mb-sm-6 mb-4'>
                      <Col lg="3">
                        <label className='col-form-label fw-bold fs-6' htmlFor='postalCode'>
                          <span className='required'>Postal Code</span>
                        </label>
                      </Col>
                      <Col lg="9" className='fv-row'>
                        <input
                          type='text'
                          id='postalCode'
                          className='form-control  form-control-solid'
                          placeholder='Postal Code'
                          {...formik.getFieldProps('postalCode')}
                          disabled={isFieldDisabled}
                        />
                        {formik.touched.postalCode && formik.errors.postalCode && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.postalCode}</div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className='pb-5'>
                  {' '}
                  <h3 className='card-title mb-4'>
                    <span className='me-2 align-middle'>
                      <i className='la la-clock fs-1' aria-hidden='true'></i>
                    </span>
                    <span className='card-label fw-bold fs-3 mb-1'>TimeZone & Currency</span>
                  </h3>
                  <span className='fw-bolder m-0'>
                    Adjust the settings to fit your company's local time zone, currency, and date
                    format.
                  </span>
                </div>
                <Row>
                  <Col lg="10" xs="12" className='offset-lg-1'>
                    <Row className='mb-sm-6 mb-4'>
                      <Col lg="3">
                        <label className='col-form-label required fw-bold fs-6' htmlFor='timeZone'> {' '} TimeZone {' '} </label>
                      </Col>
                      <Col lg="9" className='fv-row'>
                        <select
                          id='timeZone'
                          className='form-select form-control form-control-solid form-select-solid'
                          {...formik.getFieldProps('timeZone')}
                          disabled={isFieldDisabled}
                          onChange={(e) => {
                            formik.setFieldTouched('timeZone', true)
                            formik.handleChange(e)
                          }}
                        >
                          <option value=''>Select TimeZone</option>
                          {timezones?.map((time, index) => (
                            <option key={index} value={time?.label}>
                              {time?.label}
                            </option>
                          ))}
                        </select>
                        {formik.touched.timeZone && formik.errors.timeZone && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.timeZone}</div>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className='mb-sm-6 mb-4'>
                      <Col lg="3">
                        <label className='col-form-label required fw-bold fs-6' htmlFor='currency'> {' '} Currency Symbol {' '} </label>
                      </Col>
                      <Col lg="9" className='fv-row'>
                        <select
                          id='currency'
                          className='form-select form-control form-control-solid form-select-solid'
                          {...formik.getFieldProps('currency')}
                          disabled={isFieldDisabled}
                          onChange={(e) => {
                            formik.setFieldTouched('currency', true)
                            formik.handleChange(e)
                          }}
                        >
                          <option value=''>Select Currency</option>
                          {currencies?.map((curr, index) => (
                            <option key={index} value={curr?.value}>
                              {curr?.name}
                            </option>
                          ))}
                        </select>
                        {formik.touched.currency && formik.errors.currency && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.currency}</div>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className='mb-sm-6 mb-4'>
                      <Col lg="3">
                        <label className='col-form-label required fw-bold fs-6' htmlFor='dateFormat'>  {' '} Date Format{' '}</label>
                      </Col>
                      <Col lg="9" className='fv-row'>
                        <select
                          id='dateFormat'
                          className='form-select form-control form-control-solid form-select-solid  '
                          {...formik.getFieldProps('dateFormat')}
                          disabled={isFieldDisabled}
                          onChange={(e) => {
                            formik.setFieldTouched('dateFormat', true)
                            formik.handleChange(e)
                          }}
                        >
                          <option value=''>Select Date Format</option>
                          <option value='MM/DD/YYYY'>
                            MM/DD/YYYY (e.g {moment().format('MM/DD/YYYY')})
                          </option>
                          <option value='YYYY-MM-DD'>
                            YYYY-MM-DD (e.g {moment().format('YYYY-MM-DD')})
                          </option>
                          <option value='DD/MM/YYYY'>
                            DD/MM/YYYY (e.g {moment().format('DD/MM/YYYY')})
                          </option>
                        </select>
                        {formik.touched.dateFormat && formik.errors.dateFormat && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.dateFormat}</div>
                          </div>
                        )}

                      </Col>
                    </Row>
                    <Row className='mb-sm-6 mb-4'>
                      <Col lg="3">
                        <label htmlFor='financialYearMonth' className='col-form-label required fw-bold fs-6' >{' '} Financial Year begins on{' '} </label>
                      </Col>
                      <Col lg="9" className='fv-row'>
                        <Row>
                          <Col sm="6" >
                            <div className='mb-sm-0 mb-3'>
                              <select
                                id='financialYearMonth'
                                className='form-select form-control form-control-solid form-select-solid me-2'
                                {...formik.getFieldProps('financialYearMonth')}
                                disabled={isFieldDisabled}
                                onClick={() => formik.setFieldTouched('financialYearMonth', true)}
                                onChange={(e) => {
                                  formik.setFieldTouched('financialYearMonth', true)
                                  handleMonthChange(e)
                                }}
                              >
                                <option value=''>Select Month</option>
                                {months.map((value, index) => (
                                  <option key={index} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.financialYearMonth &&
                                formik.errors.financialYearMonth && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {formik.errors.financialYearMonth}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </Col>
                          <Col sm="6" >
                            <div>
                              <select
                                id='financialYearDate'
                                className='form-select form-control form-control-solid form-select-solid'
                                {...formik.getFieldProps('financialYearDate')}
                                disabled={isFieldDisabled}
                                onChange={(e) => {
                                  formik.setFieldTouched('financialYearDate', true)
                                  formik.handleChange(e)
                                }}
                              >
                                <option value=''>Select Date</option>
                                {dateOptions.map((value) => (
                                  <option key={value} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.financialYearDate &&
                                formik.errors.financialYearDate && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {formik.errors.financialYearDate}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className='pb-5'>
                  {' '}
                  <h3 className='card-title mb-4'>
                    <span className='me-2 align-middle'>
                      <i className='la la-image fs-1' aria-hidden='true'></i>
                    </span>
                    <span className='card-label fw-bold fs-3 mb-1'>Company Logo</span>
                  </h3>
                  <span className='fw-bolder m-0'>
                    Upload your organization’s logo to make this space your own.
                  </span>
                </div>
                {!permission?.updateCompanyInfo && staffUser ? (
                  ''
                ) : (
                  <Row>
                    <Col lg="10" className='offset-lg-1 col-12'>
                      <Row className='mb-sm-6 mb-4'>
                        <Col lg="3">
                          <label htmlFor='logo' className='col-form-label fw-bold fs-6'>Logo</label>
                        </Col>
                        <Col lg="9">
                          {loadingAvatar ? (
                            <MoonLoader color='#0d145c' loading={loadingAvatar} />
                          ) : (
                            <div
                              className='image-input image-input-outline'
                              data-kt-image-input='true'
                            >
                              <label htmlFor='logo' className='cursor-pointer position-relative'>
                                <div>
                                  <img
                                    className='image-input-wrapper w-125px h-125px object-fit-contain'
                                    src={
                                      fileDataURL
                                        ? fileDataURL.toString()
                                        : logo
                                          ? toAbsoluteUrlImage(logo)
                                          : Images.Avatar
                                    }
                                    alt='profile'
                                    crossOrigin='anonymous'
                                    onError={(e) => {
                                      e.currentTarget.src = Images.Avatar
                                      setImageErr(true)
                                    }}
                                  />
                                </div>
                                <span className='edit-icon'>
                                  <i className='las la-camera fs-2'></i>
                                </span>
                                {logo && (
                                  <span onClick={(e) => deleteImage(e)} className='delete-icon'>
                                    <i className='las la-trash-alt fs-2'></i>
                                  </span>
                                )}
                              </label>
                              <div>
                                <input
                                  className='form-control d-none'
                                  type='file'
                                  name='logo'
                                  id='logo'
                                  accept='.png, .jpg, .jpeg'
                                  onChange={onImageChange}
                                />
                              </div>
                              <p className='mb-0'>
                                Only (<strong>JPG, GIF, PNG</strong>) are allowed
                              </p>
                              {imageErr && (
                                <div className='text-danger py-2'>
                                  Something went wrong while uploading image
                                </div>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                {!permission?.updateCompanyInfo && staffUser ? (
                  ''
                ) : (
                  <button
                    onClick={(e) => formSubmit(e)}
                    className='btn btn-primary main-btn-style me-2'
                    disabled={isUpdating || !formik.isValid || imageErr}
                  >
                    {' '}
                    {!isUpdating && 'Submit'}
                    {isUpdating && (
                      <span className='indicator-progress d-block'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                )}
                {props.showContinue && pathname == '/apps/setup/wizard' && (
                  <button className='btn btn-light-primary' onClick={props.handleContinue}>
                    {' '}
                    Continue
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        {userType === 1 && companyData && pathname !== '/apps/setup/wizard' ? (
          <DeleteAccount handleDelete={handleDelete} />
        ) : null}
      </>
      <Loader loading={loading} />
    </>
  )
}

export default CompanyInfo
