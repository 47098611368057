import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import ReCAPTCHA from 'react-google-recaptcha'
import { useSendAssetEmailMutation } from 'App/Modules/Services/Assets'
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { commaRemoval, mailCheck } from 'config'

interface EmailModalProps {
  emailModal: boolean
  handleClose: () => void
  assetId: any
}

const validationSchema = Yup.object({
  email: Yup.string()
    .required('Email is required')
    .test('email-format', 'Invalid email format', (value) => {
      if (!value) return false
      const newValue = value.trim().replace(commaRemoval, '')
      const emails = newValue.split(',').map((email) => email.trim())
      return emails.every((email) => mailCheck.test(email))
    }),
  notes: Yup.string().max(250, 'Note should not exceed 250 characters'),
  document: Yup.boolean(),
  reCaptcha: Yup.string().required('ReCAPTCHA verification is required'),
})

const EmailModal: React.FC<EmailModalProps> = ({ emailModal, handleClose, assetId }) => {
  const [sendAssetEmail] = useSendAssetEmailMutation()
  const { saveAuth, setCurrentUser } = useAuth()

  const handleCaptchaChange = (
    value: string | null,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setFieldValue('reCaptcha', value || '')
  }

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      const { email, notes, document } = values
      const mailString = email.trim().replace(commaRemoval, '')
      const data = await sendAssetEmail({
        assetId,
        email: mailString,
        notes,
        document,
      }).unwrap()

      if (data.success) {
        toast.success('Email sent successfully!')
        handleClose()
      }
    } catch (error: any) {
      if (error instanceof Error) {
        toast.error(`Failed to send email. ${error.message || 'Please try again later.'}`)
        checkUserUnauthorized(error, saveAuth, setCurrentUser, toast)
      } else {
        toast.error('Failed to send email. Please try again later.')
        checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Modal show={emailModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Email Asset</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ email: '', notes: '', document: false, reCaptcha: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnMount={true}
        >
          {({ setFieldValue, isValid }) => (
            <FormikForm>
              <Form.Group className='mb-3' controlId='email'>
                <Form.Label className='required'>Email </Form.Label>
                <Field
                  type='text'
                  name='email'
                  placeholder='Enter email'
                  as={Form.Control}
                  onChange={(e) => {
                    const lastVal = e.target?.value[e.target?.value?.length - 1]
                    setFieldValue('email', e.target.value)
                    if (lastVal === ',') {
                      const emails = e.target.value
                        .split(',')
                        .map((email) => email.trim())
                        .filter((email) => mailCheck.test(email))
                      setFieldValue('email', emails.join(' ,').concat(' ,'))
                    }
                  }}
                />

                <ErrorMessage name='email' component='div' className='text-danger' />
                <Form.Text className='text-muted'>* Separate each email with a comma.</Form.Text>
              </Form.Group>
              <Form.Group className='mb-3' controlId='notes'>
                <Form.Label>Notes</Form.Label>
                <Field rows={3} name='notes' placeholder='Enter notes' as={Form.Control} />
                <ErrorMessage name='notes' component='div' className='text-danger' />
              </Form.Group>
              <Form.Label className='me-2'>Attachment</Form.Label>
              <Form.Group className='mb-3 d-flex align-items-center'>
                <Field type='checkbox' name='document' as={Form.Check} />
                <Form.Check.Label className='ms-2'>Document</Form.Check.Label>
              </Form.Group>
              <Form.Group className='mb-5 d-flex align-items-center justify-content-center'>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                  onChange={(value) => handleCaptchaChange(value, setFieldValue)}
                />
                <ErrorMessage name='reCaptcha' component='div' className='text-danger' />
              </Form.Group>
              <Modal.Footer>
                <button type='button' className='btn cancel-btn border' onClick={handleClose}>
                  Cancel
                </button>
                <button type='submit' className='btn btn-primary' disabled={!isValid}>
                  Send Email
                </button>
              </Modal.Footer>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default EmailModal
