import { useAddAuditNameMutation } from 'App/Modules/Services/AuditName'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'

import { toast } from 'react-toastify'

interface NewAuditModalProps {
  newAuditModal: boolean
  handleClose: () => void
}

const NewAuditModal: React.FC<NewAuditModalProps> = ({ newAuditModal, handleClose }) => {
  const [auditName, setAuditName] = useState('')
  const [addAuditName, { isLoading: isAdding }] = useAddAuditNameMutation()

  const handleAdd = async () => {
    try {
      const result = await addAuditName({ name: auditName })
      if (result?.data?.success) {
        handleClose()
        toast.success(result?.data?.message)
      } else {
        toast.error((result?.error as any)?.data.message)
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  return (
    <Modal show={newAuditModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Add Audit Name</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <div className='mb-3'>
            <input
              name='name'
              type='text'
              className='form-control'
              onChange={(e) => setAuditName(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn cancel-btn border' onClick={handleClose}>
            Cancel
          </button>
          <button type='button' className='btn btn-primary' onClick={handleAdd}>
            Add
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default NewAuditModal
