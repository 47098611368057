export const currencies = [
  { value: 'AFN', name: 'AFN - Afghan Afghani - ؋' },
  { value: 'ALL', name: 'ALL - Albanian Lek - Lek' },
  { value: 'DZD', name: 'DZD - Algerian Dinar - دج' },
  { value: 'AOA', name: 'AOA - Angolan Kwanza - Kz' },
  { value: 'ARS', name: 'ARS - Argentine Peso - $' },
  { value: 'AMD', name: 'AMD - Armenian Dram - ֏' },
  { value: 'AWG', name: 'AWG - Aruban Florin - ƒ' },
  { value: 'AUD', name: 'AUD - Australian Dollar - $' },
  { value: 'AZN', name: 'AZN - Azerbaijani Manat - m' },
  { value: 'BSD', name: 'BSD - Bahamian Dollar - B$' },
  { value: 'BHD', name: 'BHD - Bahraini Dinar - .د.ب' },
  { value: 'BDT', name: 'BDT - Bangladeshi Taka - ৳' },
  { value: 'BBD', name: 'BBD - Barbadian Dollar - Bds$' },
  { value: 'BYR', name: 'BYR - Belarusian Ruble - Br' },
  { value: 'BEF', name: 'BEF - Belgian Franc - fr' },
  { value: 'BZD', name: 'BZD - Belize Dollar - $' },
  { value: 'BMD', name: 'BMD - Bermudan Dollar - $' },
  { value: 'BTN', name: 'BTN - Bhutanese Ngultrum - Nu.' },
  { value: 'BTC', name: 'BTC - Bitcoin - ฿' },
  { value: 'BOB', name: 'BOB - Bolivian Boliviano - Bs.' },
  { value: 'BAM', name: 'BAM - Bosnia-Herzegovina Convertible Mark - KM' },
  { value: 'BWP', name: 'BWP - Botswanan Pula - P' },
  { value: 'BRL', name: 'BRL - Brazilian Real - R$' },
  { value: 'GBP', name: 'GBP - British Pound Sterling - £' },
  { value: 'BND', name: 'BND - Brunei Dollar - B$' },
  { value: 'BGN', name: 'BGN - Bulgarian Lev - Лв.' },
  { value: 'BIF', name: 'BIF - Burundian Franc - FBu' },
  { value: 'KHR', name: 'KHR - Cambodian Riel - KHR' },
  { value: 'CAD', name: 'CAD - Canadian Dollar - $' },
  { value: 'CVE', name: 'CVE - Cape Verdean Escudo - $' },
  { value: 'KYD', name: 'KYD - Cayman Islands Dollar - $' },
  { value: 'XOF', name: 'XOF - CFA Franc BCEAO - CFA' },
  { value: 'XAF', name: 'XAF - CFA Franc BEAC - FCFA' },
  { value: 'XPF', name: 'XPF - CFP Franc - ₣' },
  { value: 'CLP', name: 'CLP - Chilean Peso - $' },
  { value: 'CNY', name: 'CNY - Chinese Yuan - ¥' },
  { value: 'COP', name: 'COP - Colombian Peso - $' },
  { value: 'KMF', name: 'KMF - Comorian Franc - CF' },
  { value: 'CDF', name: 'CDF - Congolese Franc - FC' },
  { value: 'CRC', name: 'CRC - Costa Rican Colón - ₡' },
  { value: 'HRK', name: 'HRK - Croatian Kuna - kn' },
  { value: 'CUC', name: 'CUC - Cuban Convertible Peso - $, CUC' },
  { value: 'CZK', name: 'CZK - Czech Republic Koruna - Kč' },
  { value: 'DKK', name: 'DKK - Danish Krone - Kr.' },
  { value: 'DJF', name: 'DJF - Djiboutian Franc - Fdj' },
  { value: 'DOP', name: 'DOP - Dominican Peso - $' },
  { value: 'XCD', name: 'XCD - East Caribbean Dollar - $' },
  { value: 'EGP', name: 'EGP - Egyptian Pound - ج.م' },
  { value: 'ERN', name: 'ERN - Eritrean Nakfa - Nfk' },
  { value: 'EEK', name: 'EEK - Estonian Kroon - kr' },
  { value: 'ETB', name: 'ETB - Ethiopian Birr - Nkf' },
  { value: 'EUR', name: 'EUR - Euro - €' },
  { value: 'FKP', name: 'FKP - Falkland Islands Pound - £' },
  { value: 'FJD', name: 'FJD - Fijian Dollar - FJ$' },
  { value: 'GMD', name: 'GMD - Gambian Dalasi - D' },
  { value: 'GEL', name: 'GEL - Georgian Lari - ლ' },
  { value: 'DEM', name: 'DEM - German Mark - DM' },
  { value: 'GHS', name: 'GHS - Ghanaian Cedi - GH₵' },
  { value: 'GIP', name: 'GIP - Gibraltar Pound - £' },
  { value: 'GRD', name: 'GRD - Greek Drachma - ₯, Δρχ, Δρ' },
  { value: 'GTQ', name: 'GTQ - Guatemalan Quetzal - Q' },
  { value: 'GNF', name: 'GNF - Guinean Franc - FG' },
  { value: 'GYD', name: 'GYD - Guyanaese Dollar - $' },
  { value: 'HTG', name: 'HTG - Haitian Gourde - G' },
  { value: 'HNL', name: 'HNL - Honduran Lempira - L' },
  { value: 'HKD', name: 'HKD - Hong Kong Dollar - $' },
  { value: 'HUF', name: 'HUF - Hungarian Forint - Ft' },
  { value: 'ISK', name: 'ISK - Icelandic Króna - kr' },
  { value: 'INR', name: 'INR - Indian Rupee - ₹' },
  { value: 'IDR', name: 'IDR - Indonesian Rupiah - Rp' },
  { value: 'IRR', name: 'IRR - Iranian Rial - ﷼' },
  { value: 'IQD', name: 'IQD - Iraqi Dinar - د.ع' },
  { value: 'ILS', name: 'ILS - Israeli New Sheqel - ₪' },
  { value: 'ITL', name: 'ITL - Italian Lira - L,£' },
  { value: 'JMD', name: 'JMD - Jamaican Dollar - J$' },
  { value: 'JPY', name: 'JPY - Japanese Yen - ¥' },
  { value: 'JOD', name: 'JOD - Jordanian Dinar - ا.د' },
  { value: 'KZT', name: 'KZT - Kazakhstani Tenge - лв' },
  { value: 'KES', name: 'KES - Kenyan Shilling - KSh' },
  { value: 'KWD', name: 'KWD - Kuwaiti Dinar - ك.د' },
  { value: 'KGS', name: 'KGS - Kyrgystani Som - лв' },
  { value: 'LAK', name: 'LAK - Laotian Kip - ₭' },
  { value: 'LVL', name: 'LVL - Latvian Lats - Ls' },
  { value: 'LBP', name: 'LBP - Lebanese Pound - £' },
  { value: 'LSL', name: 'LSL - Lesotho Loti - L' },
  { value: 'LRD', name: 'LRD - Liberian Dollar - $' },
  { value: 'LYD', name: 'LYD - Libyan Dinar - د.ل' },
  { value: 'LTL', name: 'LTL - Lithuanian Litas - Lt' },
  { value: 'MOP', name: 'MOP - Macanese Pataca - $' },
  { value: 'MKD', name: 'MKD - Macedonian Denar - ден' },
  { value: 'MGA', name: 'MGA - Malagasy Ariary - Ar' },
  { value: 'MWK', name: 'MWK - Malawian Kwacha - MK' },
  { value: 'MYR', name: 'MYR - Malaysian Ringgit - RM' },
  { value: 'MVR', name: 'MVR - Maldivian Rufiyaa - Rf' },
  { value: 'MRO', name: 'MRO - Mauritanian Ouguiya - MRU' },
  { value: 'MUR', name: 'MUR - Mauritian Rupee - ₨' },
  { value: 'MXN', name: 'MXN - Mexican Peso - $' },
  { value: 'MDL', name: 'MDL - Moldovan Leu - L' },
  { value: 'MNT', name: 'MNT - Mongolian Tugrik - ₮' },
  { value: 'MAD', name: 'MAD - Moroccan Dirham - MAD' },
  { value: 'MZM', name: 'MZM - Mozambican Metical - MT' },
  { value: 'MMK', name: 'MMK - Myanmar Kyat - K' },
  { value: 'NAD', name: 'NAD - Namibian Dollar - $' },
  { value: 'NPR', name: 'NPR - Nepalese Rupee - ₨' },
  { value: 'ANG', name: 'ANG - Netherlands Antillean Guilder - ƒ' },
  { value: 'TWD', name: 'TWD - New Taiwan Dollar - $' },
  { value: 'NZD', name: 'NZD - New Zealand Dollar - $' },
  { value: 'NIO', name: 'NIO - Nicaraguan Córdoba - C$' },
  { value: 'NGN', name: 'NGN - Nigerian Naira - ₦' },
  { value: 'KPW', name: 'KPW - North Korean Won - ₩' },
  { value: 'NOK', name: 'NOK - Norwegian Krone - kr' },
  { value: 'OMR', name: 'OMR - Omani Rial - .ع.ر' },
  { value: 'PKR', name: 'PKR - Pakistani Rupee - ₨' },
  { value: 'PAB', name: 'PAB - Panamanian Balboa - B/.' },
  { value: 'PGK', name: 'PGK - Papua New Guinean Kina - K' },
  { value: 'PYG', name: 'PYG - Paraguayan Guarani - ₲' },
  { value: 'PEN', name: 'PEN - Peruvian Nuevo Sol - S/.' },
  { value: 'PHP', name: 'PHP - Philippine Peso - ₱' },
  { value: 'PLN', name: 'PLN - Polish Zloty - zł' },
  { value: 'QAR', name: 'QAR - Qatari Rial - ق.ر' },
  { value: 'RON', name: 'RON - Romanian Leu - lei' },
  { value: 'RUB', name: 'RUB - Russian Ruble - ₽' },
  { value: 'RWF', name: 'RWF - Rwandan Franc - FRw' },
  { value: 'SVC', name: 'SVC - Salvadoran ColÃ³n - ₡' },
  { value: 'WST', name: 'WST - Samoan Tala - SAT' },
  { value: 'SAR', name: 'SAR - Saudi Riyal - ﷼' },
  { value: 'RSD', name: 'RSD - Serbian Dinar - din' },
  { value: 'SCR', name: 'SCR - Seychellois Rupee - SRe' },
  { value: 'SLL', name: 'SLL - Sierra Leonean Leone - Le' },
  { value: 'SGD', name: 'SGD - Singapore Dollar - $' },
  { value: 'SKK', name: 'SKK - Slovak Koruna - Sk' },
  { value: 'SBD', name: 'SBD - Solomon Islands Dollar - Si$' },
  { value: 'SOS', name: 'SOS - Somali Shilling - Sh.so.' },
  { value: 'ZAR', name: 'ZAR - South African Rand - R' },
  { value: 'KRW', name: 'KRW - South Korean Won - ₩' },
  { value: 'XDR', name: 'XDR - Special Drawing Rights - SDR' },
  { value: 'LKR', name: 'LKR - Sri Lankan Rupee - Rs' },
  { value: 'SHP', name: 'SHP - St. Helena Pound - £' },
  { value: 'SDG', name: 'SDG - Sudanese Pound - .س.ج' },
  { value: 'SRD', name: 'SRD - Surinamese Dollar - $' },
  { value: 'SZL', name: 'SZL - Swazi Lilangeni - E' },
  { value: 'SEK', name: 'SEK - Swedish Krona - kr' },
  { value: 'CHF', name: 'CHF - Swiss Franc - CHf' },
  { value: 'SYP', name: 'SYP - Syrian Pound - LS' },
  { value: 'STD', name: 'STD - São Tomé and Príncipe Dobra - Db' },
  { value: 'TJS', name: 'TJS - Tajikistani Somoni - SM' },
  { value: 'TZS', name: 'TZS - Tanzanian Shilling - TSh' },
  { value: 'THB', name: 'THB - Thai Baht - ฿' },
  { value: 'TOP', name: "TOP - Tongan pa'anga - $" },
  { value: 'TTD', name: 'TTD - Trinidad & Tobago Dollar - $' },
  { value: 'TND', name: 'TND - Tunisian Dinar - ت.د' },
  { value: 'TRY', name: 'TRY - Turkish Lira - ₺' },
  { value: 'TMT', name: 'TMT - Turkmenistani Manat - T' },
  { value: 'UGX', name: 'UGX - Ugandan Shilling - USh' },
  { value: 'UAH', name: 'UAH - Ukrainian Hryvnia - ₴' },
  { value: 'AED', name: 'AED - United Arab Emirates Dirham - إ.د' },
  { value: 'UYU', name: 'UYU - Uruguayan Peso - $' },
  { value: 'USD', name: 'USD - US Dollar - $' },
  { value: 'UZS', name: 'UZS - Uzbekistan Som - лв' },
  { value: 'VUV', name: 'VUV - Vanuatu Vatu - VT' },
  { value: 'VEF', name: 'VEF - Venezuelan BolÃ­var - Bs' },
  { value: 'VND', name: 'VND - Vietnamese Dong - ₫' },
  { value: 'YER', name: 'YER - Yemeni Rial - ﷼' },
  { value: 'ZMK', name: 'ZMK - Zambian Kwacha - ZK' },
]
