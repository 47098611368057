import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const auditNameApi = createApi({
  reducerPath: 'auditNameApi',
  baseQuery: customFetchBaseQuery({ path: 'audit-name' }),
  tagTypes: ['AuditNames'],
  endpoints: (build) => ({
    getAllAuditNames: build.query<any, any>({
      query: () => ({
        url: 'get-all',
        method: 'GET',
      }),
      providesTags: ['AuditNames'],
    }),
    addAuditName: build.mutation<any, any>({
      query: (body) => ({
        url: 'create',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'AuditNames' }],
    }),
    updateAuditName: build.mutation<any, { auditNameId: any; values: any }>({
      query: ({ auditNameId, values }) => ({
        url: `update/${auditNameId}`,
        method: 'PUT',
        body: values,
      }),
    }),
    deleteAuditName: build.mutation<any, { auditNameId: any }>({
      query: ({ auditNameId }) => ({
        url: `delete/${auditNameId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetAllAuditNamesQuery,
  useAddAuditNameMutation,
  useUpdateAuditNameMutation,
  useDeleteAuditNameMutation,
} = auditNameApi
