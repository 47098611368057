import Modal from 'react-bootstrap/Modal'
import QRCodeComponent from '../QrCode'
import BarcodeComponent from '../BarCode'

interface NewAuditModalProps {
  scanModal: boolean
  handleClose: () => void
  assetId: any
}

const ScanModal: React.FC<NewAuditModalProps> = ({ scanModal, handleClose, assetId }) => {
  return (
    <Modal show={scanModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Scan Asset Data</Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex justify-content-center align-items-center gap-4'>
        <QRCodeComponent asset={assetId} />
        <BarcodeComponent asset={assetId} />
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn cancel-btn border' onClick={handleClose}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ScanModal
