import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const exportsApi = createApi({
  reducerPath: 'exportsApi',
  baseQuery: customFetchBaseQuery({ path: 'export' }),
  endpoints: (build) => ({
    getTableColumns: build.mutation<any, any>({
      query: (data) => ({
        url: `get-columns`,
        method: 'POST',
        body: data,
      }),
    }),
    getExportCategories: build.mutation<any, any>({
      query: (data) => ({
        url: `categories`,
        method: 'POST',
        body: data,
      }),
    }),
    getExportDepartments: build.mutation<any, any>({
      query: (data) => ({
        url: `departments`,
        method: 'POST',
        body: data,
      }),
    }),
    getExportSites: build.mutation<any, any>({
      query: (data) => ({
        url: `sites`,
        method: 'POST',
        body: data,
      }),
    }),
    getExportCustomers: build.mutation<any, any>({
      query: (data) => ({
        url: `customers`,
        method: 'POST',
        body: data,
      }),
    }),
    getExportLocations: build.mutation<any, any>({
      query: (data) => ({
        url: `locations`,
        method: 'POST',
        body: data,
      }),
    }),
    getExportAssets: build.mutation<any, any>({
      query: (data) => ({
        url: `assets`,
        method: 'POST',
        body: data,
      }),
    }),
    getExportMaintenances: build.mutation<any, any>({
      query: (data) => ({
        url: `maintenances`,
        method: 'POST',
        body: data,
      }),
    }),
    getExportPersons: build.mutation<any, any>({
      query: (data) => ({
        url: `persons`,
        method: 'POST',
        body: data,
      }),
    }),
    getExportWarranties: build.mutation<any, any>({
      query: (data) => ({
        url: `warranties`,
        method: 'POST',
        body: data,
      }),
    }),
    updateExportSetupColumns: build.mutation<any, any>({
      query: (data) => ({
        url: `update`,
        method: 'PUT',
        body: data,
      }),
    }),
  }),
})

export const {
  useGetTableColumnsMutation,
  useGetExportCategoriesMutation,
  useGetExportDepartmentsMutation,
  useGetExportSitesMutation,
  useGetExportCustomersMutation,
  useGetExportLocationsMutation,
  useGetExportAssetsMutation,
  useGetExportMaintenancesMutation,
  useGetExportPersonsMutation,
  useGetExportWarrantiesMutation,
  useUpdateExportSetupColumnsMutation,
} = exportsApi
