import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { register } from '../Core/Requests'
import { Link, useNavigate } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../Template/assets/ts/components'
import ReCAPTCHA from 'react-google-recaptcha'
import { toast } from 'react-toastify'
import UserAccountModal from './Modal/userAccountModal'
import { emailRegex, multiSpace, nameRegex, passwordRegex } from 'config'
import * as Yup from 'yup'
import clsx from 'clsx'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  agreedToTerms: false,
  reCaptcha: '',
}

const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(nameRegex, 'Only letters are allowed')
    .min(3, 'Minimum 3 letters')
    .max(20, 'First name should not exceed 20 characters')
    .required('First name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  lastName: Yup.string()
    .trim()
    .matches(nameRegex, 'Only letters are allowed')
    .min(3, 'Minimum 3 letters')
    .max(20, 'Last name should not exceed 20 characters')
    .required('Last name is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(50, 'Maximum 50 characters')
    .matches(
      passwordRegex,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, one special character and no spaces'
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Confirm new password is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  agreedToTerms: Yup.bool().required('You must accept the Terms and Conditions'),
  isSetupComleted: Yup.bool(),
  reCaptcha: Yup.string().required('ReCaptcha is required'),
})

export function Registration() {
  const navigate = useNavigate()
  const [modalShow, setModalShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [captchaLoaded, setCaptchaLoaded] = useState(false)

  const handleCaptchaChange = (response: string | null): void => {
    if (response) {
      formik.setFieldValue('reCaptcha', response || '')
    }
  }

  const handleCaptchaLoad = () => {
    setCaptchaLoaded(true)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true)
      try {
        const res = await register(
          values.email?.trim(),
          values.firstName?.trim().replace(multiSpace, ' '),
          values.lastName?.trim().replace(multiSpace, ' '),
          values.password,
          values.agreedToTerms
        )
        toast.success(res?.data?.message)
        setSubmitting(false)
        setLoading(false)
        navigate(`/auth/verify-email?email=${values.email}`)
      } catch (error: any) {
        toast.dismiss()
        if (!error?.response?.data?.success) {
          toast.error(error?.response?.data?.message)
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='mb-3 text-center'>
        <h1 className='text-dark mb-2'>Create an Account</h1>
        <div className='text-gray-700 fs-5'>
          Already have an account?
          <Link to='/auth/login' className='link-primary fw-bolder ms-1'>
            Sign In
          </Link>
        </div>
        <span
          className='link-primary fw-bolder fs-5 mb-2 cursor-pointer'
          onClick={() => setModalShow(true)}
        >
          Need to be added as a user to an existing account?
        </span>
        <UserAccountModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark required fs-6'>First name</label>
        <input
          placeholder='First name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstName')}
          className={clsx(
            'form-control  form-control-solid',
            {
              'is-invalid': formik.touched.firstName && formik.errors.firstName,
            },
            {
              'is-valid': formik.touched.firstName && !formik.errors.firstName,
            }
          )}
        />
        {formik.touched.firstName && formik.errors.firstName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstName}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark required fs-6'>Last name</label>
        <input
          placeholder='Last name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastName')}
          className={clsx(
            'form-control  form-control-solid',
            {
              'is-invalid': formik.touched.lastName && formik.errors.lastName,
            },
            {
              'is-valid': formik.touched.lastName && !formik.errors.lastName,
            }
          )}
        />
        {formik.touched.lastName && formik.errors.lastName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastName}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control  form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-4' data-kt-password-meter='true'>
        <div className='mb-1 p-0'>
          <label className='form-label fw-bolder text-dark required required fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control  form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <i
              onClick={() => setShowPassword((prevState) => !prevState)}
              className={
                showPassword
                  ? 'bi bi-eye-slash fs-md-5 fs-6' + ' login-eye'
                  : 'bi bi-eye fs-md-5 fs-6' + ' login-eye'
              }
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-danger rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-warning rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
        </div>
        <div className='text-gray-700'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark required fs-6'>Confirm Password</label>
        <div className='position-relative mb-3'>
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('confirmPassword')}
            className={clsx(
              'form-control form-control-solid',
              {
                'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
              },
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              }
            )}
          />
          <i
            onClick={() => setShowConfirmPassword((prevState) => !prevState)}
            className={
              showConfirmPassword
                ? 'bi bi-eye-slash fs-md-5 fs-6' + ' login-eye'
                : 'bi bi-eye fs-md-5 fs-6' + ' login-eye'
            }
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='fv-row mb-4'>
        <div className='form-check form-check-custom '>
          <input
            className='form-check-input custom-form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('agreedToTerms')}
          />
          <label className='form-check-label text-gray-700' htmlFor='kt_login_toc_agree'>
            I Agree with{''}
            <Link to='/terms-conditions' className='ms-1 link-primary fw-bold'>
              Terms and Conditions
            </Link> .
          </label>
          {formik.touched.agreedToTerms && formik.errors.agreedToTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.agreedToTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='fv-row mb-sm-7 mb-4 d-flex flex-column justify-content-center align-items-center'>
        <div className='site-recaptcha'>
          <div className='captcha'>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
              onChange={handleCaptchaChange}
              onExpired={() => formik.setFieldValue('reCaptcha', '')}
              onErrored={() => formik.setFieldValue('reCaptcha', '')}
              asyncScriptOnLoad={handleCaptchaLoad}
            />
            {!captchaLoaded ? <> Captcha Loading...</> : <></>}
          </div>
        </div>
        {formik.touched.reCaptcha && formik.errors.reCaptcha && (
          <div className='fv-plugins-message-container site-recaptcha-message'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.reCaptcha}</span>
            </div>
          </div>
        )}
      </div>
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-sm-4 mb-3'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.agreedToTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress d-block'>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-0'
          >
            Cancel
          </button>
        </Link>
      </div>
    </form>
  )
}
