import Loader from 'App/Loaders/BeatLoader'
import { useDeleteReserveAssetsByIdMutation } from 'App/Modules/Services/Reserve'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'

interface DeleteModalProps {
  deleteReserveModal: boolean
  handleClose: () => void
  reserveId: any
  handleCloseEdit: any
  refetch: any
}

const DeleteReserveModal: React.FC<DeleteModalProps> = ({
  deleteReserveModal,
  handleClose,
  reserveId,
  handleCloseEdit,
  refetch,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteReserve] = useDeleteReserveAssetsByIdMutation()

  const handleDelete = async () => {
    setLoading(true)
    try {
      await deleteReserve({ reserveId }).unwrap()
      handleClose()
      handleCloseEdit()
      refetch()
    } catch (error: any) {
      throw new Error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal show={deleteReserveModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Delete Reservation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className='mb-5'>Are you sure you want to delete this reservation?</h5>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={handleDelete} disabled={loading}>
          {loading ? 'Deleting...' : 'Delete'}
        </button>
      </Modal.Footer>
      <Loader loading={loading} />
    </Modal>
  )
}

export default DeleteReserveModal
