import React, { useEffect, useState } from 'react'
import { checkUserUnauthorized, useAuth } from '../Auth'
import { AccountDetail } from './AccountDetailsList/Core/Model'
import { useGetAccountDetailsQuery } from '../Services/AccountsDetails'
import SplashScreen from 'App/Loaders/SplashScreen'
import { toast } from 'react-toastify'
import { dateFormat, defaultDateFormat } from 'config'
import { currencies } from 'Data/Currency'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const AccountDetailHeader: React.FC = () => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const personUser = currentUser?.userType === 3
  const customerUser = currentUser?.userType === 4
  const [account, setAccount] = useState<AccountDetail>()
  const [error, setError] = useState<any>()
  const {
    data: accountData,
    isSuccess,
    isError,
    error: accountError,
    isLoading,
  } = useGetAccountDetailsQuery()

  useEffect(() => {
    if (currentUser) {
      let accountDetails: React.SetStateAction<AccountDetail | undefined>
      const condition =
        isSuccess && accountData ? 'success' : isError && accountError ? 'error' : 'default'

      switch (condition) {
        case 'success':
          accountDetails = accountData!
          setAccount(accountDetails)
          setError('')
          break

        case 'error':
          setError(accountError!)
          break

        default:
          // Optional: handle cases where neither condition is met
          break
      }
    }
  }, [accountData, isSuccess, isError])

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
    }
  }, [error])

  const currencyObject = currencies.find((currency) => currency.value === currentUser?.currency)

  const splitName = currencyObject?.name.split(' - ')
  const currencySymbol = splitName && splitName[2]

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoading} />
      {!isLoading ? (
        <Row className='g-5 g-xl-8 mb-5'>
          <Col xl={2} lg={4} md={3} sm={4} xs={6}>
            <div className='card  h-100'>
              <div className='account_details_card d-flex px-3 py-5 justify-content-start'>
                <div className='account_details_icon me-2'>
                  <i className='la la-user fs-2' aria-hidden='true'></i>
                </div>
                <div className='account_details_desc'>
                  <div className=' mb-2'>Account No.</div>
                  <div>
                    <strong>
                      {account?.accountDetails?.accountNo
                        ? account?.accountDetails?.accountNo
                        : 'N/A'}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {!(personUser || customerUser) && (
            <Col xl={2} lg={4} md={3} sm={4} xs={6}>
              <div className='card  h-100'>
                <div className='account_details_card d-flex px-3 py-5 justify-content-start'>
                  <div className='account_details_icon me-2'>
                    <i className='la fs-2' aria-hidden='true'>
                      {currencySymbol}
                    </i>
                  </div>
                  <div className='account_details_desc'>
                    <div className=' mb-2'>Balance:</div>
                    <div>
                      <i className='la fs-2' aria-hidden='true' style={{ marginRight: '4px' }}>
                        {currencySymbol}
                      </i>
                      <strong>{account?.accountDetails?.balance}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
         <Col xl={2} lg={4} md={3} sm={4} xs={6}>
            <div className='card  h-100'>
              <div className='account_details_card d-flex px-3 py-5 justify-content-start'>
                <div className='account_details_icon me-2'>
                  <i className='la la-user-clock fs-2' aria-hidden='true'></i>
                </div>
                <div className='account_details_desc'>
                  <div className=' mb-2'>Account Status:</div>
                  <div>
                    <strong>
                      {account?.accountDetails?.accountStatus
                        ? account?.accountDetails?.accountStatus
                        : 'N/A'}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {!(personUser || customerUser) && (
          <Col xl={2} lg={4} md={3} sm={4} xs={6}>
              <div className='card  h-100'>
                <div className='account_details_card d-flex px-3 py-5 justify-content-start'>
                  <div className='account_details_icon me-2'>
                    <i className='la la-puzzle-piece fs-2' aria-hidden='true'></i>
                  </div>
                  <div className='account_details_desc'>
                    <div className=' mb-2'>No. of Assets:</div>
                    <div>
                      <strong>
                        {account?.accountDetails?.assetCount
                          ? account?.accountDetails?.assetCount
                          : 0}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
          </Col>
          )}
          <Col xl={2} lg={4} md={3} sm={4} xs={6}>
            <div className='card  h-100'>
              <div className='account_details_card d-flex px-3 py-5 justify-content-start'>
                <div className='account_details_icon me-2'>
                  <i className='la la-calendar fs-2' aria-hidden='true'></i>
                </div>
                <div className='account_details_desc'>
                  <div className=' mb-2'>Account Since:</div>
                  <div>
                    <strong>
                      {account?.accountDetails?.createdAt
                        ? dateFormat(
                          account?.accountDetails?.createdAt,
                          currentUser?.dateFormat || defaultDateFormat
                        )
                        : 'N/A'}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      ) : null}
    </>
  )
}

export default AccountDetailHeader
