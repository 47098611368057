import { Link } from 'react-router-dom'
import './Subscription.css'

function Subscription() {
  return (
    <section className='subscription-section'>
      <div className='custom-container container'>
        <div className='text text-center mb-xl-5 mb-4'>
          <h2 className='h-main mb-xl-5 mb-4 text-white'>Subscription Plans</h2>
          <p className='mb-xl-5 mb-4'>
            Effective January 1, 2022. Subscription is annual, fixed to the calendar year. Late
            Subscription will be prorated within the first year.
          </p>
        </div>
        <div className='row pt-5'>
          <div className='col-xl-2 col-lg-4 col-sm-6 mb-lg-3 mb-md-4 mb-3'>
            <div className='box text-center active'>
              <div className='box-head'>
                <div className='text mb-xxl-5 mb-2'>
                  <p>Upto</p>
                  <strong className='h-main'>500</strong>
                  <p className='mb-0'>Assets</p>
                </div>
              </div>
              <div className='box-body'>
                <div>
                  <p className='mb-xl-5 mb-4'>Subscription Plan</p>
                  <span className='d-block mb-xxl-5 mb-2 fw-bold p-mini'>ARS-500</span>
                  <strong className='h-main'>500</strong>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-2 col-lg-4 col-sm-6 mb-lg-3 mb-md-4 mb-3'>
            <div className='box text-center'>
              <div className='box-head'>
                <div className='text mb-xxl-5 mb-2'>
                  <p>Upto</p>
                  <strong className='h-main'>500</strong>
                  <p className='mb-0'>Assets</p>
                </div>
              </div>
              <div className='box-body'>
                <p className='mb-xl-5 mb-4'>Subscription Plan</p>
                <span className='d-block mb-xxl-5 mb-2 fw-bold p-mini'>ARS-500</span>
                <div className='price mb-xxl-5 mb-2'>
                  <strong>
                    <span>$120</span>
                    {' / '}
                    <span>Year</span>
                  </strong>
                </div>
                <div className='or-section d-flex justify-content-center position-relative mb-xxl-5 mb-2'>
                  <p className='mb-0'>or</p>
                </div>
                <p className='mb-xxl-5 mb-2'>Buy Asset Tags Worth</p>
                <div className='price mb-xxl-8 mb-xl-4 mb-3'>
                  <strong>
                    <span>$150</span>
                    {' / '}
                    <span>Year</span>
                  </strong>
                </div>
                <Link to='/' className='main-btn main-round-btn d-inline-block'>
                  Buy Tags
                </Link>
              </div>
            </div>
          </div>
          <div className='col-xl-2 col-lg-4 col-sm-6 mb-lg-3 mb-md-4 mb-3'>
            <div className='box text-center'>
              <div className='box-head'>
                <div className='text mb-xxl-5 mb-2'>
                  <p>Upto</p>
                  <strong className='h-main'>500</strong>
                  <p className='mb-0'>Assets</p>
                </div>
              </div>
              <div className='box-body'>
                <p className='mb-xl-5 mb-4'>Subscription Plan</p>
                <span className='d-block mb-xxl-5 mb-2 fw-bold p-mini'>ARS-500</span>
                <div className='price mb-xxl-5 mb-2'>
                  <strong>
                    <span>$120</span>
                    {' / '}
                    <span>Year</span>
                  </strong>
                </div>
                <div className='or-section d-flex justify-content-center position-relative mb-xxl-5 mb-2'>
                  <p className='mb-0'>or</p>
                </div>
                <p className='mb-xxl-5 mb-2'>Buy Asset Tags Worth</p>
                <div className='price mb-xxl-8 mb-xl-4 mb-3'>
                  <strong>
                    <span>$150</span>
                    {' / '}
                    <span>Year</span>
                  </strong>
                </div>
                <Link to='/' className='main-btn main-round-btn d-inline-block'>
                  Buy Tags
                </Link>
              </div>
            </div>
          </div>
          <div className='col-xl-2 col-lg-4 col-sm-6 mb-lg-3 mb-md-4 mb-3'>
            <div className='box text-center'>
              <div className='box-head'>
                <div className='text mb-xxl-5 mb-2'>
                  <p>Upto</p>
                  <strong className='h-main'>500</strong>
                  <p className='mb-0'>Assets</p>
                </div>
              </div>
              <div className='box-body'>
                <p className='mb-xl-5 mb-4'>Subscription Plan</p>
                <span className='d-block mb-xxl-5 mb-2 fw-bold p-mini'>ARS-500</span>
                <div className='price mb-xxl-5 mb-2'>
                  <strong>
                    <span>$120</span>
                    {' / '}
                    <span>Year</span>
                  </strong>
                </div>
                <div className='or-section d-flex justify-content-center position-relative mb-xxl-5 mb-2'>
                  <p className='mb-0'>or</p>
                </div>
                <p className='mb-xxl-5 mb-2'>Buy Asset Tags Worth</p>
                <div className='price mb-xxl-8 mb-xl-4 mb-3'>
                  <strong>
                    <span>$150</span>
                    {' / '}
                    <span>Year</span>
                  </strong>
                </div>
                <Link to='/' className='main-btn main-round-btn d-inline-block'>
                  Buy Tags
                </Link>
              </div>
            </div>
          </div>
          <div className='col-xl-2 col-lg-4 col-sm-6 mb-lg-3 mb-md-4 mb-3'>
            <div className='box text-center'>
              <div className='box-head'>
                <div className='text mb-xxl-5 mb-2'>
                  <p>Upto</p>
                  <strong className='h-main'>500</strong>
                  <p className='mb-0'>Assets</p>
                </div>
              </div>
              <div className='box-body'>
                <p className='mb-xl-5 mb-4'>Subscription Plan</p>
                <span className='d-block mb-xxl-5 mb-2 fw-bold p-mini'>ARS-500</span>
                <div className='price mb-xxl-5 mb-2'>
                  <strong>
                    <span>$120</span>
                    {' / '}
                    <span>Year</span>
                  </strong>
                </div>
                <div className='or-section d-flex justify-content-center position-relative mb-xxl-5 mb-2'>
                  <p className='mb-0'>or</p>
                </div>
                <p className='mb-xxl-5 mb-2'>Buy Asset Tags Worth</p>
                <div className='price mb-xxl-8 mb-xl-4 mb-3'>
                  <strong>
                    <span>$150</span>
                    {' / '}
                    <span>Year</span>
                  </strong>
                </div>
                <Link to='/' className='main-btn main-round-btn d-inline-block'>
                  Buy Tags
                </Link>
              </div>
            </div>
          </div>
          <div className='col-xl-2 col-lg-4 col-sm-6 mb-lg-3 mb-md-4 mb-3'>
            <div className='box text-center'>
              <div className='box-head'>
                <div className='text mb-xxl-5 mb-2'>
                  <p>Upto</p>
                  <strong className='h-main'>500</strong>
                  <p className='mb-0'>Assets</p>
                </div>
              </div>
              <div className='box-body'>
                <p className='mb-xl-5 mb-4'>Subscription Plan</p>
                <span className='d-block mb-xxl-5 mb-2 fw-bold p-mini'>ARS-500</span>
                <div className='price mb-xxl-5 mb-2'>
                  <strong>
                    <span>$120</span>
                    {' / '}
                    <span>Year</span>
                  </strong>
                </div>
                <div className='or-section d-flex justify-content-center position-relative mb-xxl-5 mb-2'>
                  <p className='mb-0'>or</p>
                </div>
                <p className='mb-xxl-5 mb-2'>Buy Asset Tags Worth</p>
                <div className='price mb-xxl-8 mb-xl-4 mb-3'>
                  <strong>
                    <span>$150</span>
                    {' / '}
                    <span>Year</span>
                  </strong>
                </div>
                <Link to='/' className='main-btn main-round-btn d-inline-block'>
                  Buy Tags
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Subscription
