import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../Template/helpers'

const Error500: FC = () => {
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-column-fluid'>
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-15'>
          <Link to='/' className='mb-10 pt-lg-10'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/default.svg')}
              className='h-40px mb-5'
            />
          </Link>
          <div className='pt-lg-10 mb-10'>
            <h1 className='fw-bolder fs-2qx text-gray-800 mb-10'>System Error</h1>
            <div className='fw-bold fs-3 text-muted mb-15'>
              <span className='d-block'>Something went wrong!</span>
              <span className='d-block'>Please try again later.</span>
            </div>
            <div className='text-center'>
              <Link to='/' className='btn btn-lg btn-primary fw-bolder'>
                Go to homepage
              </Link>
            </div>
          </div>
          <div className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px'></div>
        </div>
        <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            <Link
              to={process.env.REACT_APP_PUBLIC_URL || '/'}
              className='text-muted text-hover-primary px-2'
            >
              About
            </Link>
            <Link to='mailto:support@arhamsoft.com' className='text-muted text-hover-primary px-2'>
              Contact
            </Link>
            <Link
              to={`${process.env.REACT_APP_PUBLIC_URL}#contact`}
              className='text-muted text-hover-primary px-2'
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Error500 }
