import { useSendEmailSubUserMutation } from 'App/Modules/Services/SubUser'
import { toast } from 'react-toastify'
interface ColumnDataProps {
  column: string
  assetsData?: any
  currencyLogo?: any
}

export const ColumnData: React.FC<ColumnDataProps> = ({ column, assetsData }) => {
  const [sendEmailMutation] = useSendEmailSubUserMutation()
  const sendEmail = async (id: number) => {
    const userType = 4
    try {
      const data = await sendEmailMutation({ id, userType }).unwrap()
      toast.success(data?.message)
    } catch (error: any) {
      if (!error?.data?.success) {
        toast.error(error?.data?.message)
      }
    }
  }

  const renderData = () => {
    switch (column) {
      case 'First Name':
        return assetsData.allAssets.map((customer: any, index: number) => (
          <div>
            <div className='text-truncate me-2'>{customer.firstName}</div>
          </div>
        ))
      case 'Last Name':
        return assetsData.allAssets.map((customer: any, index: number) => (
          <div>
            <div className='text-truncate me-2'>{customer.lastName}</div>
          </div>
        ))
      case 'Created By':
        return assetsData.allAssets.map((customer: any, index: number) => (
          <span key={index}>
            {customer?.user ? `${customer?.user?.firstName} ${customer?.user?.lastName}` : ''}
          </span>
        ))
      case 'Group':
        return assetsData.allAssets.map((customer: any, index: number) => (
          <span key={index}>{customer?.security_group?.name || ''}</span>
        ))
      case 'Email':
        return assetsData.allAssets.map((customer: any, index: number) => (
          <div>
            <span key={index}>{customer?.email}</span>
            <div>
              {!customer?.isEmailVerified ? (
                <button
                  className='btn bg-success fw-bold text-white px-3 py-2 fs-8'
                  onClick={() => {
                    sendEmail(customer?.id) // setSendEmail(true)
                  }}
                >
                  {'Send Set Password Email'}
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        ))
      case 'Company':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.company}</span>
        ))
      case 'Address1':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.address1}</span>
        ))
      case 'Address2':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.address2}</span>
        ))
      case 'Phone':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.phone}</span>
        ))
      case 'City':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.city}</span>
        ))
      case 'State':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.state}</span>
        ))
      case 'Country':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.country}</span>
        ))
      case 'Zip':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.zip}</span>
        ))
      case 'Notes':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.notes}</span>
        ))
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}

export const SampleColumnData: React.FC<ColumnDataProps> = ({ column }) => {
  const renderData = () => {
    switch (column) {
      case 'Created By':
        return 'Sample Created By'
      case 'First Name':
        return 'Sample First Name'
      case 'Last Name':
        return 'Sample Last Name'
      case 'Group':
        return 'Admin Group'
      case 'Email':
        return 'Sample Email'
      case 'Company':
        return 'Sample Company'
      case 'Address1':
        return 'Sample Address1'
      case 'Address2':
        return 'Sample Address2'
      case 'Notes':
        return 'Sample Notes'
      case 'Phone':
        return 'Sample Phone'
      case 'City':
        return 'Sample City'
      case 'State':
        return 'Sample State'
      case 'Zip':
        return 'Sample Zip'
      case 'Country':
        return 'Sample Country'
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}
