import React from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../Template/layout/core'
import { Overview } from './Components/Overview'
import { AccountHeader } from './AccountHeader'
import { SignInMethod } from './Components/Settings/Cards/SignInMethod'
import { Settings } from './Components/Settings/Settings_'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Account',
    path: '/account/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AccountPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <AccountHeader />
            <Outlet />
          </>
        }
      >
        <Route
          path='overview'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
              <Overview />
            </>
          }
        />
        <Route
          path='settings'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle>
              <Settings />
            </>
          }
        />
        <Route index element={<Navigate to='/account/overview' />} />
      </Route>
      <Route
        path='change-password'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Change Password</PageTitle>
            <SignInMethod />
          </>
        }
      />
    </Routes>
  )
}

export default AccountPage
