export interface DisposeInterface {
  disposedDate: Date | string
  disposedTo: string | null
  notes: string | ''
  assetId: number | null
}
export const defaultDisposeDetail: DisposeInterface = {
  disposedDate: new Date().toISOString().split('T')[0],
  disposedTo: null,
  notes: '',
  assetId: null,
}
