import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { useGetAssetEventsQuery } from 'App/Modules/Services/AssetEventHistory'
import { dateFormat, defaultDateFormat } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import EditCheckoutEventModal from '../Modals/EditCheckoutEventModal'
import EditCheckInEventModal from '../Modals/EditCheckInEventModal'
import EditLeaseEventModal from '../Modals/EditLeaseEventModal'
import EditLeaseReturnEventModal from '../Modals/EditLeaseReturnEventModal'
import EditDisposeEventModal from '../Modals/EditDisposeEventModal'
import Loader from 'App/Loaders/BeatLoader'
import DeleteEventModal from '../Modals/DeleteEventModal'
import { useDispatch, useSelector } from 'react-redux'
import { refresh, selectValue } from 'App/Store'
import EditLostEventModal from '../Modals/EditLostEventModal'
import EditSellEventModal from '../Modals/EditSellEventModal'
import EditRepairEventModal from '../Modals/EditRepairEventModal'
import EditDonateEventModal from '../Modals/EditDonateEventModal'
import EditBrokenEventModal from '../Modals/EditBrokenEventModal'
import EditFoundEventModal from '../Modals/EditFoundEventModal'

const EventHistory = ({
  assetDetail,
  refreshKey,
  currencyObject,
  personUser,
  customerUser,
  permission,
  staffUser,
}) => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const value = useSelector(selectValue)
  const dispatch = useDispatch()
  const [events, setEvents] = useState<any>([])
  const [editCheckoutEventModal, setEditCheckoutEventModal] = useState<boolean>(false)
  const [editCheckInEventModal, setEditCheckInEventModal] = useState<boolean>(false)
  const [editLeaseEventModal, setEditLeaseEventModal] = useState<boolean>(false)
  const [editLeaseReturnEventModal, setEditLeaseReturnEventModal] = useState<boolean>(false)
  const [editDisposeEventModal, setEditDisposeEventModal] = useState<boolean>(false)
  const [editLostEventModal, setEditLostEventModal] = useState<boolean>(false)
  const [editSellEventModal, setEditSellEventModal] = useState<boolean>(false)
  const [editRepairEventModal, setEditRepairEventModal] = useState<boolean>(false)
  const [editDonateEventModal, setEditDonateEventModal] = useState<boolean>(false)
  const [editBrokenEventModal, setEditBrokenEventModal] = useState<boolean>(false)
  const [editFoundEventModal, setEditFoundEventModal] = useState<boolean>(false)
  const [deleteEventModal, setDeleteEventModal] = useState<boolean>(false)
  const [selectedEvent, setSelectedEvent] = useState<any>({})

  const toggleEditCheckoutEventModal = (): void =>
    setEditCheckoutEventModal(!editCheckoutEventModal)
  const toggleEditCheckInEventModal = (): void => setEditCheckInEventModal(!editCheckInEventModal)
  const toggleEditLeaseEventModal = (): void => setEditLeaseEventModal(!editLeaseEventModal)
  const toggleEditLeaseReturnEventModal = (): void =>
    setEditLeaseReturnEventModal(!editLeaseReturnEventModal)
  const toggleEditDisposeEventModal = (): void => setEditDisposeEventModal(!editDisposeEventModal)
  const toggleEditLostEventModal = (): void => setEditLostEventModal(!editLostEventModal)
  const toggleEditSellEventModal = (): void => setEditSellEventModal(!editSellEventModal)
  const toggleEditRepairEventModal = (): void => setEditRepairEventModal(!editRepairEventModal)
  const toggleEditDonateEventModal = (): void => setEditDonateEventModal(!editDonateEventModal)
  const toggleEditBrokenEventModal = (): void => setEditBrokenEventModal(!editBrokenEventModal)
  const toggleEditFoundEventModal = (): void => setEditFoundEventModal(!editFoundEventModal)

  const toggleDeleteEventModal = (): void => setDeleteEventModal(!deleteEventModal)

  const { data, error, isSuccess, isLoading, isError, refetch, isRefeching }: any =
    useGetAssetEventsQuery(
      {
        id: assetDetail?.asset?.id,
      },
      { skip: personUser || customerUser || (staffUser && !permission?.viewEvent) }
    )

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    refetch()
  }, [refreshKey])

  useEffect(() => {
    if (value) {
      refetch()
      dispatch(refresh(!value))
    }
  }, [value])

  useEffect(() => {
    if (isSuccess && data) {
      setEvents(data?.events)
    } else if (error && isError) {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error, saveAuth, setCurrentUser, toast)
    }
  }, [data, error, isSuccess, isError])

  const handleDeleteEvent = (event) => {
    setSelectedEvent(event)
    toggleDeleteEventModal()
  }

  const handleEditCheckoutEvent = (event) => {
    setSelectedEvent(event)
    toggleEditCheckoutEventModal()
  }

  const handleEditCheckInEvent = (event) => {
    setSelectedEvent(event)
    toggleEditCheckInEventModal()
  }

  const handleEditLeaseEvent = (event) => {
    setSelectedEvent(event)
    toggleEditLeaseEventModal()
  }

  const handleEditLeaseReturnEvent = (event) => {
    setSelectedEvent(event)
    toggleEditLeaseReturnEventModal()
  }

  const handleEditDisposeEvent = (event) => {
    setSelectedEvent(event)
    toggleEditDisposeEventModal()
  }

  const handleEditLostEvent = (event) => {
    setSelectedEvent(event)
    toggleEditLostEventModal()
  }

  const handleEditSellEvent = (event) => {
    setSelectedEvent(event)
    toggleEditSellEventModal()
  }

  const handleEditRepairEvent = (event) => {
    setSelectedEvent(event)
    toggleEditRepairEventModal()
  }

  const handleEditBrokenEvent = (event) => {
    setSelectedEvent(event)
    toggleEditBrokenEventModal()
  }

  const handleEditDonateEvent = (event) => {
    setSelectedEvent(event)
    toggleEditDonateEventModal()
  }

  const handleEditFoundEvent = (event) => {
    setSelectedEvent(event)
    toggleEditFoundEventModal()
  }

  return (
    <>
      {isLoading || isRefeching ? (
        <Loader loading={isLoading || isRefeching} />
      ) : (
        <tbody>
          {events?.length > 0 ? (
            <>
              {events.map((event) => {
                switch (event?.event) {
                  case 'Check out':
                    return (
                      <tr>
                        <td colSpan={5}>
                          <div className='event-item'>
                            <table className='event-table'>
                              <tr>
                                <td className='event-cell events-date'>
                                  <span className='d-block'>Check-out Date</span>
                                  {event?.checkOut?.checkoutDate
                                    ? dateFormat(
                                      event?.checkOut?.checkoutDate,
                                      currentUser?.dateFormat || defaultDateFormat
                                    )
                                    : null}
                                </td>
                                <td className='event-cell events-category'>
                                  <span>
                                    <i className='fas fa-user icon'></i> Check out
                                  </span>
                                </td>
                                <td className='event-cell' colSpan={2}>
                                  Assigned to:
                                  <span className='event-link d-block cursor-pointer'>
                                    {event?.checkOut?.assigneeType == 1 ? (
                                      <span>
                                        {`${event?.checkOut?.Person?.firstName || ''} ${event?.checkOut?.Person?.lastName || ''
                                          }`}
                                      </span>
                                    ) : event?.checkOut?.assigneeType == 2 ? (
                                      <span>
                                        {`${event?.checkOut?.Site?.name || ''} ${event?.checkOut?.Site?.name &&
                                          event?.checkOut?.Location?.location
                                          ? '/'
                                          : ''
                                          } ${event?.checkOut?.Location?.location || ''}`}
                                      </span>
                                    ) : (
                                      <span></span>
                                    )}
                                  </span>
                                </td>
                                <td className='event-cell'>
                                  <span className='d-block'>Due date:</span>{' '}
                                  {event?.checkOut?.dueDate
                                    ? dateFormat(
                                      event?.checkOut?.dueDate,
                                      currentUser?.dateFormat || defaultDateFormat
                                    )
                                    : null}
                                </td>
                                <td className='event-cell'>
                                  <span className='d-block'>Check-out Notes:</span>
                                  {event?.checkOut?.notes}{' '}
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleEditCheckoutEvent(event)}
                                    className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                  >
                                    <i className='fas fa-pencil icon'></i> Edit
                                  </button>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleDeleteEvent(event)}
                                    className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                  >
                                    <i className='fas fa-trash icon fs-16 me-1'></i> Delete
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )
                  case 'Dispose':
                    return (
                      <tr>
                        <td colSpan={5}>
                          <div className='table-responsive'>
                            <div className='event-item'>
                              <table className='event-table '>
                                <tr>
                                  <td className='event-cell events-date'>
                                    <span className='d-block'>Date Disposed</span>
                                    {event?.dispose?.disposedDate
                                      ? dateFormat(
                                        event?.dispose?.disposedDate,
                                        currentUser?.dateFormat || defaultDateFormat
                                      )
                                      : null}
                                  </td>
                                  <td className='event-cell events-category'>
                                    <span>
                                      <i className='fas fa-trash icon'></i> Dispose
                                    </span>
                                  </td>
                                  <td className='event-cell' colSpan={2}>
                                    Dispose to:
                                  </td>
                                  <td className='event-cell'>Notes:</td>
                                  {event?.dispose?.notes}{' '}
                                  <td className='event-cell events-category'>
                                    <button
                                      onClick={() => handleEditDisposeEvent(event)}
                                      className='btn btn-primary py-2 px-4'
                                    >
                                      <i className='fas fa-pencil icon'></i> Edit
                                    </button>
                                  </td>
                                  <td className='event-cell events-category'>
                                    <button
                                      onClick={() => handleDeleteEvent(event)}
                                      className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                    >
                                      <i className='fas fa-trash icon fs-16 me-1'></i>Delete
                                    </button>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  case 'Broken':
                    return (
                      <tr>
                        <td colSpan={5}>
                          <div className='event-item'>
                            <table className='event-table'>
                              <tr>
                                <td className='event-cell events-date'>
                                  <span className='d-block'>Date Broken</span>
                                  {event?.broken?.brokenDate
                                    ? dateFormat(
                                      event?.broken?.brokenDate,
                                      currentUser?.dateFormat || defaultDateFormat
                                    )
                                    : null}
                                </td>
                                <td className='event-cell events-category'>
                                  <span>
                                    <i className='fas fa-tools icon'></i> Broken
                                  </span>
                                </td>
                                <td className='event-cell' colSpan={3}>
                                  Notes:
                                  <span className='d-block text-break pre-line'>{event?.broken?.notes}</span>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleEditBrokenEvent(event)}
                                    className='btn btn-primary py-2 px-4'
                                  >
                                    <i className='fas fa-pencil icon'></i> Edit
                                  </button>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleDeleteEvent(event)}
                                    className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                  >
                                    <i className='fas fa-trash icon fs-16 me-1'></i>Delete
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )
                  case 'Donate':
                    return (
                      <tr>
                        <td colSpan={5}>
                          <div className='event-item'>
                            <table className='event-table'>
                              <tr>
                                <td className='event-cell events-date'>
                                  <span className='d-block'>Date Donated</span>
                                  {event?.donate?.donatedDate
                                    ? dateFormat(
                                      event?.donate?.donatedDate,
                                      currentUser?.dateFormat || defaultDateFormat
                                    )
                                    : null}
                                </td>
                                <td className='event-cell events-category'>
                                  <span>
                                    <i className='fas fa-heart icon'></i> Donate
                                  </span>
                                </td>
                                <td className='event-cell'>
                                  Donate to:
                                  <span className='d-block'>{event?.donate?.donateTo}</span>
                                </td>
                                <td className='event-cell'>
                                  <span className='d-block'>Donated value:</span>{' '}
                                  {event?.donate?.donateValue}
                                </td>
                                <td className='event-cell'>
                                  <span className='d-block'>Deductible:</span>{' '}
                                  {event?.donate?.deductible === true ? 'Yes' : 'No'}
                                </td>
                                <td className='event-cell'>
                                  <span className='d-block'>Notes:</span>
                                  <span className='d-block text-break pre-line'> {event?.donate?.notes} </span>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleEditDonateEvent(event)}
                                    className='btn btn-primary py-2 px-4'
                                  >
                                    <i className='fas fa-pencil icon'></i> Edit
                                  </button>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleDeleteEvent(event)}
                                    className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                  >
                                    <i className='fas fa-trash icon fs-16 me-1'></i>Delete
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )
                  case 'Lease':
                    return (
                      <tr>
                        <td colSpan={5}>
                          <div className='event-item'>
                            <table className='event-table'>
                              <tr>
                                <td className='event-cell events-date'>
                                  <span className='d-block'>Lease Begins</span>
                                  {event?.leased?.startDate
                                    ? dateFormat(
                                      event?.leased?.startDate,
                                      currentUser?.dateFormat || defaultDateFormat
                                    )
                                    : null}
                                </td>
                                <td className='event-cell events-category'>
                                  <span>
                                    <i className='fas fa-file-contract icon'></i> Lease
                                  </span>
                                </td>
                                <td className='event-cell' colSpan={2}>
                                  Leasing Customer:
                                  <span className='event-link d-block cursor-pointer'>
                                    {`${event?.leased?.Customer?.firstName || ''} ${event?.leased?.Customer?.lastName || ''}`}
                                  </span>
                                </td>
                                <td className='event-cell'>
                                  <span className='d-block'>Lease Expires:</span>{' '}
                                  {event?.leased?.expiryDate
                                    ? dateFormat(
                                      event?.leased?.expiryDate,
                                      currentUser?.dateFormat || defaultDateFormat
                                    )
                                    : null}
                                </td>
                                <td className='event-cell'>
                                  <span className='d-block'>Leasing Notes:</span>
                                  {event?.leased?.notes}{' '}
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleEditLeaseEvent(event)}
                                    className='btn btn-primary py-2 px-4'
                                  >
                                    <i className='fas fa-pencil icon'></i> Edit
                                  </button>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleDeleteEvent(event)}
                                    className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                  >
                                    <i className='fas fa-trash icon fs-16 me-1'></i> Delete
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )
                  case 'Lost/Missing':
                    return (
                      <tr>
                        <td colSpan={5}>
                          <div className='event-item'>
                            <table className='event-table'>
                              <tr>
                                <td className='event-cell events-date'>
                                  <span className='d-block'>Date Lost</span>
                                  {event?.lost?.lostDate
                                    ? dateFormat(
                                      event?.lost?.lostDate,
                                      currentUser?.dateFormat || defaultDateFormat
                                    )
                                    : null}
                                </td>
                                <td className='event-cell events-category'>
                                  <span>
                                    <i className='fas fa-thumbs-down icon'></i> Lost / Missing
                                  </span>
                                </td>
                                <td className='event-cell' colSpan={3}>
                                  Notes:
                                  <span className='d-block text-break pre-line'>{event?.lost?.notes}</span>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleEditLostEvent(event)}
                                    className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                  >
                                    <i className='fas fa-pencil icon'></i> Edit
                                  </button>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleDeleteEvent(event)}
                                    className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                  >
                                    <i className='fas fa-trash icon fs-16 me-1'></i>Delete
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )
                  case 'Found':
                    return (
                      <tr>
                        <td colSpan={5}>
                          <div className='event-item'>
                            <table className='event-table'>
                              <tr>
                                <td className='event-cell events-date'>
                                  <span className='d-block'>Date Found</span>
                                  {event?.found?.foundDate
                                    ? dateFormat(
                                      event?.found?.foundDate,
                                      currentUser?.dateFormat || defaultDateFormat
                                    )
                                    : null}
                                </td>
                                <td className='event-cell events-category'>
                                  <span>
                                    <i className='fas fa-thumbs-up icon'></i> Found
                                  </span>
                                </td>
                                <td className='event-cell' colSpan={3}>
                                  Notes:
                                  <span className='d-block text-break pre-line'>{event?.found?.notes}</span>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleEditFoundEvent(event)}
                                    className='btn btn-primary py-2 px-4'
                                  >
                                    <i className='fas fa-pencil icon'></i> Edit
                                  </button>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleDeleteEvent(event)}
                                    className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                  >
                                    <i className='fas fa-trash icon fs-16 me-1'></i>Delete
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )
                  case 'Repair':
                    return (
                      <tr>
                        <td colSpan={5}>
                          <div className='event-item'>
                            <table className='event-table'>
                              <tr>
                                <td className='event-cell events-date'>
                                  <span className='d-block'>Date Repair</span>
                                  {event?.repair?.scheduleDate
                                    ? dateFormat(
                                      event?.repair?.scheduleDate,
                                      currentUser?.dateFormat || defaultDateFormat
                                    )
                                    : null}
                                </td>
                                <td className='event-cell events-category'>
                                  <span>
                                    <i className='fas fa-tools icon'></i> Repair
                                  </span>
                                </td>
                                <td className='event-cell'>
                                  Repair to:
                                  <span className='d-block text-break pre-line'>{event?.repair?.assignedTo}</span>
                                </td>
                                <td className='event-cell'>
                                  <span className='d-block'>Repair Cost:</span>{' '}
                                  <span className='d-block'>{event?.repair?.repairCost}</span>
                                </td>
                                <td className='event-cell'>
                                  <span className='d-block'>Due date:</span>{' '}
                                  <span className='d-block '>
                                    {event?.repair?.completeDate
                                      ? dateFormat(
                                        event?.repair?.completeDate,
                                        currentUser?.dateFormat || defaultDateFormat
                                      )
                                      : null}
                                  </span>
                                </td>
                                <td className='event-cell'>
                                  <span className='d-block'>Notes:</span>
                                  <span className='d-block text-break pre-line'>{event?.repair?.notes}</span>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleEditRepairEvent(event)}
                                    className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                  >
                                    <i className='fas fa-pencil icon'></i> Edit
                                  </button>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleDeleteEvent(event)}
                                    className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                  >
                                    <i className='fas fa-trash icon fs-16 me-1'></i>Delete
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )
                  case 'Sell':
                    return (
                      <tr>
                        <td colSpan={5}>
                          <div className='event-item'>
                            <table className='event-table'>
                              <tr>
                                <td className='event-cell events-date'>
                                  <span className='d-block'>Sale Date</span>
                                  {event?.sell?.saleDate
                                    ? dateFormat(
                                      event?.sell?.saleDate,
                                      currentUser?.dateFormat || defaultDateFormat
                                    )
                                    : null}
                                </td>
                                <td className='event-cell events-category'>
                                  <span>
                                    <i className='fas fa-shopping-cart icon'></i> Sell
                                  </span>
                                </td>
                                <td className='event-cell'>
                                  Sell to:<span className='d-block'>{event?.sell?.soldTo}</span>
                                </td>
                                <td className='event-cell'>
                                  <span className='d-block'>Sale Amount:</span>{' '}
                                  {event?.sell?.saleAmount}
                                </td>
                                <td className='event-cell'>
                                  <span className='d-block'>Notes:</span>
                                  <span className='d-block text-break pre-line'>{event?.sell?.notes}</span>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleEditSellEvent(event)}
                                    className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                  >
                                    <i className='fas fa-pencil icon'></i> Edit
                                  </button>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleDeleteEvent(event)}
                                    className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                  >
                                    <i className='fas fa-trash icon fs-16 me-1'></i>Delete
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )
                  case 'Lease return':
                    return (
                      <tr>
                        <td colSpan={5}>
                          <div className='event-item'>
                            <table className='event-table'>
                              <tr>
                                <td className='event-cell events-date'>
                                  <span className='d-block'>Return Date</span>
                                  {event?.leaseReturned?.returnDate
                                    ? dateFormat(
                                      event?.leaseReturned?.returnDate,
                                      currentUser?.dateFormat || defaultDateFormat
                                    )
                                    : null}
                                </td>
                                <td className='event-cell events-category'>
                                  <span>
                                    <i className='fas fa-undo-alt icon'></i> Lease return
                                  </span>
                                </td>
                                <td className='event-cell'>
                                  <span className='d-block'>Leasing return Notes:</span>
                                  {event?.leaseReturned?.notes}{' '}
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleEditLeaseReturnEvent(event)}
                                    className='btn btn-primary py-2 px-4'
                                  >
                                    <i className='fas fa-pencil icon'></i> Edit
                                  </button>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleDeleteEvent(event)}
                                    className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                  >
                                    <i className='fas fa-trash icon fs-16 me-1'></i> Delete
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )
                  case 'Check in':
                    return (
                      <tr>
                        <td colSpan={5}>
                          <div className='event-item'>
                            <table className='event-table'>
                              <tr>
                                <td className='event-cell events-date'>
                                  <span className='d-block'>Return Date</span>
                                  {event?.checkIn?.returnDate
                                    ? dateFormat(
                                      event?.checkOut?.returnDate,
                                      currentUser?.dateFormat || defaultDateFormat
                                    )
                                    : null}
                                </td>
                                <td className='event-cell events-category'>
                                  <span>
                                    <i className='fas fa-user icon'></i> Check in
                                  </span>
                                </td>
                                {event?.checkIn.checkinType == 2 && (
                                  <td className='event-cell' colSpan={2}>
                                    Returned to:
                                    <span className='event-link d-block cursor-pointer'>
                                      {event?.checkIn?.Site && (
                                        <span>{`${event?.checkIn?.Site?.name || ''}`}</span>
                                      )}
                                    </span>
                                  </td>
                                )}
                                <td className='event-cell'>
                                  <span className='d-block'>Check-in Notes:</span>
                                  {event?.checkIn?.notes}{' '}
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleEditCheckInEvent(event)}
                                    className='btn btn-primary py-2 px-4'
                                  >
                                    <i className='fas fa-pencil icon'></i> Edit
                                  </button>
                                </td>
                                <td className='event-cell events-category'>
                                  <button
                                    onClick={() => handleDeleteEvent(event)}
                                    className='btn btn-primary py-2 px-4 d-flex align-items-center'
                                  >
                                    <i className='fas fa-trash icon fs-16 me-1'></i> Delete
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )
                  default:
                    ; <></>
                }
              })}
            </>
          ) : (
            <tr>
              <td colSpan={8}>
                <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                  No records found
                </div>
              </td>
            </tr>
          )}
        </tbody>
      )}
      {editCheckoutEventModal && (
        <EditCheckoutEventModal
          editCheckoutEventModal={editCheckoutEventModal}
          handleClose={toggleEditCheckoutEventModal}
          event={selectedEvent}
          assetId={assetDetail?.asset?.id}
        />
      )}
      {editCheckInEventModal && (
        <EditCheckInEventModal
          editCheckInEventModal={editCheckInEventModal}
          handleClose={toggleEditCheckInEventModal}
          event={selectedEvent}
          assetId={assetDetail?.asset?.id}
        />
      )}
      {editLeaseEventModal && (
        <EditLeaseEventModal
          editLeaseEventModal={editLeaseEventModal}
          handleClose={toggleEditLeaseEventModal}
          event={selectedEvent}
          assetId={assetDetail?.asset?.id}
        />
      )}
      {editLeaseReturnEventModal && (
        <EditLeaseReturnEventModal
          editLeaseReturnEventModal={editLeaseReturnEventModal}
          handleClose={toggleEditLeaseReturnEventModal}
          event={selectedEvent}
          assetId={assetDetail?.asset?.id}
        />
      )}
      {editDisposeEventModal && (
        <EditDisposeEventModal
          editDisposeEventModal={editDisposeEventModal}
          handleClose={toggleEditDisposeEventModal}
          event={selectedEvent}
          assetId={assetDetail?.asset?.id}
        />
      )}
      {editLostEventModal && (
        <EditLostEventModal
          editLostEventModal={editLostEventModal}
          handleClose={toggleEditLostEventModal}
          event={selectedEvent}
          assetId={assetDetail?.asset?.id}
        />
      )}
      {editSellEventModal && (
        <EditSellEventModal
          editSellEventModal={editSellEventModal}
          handleClose={toggleEditSellEventModal}
          event={selectedEvent}
          assetId={assetDetail?.asset?.id}
          currencyObject={currencyObject}
        />
      )}
      {editRepairEventModal && (
        <EditRepairEventModal
          editRepairEventModal={editRepairEventModal}
          handleClose={toggleEditRepairEventModal}
          event={selectedEvent}
          assetId={assetDetail?.asset?.id}
          currencyObject={currencyObject}
        />
      )}
      {editDonateEventModal && (
        <EditDonateEventModal
          editDonateEventModal={editDonateEventModal}
          handleClose={toggleEditDonateEventModal}
          event={selectedEvent}
          assetId={assetDetail?.asset?.id}
          currencyObject={currencyObject}
        />
      )}
      {editBrokenEventModal && (
        <EditBrokenEventModal
          editBrokenEventModal={editBrokenEventModal}
          handleClose={toggleEditBrokenEventModal}
          event={selectedEvent}
          assetId={assetDetail?.asset?.id}
        />
      )}
      {editFoundEventModal && (
        <EditFoundEventModal
          editFoundEventModal={editFoundEventModal}
          handleClose={toggleEditFoundEventModal}
          event={selectedEvent}
          assetId={assetDetail?.asset?.id}
        />
      )}
      {deleteEventModal && (
        <DeleteEventModal
          deleteEventModal={deleteEventModal}
          handleClose={toggleDeleteEventModal}
          document={selectedEvent?.id}
          event={selectedEvent?.event}
          assetId={assetDetail?.asset?.id}
        />
      )}
    </>
  )
}

export default EventHistory
