import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery: customFetchBaseQuery({ path: 'document' }),
  tagTypes: ['Document'],
  endpoints: (build) => ({
    addDocument: build.mutation<any, any>({
      query: (body) => ({
        url: 'create',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Document' }],
    }),
    getAllDocuments: build.query<any, any>({
      query: ({ assetId }) => ({
        url: `get/${assetId}`,
        method: 'GET',
      }),
      providesTags: ['Document'],
    }),
    deleteDocument: build.mutation<any, { documentId: any }>({
      query: ({ documentId }) => ({
        url: `delete/${documentId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const { useAddDocumentMutation, useGetAllDocumentsQuery, useDeleteDocumentMutation } =
  documentApi
