import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: customFetchBaseQuery({ path: 'dashboard' }),
  tagTypes: ['Dashboard'],
  endpoints: (build) => ({
    getDashboardStats: build.query<any, void>({
      query: () => `stats`,
      providesTags: ['Dashboard'],
    }),
  }),
})

export const { useGetDashboardStatsQuery } = dashboardApi
