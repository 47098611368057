import { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useGetSiteQuery } from 'App/Modules/Services/Sites'
import { toast } from 'react-toastify'
import Loader from 'App/Loaders/BeatLoader'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

const ViewSiteModal = (props) => {
  const { saveAuth, setCurrentUser } = useAuth()
  const { data, isSuccess, isError, error, isLoading }: any = useGetSiteQuery(props?.siteId, {
    skip: !props?.siteId,
  })
  const [site, setSite] = useState<any>({})

  useEffect(() => {
    if (isSuccess && data) {
      setSite(data?.site)
    } else {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
    }
  }, [data, isSuccess, isError, error])

  return (
    <>
      {!isLoading && (
        <Modal
          className='px-2'
          size='lg'
          show={props.show}
          onHide={props.handleClose}
          aria-labelledby='contained-modal-title-vcenter'
          backdrop='static'
        >
          {' '}
          <Modal.Header closeButton>
            <Modal.Title>{site?.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='assetProduct_detail mb-5'>
              <div className='asset_detail_table table-responsive'>
                <table
                  className='table table-bordered detail-table table-sm mb-0'
                  aria-label='Quick View'
                  role='presentation'
                >
                  <tbody>
                    <tr>
                      <td className='label_col bg-light'>Address</td>
                      <td className='fw-bold detail_col table-elipses'>{site?.address || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Apt. / Suite</td>
                      <td className='fw-bold detail_col table-elipses'>{site?.suite || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>City</td>
                      <td className='fw-bold detail_col table-elipses'>{site?.city || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>State</td>
                      <td className='fw-bold detail_col table-elipses'>{site?.state || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Postal Code</td>
                      <td className='fw-bold detail_col table-elipses'>{site?.postalCode || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Country</td>
                      <td className='fw-bold detail_col table-elipses'>{site?.country || ''}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='pt-4 p-0 border-top d-flex justify-content-end'>
              <button
                onClick={props.handleClose}
                type='button'
                className='btn btn-light-primary   main-btn-style me-2'
                data-bs-dismiss='modal'
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Loader loading={isLoading} />
    </>
  )
}

export default ViewSiteModal
