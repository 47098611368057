import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../Template/layout/core'
import Location from './Locations/Location'
import CompanyInfo from './CompnayInfo/CompanyInfo'
import Sites from './Sites/Sites'
import Departments from './Departments/Departments'
import Events from './Event/Components/Events'
import Categories from './Categories/Categories'
import AddCustomEvents from './Event/Components/AddCustomEvents'
import SetupWizard from './SetupWizard'

const setupBreadCrumbs: Array<PageLink> = [
  {
    title: 'Company',
    path: '/apps/setup/company',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SetupPage = ({ permission, userType }) => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        {userType === 1 && (
          <Route
            path='wizard'
            element={
              <>
                <PageTitle breadcrumbs={setupBreadCrumbs}>Setup Wizard</PageTitle>
                <SetupWizard />
              </>
            }
          />
        )}
        {(userType === 1 || (userType === 2 && permission?.viewCompanyInfo)) && (
          <Route
            path='company'
            element={
              <>
                <PageTitle breadcrumbs={setupBreadCrumbs}>Company Information</PageTitle>
                <CompanyInfo />
              </>
            }
          />
        )}
        {(userType === 1 || (userType === 2 && permission?.viewSite)) && (
          <Route
            path='location'
            element={
              <>
                <PageTitle breadcrumbs={setupBreadCrumbs}>Locations</PageTitle>
                <Location />
              </>
            }
          />
        )}
        {(userType === 1 || (userType === 2 && permission?.viewSite)) && (
          <Route
            path='sites'
            element={
              <>
                <PageTitle breadcrumbs={setupBreadCrumbs}>Sites</PageTitle>
                <Sites />
              </>
            }
          />
        )}
        {(userType === 1 || (userType === 2 && permission?.viewCategory)) && (
          <Route
            path='categories'
            element={
              <>
                <PageTitle breadcrumbs={setupBreadCrumbs}>Categories</PageTitle>
                <Categories />
              </>
            }
          />
        )}
        {(userType === 1 || (userType === 2 && permission?.viewDepartment)) && (
          <Route
            path='department'
            element={
              <>
                <PageTitle breadcrumbs={setupBreadCrumbs}>Departments</PageTitle>
                <Departments />
              </>
            }
          />
        )}
        <Route
          path='events'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Events</PageTitle>
              <Events />
            </>
          }
        />
        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Add Custom Event</PageTitle>
              <AddCustomEvents />
            </>
          }
        />
        <Route
          path='events/edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Edit Custom Event</PageTitle>
              <AddCustomEvents />
            </>
          }
        />
        <Route index element={<Navigate to='/apps/setup/company' />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export default SetupPage
