export interface MaintenanceInterface {
  title: string | null
  details: string | null
  dueDate: string | null
  maintenanceBy: string | null
  status: string | null
  dateCompleted: string | null
  cost: number | null
  repeating: boolean
  frequency: string
  recurOnEvery: string | null
  recurOn: string | null
}
export const defaultMaintenanceDetail: MaintenanceInterface = {
  title: '',
  details: null,
  dueDate: null,
  maintenanceBy: null,
  status: null,
  dateCompleted: null,
  cost: null,
  repeating: false,
  frequency: '',
  recurOnEvery: null,
  recurOn: null,
}
