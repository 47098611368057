import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const groupsApi = createApi({
  reducerPath: 'groupsApi',
  baseQuery: customFetchBaseQuery({ path: 'security-group' }),
  tagTypes: ['Groups'],
  endpoints: (build) => ({
    getAllGroups: build.query<any, any>({
      query: ({ page = '', limit = '' }) => ({
        url: `list`,
        method: 'GET',
        params: { page, limit },
      }),
      providesTags: ['Groups'],
    }),
    getGroup: build.query<any, any>({
      query: (id) => ({
        url: `get/${id}`,
        method: 'GET',
      }),
      providesTags: ['Groups'],
    }),
    addGroups: build.mutation<any, any>({
      query: (body) => ({
        url: `create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Groups' }],
    }),
    deleteGroup: build.mutation<any, any>({
      query: (id) => ({
        url: `delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'Groups' }],
    }),
    updateGroup: build.mutation<any, any>({
      query: ({ tempData, id }) => ({
        url: `update/${id}`,
        method: 'PUT',
        body: tempData,
      }),
      invalidatesTags: () => [{ type: 'Groups' }],
    }),
  }),
})

export const {
  useGetAllGroupsQuery,
  useGetGroupQuery,
  useAddGroupsMutation,
  useDeleteGroupMutation,
  useUpdateGroupMutation,
} = groupsApi
