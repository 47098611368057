import { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import { useGetSinglePersonQuery } from 'App/Modules/Services/Persons'
import Loader from 'App/Loaders/BeatLoader'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

const ViewPersonModal = (props) => {
  const { saveAuth, setCurrentUser } = useAuth()
  const { data, isSuccess, isError, error, isLoading }: any = useGetSinglePersonQuery(
    {
      id: props?.personId,
    },
    { skip: !props.personId }
  )
  const [person, setPerson] = useState<any>({})

  useEffect(() => {
    if (isSuccess && data) {
      setPerson(data?.person)
    } else {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error, saveAuth, setCurrentUser, toast)
      props.handleClose()
    }
  }, [data, isSuccess, isError, error])

  return (
    <>
      {!isLoading && (
        <Modal
          className='px-2'
          size='lg'
          {...props}
          onHide={props.handleClose}
          aria-labelledby='contained-modal-title-vcenter'
          backdrop='static'
        >
          <Modal.Header closeButton>
            <Modal.Title>{person?.firstName || ''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='assetProduct_detail mb-5'>
              <div className='asset_detail_table table-responsive'>
                <table
                  className='table table-bordered detail-table table-sm mb-0'
                  aria-label='Quick View'
                  role='presentation'
                >
                  <tbody>
                    <tr>
                      <td className='label_col bg-light'>Full Name</td>
                      <td className='fw-bold detail_col table-elipses'>
                        {person?.firstName || ''} {person?.lastName || ''}
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Employee ID</td>
                      <td className='fw-bold detail_col table-elipses'>
                        {person?.employeeId || ''}
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Title</td>
                      <td className='fw-bold detail_col table-elipses'>{person?.title || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Phone</td>
                      <td className='fw-bold detail_col table-elipses'>{person?.phone || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Email</td>
                      <td className='fw-bold detail_col table-elipses'>{person?.email || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Site</td>
                      <td className='fw-bold detail_col table-elipses'>
                        {person?.Site?.name || ''}
                      </td>
                    </tr>
                    <tr>
                      <td className='label_co bg-light'>Location</td>
                      <td className='fw-bold detail_col table-elipses'>
                        {person?.Location?.location || ''}
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Department</td>
                      <td className='fw-bold detail_col table-elipses'>
                        {person?.Department?.department || ''}
                      </td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Notes</td>
                      <td className='fw-bold detail_col table-elipses'>{person?.notes || ''}</td>
                    </tr>
                    <tr>
                      <td className='label_col bg-light'>Created By</td>
                      <td className='fw-bold detail_col table-elipses'>
                        {`${person?.creator?.firstName || ''} ${person?.creator?.lastName || ''}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='pt-4 p-0 border-top d-flex justify-content-end'>
              <button
                onClick={props.handleClose}
                type='button'
                className='btn btn-light-primary   main-btn-style me-2'
                data-bs-dismiss='modal'
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Loader loading={isLoading} />
    </>
  )
}

export default ViewPersonModal
