import { useEffect, useState } from 'react'
import { useDeleteSubUserMutation, useUpdateSubUserMutation } from 'App/Modules/Services/SubUser'
import { useNavigate } from 'react-router'
import { useFormik } from 'formik'
import { IProfileDetails, profileDetailsInitValues as initialValues } from './SettingsModel'
import Profile from './Profile'
import Localization from './Localization'
import { checkUserUnauthorized, useAuth } from '../../../../Auth'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { dateFormat } from 'config'

const profileDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'First name must be at least 3 characters')
    .max(20, 'First name should not exceed 20 characters')
    .required('First name is required')
    .nullable(),
  lastName: Yup.string()
    .min(3, 'Last name must be at least 3 characters')
    .max(20, 'Last name should not exceed 20 characters')
    .required('Last name is required')
    .nullable(),
  title: Yup.string()
    .min(5, 'Title must be at least 5 characters')
    .max(20, 'Title should not exceed 20 characters')
    .nullable(),
  timeZone: Yup.string().required('Time zone is required').nullable(),
  dateFormat: Yup.string().required('Date Format is required').nullable(),
  timeFormat: Yup.string().required('Time Format is required').nullable(),
})

const EditUserDetails = ({ loading, formik, userData, id, selectedGroupName }) => {
  const { currentUser, setCurrentUser, saveAuth } = useAuth()
  const permission = currentUser?.SecurityGroup
  const staffUser = currentUser?.userType === 2
  const navigate = useNavigate()
  const [userUpdate, setUserUpdate] = useState(false)
  const [image, setImage] = useState<string | null>(null)
  const [updateSubUser] = useUpdateSubUserMutation()
  const [deleteSubUser] = useDeleteSubUserMutation()

  useEffect(() => {
    setImage(userData?.user?.photo)
  }, [userData])

  const handleDelete = () => {
    if (currentUser) {
      deleteSubUser(id)
        .then((res: any) => {
          if (res.data) {
            toast.success(res?.data?.message)
            navigate('/users')
          } else {
            toast.error(res?.error?.data?.message)
            checkUserUnauthorized(res?.error?.data, saveAuth, setCurrentUser, toast)
          }
        })
        .catch((err: any) => {
          toast.error('Error in response!')
        })
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div>
            <Profile formik={formik} image={image} />
            <Localization formik={formik} />
          </div>
          <div className='card-footer d-flex justify-content-between flex-wrap py-6 px-9'>
            {!permission?.deleteUser && staffUser ? (
              ''
            ) : (
              <button
                type='button'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                className='btn btn-danger m-1'
              >
                <i className='fa fa-exclamation-triangle fs-4 pb-1' />
                Deactivate Account
              </button>
            )}
            <button
              type='submit'
              className='btn btn-primary main-btn-style m-1'
              disabled={loading || !formik.isValid}
            >
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress d-block'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
            <div className='modal-dialog modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>
                    <i className='fa fa-exclamation-triangle fs-3 pb-1 pe-3' />
                    Deactivate Account
                  </h5>
                  <div
                    className='btn-close ms-2 cursor-pointer'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  ></div>
                </div>
                <div className='modal-body'>
                  <p className='mb-3'>
                    {' '}
                    Are you sure you want to deactivate account of{' '}
                    <strong className='text-capitalize'>
                      {userData?.user?.firstName + ' ' + userData?.user?.lastName}
                    </strong>
                  </p>
                  <div className='form-group d-flex flex-wrap align-items-center mb-3'>
                    <span
                      className='field-validation-valid'
                      data-valmsg-for='txt_validate_delete'
                      data-valmsg-replace='true'
                    ></span>
                  </div>
                  <p className='text-danger'>This operation is irrevocable.</p>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-light-primary   main-btn-style me-2'
                    data-bs-dismiss='modal'
                  >
                    Close
                  </button>
                  <button
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_1'
                    className='btn btn-danger'
                    onClick={handleDelete}
                  >
                    <i className='fa fa-exclamation-triangle fs-4 pb-1' />
                    Deactivate Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export { EditUserDetails }
