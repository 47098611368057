import React, { useEffect, useState } from 'react'
import { PageTitle } from '../../../Template/layout/core'
import {
  useGetTableColumnsMutation,
  useGetExportCategoriesMutation,
  useGetExportDepartmentsMutation,
  useGetExportSitesMutation,
  useGetExportCustomersMutation,
  useGetExportLocationsMutation,
  useGetExportAssetsMutation,
  useGetExportMaintenancesMutation,
  useGetExportPersonsMutation,
  useGetExportWarrantiesMutation,
  useUpdateExportSetupColumnsMutation,
} from '../Services/Exports'
import OrderTableColumns from './OrderTableColumns'
import { toast } from 'react-toastify'
import Loader from 'App/Loaders/BeatLoader'
import { exportOptions } from 'config'
import { checkUserUnauthorized, useAuth } from '../Auth'

const labels = {
  'apt. / suite': 'suite',
  'postal code': 'postalCode',
  'first name': 'firstName',
  'last name': 'lastName',
  'employee id': 'employeeId',
  'created by': 'createdBy',
  'asset photo': 'photos',
  'asset tag id': 'assetTagId',
  'purchased date': 'purchasedDate',
  'purchased from': 'purchasedFrom',
  'date created': 'createdAt',
  'serial no': 'serialNo',
  port: 'ports',
  mbp: 'mbps',
  stand: 'stands',
  battery: 'batteries',
  bulb: 'bulbs',
  len: 'lens',
  site: 'Site',
  expirationDate: 'expiryDate',
  'warranty created by': 'createdBy',
  'length (months)': 'length',
  'is repeating': 'repeating',
  'maintenance by': 'maintenanceBy',
  'maintenance completion date': 'dateCompleted',
  'maintenance cost': 'cost',
  'maintenance detail': 'details',
  'maintenance due date': 'dueDate',
  'recur on every': 'recurOnEvery',
  'on (day or weekday)': 'recurOn',
}

function Export() {
  const [exportbtn, setExportbtn] = useState<boolean>(false)
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const permission = currentUser?.SecurityGroup
  const ownerUser = currentUser?.userType === 1
  const staffUser = currentUser?.userType === 2
  const [selectedValue, setSelectedValue] = useState<string>('')
  const [columnsData, setColumnsData] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loader, setLoader] = useState(false)
  const [fileType, setFileType] = useState<string>('xlsx')
  const [getColumnsName] = useGetTableColumnsMutation()
  const [updateExportColumns] = useUpdateExportSetupColumnsMutation()
  const [exportCategories] = useGetExportCategoriesMutation()
  const [exportDepartments] = useGetExportDepartmentsMutation()
  const [exportSites] = useGetExportSitesMutation()
  const [exportCustomers] = useGetExportCustomersMutation()
  const [exportLocations] = useGetExportLocationsMutation()
  const [exportAssets] = useGetExportAssetsMutation()
  const [exportMaintenances] = useGetExportMaintenancesMutation()
  const [exportPersons] = useGetExportPersonsMutation()
  const [exportWarranties] = useGetExportWarrantiesMutation()

  const handleChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setExportbtn(e.target.value !== '' && e.target.value !== 'Select')
    setSelectedValue(e.target.value)
    if (e.target.value !== '') getColumns(e.target.value)
  }

  const getColumns = async (tableName: string) => {
    const obj: any = { tableName: tableName, type: 'custom' }
    const data: any = await getColumnsName(obj)
    if (data?.error) {
      toast.error(data?.error?.data?.message)
      checkUserUnauthorized(data?.error?.data, saveAuth, setCurrentUser, toast)
    } else {
      setColumnsData(data?.data?.columns)
    }
  }

  const handleLoadDefault = async (selectedValue) => {
    const obj: any = { tableName: selectedValue, type: 'default' }
    const data: any = await getColumnsName(obj)
    if (data?.error) {
      toast.error(data?.error?.data?.message)
      checkUserUnauthorized(data?.error?.data, saveAuth, setCurrentUser, toast)
    } else {
      setColumnsData(data?.data?.columns)
    }
  }

  const downloadExcel = (fileName: any) => {
    const file = process.env.REACT_APP_PUBLIC_URL + fileName
    const link = document.createElement('a')
    link.href = file
    link.download = file
    link.click()
  }

  const handleSubmit = async () => {
    setLoader(true)
    const activeColumns = columnsData.selectedColumns.filter((col) => {
      const key = Object.keys(col)[0]
      return col[key] === true
    })

    const filterColumns = activeColumns.map((col) => {
      const key = Object.keys(col)[0]
      return {
        header: key,
        key: labels[key.toLowerCase()] || key.toLowerCase(),
        width: 20,
      }
    })

    const obj = { fileType: fileType, columns: filterColumns }
    switch (selectedValue) {
      case 'exportAsset':
        {
          const { data, error }: any = await exportAssets(obj)
          if (data) downloadExcel(data?.fileName)
          else {
            toast.error(error?.data?.message)
            checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
          }
        }
        break
      case 'exportCategory':
        {
          const { data, error }: any = await exportCategories(obj)
          if (data) downloadExcel(data?.fileName)
          else {
            toast.error(error?.data?.message)
            checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
          }
        }
        break
      case 'exportCustomer':
        {
          const { data, error }: any = await exportCustomers(obj)
          if (data) downloadExcel(data?.fileName)
          else {
            toast.error(error?.data?.message)
            checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
          }
        }
        break
      case 'exportDepartment':
        {
          const { data, error }: any = await exportDepartments(obj)
          if (data) downloadExcel(data?.fileName)
          else {
            toast.error(error?.data?.message)
            checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
          }
        }
        break
      case 'exportWarranty':
        {
          const { data, error }: any = await exportWarranties(obj)
          if (data) downloadExcel(data?.fileName)
          else {
            toast.error(error?.data?.message)
            checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
          }
        }
        break
      case 'exportLocation':
        {
          const { data, error }: any = await exportLocations(obj)
          if (data) downloadExcel(data?.fileName)
          else {
            toast.error(error?.data?.message)
            checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
          }
        }
        break
      case 'exportMaintenance':
        {
          const { data, error }: any = await exportMaintenances(obj)
          if (data) downloadExcel(data?.fileName)
          else {
            toast.error(error?.data?.message)
            checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
          }
        }
        break
      case 'exportPerson':
        {
          const { data, error }: any = await exportPersons(obj)
          if (data) downloadExcel(data?.fileName)
          else {
            toast.error(error?.data?.message)
            checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
          }
        }
        break
      case 'exportSite':
        {
          const { data, error }: any = await exportSites(obj)
          if (data) downloadExcel(data?.fileName)
          else {
            toast.error(error?.data?.message)
            checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
          }
        }
        break
      default:
        // Handle default case if necessary
        break
    }

    setLoader(false)
  }

  const handleUpdate = async () => {
    const obj = { type: selectedValue, selectedColumns: columnsData?.selectedColumns }
    const { data, error }: any = await updateExportColumns(obj)
    if (error) {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
    } else {
      toast.success(data?.message)
      getColumns(selectedValue)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Export</PageTitle>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0 '>
            <h3 className='fw-bolder m-0'>
              <span className='me-2 align-middle'>
                <i className='la la-cloud-download fs-1'></i>
              </span>
              Export Tables
            </h3>
          </div>
        </div>
        <div id='kt_account_profile_details' className='collapse show'>
          <div className='form'>
            <div className='card-body border-top'>
              <div className='pb-5 mb-2'>
                <h5>Step 1: Select Table</h5>
              </div>
              <div className='row mb-5'>
                <div className='col-xl-6'>
                  <div className='row mb-6'>
                    <div className='col-lg-3 col-form-label fw-bold fs-6'>Export Table</div>
                    <div className='col-lg-9 fv-row'>
                      <select className='form-select' onChange={(e) => handleChange(e)}>
                        <option value=''>- select -</option>
                        {exportOptions.map((option) => {
                          if (ownerUser || (staffUser && permission?.[option.permission])) {
                            return (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            )
                          }
                          return null
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {selectedValue && isLoading ? (
                <div className='card-body border-top px-0 pb-3'>Loading...</div>
              ) : selectedValue &&
                columnsData?.selectedColumns &&
                Object.keys(columnsData?.selectedColumns).length > 0 ? (
                <OrderTableColumns setColumnsData={setColumnsData} columnsData={columnsData} />
              ) : null}
              {selectedValue && columnsData?.selectedColumns ? (
                <div className='card-footer d-flex justify-content-end pt-2 p-0 border-0 pb-5'>
                  <button
                    type='button'
                    className='btn btn-primary d-flex align-items-center justify-content-center me-3'
                    onClick={() => handleLoadDefault(selectedValue)}
                  >
                    <i className='fas fa-upload'></i>
                    Load Default
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary d-flex align-items-center justify-content-center'
                    onClick={handleUpdate}
                  >
                    <i className='fas fa-download'></i>
                    Save Setup
                  </button>
                </div>
              ) : null}
              {exportbtn && (
                <div className='card-body border-top px-0 pb-0'>
                  <div className='heading'>
                    <h5>Step 3: Export File Format</h5>
                  </div>
                  <div className='mb-5'>Select a file format that works for you.</div>
                  <div className='col-xl-6'>
                    <div className='row mb-6'>
                      <div className='col-lg-3 col-form-label fw-bold fs-6'>File type</div>
                      <div className='col-lg-9 fv-row'>
                        <select
                          className='form-select mb-5'
                          onChange={(e) => setFileType(e.target.value)}
                        >
                          <option value='xlsx'>Excel</option>
                          <option value='csv'>CSV</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='card-footer d-flex justify-content-end pt-6 p-0'>
                    <button
                      type='button'
                      className='btn btn-primary d-flex justify-content-center'
                      onClick={() => handleSubmit()}
                    >
                      <i className='la la-cloud-download fs-1 me-1'></i>
                      Export
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Loader loading={loader} />
    </>
  )
}

export default Export
