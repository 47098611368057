import { BeatLoader } from 'react-spinners'

const Loader = ({ loading }) => {
  return (
    <>
      {loading ? (
        <div className='loader-wrapper'>
          <div className='card loader-wrap'>
            <BeatLoader color='#0d145c' size={20} margin={4} />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Loader
