import LocationListing from './Components/LocationListing'

const Location = () => {
  return (
    <div>
      <LocationListing />
    </div>
  )
}

export default Location
