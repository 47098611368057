import { createApi } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { dashboardApi } from './Dashboard'
import { toast } from 'react-toastify'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const personsApi = createApi({
  reducerPath: 'personsApi',
  baseQuery: customFetchBaseQuery({ path: 'person' }),
  tagTypes: ['Persons'],
  endpoints: (build) => ({
    getAllPersons: build.query<any, any>({
      query: ({ search, searchField, limit = 10, page = 1 }) => ({
        url: 'get',
        params: { search, searchField, limit, page },
        method: 'GET',
      }),
      providesTags: ['Persons'],
    }),
    getPersons: build.query<any, any>({
      query: () => 'get-all',
      providesTags: ['Persons'],
    }),
    getAssignAssets: build.query<any, any>({
      query: () => ({
        url: `assign-assets`,
        method: 'GET',
      }),
      providesTags: ['Persons'],
    }),
    getSinglePerson: build.query<any, any>({
      query: ({ id }) => ({
        url: `get-person/${id}`,
        method: 'GET',
      }),
      providesTags: ['Persons'],
    }),
    addPerson: build.mutation<any, any>({
      query: (body) => ({
        url: `add`,
        method: 'POST',
        body,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
          })
          .catch((err) => toast.error(err))
      },
      invalidatesTags: () => [{ type: 'Persons' }],
    }),
    updatePerson: build.mutation<any, { id: any; values: any }>({
      query: ({ id, values }) => ({
        url: `update/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: () => [{ type: 'Persons' }],
    }),
    deletePersons: build.mutation<any, any>({
      query({ id }) {
        return {
          url: `delete`,
          method: 'DELETE',
          body: id,
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(assetsApi.util.invalidateTags(['Assets']))
            api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
          })
          .catch((err) => toast.error(err?.message))
      },
      invalidatesTags: (result, error, userId) => [{ type: 'Persons', userId }],
    }),
  }),
})

export const {
  useGetAllPersonsQuery,
  useGetPersonsQuery,
  useGetAssignAssetsQuery,
  useGetSinglePersonQuery,
  useAddPersonMutation,
  useUpdatePersonMutation,
  useDeletePersonsMutation,
} = personsApi
