export interface LeaseInterface {
  startDate: string | Date
  customerId: number | null
  expiryDate: string
  notes: string | null
  sendEmail: boolean
  assetId: number[]
  overrideReservation: string | false
}

export const defaultLeaseDetail: LeaseInterface = {
  startDate: new Date().toISOString().split('T')[0],
  customerId: null,
  expiryDate: '',
  notes: null,
  sendEmail: false,
  assetId: [],
  overrideReservation: false,
}
