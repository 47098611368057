import { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useAuth } from '../../../../Auth'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useAddDepartmentMutation, useUpdateDepartmentMutation,} from '../../../../Services/Departments'
import { checkUserUnauthorized } from '../../../../Auth'
import { multiSpace } from 'config'

const departmentsSchema = Yup.object().shape({
  department: Yup.string()
    .trim()
    .min(2, 'Minimum 2 symbols')
    .max(20, 'Department should not exceed 20 characters')
    .required('Department name is required'),
})

const initialValues = {
  department: '',
}

const DepartmentsModel = (props: any) => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const [addDepartment, { isLoading: isAdding }] = useAddDepartmentMutation()
  const [updateDepartment, { isLoading: isUpdating }] = useUpdateDepartmentMutation()
  useEffect(() => {
    if (props?.data?.id) {
      formik.setValues({
        department: props?.data?.department,
      })
    }
  }, [props?.data?.id, props?.data])

  const formik = useFormik({
    initialValues,
    validationSchema: departmentsSchema,
    onSubmit: async (values: any) => {
      try {
        if (currentUser) {
          values.department = values?.department?.trim().replace(multiSpace, ' ')
          const data = props.data?.id
            ? await updateDepartment({
                id: props.data?.id,
                values,
              }).unwrap()
            : await addDepartment(values).unwrap()
          if (data) {
            toast.dismiss()
            if (data?.success) {
              formik.resetForm()
              toast.success(data?.message)
              props.handleClose()
              props.setLoading(true)
            } else {
              toast.error(data?.message)
            }
          }
        }
      } catch (error: any) {
        if (!error?.data?.success) {
          toast.error(error?.data?.message)
        }
        checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
      }
    },
  })

  const handleClose = (e: any) => {
    e?.preventDefault()
    formik.resetForm()
    props.handleClose()
  }

  const formikSubmit = (e: any) => {
    e?.preventDefault()
    formik.handleSubmit()
  }

  return (
    <Modal show={props.show} onHide={props.handleClose} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{props?.data?.id ? 'Edit Department' : 'Add a Department'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form noValidate className='form'>
          <p className='mb-4 fs-6'>
            Add <strong>departments</strong> that own or house the particular assets. Make them as
            broad or as specific as you want. <strong>Departments</strong> can be{' '}
            <em>'Accounting'</em>, <em>'Marketing'</em>, or <em>'Executive'</em>. Customize to your
            particular need.
          </p>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark required' htmlFor='Department'>
              Department
            </label>
            <div>
              <input
                id='Department'
                placeholder='Department'
                {...formik.getFieldProps('department')}
                className={clsx(
                  'form-control  form-control-solid',
                  { 'is-invalid': formik.touched.department && formik.errors.department },
                  {
                    'is-valid': formik.touched.department && !formik.errors.department,
                  }
                )}
                type='text'
                name='department'
                autoComplete='off'
              />
            </div>
            {formik?.touched?.department && formik?.errors?.department && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    {typeof formik?.errors?.department === 'string' && formik?.errors?.department}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className=' d-flex align-items-center justify-content-end'>
            <button className='btn btn-light-primary   main-btn-style me-2' onClick={handleClose}>
              Close
            </button>

            <button
              type='submit'
              onClick={formikSubmit}
              className='btn btn-primary main-btn-style'
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            >
              {isAdding || isUpdating ? (
                <span className='indicator-progress d-block'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <span className='indicator-label'>
                  {props.data?.id ? 'Update Department' : 'Add Department'}
                </span>
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default DepartmentsModel
