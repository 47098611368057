import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { resetPassword, verifyEmail } from '../Core/Requests'
import { toast } from 'react-toastify'
import { passwordRegex } from 'config'
import * as authHelper from '../Core/AuthHelpers'
import * as Yup from 'yup'
import clsx from 'clsx'

const initialValues = {
  newPassword: '',
  passwordConfirmation: '',
}

const changePasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'New password must be at least 8 characters')
    .max(50, 'New password must not exceed 50 characters')
    .matches(
      passwordRegex,
      'New password must include at least one uppercase letter, one lowercase letter, one number, and one symbol'
    )
    .required('New password is required'),
  passwordConfirmation: Yup.string()
    .min(8, 'Confir new password must be at least 8 characters')
    .max(50, 'Confirm new password must not exceed 50 characters')
    .matches(
      passwordRegex,
      'Confirm new password must include at least one uppercase letter, one lowercase letter, one number, and one symbol'
    )
    .required('Confirm new password is required')
    .oneOf([Yup.ref('newPassword')], 'Confirm new passwords must match'),
})

const CreatePassword = () => {
  const { token } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [showPassword, setShowPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState(false)
  const [disable, setDisable] = useState(false)

  const emailVerification = async (token: string | undefined) => {
    try {
      const response: any = await verifyEmail(token)
      if (response?.data?.success) {
        toast.success(response?.data?.message)
        authHelper.setAuth(response?.data?.token)
        return true
      } else {
        toast.error(response?.error?.data?.message)
        return false
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
      return false
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordValidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)

      if (token) {
        const emailVerifiedToken = await emailVerification(token)
        const { passwordConfirmation, newPassword } = values
        if (emailVerifiedToken) {
          const flag = 1
          resetPassword(newPassword, passwordConfirmation, token, flag)
            .then(({ data: { result } }) => {
              setHasErrors(false)
              setLoading(false)
              navigate('/auth')
            })
            .catch((err: any) => {
              toast.error(err?.response?.data?.message)
              setHasErrors(true)
              setLoading(false)
              setDisable(true)
              setSubmitting(false)
            })
        } else {
          setDisable(true)
          setHasErrors(true)
          setLoading(false)
        }
      } else {
        setStatus('Something went wrong')
      }
    },
  })

  const handleInputChange = (e) => {
    formik.handleChange(e)
    setDisable(false)
  }

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Create Password</h1>
        </div>
        <div className='fv-row mb-4'>
          <label className='form-label fw-bolder text-gray-900 fs-6 required'>New Password</label>
          <div className='d-flex position-relative'>
            <div className='w-100'>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder='New Password'
                autoComplete='off'
                {...formik.getFieldProps('newPassword')}
                className={clsx(
                  'form-control form-control-solid',
                  { 'is-invalid': formik.touched.newPassword && formik.errors.newPassword },
                  {
                    'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                  }
                )}
                onChange={handleInputChange}
              />
              <i
                onClick={() => setShowPassword((prevState) => !prevState)}
                className={
                  showPassword
                    ? 'bi bi-eye-slash fs-5' + ' login-eye'
                    : 'bi bi-eye fs-5' + ' login-eye'
                }
              />
            </div>
          </div>
          {formik.touched.newPassword && formik.errors.newPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.newPassword}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10 '>
          <label className='form-label fw-bolder text-gray-900 fs-6 required'>
            Confirm New Password
          </label>
          <div className='d-flex position-relative'>
            <div className='w-100'>
              <input
                type={confirmPassword ? 'text' : 'password'}
                placeholder='Confirm New Password'
                autoComplete='off'
                {...formik.getFieldProps('passwordConfirmation')}
                className={clsx(
                  'form-control  form-control-solid',
                  {
                    'is-invalid':
                      formik.touched.passwordConfirmation && formik.errors.passwordConfirmation,
                  },
                  {
                    'is-valid':
                      formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
                  }
                )}
                onChange={handleInputChange}
              />
              <i
                onClick={() => setConfirmPassword((prevState) => !prevState)}
                className={
                  confirmPassword
                    ? 'bi bi-eye-slash fs-5' + ' login-eye'
                    : 'bi bi-eye fs-5' + ' login-eye'
                }
              />
            </div>
          </div>
          {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.passwordConfirmation}</span>
              </div>
            </div>
          )}
        </div>
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary w-100 mb-sm-4 mb-3'
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || disable}
          >
            {loading ? (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <span className='indicator-label'>Submit</span>
            )}
          </button>
          <Link to='/auth/login' className='btn btn-lg btn-light-primary main-btn-style w-100'>
            Cancel
          </Link>{' '}
        </div>
      </form>
    </>
  )
}

export default CreatePassword
