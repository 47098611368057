import { createApi } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { customFetchBaseQuery } from './Headers/CustomHeaders'
import { toast } from 'react-toastify'

export const leaseApi = createApi({
  reducerPath: 'leaseApi',
  baseQuery: customFetchBaseQuery({ path: '' }),
  tagTypes: ['Lease'],
  endpoints: (build) => ({
    addLease: build.mutation<any, any>({
      query: (data) => ({
        url: `lease`,
        method: 'POST',
        body: data,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(assetsApi.util.invalidateTags(['Assets']))
          })
          .catch((error) => {
            toast.error(error?.messages)
          })
      },
      invalidatesTags: () => [{ type: 'Lease' }],
    }),
  }),
})

export const { useAddLeaseMutation } = leaseApi
