import { Editor } from '@tinymce/tinymce-react'
import Modal from 'react-bootstrap/Modal'
import EmailTo from './EmailTo'
import EmailSubject from './EmailSubject'
import EmailFrequency from './EmailFrequency'
import EmailTime from './EmailTime'
import StopEmails from './StopEmails'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const SetupEmailModal = (props: any) => {
  const { frequencyAllDays } = props
  const { stopEmailPara_1 } = props

  const handleClose = () => {
    props.handleClose()
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      handleClose()
    }
  }

  return (
    <div>
      <>
        <Modal
          className='custom-modal-lg px-2'
          show={props.show}
          onHide={props.handleCancel}
          backdrop='static'
          size='lg'
          onKeyDown={handleKeyDown}
        >
          <Modal.Header closeButton>
            <Modal.Title>Asset Past Due Alerts</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EmailTo />
            <EmailSubject />
            <Row className='mb-6'>
              <Col lg="2">
                <div className='col-form-label fw-bold fs-6'>Email Body</div>
              </Col>
              <Col lg="10">
                <div className='fv-row'>
                  <Editor />
                </div>
              </Col>
            </Row>
            <EmailFrequency frequencyAllDays={frequencyAllDays} />
            <EmailTime />
            <StopEmails stopEmailPara_1={stopEmailPara_1} />
          </Modal.Body>
        </Modal>
      </>
    </div>
  )
}

export default SetupEmailModal
