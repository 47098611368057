import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

interface NewAuditModalProps {
  editEventModal: boolean
  handleClose: () => void
}

const EditEventModal: React.FC<NewAuditModalProps> = ({ editEventModal, handleClose }) => {
  return (
    <Modal show={editEventModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Dispose Asset</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-3' controlId='formDate'>
            <Form.Label className='required-label'>Date Disposed</Form.Label>
            <InputGroup>
              <Form.Control type='date' />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formTextArea'>
            <Form.Label>Dispose to</Form.Label>
            <Form.Control type='text' />
          </Form.Group>
          <Form.Group className='mb-3' controlId='formTextArea'>
            <Form.Label>Notes</Form.Label>
            <Form.Control as='textarea' rows={3} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={handleClose}>
          Update
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditEventModal
