import { useEffect, useState } from 'react'

function AssetsTab({ actionChecked, checked, handleCheckboxChange }) {
  const [assetActions, setAssetActions] = useState(false)

  const onClickAssetActions = () => {
    const newAssetActionsState = !assetActions
    setAssetActions(newAssetActionsState)
    handleCheckboxChange('hasCheckout', newAssetActionsState)
    handleCheckboxChange('hasCheckin', newAssetActionsState)
    handleCheckboxChange('hasLease', newAssetActionsState)
    handleCheckboxChange('hasLeaseReturn', newAssetActionsState)
    handleCheckboxChange('hasMove', newAssetActionsState)
    handleCheckboxChange('hasMaintenance', newAssetActionsState)
    handleCheckboxChange('hasDispose', newAssetActionsState)
    handleCheckboxChange('hasReplicate', newAssetActionsState)
    handleCheckboxChange('hasEmail', newAssetActionsState)
    handleCheckboxChange('hasLost', newAssetActionsState)
    handleCheckboxChange('hasSell', newAssetActionsState)
    handleCheckboxChange('hasDonate', newAssetActionsState)
    handleCheckboxChange('hasBroken', newAssetActionsState)
    handleCheckboxChange('hasFound', newAssetActionsState)
    handleCheckboxChange('hasRepair', newAssetActionsState)
    handleCheckboxChange('hasUndoSell', newAssetActionsState)
    handleCheckboxChange('hasUndoBroken', newAssetActionsState)
    handleCheckboxChange('hasUndoDonate', newAssetActionsState)
  }
  useEffect(() => {
    if (
      checked.hasCheckout &&
      checked.hasCheckin &&
      checked.hasLease &&
      checked.hasLeaseReturn &&
      checked.hasMove &&
      checked.hasMaintenance &&
      checked.hasDispose &&
      checked.hasReplicate &&
      checked.hasEmail &&
      checked.hasLost &&
      checked.hasSell &&
      checked.hasDonate &&
      checked.hasBroken &&
      checked.hasFound &&
      checked.hasRepair &&
      checked.hasUndoSell &&
      checked.hasUndoBroken &&
      checked.hasUndoDonate
    ) {
      setAssetActions(true)
    } else {
      setAssetActions(false)
    }
  }, [checked])
  return (
    <>
      <div className='mb-5'>
        <h4 className='mb-3'>View and Edit Assets</h4>
        <p className='mb-5'>Set user's permission to add / edit / delete / view assets:</p>
        <div className='table-responsive mb-5 pb-4'>
          <table className='table table-bordered mb-4 dataTable'>
            <thead className='thead-light  light-yellow-table'>
              <tr>
                <th className='w-14 align-middle'>
                  <label className='radio mb-0 d-inline-flex' htmlFor='viewAssets'>
                    <div>
                      <input
                        type='checkbox'
                        className='form-check-input custom-form-check-input sig_input me-2'
                        checked={checked?.viewAsset ?? true}
                        id='viewAssets'
                        name='viewAssets'
                        onChange={(e) => handleCheckboxChange('viewAsset', e.target.checked)}
                        value='True'
                      />
                    </div>
                    <span className='vertically_align'>View Only</span>
                  </label>
                </th>
                <th className='w-14 align-middle'>
                  <label className='radio mb-0 d-inline-flex' htmlFor='hasReserve'>
                    <div>
                      <input
                        type='checkbox'
                        className='form-check-input custom-form-check-input sig_input me-2'
                        checked={checked?.hasReserve}
                        id='hasReserve'
                        name='hasReserve'
                        onChange={(e) => handleCheckboxChange('hasReserve', e.target.checked)}
                        value='True'
                      />
                    </div>
                    <span className='vertically_align'>Reserve</span>
                  </label>
                </th>
                <th className='w-14 align-middle'>
                  <label className='radio mb-0 d-inline-flex' htmlFor='addAsset'>
                    <div>
                      <input
                        type='checkbox'
                        className='form-check-input custom-form-check-input sig_input me-2'
                        checked={checked?.addAsset ?? true}
                        id='addAsset'
                        name='hasCheckOut'
                        onChange={(e) => handleCheckboxChange('addAsset', e.target.checked)}
                        value='True'
                      />
                    </div>
                    <span className='vertically_align'>Add Assets</span>
                  </label>
                </th>
                <th className='w-14 align-middle'>
                  <label className='radio mb-0 d-inline-flex' htmlFor='updateAsset'>
                    <div>
                      <input
                        type='checkbox'
                        className='form-check-input custom-form-check-input sig_input me-2'
                        checked={checked?.updateAsset}
                        id='updateAsset'
                        name='hasCheckOut'
                        onChange={(e) => handleCheckboxChange('updateAsset', e.target.checked)}
                        value='True'
                      />
                    </div>
                    <span className='vertically_align'>Edit Assets</span>
                  </label>
                </th>
                <th className='w-14 align-middle'>
                  <label className='radio mb-0 d-inline-flex' htmlFor='deleteAsset'>
                    <div>
                      <input
                        type='checkbox'
                        className='form-check-input custom-form-check-input sig_input me-2'
                        checked={checked?.deleteAsset}
                        id='deleteAsset'
                        name='hasCheckOut'
                        onChange={(e) => handleCheckboxChange('deleteAsset', e.target.checked)}
                        value='True'
                      />
                    </div>
                    <span className='vertically_align'>Delete Assets</span>
                  </label>
                </th>
                <th className='w-14 align-middle'>
                  <label className='radio mb-0 d-inline-flex' htmlFor='importAsset'>
                    <div>
                      <input
                        type='checkbox'
                        className='form-check-input custom-form-check-input sig_input me-2'
                        checked={checked?.importAsset}
                        id='importAsset'
                        name='hasCheckOut'
                        onChange={(e) => handleCheckboxChange('importAsset', e.target.checked)}
                        value='True'
                      />
                    </div>
                    <span className='vertically_align'>Import Assets</span>
                  </label>
                </th>
                <th className='w-14 align-middle'>
                  <label className='radio mb-0 d-inline-flex' htmlFor='exportAsset'>
                    <div>
                      <input
                        type='checkbox'
                        className='form-check-input custom-form-check-input sig_input me-2'
                        checked={checked?.exportAsset}
                        id='exportAsset'
                        name='hasCheckOut'
                        onChange={(e) => handleCheckboxChange('exportAsset', e.target.checked)}
                        value='True'
                      />
                    </div>
                    <span className='vertically_align'>Export Assets</span>
                  </label>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    <p>User can only view assets.</p>
                    <label className='radio mb-0 d-inline-flex mb-3' htmlFor='hidePriceInfo'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          checked={checked?.hidePriceInfo}
                          id='hidePriceInfo'
                          name='hidePriceInfo'
                          onChange={(e) => handleCheckboxChange('hidePriceInfo', e.target.checked)}
                          value='True'
                        />
                      </div>
                      <span className='vertically_align'>Hide Price info.</span>
                    </label>
                    <p>
                      (This option will also curtail any Editing priviledges allowed elsewhere.)
                    </p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>User can reserve assets.</p>
                    <label className='radio mb-0 d-inline-flex mb-3' htmlFor='overRideReserve'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          checked={checked?.hasReserve}
                          id='overRideReserve'
                          name='overRideReserve'
                          onChange={(e) => handleCheckboxChange('updateReserve', e.target.checked)}
                          value='True'
                        />
                      </div>
                      <span className='vertically_align'>Override Reservation</span>
                    </label>
                    <p>(User can delete or override existing reservation with new reservation.)</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>
                      User can view and create new assets. User can edit or delete{' '}
                      <strong>self-created</strong> assets.
                    </p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>
                      User can view and edit <strong>ANY</strong> asset.
                    </p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>
                      User can view and delete <strong>ANY</strong> asset.
                    </p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>
                      User can <strong>Import</strong> asset.
                    </p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>
                      User can <strong>Export</strong> asset.
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='mb-5'>
        <div className='mb-5'>
          <h4 className='mb-3'>Actions on Assets</h4>
          <p className='mb-5'>User can perform these actions on assets:</p>
          <div className='table-responsive mb-5 pb-4'>
            <table className='table table-bordered mb-4 dataTable'>
              <thead className='thead-light  light-yellow-table'>
                <tr>
                  <th colSpan={5}>
                    <label className='radio mb-0 d-inline-flex' htmlFor='allActions'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='allActions'
                          name='allActions'
                          checked={assetActions}
                          onClick={onClickAssetActions}
                        />
                      </div>
                      <span className='vertically_align'>All Actions</span>
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='checkout'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='checkout'
                          name='hasCheckOut'
                          value='True'
                          checked={checked?.hasCheckout}
                          onChange={(e) => handleCheckboxChange('hasCheckout', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Check Out</span>
                    </label>
                  </td>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='checkIn'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='checkIn'
                          name='hasCheckIn'
                          value='True'
                          checked={checked?.hasCheckin}
                          onChange={(e) => handleCheckboxChange('hasCheckin', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Check In</span>
                    </label>
                  </td>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='lease'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='lease'
                          name='hasLease'
                          value='True'
                          checked={checked?.hasLease}
                          onChange={(e) => handleCheckboxChange('hasLease', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Lease</span>
                    </label>
                  </td>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='leaseReturn'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='leaseReturn'
                          name='hasLeaseReturn'
                          value='True'
                          checked={checked?.hasLeaseReturn}
                          onChange={(e) => handleCheckboxChange('hasLeaseReturn', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Lease Return</span>
                    </label>
                  </td>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='hasDispoe'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='hasDispoe'
                          name='hasDispoe'
                          value='True'
                          checked={checked?.hasDispose}
                          onChange={(e) => handleCheckboxChange('hasDispose', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Dispose</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='move'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='move'
                          name='hasMove'
                          value='True'
                          checked={actionChecked.hasMove || checked?.hasMove}
                          onChange={(e) => handleCheckboxChange('hasMove', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Move</span>
                    </label>
                  </td>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='maintenance'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='maintenance'
                          name='maintenance'
                          value='True'
                          checked={checked?.hasMaintenance}
                          onChange={(e) => handleCheckboxChange('hasMaintenance', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Maintenance</span>
                    </label>
                  </td>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='lost'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='lost'
                          name='hasLost'
                          checked={checked?.hasLost}
                          onChange={(e) => handleCheckboxChange('hasLost', e.target.checked)}
                          value='True'
                        />
                      </div>
                      <span className='vertically_align'>Lost</span>
                    </label>
                  </td>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='sell'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='sell'
                          name='hasSell'
                          value='True'
                          checked={checked?.hasSell}
                          onChange={(e) => handleCheckboxChange('hasSell', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Sell</span>
                    </label>
                  </td>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='broken'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='broken'
                          name='hasBroken'
                          value='True'
                          checked={checked?.hasBroken}
                          onChange={(e) => handleCheckboxChange('hasBroken', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Broken</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='replicate'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='replicate'
                          name='hasReplicate'
                          value='True'
                          checked={actionChecked.hasReplicate || checked?.hasReplicate}
                          onChange={(e) => handleCheckboxChange('hasReplicate', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Replicate</span>
                    </label>
                  </td>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='donate'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='donate'
                          name='hasDonate'
                          value='True'
                          checked={checked?.hasDonate}
                          onChange={(e) => handleCheckboxChange('hasDonate', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Donate</span>
                    </label>
                  </td>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='repair'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='repair'
                          name='hasRepair'
                          checked={checked?.hasRepair}
                          onChange={(e) => handleCheckboxChange('hasRepair', e.target.checked)}
                          value='True'
                        />
                      </div>
                      <span className='vertically_align'>Repair</span>
                    </label>
                  </td>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='found'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='found'
                          name='hasFound'
                          value='True'
                          checked={checked?.hasFound}
                          onChange={(e) => handleCheckboxChange('hasFound', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Found</span>
                    </label>
                  </td>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='undoSell'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='undoSell'
                          name='hasUndoSell'
                          value='True'
                          checked={checked?.hasUndoSell}
                          onChange={(e) => handleCheckboxChange('hasUndoSell', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Undo Sell</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='undoBroken'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='undoBroken'
                          name='hasUndoBroken'
                          value='True'
                          checked={checked?.hasUndoBroken}
                          onChange={(e) => handleCheckboxChange('hasUndoBroken', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Undo Broken</span>
                    </label>
                  </td>
                  <td>
                    <label className='radio mb-0 d-inline-flex' htmlFor='undoDonate'>
                      <div>
                        <input
                          type='checkbox'
                          className='form-check-input custom-form-check-input sig_input me-2'
                          id='undoDonate'
                          name='undoDonate'
                          value='True'
                          checked={checked?.hasUndoDonate}
                          onChange={(e) => handleCheckboxChange('hasUndoDonate', e.target.checked)}
                        />
                      </div>
                      <span className='vertically_align'>Undo Donate</span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default AssetsTab
