import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function EmailTo() {
  return (
    <div>
      <Row className='mb-6'>
        <Col lg="2">
          <div className='col-form-label fw-bold fs-6'>To Emails</div>
        </Col>
        <Col lg="10">
          <div className='fv-row'>
            <p className='mb-1 text-muted'>
              {' '}
              <i className='la la-info-circle fs-5 text-muted'></i> List the email addresses that you
              wish to send alerts to.
            </p>
            <input type='email' className='form-control form-control-solid' name='emailTo' />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default EmailTo
