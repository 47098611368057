import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getCurrentDate } from 'config'
import { useUpdateAssetEventMutation } from 'App/Modules/Services/Assets'
import { useDispatch } from 'react-redux'
import { refresh } from 'App/Store'
import { useNavigate } from 'react-router-dom'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

interface NewAuditModalProps {
  editLostEventModal: boolean
  handleClose: () => void
  assetId: any
  event: any
}

const validationSchema = Yup.object().shape({
  lostDate: Yup.string().required('Date Lost is required.').nullable(),
  notes: Yup.string().max(250, 'Note should not exceed 250 characters'),
})

const EditLostEventModal: React.FC<NewAuditModalProps> = ({
  editLostEventModal,
  handleClose,
  assetId,
  event,
}) => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const [updateAssetEvent, { isLoading }] = useUpdateAssetEventMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentDate = getCurrentDate()

  const formik = useFormik({
    initialValues: {
      lostDate: event?.lost?.lostDate
        ? new Date(event.lost.lostDate).toISOString().slice(0, 10)
        : currentDate,
      notes: event?.lost?.notes || '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        const payload = {
          eventId: event?.id,
          data: {
            lostDate: values.lostDate,
            notes: values.notes,
          },
        }
        const result: any = await updateAssetEvent(payload)
        if (result?.data?.success) {
          dispatch(refresh(true))
          toast.success(result.data.message)
          handleClose()
          navigate(`/asset/view/${assetId}`)
        } else {
          toast.error('An error occurred while updating the status.')
          checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
        }
      } catch (error: any) {
        toast.error(error?.data?.message)
      }
    },
  })

  return (
    <Modal show={editLostEventModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Lost / Missing Asset</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='formDate'>
            <Form.Label className='required-label'>Date Lost</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                name='lostDate'
                max={currentDate}
                value={formik.values.lostDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.lostDate && formik.touched.lostDate}
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
            {formik.errors.lostDate && formik.touched.lostDate && (
              <div className='text-danger'>{formik.errors.lostDate}</div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='formTextArea'>
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as='textarea'
              name='notes'
              rows={3}
              value={formik.values.notes}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.notes && formik.errors.notes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{String(formik.errors.notes)}</div>
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn cancel-btn border' onClick={handleClose}>
            Cancel
          </button>
          <button type='submit' className='btn btn-primary' disabled={isLoading || !formik.isValid}>
            Update
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditLostEventModal
