import { IProfileDetails } from 'App/Modules/Accounts/Components/Settings/SettingsModel'
import axios from 'axios'
import { setupAxios } from './AuthHelpers'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify-token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/signup`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const REQUEST_CHANGE_PASSWORD_URL = `${API_URL}/auth/change-password`
export const REQUEST_RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const REQUERT_LOGOUT_URL = `${API_URL}/auth/logout`
export const USER_VIEW_PROFILE = `${API_URL}/user`
export const USER_UPDATE_PROFILE = `${API_URL}/user`
export const CONTACT_US_URL = `${API_URL}/auth/contact-us`

export function login(email: string, password: string, otp?: string) {
  return axios.post<any>(LOGIN_URL, {
    email,
    password,
    otp,
  })
}

export function register(
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  agreedToTerms: boolean
) {
  return axios.post(REGISTER_URL, {
    email,
    firstName,
    lastName,
    password,
    agreedToTerms,
  })
}

export function conatactUs(name: string, email: string, message: string) {
  return axios.post(CONTACT_US_URL, {
    email,
    name,
    message,
  })
}

export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function resetPassword(
  newPassword: string,
  passwordConfirmation: string,
  verifyToken: string | null,
  flag: number | null
) {
  return axios.post<any>(REQUEST_RESET_PASSWORD_URL, {
    newPassword,
    passwordConfirmation,
    verifyToken,
    flag,
  })
}

export function getUserByToken(token: string) {
  return axios.post<any>(GET_USER_BY_ACCESSTOKEN_URL, {
    token,
  })
}

export function UpdateProfile(values: IProfileDetails) {
  const formData = new FormData()
  for (const key of Object.keys(values)) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      if (values) {
        const value = values[key as keyof IProfileDetails]
        formData.append(key, value)
      }
    }
  }
  return axios.put(`${USER_UPDATE_PROFILE}/profile`, formData)
}

export function verifyEmail(token: string | undefined) {
  return axios.get(`${API_URL}/auth/verify-email/${token}`)
}

export function resendEmail(email: string) {
  return axios.post(`${API_URL}/auth/resend-email`, { email })
}

export function googleLogin() {
  return axios.get(`${API_URL}/auth/google`)
}

export function changePassword(
  currentPassword: string,
  newPassword: string,
  passwordConfirmation: string
) {
  return axios.patch<any>(REQUEST_CHANGE_PASSWORD_URL, {
    currentPassword,
    newPassword,
    passwordConfirmation,
  })
}

export function logoutUser(token: string) {
  setupAxios(axios)
  return axios.post<any>(REQUERT_LOGOUT_URL, { token })
}
