import { useEffect, useState } from 'react'
import { useGetProfileQuery } from 'App/Modules/Services/Profile'
import { PageTitle } from 'Template/layout/core'
import ManageGroup from './ManageGroup'
import { AddSubUser } from 'App/Modules/Accounts/Components/Settings/Cards/AddSubUser'
import { useFormik } from 'formik'
import { IProfileDetails } from './SettingsModel'
import * as Yup from 'yup'
import { useAddSubUserMutation } from 'App/Modules/Services/SubUser'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { nameRegex, titleRegex } from 'config'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

const addSubUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(3, 'First name must be at least 3 characters')
    .max(20, 'First name should not exceed 20 characters')
    .matches(nameRegex, 'Only letters are allowed')
    .required('First name is required')
    .nullable(),

  lastName: Yup.string()
    .trim()
    .min(3, 'Last name must be at least 3 characters')
    .max(20, 'Last name should not exceed 20 characters')
    .matches(nameRegex, 'Only letters are allowed')
    .required('Last name is required')
    .nullable(),
  title: Yup.string()
    .trim()
    .min(5, 'Title must be at least 5 characters')
    .max(20, 'Title should not exceed 20 characters')
    .matches(titleRegex, 'Only alphabets and special characters are allowed')
    .nullable(),
  phone: Yup.string()
    .matches(
      /^(\+)?[0-9]*$/,
      'Phone number must contain only numeric characters without spaces and may start with a plus sign'
    )
    .test('is-valid-number', 'Phone number must be between 10 and 15 digits', (value) => {
      if (value && value.trim().length > 0) {
        const stringValue = String(value).replace(/\D/g, '')
        return stringValue.length >= 10 && stringValue.length <= 15
      }
      return true
    })
    .nullable(),
  email: Yup.string()
    .email('Invalid email address')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  timezone: Yup.string().required('Time zone is required').nullable(),
  dateFormat: Yup.string().required('Date Format is required').nullable(),
  timeFormat: Yup.string().required('Time Format is required').nullable(),
  groupId: Yup.number().required('Group Name is required'),
})

function AddUsers() {
  const { data, error }: any = useGetProfileQuery()
  const { saveAuth, setCurrentUser } = useAuth()
  const [selectedGroupName, setSelectedGroupName] = useState('')
  const [loading, setLoading] = useState(false)
  const [addSubUser] = useAddSubUserMutation()
  const navigate = useNavigate()

  const handleGroupSelect = (groupId) => {
    setSelectedGroupName(groupId)
  }

  useEffect(() => {
    if (error) checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
  }, [error])

  const formik = useFormik<IProfileDetails>({
    initialValues: {
      image: null,
      firstName: '',
      lastName: '',
      title: '',
      phone: '',
      email: '',
      timezone: '',
      dateFormat: '',
      timeFormat: '',
      groupId: 0,
    },
    validationSchema: addSubUserSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const formData = new FormData()
        for (const key of Object.keys(values)) {
          if (Object.prototype.hasOwnProperty.call(values, key)) {
            const value = values[key as keyof IProfileDetails]
            formData.append(key, value)
          }
        }
        const { data, error }: any = await addSubUser(formData)
        if (data?.success) {
          toast.success(data?.message)
          navigate('/users')
        } else {
          toast.error(error?.data?.message)
          checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
        }
      } catch (error: any) {
        throw new Error(error?.message || 'An error occurred')
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <div>
      <PageTitle breadcrumbs={[]}>Add Users</PageTitle>
      <ManageGroup
        formik={formik}
        onSelectGroup={handleGroupSelect}
        userData={[]}
        selectedGroupName={selectedGroupName}
      />
      <AddSubUser
        loading={loading}
        formik={formik}
        userData={data}
        selectedGroupName={selectedGroupName}
      />
    </div>
  )
}

export default AddUsers
