import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getCurrentDate } from 'config'
import { useUpdateAssetEventMutation } from 'App/Modules/Services/Assets'
import { useDispatch } from 'react-redux'
import { refresh } from 'App/Store'
import { useNavigate } from 'react-router-dom'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

interface NewAuditModalProps {
  editSellEventModal: boolean
  handleClose: () => void
  event: any
  assetId: any
  currencyObject: any
}

const validationSchema = Yup.object().shape({
  saleDate: Yup.string().required('Sale Date is required.'),
  notes: Yup.string().max(250, 'Note should not exceed 250 characters'),
})

const EditSellEventModal: React.FC<NewAuditModalProps> = ({
  editSellEventModal,
  handleClose,
  assetId,
  event,
  currencyObject,
}) => {
  const [updateAssetEvent, { isLoading }] = useUpdateAssetEventMutation()
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentDate = getCurrentDate()

  const formik = useFormik({
    initialValues: {
      saleDate: event?.sell?.saleDate
        ? new Date(event.sell.saleDate).toISOString().slice(0, 10)
        : currentDate,
      soldTo: event.sell.soldTo || '',
      saleAmount: event.sell.saleAmount || '',
      notes: event.sell.notes || '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const processedValues = {
        ...values,
        saleAmount: values.saleAmount || null,
      }
      try {
        const payload = {
          eventId: event?.id,
          data: {
            saleDate: processedValues.saleDate,
            soldTo: processedValues.soldTo,
            saleAmount: processedValues.saleAmount,
            notes: processedValues.notes,
          },
        }
        const result: any = await updateAssetEvent(payload)
        if (result?.data?.success) {
          dispatch(refresh(true))
          toast.success(result.data.message)
          handleClose()
          navigate(`/asset/view/${assetId}`)
        } else {
          toast.error(result?.error?.data.message)
          checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
        }
      } catch (error: any) {
        toast.error('Failed to update asset sell status')
        checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
      }
    },
  })

  const splitName = currencyObject?.name.split(' - ')
  const currencyName = splitName && splitName[1]
  const currencySymbol = splitName && splitName[2]

  return (
    <Modal show={editSellEventModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Sell Asset</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='formDate'>
            <Form.Label className='required-label'>Sale Date</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                name='saleDate'
                max={currentDate}
                value={formik.values.saleDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.saleDate && formik.touched.saleDate} // Highlight the field if there's an error
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
            {formik.errors.saleDate && formik.touched.saleDate && (
              <div className='text-danger'>{formik.errors.saleDate}</div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='formSoldTo'>
            <Form.Label>Sold To</Form.Label>
            <Form.Control
              type='text'
              name='soldTo'
              value={formik.values.soldTo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='formSaleAmount'>
            <Form.Label>Sale Amount</Form.Label>
            <InputGroup>
              <InputGroup.Text className='bg-primary text-light'>{currencySymbol}</InputGroup.Text>
              <Form.Control
                type='text'
                name='saleAmount'
                value={formik.values.saleAmount}
                onChange={(e) => {
                  const value = e.target.value
                  if (/^\d*$/.test(value)) {
                    formik.handleChange(e)
                  }
                }}
                onBlur={formik.handleBlur}
                placeholder={currencyName}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formNotes'>
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              name='notes'
              value={formik.values.notes}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.notes && formik.errors.notes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{String(formik.errors.notes)}</div>
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn cancel-btn border' onClick={handleClose}>
            Cancel
          </button>
          <button type='submit' className='btn btn-primary' disabled={isLoading || !formik.isValid}>
            Update
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditSellEventModal
