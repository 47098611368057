import Lottie from 'react-lottie'
import { Images } from 'Template/assets/Assets'
import { Suspense } from 'react'
import './Benefits.css'

function Benefits() {
  const LayersLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: Images.LayersLottie,
  }
  return (
    <section className='benefits-section' id='benefits'>
      <div className='text text-center mb-xl-5 mb-4'>
        <h2 className='h-main mb-xl-5 mb-4'>Benefits of Asset Rack</h2>
        <p>Optimize your asset management with streamlined tracking, security, and reporting.</p>
      </div>
      <div className='benefits-listing pt-5'>
        <div className='custom-container container'>
          <div className='row'>
            <div className='col-xl-3 col-lg-4 col-sm-6'>
              <div className='box d-flex justify-content-center align-items-center flex-column position-relative'>
                <div className='layers-lottie'>
                  <Suspense fallback={<>Loading....</>}>
                    <Lottie options={LayersLottieOptions} />
                  </Suspense>
                </div>
                <div className='icon mb-xl-5 mb-4'>
                  <img
                    src={Images.Benefit_1}
                    alt='icon'
                    width='100%'
                    height='100%'
                    loading='lazy'
                  />
                </div>
                <strong className='name mb-3 d-block'>Assets</strong>
                <p className='description mb-0'>Easily track and manage all your business and personal assets.</p>
              </div>
            </div>
            <div className='col-xl-3 col-lg-4 col-sm-6'>
              <div className='box d-flex justify-content-center align-items-center flex-column position-relative'>
                <div className='layers-lottie'>
                  <Lottie options={LayersLottieOptions} />
                </div>
                <div className='icon mb-xl-5 mb-4'>
                  <img
                    src={Images.Benefit_2}
                    alt='icon'
                    width='100%'
                    height='100%'
                    loading='lazy'
                  />
                </div>
                <strong className='name mb-3 d-block'>Contracts and Licenses</strong>
                <p className='description mb-0'>
                  Monitor expiration dates and renewals for all contracts and licenses.
                </p>
              </div>
            </div>
            <div className='col-xl-3 col-lg-4 col-sm-6'>
              <div className='box d-flex justify-content-center align-items-center flex-column position-relative'>
                <div className='layers-lottie'>
                  <Lottie options={LayersLottieOptions} />
                </div>
                <div className='icon mb-xl-5 mb-4'>
                  <img
                    src={Images.Benefit_3}
                    alt='icon'
                    width='100%'
                    height='100%'
                    loading='lazy'
                  />
                </div>
                <strong className='name mb-3 d-block'>Reports</strong>
                <p className='description mb-0'>
                  Generate detailed, customizable reports to gain insights into your assets.
                </p>
              </div>
            </div>
            <div className='col-xl-3 col-lg-4 col-sm-6'>
              <div className='box d-flex justify-content-center align-items-center flex-column position-relative'>
                <div className='layers-lottie'>
                  <Lottie options={LayersLottieOptions} />
                </div>
                <div className='icon mb-xl-5 mb-4'>
                  <img
                    src={Images.Benefit_4}
                    alt='icon'
                    width='100%'
                    height='100%'
                    loading='lazy'
                  />
                </div>
                <strong className='name mb-3 d-block'>Security</strong>
                <p className='description mb-0'>
                  Secure data storage with robust protection and privacy measures.
                </p>
              </div>
            </div>
            <div className='col-xl-3 col-lg-4 col-sm-6'>
              <div className='box d-flex justify-content-center align-items-center flex-column position-relative'>
                <div className='layers-lottie'>
                  <Suspense fallback={<>Loading...</>}>
                    <Lottie options={LayersLottieOptions} />
                  </Suspense>
                </div>
                <div className='icon mb-xl-5 mb-4'>
                  <img
                    src={Images.Benefit_5}
                    alt='icon'
                    width='100%'
                    height='100%'
                    loading='lazy'
                  />
                </div>
                <strong className='name mb-3 d-block'>Mobile</strong>
                <p className='description mb-0'>
                  Access and manage your assets on-the-go with our mobile-friendly interface.
                </p>
              </div>
            </div>
            <div className='col-xl-3 col-lg-4 col-sm-6'>
              <div className='box d-flex justify-content-center align-items-center flex-column position-relative'>
                <div className='layers-lottie'>
                  <Lottie options={LayersLottieOptions} />
                </div>
                <div className='icon mb-xl-5 mb-4'>
                  <img
                    src={Images.Benefit_6}
                    alt='icon'
                    width='100%'
                    height='100%'
                    loading='lazy'
                  />
                </div>
                <strong className='name mb-3 d-block'>Innovation</strong>
                <p className='description mb-0'>
                  Stay ahead with continuous updates and innovative features.
                </p>
              </div>
            </div>
            <div className='col-xl-3 col-lg-4 col-sm-6'>
              <div className='box d-flex justify-content-center align-items-center flex-column position-relative'>
                <div className='layers-lottie'>
                  <Lottie options={LayersLottieOptions} />
                </div>
                <div className='icon mb-xl-5 mb-4'>
                  <img
                    src={Images.Benefit_7}
                    alt='icon'
                    width='100%'
                    height='100%'
                    loading='lazy'
                  />
                </div>
                <strong className='name mb-3 d-block'>Users</strong>
                <p className='description mb-0'>
                  Manage multiple user accounts with customizable access permissions.
                </p>
              </div>
            </div>
            <div className='col-xl-3 col-lg-4 col-sm-6'>
              <div className='box d-flex justify-content-center align-items-center flex-column position-relative'>
                <div className='layers-lottie'>
                  <Lottie options={LayersLottieOptions} />
                </div>
                <div className='icon mb-xl-5 mb-4'>
                  <img
                    src={Images.Benefit_8}
                    alt='icon'
                    width='100%'
                    height='100%'
                    loading='lazy'
                  />
                </div>
                <strong className='name mb-3 d-block'>Reserve & Check Out</strong>
                <p className='description mb-0'>
                  Efficiently reserve and checkout assets with a streamlined process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Benefits
