import { Link } from 'react-router-dom'

interface SecurityHeader {
  deleteDataArr: any
  setDeleteModal: any
  setCheck: any
  permission: any
  staffUser: boolean
}

const SecurityGroupHeader = (props: SecurityHeader) => {
  return (
    <div
      className='card-body d-flex border-bottom border-bottom-gray-200 justify-content-between mb-3 align-items-center flex-wrap'
      data-kt-user-table-toolbar='base'
    >
      <h3 className='card-title mb-0'>
        <span className='me-2 align-middle'>
          <i className='la la-users-cog fs-1' aria-hidden='true'></i>
        </span>
        <span className='card-label fw-bold fs-3 mb-1'>Security Groups</span>
      </h3>
      <div className='d-flex justify-content-between'>
        {!props.permission?.addGroup && props.staffUser ? (
          ''
        ) : (
          <Link to={'/group-manager/create-group'} className='btn btn-light-primary main-btn-style'>
            <i className='la la-plus fs-3' />
            Add <span className='d-xl-inline d-none'>New Groups</span>
          </Link>
        )}
      </div>
    </div>
  )
}

export { SecurityGroupHeader }
