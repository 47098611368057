export type Asset = {
  assetTagId?: string
  brand?: string | null
  categoryId?: number
  departmentId?: number
  description?: string
  locationId?: number
  model?: string | null
  purchasedDate?: string
  purchasedFrom?: string | null
  serialNo?: string | null
  siteId?: number
  cost?: number | null
  status?: string
  photo?: any | null
  ram?: string | null
  storage?: string | null
  imei?: string | null
  ports?: number | null
  mbps?: string | null
  stands?: number | null
  batteries?: number | null
  bulbs?: number | null
  lens?: number | null
  isRestricted?: boolean | null
}

export const initialAssets: Asset = {
  assetTagId: '',
  brand: null,
  categoryId: undefined,
  departmentId: undefined,
  description: '',
  locationId: undefined,
  model: null,
  purchasedDate: undefined,
  purchasedFrom: null,
  serialNo: null,
  siteId: undefined,
  cost: null,
  status: 'Available',
  photo: null,
  ram: null,
  storage: null,
  imei: null,
  ports: null,
  mbps: null,
  stands: null,
  batteries: null,
  bulbs: null,
  lens: null,
  isRestricted: false,
}

export const assetSearchFields = {
  keyword: '',
  searchField: '',
  siteId: '',
  locationId: '',
  categoryId: '',
  departmentId: '',
  person: '',
  customer: '',
  status: '',
  groupBy: '',
  recordCount: '',
  dateRangeBy: 'purchasedDate',
  quickDateRange: '',
  startDate: '',
  endDate: '',
}

export type AssetResponse = {
  id: number
  assetTagId?: string
  brand?: string
  categoryId?: number
  createdAt?: string
  departmentId?: number
  description?: string
  locationId?: number
  model?: string
  purchasedDate?: string
  purchasedFrom?: string
  serialNo?: string
  siteId?: number
  userId?: number
  cost?: number | null
  statusType?: string
  assetstatus?: any
  site?: any
  location?: any
  photo?: any
}

export type Warranty = {
  length?: number
  expiryDate?: string
  notes?: string | undefined
  assetId?: number | null
}

export const initialWarranty: Warranty = {
  length: 1,
  expiryDate: '',
  notes: '',
  assetId: null,
}

export interface ColumnDataProps {
  column: string
  assetsData?: any
  currencyLogo?: any
}
